import React, { useState } from 'react'
import './FinancialPanel.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




export default function Financial({merchant_tlo, warnings, level2TLO, printVersion}){
    
    const [isActive, setIsActive] = useState(['bk', 'liens', 'fi', 'jm']);



    function toggle(button){
        if(isActive.includes(button)){
            setIsActive(isActive.filter(e => e !== button))
        }
        else{
            setIsActive([...isActive, button])
        }
    }
    
    function toggleAll(){
        if(isActive.length < 4){
            setIsActive(['bk', 'liens', 'fi', 'jm'])
        }
        else{
            setIsActive([])
        }
    }


    

    return (
        <div className="box1" >
            <div>
                <h3 className="h3NoTop">Financial Information {warnings !== (null || 0) ? <div className="block-meter rank-worst is-small">{warnings}</div> : ''}
                {
                    (!printVersion) && <button onClick={() => toggleAll()} style={{marginLeft:'auto', marginRight:0, float:'right', transform: 'translate(0%,-15%)'}}>{(isActive.length < 4) ? 'Open All' : 'Close All'}</button>
                }
                </h3>
                    <div className="grid1">
                        <div>
                            <span>
                                <button className="button" onClick={() => toggle('bk')}>{isActive.includes('bk') ? (
                                    <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                ) : (
                                    <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                )}<b>Bankruptcies </b>{!isNaN(parseInt(merchant_tlo.BUS_BANKRUPTCY_SUMMARY_001)) ? <div className="block-meter rank-worst is-small" >{merchant_tlo.BUS_BANKRUPTCY_SUMMARY_001}</div> : ''}</button><br/>
                                {isActive.includes('bk') && (
                                    <>
                                    <span style={{marginLeft:10}}>Number of Bankruptcies:  {merchant_tlo.BUS_BANKRUPTCY_SUMMARY_001 ? merchant_tlo.BUS_BANKRUPTCY_SUMMARY_001 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Bankruptcy Filing Date:  {merchant_tlo.BUS_BANKRUPTCY_SUMMARY_002 ? merchant_tlo.BUS_BANKRUPTCY_SUMMARY_002 : 'Not Found'}</span><br/>
                                    {/* <span style={{marginLeft:10}}>Most Recent Bankruptcy Chapter:  {merchant_tlo.BUS_BANKRUPTCY_SUMMARY_003 ? merchant_tlo.BUS_BANKRUPTCY_SUMMARY_003 : 'Not Found'}</span><br/> */}
                                    {/* <span style={{marginLeft:10}}>Most Recent Bankruptcy Voluntary:  {merchant_tlo.BUS_BANKRUPTCY_SUMMARY_004 ? merchant_tlo.BUS_BANKRUPTCY_SUMMARY_004 : 'Not Found'}</span><br/> */}
                                    <span style={{marginLeft:10}}>Most Recent Bankruptcy Status:  {merchant_tlo.BUS_BANKRUPTCY_SUMMARY_005 ? merchant_tlo.BUS_BANKRUPTCY_SUMMARY_005 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Bankruptcy Status Date:  {merchant_tlo.BUS_BANKRUPTCY_SUMMARY_006 ? merchant_tlo.BUS_BANKRUPTCY_SUMMARY_006 : 'Not Found'}</span><br/>

                                    </>
                                )}
                            </span>
                        </div>
                        <div>
                        
                            <span>
                                <button className="button" onClick={() => toggle('fi')}>{isActive.includes('fi') ? (
                                    <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                ) : (
                                    <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                )}<b>UCC Filings</b>{level2TLO.ucc.length > 0 ? <div className="block-meter rank-worst is-small" style={{marginLeft:'2px'}}>{level2TLO.ucc.length}</div> : ''}</button><br/>
                                {isActive.includes('fi') && (
                                    <>
                                    <span style={{marginLeft:10}}>Most Recent UCC Filing Date:  {merchant_tlo.UCC_SUMMARY_001 ? merchant_tlo.UCC_SUMMARY_001 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent UCC Filing Type:  {merchant_tlo.UCC_SUMMARY_002 ? merchant_tlo.UCC_SUMMARY_002 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent UCC Filing Expiration:  {merchant_tlo.UCC_SUMMARY_003 ? merchant_tlo.UCC_SUMMARY_003 : 'Not Found'}</span><br/>
                                    </>
                                )}
                            </span>
                        </div>
                        <div>
                            <span>
                                <button className="button" onClick={() => toggle('jm')}>{isActive.includes('jm') ? (
                                    <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                ) : (
                                    <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                )}<b>Judgements </b>{!isNaN(parseInt(merchant_tlo.BUS_JUDGEMENT_SUMMARY_001)) ? <div className="block-meter rank-worst is-small">{merchant_tlo.BUS_JUDGEMENT_SUMMARY_001}</div> : ''}</button><br/>
                                {isActive.includes('jm') && (
                                    <>
                                    <span style={{marginLeft:10}}>Number of Judgement Filings:  {merchant_tlo.BUS_JUDGEMENT_SUMMARY_001 ? merchant_tlo.BUS_JUDGEMENT_SUMMARY_001 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Judgement Filing Date:  {merchant_tlo.BUS_JUDGEMENT_SUMMARY_002 ? merchant_tlo.BUS_JUDGEMENT_SUMMARY_002 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Judgement Status:  {merchant_tlo.BUS_JUDGEMENT_SUMMARY_003 ? merchant_tlo.BUS_JUDGEMENT_SUMMARY_003 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Judgement Status Date:  {merchant_tlo.BUS_JUDGEMENT_SUMMARY_004 ? merchant_tlo.BUS_JUDGEMENT_SUMMARY_004 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Judgement Amount:  ${merchant_tlo.BUS_JUDGEMENT_SUMMARY_005 ? merchant_tlo.BUS_JUDGEMENT_SUMMARY_005 : '0'}</span><br/>
                                    </>
                                )}
                            </span>
                        </div>
                        <div>
                            <span>
                                <button className="button" onClick={() => toggle('liens')}>{isActive.includes('liens') ? (
                                    <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                ) : (
                                    <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                )}<b>Liens </b>{!isNaN(parseInt(merchant_tlo.BUS_LIENS_SUMMARY_001)) ? <div className="block-meter rank-worst is-small">{merchant_tlo.BUS_LIENS_SUMMARY_001}</div> : ''}</button><br/>
                                {isActive.includes('liens') && (
                                    <>
                                    <span style={{marginLeft:10}}>Number of Business Liens: {merchant_tlo.BUS_LIENS_SUMMARY_001 ? merchant_tlo.BUS_LIENS_SUMMARY_001 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Lien Filing Date:  {merchant_tlo.BUS_LIENS_SUMMARY_002 ? merchant_tlo.BUS_LIENS_SUMMARY_002 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Lien Status:  {merchant_tlo.BUS_LIENS_SUMMARY_003 ? merchant_tlo.BUS_LIENS_SUMMARY_003 : 'Not Found'}</span><br/>
                                    <span style={{marginLeft:10}}>Most Recent Lien Amount:  ${merchant_tlo.BUS_LIENS_SUMMARY_004 ? merchant_tlo.BUS_LIENS_SUMMARY_004 : '0'}</span><br/>
                                    </>
                                )}
                            </span>
                        </div>
                    
                    </div>
                
                {
                /* May uncomment later
                <div className="TLOSummary">
                    <h2>Summary</h2>
                    <span style={{marginLeft:10}}>Bankruptcy Creditor Count: {level2TLO.summary ? level2TLO.summary.bankruptcy_creditor_count : 'Not Found'}</span><br/>
                    <span style={{marginLeft:10}}>Bankruptcy Subject Count:  {level2TLO.summary ? level2TLO.summary.bankruptcy_subject_count : 'Not Found'}</span><br/>
                    <span style={{marginLeft:10}}>Judgement Creditor Count: {level2TLO.summary ? level2TLO.summary.judgement_creditor_count : 'Not Found'}</span><br/>
                    <span style={{marginLeft:10}}>Judgement Debtor Count:  {level2TLO.summary ? level2TLO.summary.judgement_debtor_count : 'Not Found'}</span><br/>
                    <span style={{marginLeft:10}}>Lien Holder Count: {level2TLO.summary ? level2TLO.summary.lien_holder_count : 'Not Found'}</span><br/>
                    <span style={{marginLeft:10}}>Lien Debtor Count:  {level2TLO.summary ? level2TLO.summary.lien_debtor_count : 'Not Found'}</span><br/>

                </div> */}
                {!printVersion && 
                <div className="all-carousel-container">
                    {level2TLO.bankruptcy.length > 0 && 
                    <div className="carousel-div-container">
                        <br></br>
                    <BankruptcyCarousel data={level2TLO.bankruptcy}/>
                    </div>
                    }
                    {level2TLO.judgement.length > 0 && 
                    <div className="carousel-div-container">
                        <br></br>
                    <JudgementCarousel data={level2TLO.judgement}/>
                    </div>
                    }
                    {level2TLO.lien.length > 0 && 
                    <div className="carousel-div-container">
                        <br></br>
                    <LienCarousel data={level2TLO.lien}/>
                    <br></br>
                    </div>
                    }
                    {level2TLO.ucc.length > 0 && 
                    <div className="carousel-div-container">
                                <br></br>
                                {/* <h2>UCC {!isNaN(parseInt(level2TLO.ucc.length)) ? <div className="block-meter rank-worst is-small">{level2TLO.ucc.length}</div> : ''}</h2> */}
                            <UCCCarousel data={level2TLO.ucc}/>
                    </div>
                    }
                </div>
                }
                {printVersion && 
                <>
                <div className="print-version-all-tlo2">
                    {level2TLO.bankruptcy.length > 0 && 
                        <h3>Bankruptcies {!isNaN(parseInt(level2TLO.bankruptcy.length)) ? <div className="block-meter rank-worst is-small">{level2TLO.bankruptcy.length}</div> : ''}</h3>
                    }
                    {level2TLO.bankruptcy.length > 0 && 
                        level2TLO.bankruptcy.map((item, index) => 
                        <div key={index} className="slider-card">
                            <span><b>Debtor Name:</b> {item.debtor_name}</span>
                            <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                            <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                            <span className='rightIndent'><b>Filing Number:</b> {item.filing_number}</span><br></br>
                            <span><b>Debtor City:</b> {item.debtor_city}</span>
                            <span className='rightIndent'><b>Status:</b> {item.status}</span><br></br>
                            <span><b>Debtor State:</b> {item.debtor_state}</span>
                            <span className='rightIndent'><b>Status Date:</b> {item.status_date}</span><br></br>
                            <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                            <span className='rightIndent'><b>Attorney Firm:</b> {item.attorney_firm_name}</span><br></br>
                            <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                            <span className='rightIndent'><b>Attorney First Name:</b> {item.attorney_first_name}</span><br></br>
                            <span><b>Verification Date:</b> {item.verification_date}</span>
                            <span className='rightIndent'><b>Attorney Last Name:</b> {item.attorney_last_name}</span><br></br>
                            <span className='rightIndent'><b>Court Name:</b> {item.court_name}</span><br></br>
                        </div>
                    )}
                    {level2TLO.judgement.length > 0 && 
                        <h3>Judgements {!isNaN(parseInt(level2TLO.judgement.length)) ? <div className="block-meter rank-worst is-small">{level2TLO.judgement.length}</div> : ''}</h3>
                    }
                    {level2TLO.judgement.length > 0 && 
                        level2TLO.judgement.map((item, index) => (
                            <div key={index} className="slider-card">
                                <span><b>Debtor Name:</b> {item.debtor_name}</span>
                                <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                                <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                                <span className='rightIndent'><b>Filing Number:</b> {item.filing_number}</span><br></br>
                                <span><b>Debtor City:</b> {item.debtor_city}</span>
                                <span className='rightIndent'><b>Filing Office Name:</b> {item.filing_office_name}</span><br></br>
                                <span><b>Debtor State:</b> {item.debtor_state}</span>
                                <span className='rightIndent'><b>Status:</b> {item.status}</span><br></br>
                                <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                                <span className='rightIndent'><b>Status Date:</b> {item.status_date}</span><br></br>
                                <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                                <span className='rightIndent'><b>Verification Date:</b> {item.verification_date}</span><br></br>
                                <span><b>Debtor Owed Amount:</b> {item.debtor_owed_amount}</span>
                                <span className='rightIndent'><b>Creditor Name:</b> {item.creditor_name}</span><br></br>
                                <span ><b>Amount Awarded:</b> {item.amount_awarded}</span>
                                <span className='rightIndent'><b>Judgement Type:</b> {item.judgement_type}</span>
                            </div>
                        ))
                    }
                    {level2TLO.lien.length > 0 && 
                        <h3>Lien {!isNaN(parseInt(level2TLO.lien.length)) ? <div className="block-meter rank-worst is-small">{level2TLO.lien.length}</div> : ''}</h3>
                    }
                    {level2TLO.lien.length > 0 && 
                        level2TLO.lien.map((item, index) => (
                            <div key={index} className="slider-card">
                                <span><b>Debtor Name:</b> {item.debtor_name}</span>
                                <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                                <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                                <span className='rightIndent'><b>Filing Number:</b> {item.filing_number}</span><br></br>
                                <span><b>Debtor City:</b> {item.debtor_city}</span>
                                <span className='rightIndent'><b>Filing Office Name:</b> {item.filing_office_name}</span><br></br>
                                <span><b>Debtor State:</b> {item.debtor_state}</span>
                                <span className='rightIndent'><b>Filing Type:</b> {item.filing_type_desc}</span><br></br>
                                <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                                <span className='rightIndent'><b>Status:</b> {item.status}</span><br></br>
                                <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                                <span className='rightIndent'><b>Status Date:</b> {item.status_date}</span><br></br>
                                <span><b>Lien Amount:</b> {item.lien_amount}</span>
                                <span className='rightIndent'><b>Received Date:</b> {item.received_date}</span><br></br>
                                <span className='rightIndent'><b>Holder Name:</b> {item.holder_name}</span><br></br>
                            </div>
                        ))
                    }
                    {level2TLO.ucc.length > 0 && 
                        <h3>UCC Filings {!isNaN(parseInt(level2TLO.ucc.length)) ? <div className="block-meter rank-worst is-small">{level2TLO.ucc.length}</div> : ''}</h3>
                    }
                    {level2TLO.ucc.length > 0 && 
                        level2TLO.ucc.map((item, index) => (
                            <div key={index} className="slider-card">
                                <span><b>Debtor Name:</b>  {item.debtor_name}</span>
                                <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>     
                                <span><b>Debtor Addr:</b>  {item.debtor_addr1}</span>
                                <span className='rightIndent'><b>Filing Type:</b>  {item.filing_type}</span><br></br>
                                <span ><b>Debtor City:</b>  {item.debtor_city}</span>
                                <span className='rightIndent'><b>SEC Party Business Name:</b>  {item.sec_party_business_name}</span><br></br>
                                <span><b>Debtor State:</b>  {item.debtor_state}</span>
                                <span className='rightIndent'><b>SEC Party Addr:</b>  {item.sec_party_addr}</span><br></br>
                                <span><b>Debtor Zip5:</b>  {item.debtor_zip5}</span>
                                <span className='rightIndent'><b>SEC Party City:</b>  {item.sec_party_city}</span><br></br>
                                <span><b>Debtor Zip4:</b>  {item.debtor_zip4}</span>
                                <span className='rightIndent'><b>SEC Party State:</b>  {item.sec_party_state}</span><br></br>
                                <span><b>Expiration Date:</b> {item.expiration_date}</span>
                                <span className='rightIndent'><b>SEC Party Zip5:</b>  {item.sec_party_zip5}</span><br></br>
                                <span className='rightIndent'><b>SEC Party Zip4:</b>  {item.sec_party_zip4}</span><br></br>
                            </div>
                        ))
                    }
                </div>
                </>}
            </div>
        </div>
    )
}

function UCCCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length

    var settings = {
    fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)
    };
    return (
        <div>
        <h3>UCC Filings</h3>
        <br></br>

      <div className="slider-container">
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">
                    <span><b>Debtor Name:</b>  {item.debtor_name}</span>
                    <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>     
                    <span><b>Debtor Addr:</b>  {item.debtor_addr1}</span>
                    <span className='rightIndent'><b>Filing Type:</b>  {item.filing_type}</span><br></br>
                    <span ><b>Debtor City:</b>  {item.debtor_city}</span>
                    <span className='rightIndent'><b>SEC Party Business Name:</b>  {item.sec_party_business_name}</span><br></br>
                    <span><b>Debtor State:</b>  {item.debtor_state}</span>
                    <span className='rightIndent'><b>SEC Party Addr:</b>  {item.sec_party_addr}</span><br></br>
                    <span><b>Debtor Zip5:</b>  {item.debtor_zip5}</span>
                    <span className='rightIndent'><b>SEC Party City:</b>  {item.sec_party_city}</span><br></br>
                    <span><b>Debtor Zip4:</b>  {item.debtor_zip4}</span>
                    <span className='rightIndent'><b>SEC Party State:</b>  {item.sec_party_state}</span><br></br>
                    <span><b>Expiration Date:</b> {item.expiration_date}</span>
                    <span className='rightIndent'><b>SEC Party Zip5:</b>  {item.sec_party_zip5}</span><br></br>
                    <span className='rightIndent'><b>SEC Party Zip4:</b>  {item.sec_party_zip4}</span><br></br>
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>

      </div>
      </div>
    )
}

function BankruptcyCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h3>Bankruptcy Details </h3>
    <br></br>

      <div className="slider-container">
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">
                    <span><b>Debtor Name:</b> {item.debtor_name}</span>
                    <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                    <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                    <span className='rightIndent'><b>Filing Number:</b> {item.filing_number}</span><br></br>
                    <span><b>Debtor City:</b> {item.debtor_city}</span>
                    <span className='rightIndent'><b>Status:</b> {item.status}</span><br></br>
                    <span><b>Debtor State:</b> {item.debtor_state}</span>
                    <span className='rightIndent'><b>Status Date:</b> {item.status_date}</span><br></br>
                    <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                    <span className='rightIndent'><b>Attorney Firm:</b> {item.attorney_firm_name}</span><br></br>
                    <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                    <span className='rightIndent'><b>Attorney First Name:</b> {item.attorney_first_name}</span><br></br>
                    <span><b>Verification Date:</b> {item.verification_date}</span>
                    <span className='rightIndent'><b>Attorney Last Name:</b> {item.attorney_last_name}</span><br></br>
                    <span className='rightIndent'><b>Court Name:</b> {item.court_name}</span><br></br>
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}

function JudgementCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h3>Judgement Details</h3>
    <br></br>
      <div className="slider-container">
        {/* <h3>Judgement</h3> */}
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">

                    <span><b>Debtor Name:</b> {item.debtor_name}</span>
                    <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                    <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                    <span className='rightIndent'><b>Filing Number:</b> {item.filing_number}</span><br></br>
                    <span><b>Debtor City:</b> {item.debtor_city}</span>
                    <span className='rightIndent'><b>Filing Office Name:</b> {item.filing_office_name}</span><br></br>
                    <span><b>Debtor State:</b> {item.debtor_state}</span>
                    <span className='rightIndent'><b>Status:</b> {item.status}</span><br></br>
                    <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                    <span className='rightIndent'><b>Status Date:</b> {item.status_date}</span><br></br>
                    <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                    <span className='rightIndent'><b>Verification Date:</b> {item.verification_date}</span><br></br>
                    <span><b>Debtor Owed Amount:</b> {item.debtor_owed_amount}</span>
                    <span className='rightIndent'><b>Creditor Name:</b> {item.creditor_name}</span><br></br>
                    <span ><b>Amount Awarded:</b> {item.amount_awarded}</span>
                    <span className='rightIndent'><b>Judgement Type:</b> {item.judgement_type}</span>
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}

function LienCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h3>Lien Details</h3>
    <br></br>
      <div className="slider-container">
        {/* <h3>Lien</h3> */}
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">

                    <span><b>Debtor Name:</b> {item.debtor_name}</span>
                    <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                    <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                    <span className='rightIndent'><b>Filing Number:</b> {item.filing_number}</span><br></br>
                    <span><b>Debtor City:</b> {item.debtor_city}</span>
                    <span className='rightIndent'><b>Filing Office Name:</b> {item.filing_office_name}</span><br></br>
                    <span><b>Debtor State:</b> {item.debtor_state}</span>
                    <span className='rightIndent'><b>Filing Type:</b> {item.filing_type_desc}</span><br></br>
                    <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                    <span className='rightIndent'><b>Status:</b> {item.status}</span><br></br>
                    <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                    <span className='rightIndent'><b>Status Date:</b> {item.status_date}</span><br></br>
                    <span><b>Lien Amount:</b> {item.lien_amount}</span>
                    <span className='rightIndent'><b>Received Date:</b> {item.received_date}</span><br></br>
                    <span><b>Holder Name:</b> {item.holder_name}</span>

                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}
