import React, {useState, useEffect} from 'react'
import { useHistory, Link } from 'react-router-dom'

import './InquiryNavigator.scss'


export default function InquiryNavigator({inquiryResults, inquiryId, merchantId }){

    const history = useHistory()
    const [ hideDropdown, setHideDropdown ] = useState(false)
    const [ viewedMerchants, setViewedMerchants ] = useState(new Set())

    // just to provide a toggle switch to collapse the dropdown contents on click
    useEffect(()=> {
       setHideDropdown(false)
    }, [ hideDropdown ])

    useEffect( () => {
        if(!merchantId) setViewedMerchants(new Set())
    }, [merchantId, inquiryId])

    useEffect( () => {
        if(merchantId) setViewedMerchants(viewedMerchants.add(merchantId))
    }, [viewedMerchants, merchantId])


    function backToSearch(){
      history.push('/onboarding')
    }

    function toMerchantRequest(){
        history.push('/merchant-request')
    }

    const showSearchButton = !!inquiryResults

    const showMerchantSelector = inquiryResults && merchantId
    const activeMerchant = showMerchantSelector &&
        inquiryResults.find( ({merchant}) => merchant.id === merchantId )

    const linkResultsCount = showMerchantSelector

    return (
       <div className='inquiry-navigator' style={{/*display: 'none'*/}}>
          { showSearchButton && (
              <div className='inquiry-navigator-content-wrap'>
                 <div>
                    <button onClick={backToSearch}>Back to Search</button>
                 </div>
                 <div>
                     { linkResultsCount ? (
                          <Link to={`/onboarding/${inquiryId}`}>
                              <strong>{inquiryResults.length}&nbsp;</strong>
                              { inquiryResults.length === 1 ? 'Match' : 'Matches' }
                          </Link>
                     ) : (
                         <span>
                             <strong>{inquiryResults.length > 10 ? '20+' : inquiryResults.length}&nbsp;</strong>
                             { inquiryResults.length === 1 ? 'Match' : 'Matches' }
                         </span>
                     )}
                     {/* { !showMerchantSelector && <button className='inquiry-button' onClick={toMerchantRequest}> Request Missing Business </button>} */}
                  { showMerchantSelector && (
                      <div className={`match-selector ${hideDropdown ? 'collapse' : ''}`}>
                          <strong>
                              {(activeMerchant
                                  ? (
                                      <>
                                          { activeMerchant.merchant.name }
                                          <MerchantAddress merchant={activeMerchant.merchant} />
                                      </>
                                    )
                                  : 'Select Match'
                              )}
                          </strong>
                          <ul>
                              { inquiryResults.map( ({merchant}) => (
                                  <li key={merchant.id}>
                                      <Link to={`/onboarding/${inquiryId}/${merchant.id}`}
                                            onClick={()=> setHideDropdown(true)}>
                                          {merchant.name}
                                          <MerchantAddress merchant={merchant} />
                                      </Link>
                                      { viewedMerchants.has(merchant.id) && (
                                          merchantId === merchant.id
                                            ? <span className='viewed-note'>viewing</span>
                                            : <span className='viewed-note'>viewed</span>
                                      )}
                                  </li>
                              )) }
                          </ul>
                      </div>
                  )}
                  </div>
              </div>
          )}
       </div>
    )
}

function MerchantAddress({merchant}){
    return (
      <span className='address'>
          {[merchant.addr1, merchant.addr2, merchant.city, merchant.state].filter(Boolean).join(', ')}
      </span>
    )
}
