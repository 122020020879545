
export default function FormatPercentage({value, places=0, empty=''}){
    if (typeof value === 'number') {
        if (value >= 1) {
            places = Math.min(places, 1)
        }
        return value.toLocaleString("en", {style: "percent", minimumFractionDigits: places})
    }
    else if (typeof value === 'string') {
        return value
    }
    else {
        return empty
    }
}