import React from 'react'
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom'
import OnboardingRoutes from 'pages/onboarding/Routes'
import MerchantRequest from './pages/onboarding/MerchantRequest'
import SettingsPage from 'pages/settings/Settings'
import HealthCheckPage from 'pages/healthcheck/HealthCheckPage'
import PortfolioSectionPage from 'pages/healthcheck/PortfolioSectionPage'
import PortfolioRoutes from 'pages/healthcheck/Routes'
import RegulatorySearch from 'pages/onboarding/merchant-report/RegulatorySearch'
import Notifications from 'pages/notifications/notifications'
import LicenseVerificationPage from 'pages/license/LicenseVerificationPage'
import AlertWords from 'pages/notifications/alertwords'
import { useRouteMatch} from "react-router-dom"

export default function Routes(){
    const { params: {section } } = useRouteMatch()


    return (
      <Switch>
        <Route path="/onboarding/:inquiryId?/:merchantId?/:section?">
            <OnboardingRoutes />
        </Route>
        <Route path="/merchant-request">
            <MerchantRequest/>
        </Route>
        <Route path="/license">
            <LicenseVerificationPage/>
        </Route>
        <Route path="/notifications">
            <Notifications/>
        </Route>
        <Route path="/alert-words">
            <AlertWords/>
        </Route>
        <Route exact path="/healthcheck">
            <HealthCheckPage />
        </Route>
          <Route path="/healthcheck/:portfolioSection?/merchant/:merchantId/:section?">
              <PortfolioRoutes />
          </Route>
        <Route exact path="/healthcheck/:section?/:levelFilter?/:riskCategory?/:newOnly?">
            <PortfolioSectionPage />
        </Route>
        <Route path="/settings/:section?">
            <SettingsPage />
        </Route>
        <Route exact path="/callback">
            <Redirect to='/onboarding' />
        </Route>
        <Route exact path="/">
          <Redirect to='/onboarding' />
        </Route>
        <Route path="/regulatory-search/:section">
            <RegulatorySearch
            section={section}/>
        </Route>
      </Switch>
    );
}