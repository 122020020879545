import React, { useEffect, useState, useContext, useRef } from 'react'
import useApi from 'hooks/useApi'
import FormatDate from 'shared/FormatDate'
import Loading from 'shared/Loading'
import SessionContext from "../../SessionContext";
import useDebouncedEffect from '../../hooks/useDebouncedEffect';
import toast from 'react-hot-toast'
import appConfig from "../../app-config.json";
import Modal from 'shared/Modal'


import './ScorecardManager.scss'

export default function ScorecardManager(){
    const { apiGet, apiPost, apiDelete } = useApi()
    const { user } = useContext(SessionContext)
    const lender_admin = user.groups.some(g => g.name === 'Lender Admin')
    const verdata_admin = user.is_staff
    const [ lenderRules, setLenderRules ] = useState([])
    const [ targetGlossary, setTargetGlossary] = useState([])
    const [loading, setLoading] = useState(false)
    const [addingRule, setAddingRule] = useState(false)
    const [editRule, setEditRule] = useState(null)
    const [ refreshList, setRefreshList ] = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteRuleText, setDeleteRuleText] = useState()
    const [updatingScorecards, setUpdatingScorecards] = useState(false)

    // for add rule form
    const [ newRuleText, setNewRuleText] = useState('')
    const [ newRuleTarget, setNewRuleTarget] = useState('')
    const [ newRuleValue, setNewRuleValue] = useState(-1)
    const [ newRuleOperator, setNewRuleOperator] = useState(-1)
    const [ newRulePassScore, setNewRulePassScore] = useState(-999)
    const [ newRuleFailScore, setNewRuleFailScore] = useState(-999)
    // const [ newRuleTargetId, setNewRuleTargetId] = useState(null)
    const [ currTargetGlossary, setCurrTargetGlossary] = useState({})
    const [ showTT, setShowTT ] = useState(false)
    const [ TTText, setTTText ] = useState("Typical Rule Name")
    const svgRectRef = useRef()




    const operatorGlossary = {
        0: 'GREATER_THAN',
        1: 'LESS_THAN',
        2: 'GREATER_EQ',
        3: 'LESS_EQ',
        4: 'EXISTS',
        5: 'NOT EXISTS',
        6: 'MATCHES',
        7: 'SOURCE_MATCHES'
    }


    useEffect(() => {
        // setLoading(true)
        apiGet('scorecard/get_lender_rules').then(rules => {
                setLenderRules(rules)
                setLoading(false)
            }
        )
        apiGet('scorecard/get_target_glossary').then(targets => {
            targets.sort((a, b)=> a.displayName.localeCompare(b.displayName))
            setTargetGlossary(targets)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshList])


    function openAddRuleForm() {
        setAddingRule(true)
    }

    function closeAddRuleForm(ev) {
        ev && ev.preventDefault()
        setAddingRule(false)
        resetAddRuleForm()
    }

    function resetAddRuleForm(){
        setNewRuleText('')
        setNewRuleTarget('')
        setNewRuleValue(-1)
        setNewRuleOperator(-1)
        setNewRulePassScore(-999)
        setNewRuleFailScore(-999)
        // setNewRuleTargetId(null)
    }

    function addRule(ev) {
        ev.preventDefault()
        setUpdatingScorecards(true)
        let temp_target = newRuleTarget
        if (newRuleTarget === "Custom Target") {
            if (!(newRuleTarget && newRuleText && (newRuleFailScore !== -999) && (newRulePassScore !== -999))) {
                alert('Target, Rule Name and Pass/Fail Scores are all required.')
                setUpdatingScorecards(false)
                return
            }
            temp_target = ""
        } else {
            // console.log(newRuleTarget, newRuleText, 'value: ', newRuleValue, 'operator: ', newRuleOperator, 'fail: ', newRuleFailScore, 'pass', newRulePassScore)
            if (!(newRuleTarget && newRuleText && newRuleValue !== -1 && (newRuleFailScore !== -999) && (newRuleOperator !== -1) && (newRulePassScore !== -999))) {
                alert('Target, Rule Name, Value, Operator, and Pass/Fail Scores are all required.')
                setUpdatingScorecards(false)
                return
            }
            if(newRuleTarget === "vrisk_score"){
                if(isNaN(newRuleValue)){
                    alert('Value must be a number between 1 and 100.')
                    setUpdatingScorecards(false)
                    return
                }
                if(!(newRuleValue >= 1 && newRuleValue <= 100)){
                    alert('Value must be a number between 1 and 100.')
                    setUpdatingScorecards(false)
                    return
                }
            }
        }
        toast('Updating Scorecards may take up to a few minutes...')

        const payload = {
            "rule_text": newRuleText,
            "target": temp_target,
            "value": newRuleValue,
            "operator": parseInt(newRuleOperator),
            "pass_score": parseInt(newRulePassScore),
            "fail_score": parseInt(newRuleFailScore)
        }

        apiPost('scorecard/add_lender_rule', payload).then(() => {
            closeAddRuleForm()
            resetAddRuleForm()
            setRefreshList(refreshList+1)
            toast('New Rule Added - ' + "'" + newRuleText + "'")
            setUpdatingScorecards(false)
        })
    }

    function handleDeleteOnClick(ev) {
        setShowDeleteModal(true)
        setDeleteRuleText(ev.rule_text)
    }

    function deleteRule() {
        toast('Updating Scorecards may take up to a few minutes...')
        setUpdatingScorecards(true)

        setShowDeleteModal(false)

        const payload = {
            "rule_text": deleteRuleText
        }

        apiPost('scorecard/delete_lender_rule', payload).then(() => {
            toast('Rule deleted - ' + "'" + deleteRuleText + "'")
            setUpdatingScorecards(false)
            closeAddRuleForm()
            resetAddRuleForm()
            setRefreshList(refreshList+1)
        })
    }

    function closeEditRuleForm(wasChanged) {
        setEditRule(null)
        if(wasChanged) setRefreshList(refreshList + 1)
    }


    const hasRules = lenderRules && lenderRules.length > 0

    function handleSelectRuleTarget(ev) {
        // ev && ev.preventDefault()
        resetAddRuleForm()
        const temp = {}
        setCurrTargetGlossary(temp)
        for(let x=0; x<targetGlossary.length; x++) {
            if (targetGlossary[x].target === ev) {
                setCurrTargetGlossary(targetGlossary[x])
                setTTText(targetGlossary[x].typicalRuleName)
            }
        }
        setNewRuleTarget(ev)

    }

    function displayTargetName(target) {
        if (target === "") {
            return "Custom Target"
        }
        for(let x=0; x<targetGlossary.length; x++) {
            if (targetGlossary[x].target === target) {
                return targetGlossary[x].displayName

            }
        }
    }

    function getTargetGlossaryForRule(ev) {
        for(let x=0; x<targetGlossary.length; x++) {
            if (targetGlossary[x].target === ev.target) {
                return targetGlossary[x]

            }
        }
    }


    return <div className='scorecard-manager'>
        <form onSubmit={addRule}>
            <header>
                <h1>Scorecard</h1>
                <i className="spacer"/>
                    {addingRule && (
                        <>
                            <button className="inverted" onClick={closeAddRuleForm}>Cancel</button>
                            <button className="primary" disabled={updatingScorecards}>Save</button>
                        </>
                    )}
                    {!addingRule && (
                        <button className="primary" onClick={openAddRuleForm}>Add Rule</button>
                    )}
            </header>
            { addingRule && (
                <>
                <div className="add-rule-form">
                    <div className="field rule-target-field">
                        <label htmlFor="rule-target-field">Target</label>
                        <select value={newRuleTarget} onChange={(event) => handleSelectRuleTarget(event.target.value)}>
                        <option value="">Select Target</option>
                        {targetGlossary.sort().map((target) => (
                            <option key={target.target} value={target.target}>
                            {target.displayName}
                            </option>
                        ))}
                    </select>  
                    </div>    
                    
                    <div className="field rule-operator-field">
                        <label htmlFor="rule-operator-field">Operator</label>
                        <select value={newRuleOperator} disabled={!newRuleTarget || newRuleTarget === 'Custom Target'} onChange={(event) => setNewRuleOperator(event.target.value)}>
                            <option value={-1}>Select Operator</option>
                            {Object.entries(currTargetGlossary).length !== 0 && currTargetGlossary['possibleOperators'].map((target) => (
                                <option key={target} value={target}>
                                {operatorGlossary[target]}
                                </option>
                            ))}
                        </select>
                    </div>
                    

                    <div className="field rule-value-field">
                    <label htmlFor="rule-value-field">Value</label>
                    {Object.entries(currTargetGlossary).length !== 0 && currTargetGlossary['flexible'] ? 
                        <input defaultValue="Input Value" onChange={(event) => setNewRuleValue(event.target.value)}/>    
                    :
                        <select value={newRuleValue} disabled={!newRuleTarget || newRuleTarget === 'Custom Target'} onChange={(event) => setNewRuleValue(event.target.value)}>
                            <option value={-1}>Select Value</option>
                            {Object.entries(currTargetGlossary).length !== 0 && currTargetGlossary['possibleValues'].map((target) => (
                                <option key={target} value={target}>
                                {target}
                                </option>
                            ))}
                        </select>}
                    </div>
                    
                    <div className="fail-pass-scores">
                        <div className="field rule-pass-field">
                            <label htmlFor="rule-pass-field">Pass Score</label>
                            <input type="number" id="rule-pass-field" autoFocus disabled={!newRuleTarget}
                                    value={newRulePassScore === -999 ? '' : newRulePassScore} onChange={ev => setNewRulePassScore(ev.target.value)} />
                        </div>
                        <div className="field rule-fail-field">
                            <label htmlFor="rule-fail-field">Fail Score</label>
                            <input type="number" id="rule-fail-field" autoFocus disabled={!newRuleTarget}
                                    value={newRuleFailScore === -999 ? '' : newRuleFailScore} onChange={ev => setNewRuleFailScore(ev.target.value)} />
                        </div>
                    </div>
                    <div className="add-rule-text-div">
                        <div className="field rule-text-field">
                            <label htmlFor="rule-text-field">Rule Text</label>
                            <input type="text" id="rule-text-field" autoFocus disabled={!newRuleTarget}
                                    value={newRuleText} onChange={ev => setNewRuleText(ev.target.value)} />
                        {Object.entries(currTargetGlossary).length !== 0 && (newRuleTarget !== "" && newRuleTarget !== "Custom Target") && 
                        
                            <span className="rule-suggestion">                        
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <rect ref={svgRectRef}></rect>
                            <rect width="100%" height="100%" fill="transparent" onMouseEnter={() => setShowTT(true)} onMouseLeave={() => setShowTT(false)}></rect>
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                                <u>Rule Suggestion:</u> Rule Text: '{currTargetGlossary.typicalRuleName}' | Value: '{currTargetGlossary.defaultValue}' | Operator: '{operatorGlossary[currTargetGlossary.defaultOperator]}'</span>
                        }                                                    
                        </div>
                        <spacer>
                        </spacer>
                    </div>

                    <div>
                    {Object.entries(currTargetGlossary).length !== 0 && (newRuleTarget !== "") ? 
                    <>

                    <p>
                    <b>&nbsp;Rule Info: </b>
                    {currTargetGlossary['info']}
                    </p>
                    </> : <>
                    <p><u><b>Note: Please select a Target first to fill out the other fields</b></u></p>
                    </>}
                    </div>
                </div>
                </>
            )}
            {!addingRule && 
            <>
            <div className="warning-message">
            <p><b>Note: </b>
                Changes to Scorecard Rules will update existing scorecards with new data for that rule.
                This may lead to permanent data loss for existing scorecards for businesses. </p>
            </div>

            </>}
        </form>
        <div className="table-container">
            <div className="table-wrapper">
                <div className="rules-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Rule Text</th>
                                <th>Target</th>
                                <th className='slim'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {hasRules && lenderRules.map((rule, index) => 
                                editRule === rule ? (
                                    <td className='stripe' key={rule.id}>
                                        <td className="edit-rule-td" colSpan={3}>
                                            <EditRuleForm rule={rule} targetGlossary={getTargetGlossaryForRule(rule)} close={closeEditRuleForm}/>
                                        </td>

                                    </td>
                                ) : (
                                <tr className={'row-' + (index+1) % 2} key={index}>
                                    <td>{rule.rule_text}</td>
                                    <td>{displayTargetName(rule.target)}</td>
                                    <td className="actions">
                                    <menu className='expanding'>
                                        <span>actions</span>
                                        <ul>
                                            <li>
                                                <button className='inverted' onClick={() => setEditRule(rule)}>
                                                    Edit <i className="fa fa-pencil"/>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="inverted" onClick={()=> handleDeleteOnClick(rule)}>
                                                    Remove <i className="fa fa-trash" />
                                                </button>
                                            </li>
                                        </ul>
                                    </menu>
                                </td>
                                </tr>
                            )  
                                )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div className='scorecard-manager-modal'>
        {showDeleteModal &&
            <Modal>
                <header>
                    <h1>Are you sure?</h1>
                    <i className="spacer"/>
                    <button className="inverted" onClick={() => {setShowDeleteModal(false); setDeleteRuleText()}}>Cancel</button>
                    <button className="primary" onClick={() => {deleteRule()}} disabled={updatingScorecards}>Save</button> 
                </header>
                <br/>
                <span>Deleting a rule may lead to a permanent loss in scorecard data and will reflect on both new and old scorecards. </span>
            </Modal>}
        </div>
    </div>
}


function EditRuleForm({rule, close, targetGlossary}) {
    const [formData, setFormData] = useState({})
    const { apiPost } = useApi()
    const [showEditModal, setShowEditModal] = useState(false)
    const [updatingScorecards, setUpdatingScorecards] = useState(false)

    useEffect(() => {
        setFormData({
            id: rule.id,
            created_at: rule.created_at,
            updated_at: rule.updated_at,
            rule_text: rule.rule_text,
            target: rule.target,
            value: rule.value,
            operator: rule.operator,
            pass_score: rule.pass_score,
            fail_score: rule.fail_score
        })
    }, [rule])

    function onSubmit() {
        toast('Updating Scorecards may take up to a few minutes...')
        setUpdatingScorecards(true)
        apiPost(`scorecard/update_lender_rule`, formData)
            .then(() => {            
                toast('Rule edited - ' + "'" + formData.rule_text + "'");
                setUpdatingScorecards(false)
                close(true)})
        }

    function updateFormData(newData) {
        setFormData({...formData, ...newData})
    }

    return (
        <>
        <form className='edit-rule-form'>
            <div className="field edit-rule-text-field">
                <label htmlFor="edit-rule-text-field">Rule Text</label>
                <input type="name" id="edit-rule-text-field" autoFocus
                       value={formData.rule_text} onChange={ev => updateFormData({rule_text: ev.target.value})}/>
            </div>
            <div className="field rule-pass-field">
                <label htmlFor="edit-rule-pass-field">Pass Score</label>
                <input type="number" id="edit-rule-pass-field" autoFocus
                       value={formData.pass_score} onChange={ev => updateFormData({pass_score: ev.target.value})}/>
            </div>
            <div className="field rule-fail-field">
                <label htmlFor="edit-rule-fail-field">Fail Score</label>
                <input type="number" id="edit-rule-fail-field" autoFocus
                       value={formData.fail_score} onChange={ev => updateFormData({fail_score: ev.target.value})}/>
            </div>
            {formData.value && formData.target !== "" && formData.target !== "Custom Target" ?
                <div className="field rule-pass-field">
                <label htmlFor="edit-rule-pass-field">Value</label>
                {Object.entries(targetGlossary).length !== 0 && targetGlossary['flexible'] ? 
                     <input type="name" id="edit-rule-pass-field" autoFocus
                        value={formData.value} onChange={ev => updateFormData({value: ev.target.value})}/>   
                :
                    <select value={formData.value}  onChange={ev => updateFormData({value: ev.target.value})}>
                        {Object.entries(targetGlossary).length !== 0 && targetGlossary['possibleValues'].map((target) => (
                            <option key={target} value={target}>
                            {target}
                            </option>
                        ))}
                    </select>}
                </div>  
            : <div className="field rule-pass-field"></div> 
            }       

            {/* <i className='spacer'/> */}
            <button className="inverted" onClick={() => close(false)}>Cancel</button>
            <button className="primary" onClick={(ev) => {ev.preventDefault(); setShowEditModal(true)}}>Save</button>
        </form>
        <div className='scorecard-manager-modal'>
            {showEditModal &&
                <Modal>
                    <header>
                        <h1>Are you sure?</h1>
                        <i className="spacer"/>
                        <button className="inverted" onClick={() => {setShowEditModal(false)}}>Cancel</button>
                        <button className="primary" disabled={updatingScorecards} onClick={() => {onSubmit()}}>Save</button> 
                    </header>
                    <br/>
                    <span>Deleting and Editting a rule may lead to a permanent loss in scorecard data and will reflect on both new and old scorecards. </span>
                </Modal>}
        </div>
        </>
    )
}