import React, { useState } from 'react'
import FormatDate from 'shared/FormatDate'
import FormatPercentage from 'shared/FormatPercentage'
import DisplayLender from 'shared/DisplayLender'

import './FraudPanel.scss'

function statusClass(lender_merchant) {
    if (lender_merchant.status === "Declined") {
        return "status-declined"
    }
    else if (lender_merchant.status === "Pending") {
        return "status-pending"
    }
    else if (lender_merchant.status === "Closed Partner") {
        return "status-closed-partner"
    }
    else if (lender_merchant.status === "Closed Out Of Business") {
        return "status-closed-out-of-business"
    }
    else if (lender_merchant.close_date) {
        return "status-closed"
    }
    else if (lender_merchant.open_date) {
        return "status-current"
    }
    else {
        return ""
    }
}

function dedupePartnerHistory(merchants) {
    const uniqueCombinations = new Set();

    // Filter the merchants based on the specified fields
    const deduplicatedList = merchants.filter(obj => {
      const key = `${obj.lender}-${obj.open_date}-${obj.close_date}-${obj.status}"`;
  
      // Check if the combination is already in the set
      if (uniqueCombinations.has(key)) {
        // Duplicate found, return false to filter it out
        return false;
      }
  
      // Unique combination, add it to the set and return true to keep the object
      uniqueCombinations.add(key);
      return true;
    });
  
    return deduplicatedList;
}

function QuarterAccountsRow({quarter_row, payment_segments}) {
    const [ isExpanded, setIsExpanded ] = useState(false)

    return (
        <>
        <tr key={quarter_row.rpt_quarter}>
            <td>
            <span className="link-button" onClick={() => setIsExpanded(!isExpanded)}>
                {quarter_row.rpt_quarter}
                { isExpanded ? (
                    <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                    <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
            </span>
            </td>
            {payment_segments.map(segment =>
                <td key={segment.key}>
                    <div className="performance-data-column">
                    <div className="performance-data-group">
                        <div className="performance-data-value"><FormatPercentage value={quarter_row[segment.key].num_loans_pct_mar} places={2}/></div>
                        <div className="performance-data-value-sep"></div>
                        <div className="performance-data-value"><FormatPercentage value={quarter_row[segment.key].num_loans_pct_cum} places={2}/></div>
                    </div>
                    </div>
                </td>
            )}
            <td>{quarter_row.credit_score}</td>
        </tr>
        {isExpanded && quarter_row.months && quarter_row.months.map(month_row => 
            <tr key={month_row.rpt_quarter}>
                <td>{month_row.rpt_month}</td>
                {payment_segments.map(segment =>
                    <td key={segment.key}>
                        <div className="performance-data-column">
                        <div className="performance-data-group">
                            <div className="performance-data-value"><FormatPercentage value={month_row[segment.key].num_loans_pct_mar} places={2}/></div>
                            <div className="performance-data-value-sep"></div>
                            <div className="performance-data-value"><FormatPercentage value={month_row[segment.key].num_loans_pct_cum} places={2}/></div>
                        </div>
                        </div>
                    </td>
                )}
                <td>{month_row.credit_score}</td>
            </tr>
        )}
        </>
    )
}

function QuarterDollarsRow({quarter_row, payment_segments}) {
    const [ isExpanded, setIsExpanded ] = useState(false)

    return (
        <>
        <tr key={quarter_row.rpt_quarter}>
            <td>
            <span className="link-button" onClick={() => setIsExpanded(!isExpanded)}>
                {quarter_row.rpt_quarter}
                { isExpanded ? (
                    <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                    <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
            </span>
            </td>
            {payment_segments.map(segment =>
                <td key={segment.key}>
                    <div className="performance-data-column">
                    <div className="performance-data-group">
                        <div className="performance-data-value"><FormatPercentage value={quarter_row[segment.key].dollars_pct_mar} places={2}/></div>
                        <div className="performance-data-value-sep"></div>
                        <div className="performance-data-value"><FormatPercentage value={quarter_row[segment.key].dollars_pct_cum} places={2}/></div>
                    </div>
                    </div>
                </td>
            )}
            <td>{quarter_row.credit_score}</td>
        </tr>
        {isExpanded && quarter_row.months && quarter_row.months.map(month_row => 
            <tr key={month_row.rpt_quarter}>
                <td>{month_row.rpt_month}</td>
                {payment_segments.map(segment =>
                    <td key={segment.key}>
                        <div className="performance-data-column">
                        <div className="performance-data-group">
                            <div className="performance-data-value"><FormatPercentage value={month_row[segment.key].dollars_pct_mar} places={2}/></div>
                            <div className="performance-data-value-sep"></div>
                            <div className="performance-data-value"><FormatPercentage value={month_row[segment.key].dollars_pct_cum} places={2}/></div>
                        </div>
                        </div>
                    </td>
                )}
                <td>{month_row.credit_score}</td>
            </tr>
        )}
        </>
    )
}

export default function({merchantReport, score, inquiryHistory, printVersion}){
    let lender_merchants = merchantReport.lender_merchants.filter((m) =>
        m.lender_type === 'Lending Partner' && (m.open_date || m.status === 'Declined' || m.status === 'Pending')
    )
    lender_merchants = dedupePartnerHistory(lender_merchants)

    const payment_segments = [
        {key: 'missed_first', desc: 'MFP'},
        {key: 'fifteen_past', desc: '15+ Rate'},
        {key: 'thirty_past', desc: '30+ Rate'},
        {key: 'missed_first_three', desc: 'Missing First 3 Payments'},
        {key: 'no_pay', desc: 'No Pay Rate'},
        {key: 'ninety_past', desc: '90-Day Past Due'}
    ]
    return (
        <div className="box">
        <div className="score-line">
                {score && (<>
                    <h2 className="label">{score.description}</h2>
                </>)}
        </div>

        {lender_merchants.length > 0 ?
            <>
                <h3>Verdata Partner History &nbsp;|&nbsp;
                    {lender_merchants.filter((m) => m.close_date).length === 1 ?
                        <>
                            {lender_merchants.filter((m) => m.close_date).length} Closure, &nbsp;
                        </>
                        :
                        <>
                            {lender_merchants.filter((m) => m.close_date).length} Closures, &nbsp;
                        </>
                    }
                    {lender_merchants.filter((m) => m.open_date && !m.close_date).length} Open
                    {lender_merchants.filter((m) => m.status === "Declined").length > 0 ?
                        <>
                            , {lender_merchants.filter((m) => m.status === "Declined").length} Declined
                        </>
                    : null}
                    {lender_merchants.filter((m) => m.status === "Pending").length > 0 ?
                        <>
                            , {lender_merchants.filter((m) => m.status === "Pending").length} Pending
                        </>
                    : null}
                </h3>
                <table>
                    <thead>
                        <tr>
                            <th>Partner</th>
                            <th>Partnership Started</th>
                            <th>Partnership Ended</th>
                            <th>Length of Partnership</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        { lender_merchants.map((lender_merchant, index) =>
                            (lender_merchant.close_date !== null && lender_merchant.status === "Open" ?
                            <></> :
                            <>
                                <tr className={'row-' + (index + 1) % 2} key={index}>
                                <td><DisplayLender alias={lender_merchant.lender}/></td>
                                <td><FormatDate date={lender_merchant.open_date} /></td>
                                <td><FormatDate date={lender_merchant.close_date} /></td>
                                <td>
                                    {(lender_merchant.status !== "Declined" && lender_merchant.status !== "Pending" && lender_merchant.open_months) ?
                                    `${lender_merchant.open_months} months` :
                                    null}
                                </td>
                                <td>
                                    <div className={"partnership-status " + statusClass(lender_merchant)}>
                                        {lender_merchant.status}
                                    </div>
                                </td>
                            </tr>
                            </>
                            )
                        )}
                    </tbody>
                </table>
            </>
        :
            <>
                <h3>Verdata Partner History</h3>
                <p>No partner history is available for this merchant.</p>
            </>
        }
        {inquiryHistory.length > 0 ? 
            <>
                <h3>Inquiry History &nbsp;|&nbsp;
                    {
                        <>                          
                            Total Inquiries for this Business ({inquiryHistory.length})
                        </>
                    }
                </h3>
                <div class={printVersion ? "table-printable" : inquiryHistory.length < 8 ? "table-scrollable-short" : "table-scrollable"}>
                    <table class="inquiry-table">
                        <thead>
                            <tr >
                                <th>Partner</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                        { inquiryHistory.map((inquiryRow, index) =>
                                        <tr className={'row-' + (index + 1) % 2} key={index}>
                                            <td><DisplayLender alias={inquiryRow.name}/></td>
                                            <td><FormatDate date={inquiryRow.date} /></td>
                                        </tr>
                                    )}
                        </tbody>
                    </table>
                </div>
            </>
        :
            <>
                <h3>Inquiry History</h3>
                <p>No Inquiry history is available for this merchant.</p>
            </>
        
    
        }

        <h3>Payment Performance</h3>
        {(merchantReport.payment_performance && false) ? 
        <>
            <div className="performance-table-header">% of Accounts</div>
            <div className="performance-table">
            <table>
                <thead className="performance-head">
                    <tr>
                        <th>
                            <div className="performance-head-group">Quarter</div>
                        </th>
                        {payment_segments.map(segment =>
                        <th key={segment.key}>
                            <div className="performance-head-group">{segment.desc}</div>
                        </th>
                        )}
                        <th>
                            <div className="performance-head-group">Credit Score</div>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <div className="performance-sub-head-group">&nbsp;</div>
                        </th>
                        {payment_segments.map(segment => 
                            <th key={segment.key}>
                                <div className="performance-sub-head-group">
                                    <div className="performance-sub-head">
                                        <div className="performance-sub-sub-head">Mar</div>
                                        <div className="performance-sub-sub-head-sep"></div>
                                        <div className="performance-sub-sub-head">Cum</div>
                                    </div>
                                </div>
                            </th>
                        )}
                        <th>
                            <div className="performance-sub-head-group">&nbsp;</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {merchantReport.payment_performance && merchantReport.payment_performance.map(row =>
                        <QuarterAccountsRow quarter_row={row} key={row.rpt_quarter} payment_segments={payment_segments} />
                    )}
                </tbody>    
            </table>
            </div>
            <div className="performance-table-footer">* MAR = Marginal, CUM = Cumulative</div>


            <div className="performance-table-header">% of Dollars</div>
            <div className="performance-table">
            <table>
                <thead className="performance-head">
                    <tr>
                        <th>
                            <div className="performance-head-group">Months</div>
                        </th>
                        {payment_segments.map(segment =>
                        <th key={segment.key}>
                            <div className="performance-head-group">{segment.desc}</div>
                        </th>
                        )}
                        <th>
                            <div className="performance-head-group">Credit Score</div>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <div className="performance-sub-head-group">&nbsp;</div>
                        </th>
                        {payment_segments.map(segment =>
                            <th key={segment.key}>
                                <div className="performance-sub-head-group">
                                    <div className="performance-sub-head">
                                        <div className="performance-sub-sub-head">Mar</div>
                                        <div className="performance-sub-sub-head-sep"></div>
                                        <div className="performance-sub-sub-head">Cum</div>
                                    </div>
                                </div>
                            </th>
                        )}
                        <th>
                            <div className="performance-sub-head-group">&nbsp;</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {merchantReport.payment_performance && merchantReport.payment_performance.map(row =>
                        <QuarterDollarsRow quarter_row={row} key={row.rpt_quarter} payment_segments={payment_segments} />
                    )}
                </tbody>    
            </table>
            </div>
            <div className="performance-table-footer">* MAR = Marginal, CUM = Cumulative</div>
        </>
        : <p>Historical payment performance (as a percent of accounts and dollars) will be available when Verdata launches Phase 2.</p>
        }
        </div>
    )
}