import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import useApi from "hooks/useApi"
import Loading from 'shared/Loading'

import './LenderManager.scss'

export default function LenderManager() {
    const [addingLender, setAddingLender] = useState(false)
    const [lenders, setLenders] = useState(undefined)
    const {apiGet, apiPost, apiDelete} = useApi()
    const [name, setName] = useState()
    const [refreshList, setRefreshList] = useState(0)
    const [loading, setLoading] = useState(false)
    const [editLender, setEditLender] = useState(null)

    useEffect(() => {
        setLoading(true)
        apiGet('lender').then(lenders => {
                setLenders(lenders.results)
                setLoading(false)
            }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshList])

    function openAddLenderForm() {
        setAddingLender(true)
    }

    function closeAddLenderForm(ev) {
        ev && ev.preventDefault()
        setAddingLender(false)
    }

    function resetAddLenderForm() {
        setName('')
    }

    function addLender(ev) {
        ev.preventDefault()
        apiPost('lender/new', {name})
            .then(() => {
                closeAddLenderForm()
                resetAddLenderForm()
                setRefreshList(refreshList + 1)
            })
    }

    function removeLender(lender) {
        const deleteConfirmed = window.confirm(`Are you sure you want to delete lender [${lender.name}]?`)
        if (!deleteConfirmed) return
        apiDelete(`lender/${lender.id}`)
            .then(() => {
                setRefreshList(refreshList + 1)
            })
    }

    function closeEditLender(wasChanged){
        setEditLender(null)
        if(wasChanged) setRefreshList(refreshList + 1)
    }

    const hasLenders = lenders && lenders.length > 0

    return <div className='lender-manager'>
        <form onSubmit={addLender}>
            <header>
                <h1>Lenders</h1>
                <i className="spacer"/>
                {addingLender && (
                    <>
                        <button className="inverted" onClick={closeAddLenderForm}>Cancel</button>
                        <button className="primary">Save</button>
                    </>
                )}
                {!addingLender && (
                    <button className="primary" onClick={openAddLenderForm}>Add Lender</button>
                )}
            </header>
            {addingLender && (
                <div className="add-lender-form">
                    <div className="field lender-name-field">
                        <label htmlFor="lender-name">Lender Name</label>
                        <input type="name" id="lender-name" autoFocus
                               value={name} onChange={ev => setName(ev.target.value)}/>
                    </div>
                </div>
            )}
        </form>
        <table>
            <thead>
            <tr>
                <th>Lender</th>
                <th>User Count</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {(loading || !hasLenders) && (
                <tr>
                    <td colSpan={4} className='message'>
                        {loading && <Loading/>}
                        {(!loading && !hasLenders) && "No Lenders Found"}
                    </td>
                </tr>
            )}
            {hasLenders && lenders.map(lender =>
                editLender === lender ? (
                    <tr className='stripe' key={lender.id}>
                        <td colSpan={3}>
                            <EditLenderForm lender={lender} close={closeEditLender}/>
                        </td>
                    </tr>
                ):(
                    <tr key={lender.id}>
                        <td>{lender.name}</td>
                        <td>
                            <Link to={`/settings/users?lenders__id=${encodeURIComponent(lender.id)}`}>
                                {lender.user_count} {lender.user_count === 1 ? 'user' : 'users'}
                            </Link>
                        </td>
                        <td className="actions">

                            <menu className='expanding'>
                                <span>actions</span>
                                <ul>
                                    <li>
                                        <button className='inverted' onClick={() => setEditLender(lender)}>
                                            Edit <i className="fa fa-pencil"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="inverted"
                                                onClick={() => removeLender(lender)}>
                                            Remove <i className="fa fa-trash"/>
                                        </button>
                                    </li>
                                </ul>
                            </menu>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    </div>
}

function EditLenderForm({lender, close}) {
    const [formData, setFormData] = useState(null)
    const [permissions, setPermissions] = useState([])
    const { apiGet, apiPost } = useApi()

    useEffect(() => {
        apiGet('feature_store/permissions').then(response => {
            setPermissions(response.results)
            setFormData({...lender})
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lender])

    function onSubmit(ev) {
        ev.preventDefault()
        apiPost(`lender/${lender.id}`, formData)
            .then(() => window.location.reload(false))
    }

    function updateFormData(newData) {
        setFormData({...formData, ...newData})
    }

    function updatePermissions(id) {
        let selected = formData.feature_store_permission

        let find = selected.indexOf(id)

        if(find > -1) {
            selected.splice(find, 1)
        } else {
            selected.push(id)
        }

        updateFormData({ feature_store_permission: selected })
    }

    return (formData) &&
        <form onSubmit={onSubmit} className='edit-lender-form'>
            <div className="field lender-name-field">
                <label htmlFor="lender-name">Lender Name</label>
                <input type="name" id="edit-lender-name" autoFocus
                       value={formData.name || ''} onChange={ev => updateFormData({name: ev.target.value})}/>
            </div>
            <div className="field lender-alias-field">
                <label htmlFor="lender-alias">Lender Alias</label>
                <input type="alias" id="edit-lender-alias" autoFocus
                       value={formData.alias || ''} onChange={ev => updateFormData({alias: ev.target.value})}/>
            </div>
            <div className="field lender-is-active-field">
                <label htmlFor="lender-active">Is Active</label>
                <input type="checkbox" id="lender-active" autoFocus
                    checked={!!formData.is_active}
                    onChange={() => updateFormData({is_active: !formData.is_active})}/>
            </div>
            <div className="field lender-is-api-enabled-field">
                <label htmlFor="lender-api-enabled">API Enabled</label>
                <input type="checkbox" id="lender-api-enabled" autoFocus
                    checked={!!formData.is_api_enabled}
                    onChange={() => updateFormData({is_api_enabled: !formData.is_api_enabled})}/>
            </div>
            <div className="field feature-store-permissions-field">
                <label htmlFor="feature-store-permissions">Feature Store Permissions</label>
                <div className="checkbox-group">
                    {permissions && permissions.map(p =>
                    <>
                        <input type="checkbox"
                            disabled={p.always_include}
                            onChange={ () => updatePermissions(p.id) }
                            checked={ p.always_include || formData.feature_store_permission.includes(p.id) }
                            />
                        <label htmlFor="feature-1">{p.name}</label>
                        <br />
                    </>)}
                </div>
            </div>
            <i className='spacer'/>
            <button className="inverted" onClick={() => close(false)}>Cancel</button>
            <button className="primary">Save</button>
        </form>
}
