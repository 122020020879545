import React, { useReducer } from "react";

function toggle(state, action) {
    return !state
}

export default function ReadMore({text, threshold=1000}) {
    const [hidden, dispatch] = useReducer(toggle, true);

    return (
        <>
            {text && 
                <>
                {hidden ? text.substring(0, threshold) : text}&nbsp;
                {(text.length > threshold) && 
                <button className="link-button" onClick={() => dispatch()}>{hidden ? 'Read More' : 'Show Less'}</button>
                }
                </>
            }
        </>
    )
}