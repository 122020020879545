import React, {useEffect, useState} from 'react'
import {Link, useRouteMatch, useHistory} from 'react-router-dom'
import useApi from 'hooks/useApi'
import Loading from 'shared/Loading'
import FormatDate from 'shared/FormatDate'
import useDebouncedCallback from 'utils/useDeboucedCallback'
import FormatNumber from 'shared/FormatNumber'
import {MultiButtonSwitcher} from 'shared/MultiButtonSwitcher'
import vRiskLogo from 'assets/vrisk-logo.png'

const sectionText = {
    overview: 'Onboarding Risk',
    regulatory: 'Regulatory Risk',
    service: 'Service Reviews'
}

const filterLevels = ['All Levels', 'Company Level Only', 'Individual Locations Only']
const riskCategories = {
    'overview': ['all-risk', 'highest-risk', 'high-risk', 'medium-risk', 'lowest-risk' ],
    'regulatory': ['all-risk', 'highest-risk', 'high-risk', 'medium-risk', 'low-risk', 'lowest-risk' ],
    'service': ['all-reviews', 'worst-reviews', 'bad-reviews', 'medium-reviews', 'good-reviews', 'best-reviews' ],
}
function riskCategoryToRank(risk) {
    const categoryToRank = {
        'all-risk': '',
        'highest-risk' : 'red', 'high-risk': 'orange', 'medium-risk': 'yellow', 'lowest-risk': 'green',
        'all-reviews': '',
        'worst-reviews': 'worst', 'bad-reviews': 'bad', 'medium-reviews': 'ok', 'good-reviews': 'good', 'best-reviews': 'best'
    }
    return categoryToRank[risk]
}
function rankToDisplayCategory(rank){
    const rankToCategory ={
        'worst': 'Highest Risk',
        'high': 'High Risk',
        'medium': 'Medium Risk',
        'lowest': 'Lowest Risk'
    }
    return rankToCategory[rank.toLowerCase()]
}

function prevRankToDisplayCategory(rank){
    const rankToCategory = {
        'highest': 'Highest Risk',
        'high': 'High Risk',
        'ok': 'Medium Risk',
        'lowest': 'Lowest Risk',
        'unknown': 'Unknown'
    }
    return rankToCategory[rank.toLowerCase()]
}

const encodeLevelFilter = (level) => level.toLowerCase().replace(/\s/g, '-')

export default function PortfolioSectionPage(){
    const { params: {
        section,
        levelFilter=encodeLevelFilter(filterLevels[0]),
        riskCategory=riskCategories[section][0],
        newOnly=false,
    } } = useRouteMatch()
    const history = useHistory()
    const { apiGet } = useApi()
    const [ count, setCount ] = useState(null)
    const [ merchants, setMerchants ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ page, setPage ] = useState(0)
    const [ sortBy, setSortBy ] = useState(['name', 'asc'])
    const [ query, setQuery ] = useState('')
    const [ queryTrigger, setQueryTrigger ] = useState(0) // used to allow query changes to be debounced
    const [ downloadingCsv, setDownloadingCsv ] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (page === 0) setMerchants([])
        const [currentCol, currentDir] = sortBy
        apiGet(`lender/portfolio/${section}/merchant`, {
            section,
            query,
            level: levelFilter,
            page: (page+1),
            sortBy: (currentDir==="desc" ? `-${currentCol}` : `${currentCol}`),
            scoreRank: (query) ? '' : riskCategoryToRank(riskCategory),
            new: newOnly || ''
        }).then( response => {
            setLoading(false)
            setCount(response.count)
            setMerchants(
                page === 0
                    ? response.results
                    : [...merchants, ...response.results]
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section, page, sortBy, queryTrigger, riskCategory, levelFilter, newOnly])

    const debouncedSetQuery = useDebouncedCallback(500,
        () => {
            setPage(0)
            setQueryTrigger(queryTrigger + 1)
        }, [setPage, setQueryTrigger, queryTrigger])

    const onClickSortBy = (sortCol) => {
        setPage(0) // reset page count
        const [currentCol, currentDir] = sortBy
        if (sortCol !== currentCol) {
            // switch to requested column, ascending
            setSortBy([sortCol, 'asc'])
        } else {
            if (currentDir === 'asc') {
                // same column, switch to descending
                setSortBy([ sortCol, 'desc'])
            } else {
                // same column, switch to acending
                setSortBy([ sortCol, 'asc'])
            }
        }
    }

    const downloadCsv = () => {
        // converts a blob to a download in the browser
        function saveBlob(blob, filename) {
           const a = document.createElement("a");
           document.body.appendChild(a);
           const blobUrl = window.URL.createObjectURL(blob);
           a.href = blobUrl;
           a.download = filename;
           a.click();
           setTimeout(() => {
             window.URL.revokeObjectURL(blobUrl);
             document.body.removeChild(a);
           }, 10);
        }
        const [currentCol, currentDir] = sortBy
        setDownloadingCsv(true)
        apiGet(`lender/portfolio/${section}/merchant`, {
            section, sortBy: (currentDir==="desc" ? `-${currentCol}` : `${currentCol}`),
            query, scoreRank: (query) ? '' : riskCategoryToRank(riskCategory), new: newOnly || '',
            csv: true, rawResponse: true
        }).then( response => {
            return response.blob()
        }).then( blob => {
            const date = new Date()
            saveBlob(blob, `${section}_portfolio_${date.getFullYear()}_${(''+(date.getMonth()+1)).padStart(2, '0')}.csv`)
            setDownloadingCsv(false)
        })
    }

    const toggleNewOnly = () => {
        navigateToNewState({newOnly: !newOnly})
    }

    const setRiskFilter = (riskValue) => {
        navigateToNewState({riskCategory: riskCategories[section].find( r => r.startsWith(`${riskValue}-`))})
    }

    const setLevelFilter = (levelFilter) => {
        navigateToNewState({levelFilter})
    }

    const navigateToNewState = (newState) => {
        const state = {section, levelFilter, riskCategory, newOnly, ...newState}
        setPage(0)
        history.push(`/healthcheck/${state.section}/${state.levelFilter}/${state.riskCategory}${ !!state.newOnly ? '/new' : ''}`)
    }

    const resultsCount = merchants.length
    const hasMore = merchants && count > resultsCount
    const sortDirFor = (col) => sortBy && sortBy[0] === col && sortBy[1]

    return (
        <div className='merchants-by-risk-score full-bleed'>
            <div className='portfolio-navigator'>
                <Link to={'/healthcheck'} className='button'>Back to Overview</Link>
                <i className='spacer' />
                <label htmlFor='search'><i className='fa fa-search' /></label>
                <input type='search' placeholder='Search My Portfolio' id='search'
                       value={query} onChange={ev => { setQuery(ev.target.value); debouncedSetQuery() }} />
            </div>

            <header>
            <h1><img src={vRiskLogo} alt="vRisk" style={{width:"48px"}}/>&nbsp;Portfolio by { sectionText[section] }</h1>
                <div>
                    <div className='risk-filter'>
                        <MultiButtonSwitcher values={riskCategories[section].map(r => r.split('-')[0])}
                                             value={riskCategory.split('-')[0]}
                                             onChange={setRiskFilter} />
                        <input type='checkbox' checked={!!newOnly} id='new-only' onChange={toggleNewOnly} />
                        <label htmlFor='new-only'>New Since Last Month</label>
                    </div>
                    <MultiButtonSwitcher values={filterLevels}
                                         value={filterLevels.find( level => encodeLevelFilter(level) === levelFilter)}
                                         onChange={(level) => setLevelFilter(encodeLevelFilter(level))} />
                </div>
            </header>

            <p>Click on any category to organize the pivot table.</p>

            <div>
            <table>
                <thead>
                <tr>
                    <th onClick={() => onClickSortBy('name')}>
                        <Sorter dir={sortDirFor('name')} />
                        Name
                    </th>
                    <th onClick={() => onClickSortBy('city')}>
                        <Sorter dir={sortDirFor('city')} />
                        City
                    </th>
                    <th onClick={() => onClickSortBy('state')}>
                        <Sorter dir={sortDirFor('state')} />
                        State
                    </th>
                    <th onClick={() => onClickSortBy('store_id')}>
                        <Sorter dir={sortDirFor('store_id')} />
                        Store ID
                    </th>
                    <th onClick={() => onClickSortBy('level')}>
                        <Sorter dir={sortDirFor('level')} />
                        Level
                    </th>
                    <th onClick={() => onClickSortBy('rank')}>
                        <Sorter dir={sortDirFor('rank')} />
                        Risk Category
                    </th>
                    <th onClick={() => onClickSortBy('score')}>
                        <Sorter dir={sortDirFor('score')} />
                        Current vRisk
                    </th>
                    <th onClick={() => onClickSortBy('date_added')}>
                        <Sorter dir={sortDirFor('date_added')} />
                        Latest Update
                    </th>
                    <th onClick={() => onClickSortBy('prev_rank')}>
                        <Sorter dir={sortDirFor('prev_rank')} />
                        Last Risk Category
                    </th>
                    <th onClick={() => onClickSortBy('prev_score')}>
                        <Sorter dir={sortDirFor('prev_score')} />
                        Previous vRisk
                    </th>
                    <th onClick={() => onClickSortBy('rank_diff')}>
                        <Sorter dir={sortDirFor('rank_diff')} />
                        vRisk Movement
                    </th>
                    <th>
                        {downloadingCsv
                            ? <button className='inverted'>Generating CSV...</button>
                            : <button
                                  onClick={downloadCsv}
                                  className='inverted'
                                  disabled={downloadingCsv}>
                                  <i className="fa fa-download" aria-hidden="true" />
                                  Download CSV
                              </button>
                        }

                    </th>
                </tr>
                </thead>
                <tbody>
                {merchants && merchants.map((merchant, i) => (
                    <tr className={i % 2 === 0 ? '' : 'stripe'} key={merchant.id}>
                        <td>{merchant.name}</td>
                        <td>{merchant.city}</td>
                        <td>{merchant.state}</td>
                        <td>{merchant.store_id}</td>
                        <td>{merchant.level}</td>
                        <td>{rankToDisplayCategory(merchant.rank)}</td>
                        <td>{merchant.score}</td>
                        <td>
                            <FormatDate date={merchant.date_added}/>
                        </td>
                        <td>{prevRankToDisplayCategory(merchant.prev_rank)}</td>
                        <td>{merchant.prev_score}</td>
                        <td>{merchant.rank_diff}</td>
                        <td>
                            <Link to={`/healthcheck/${section}/merchant/${merchant.merchant_id}`} className='button primary' target='_blank'>Run Report</Link>
                        </td>
                    </tr>

                ))}
                </tbody>
            </table>
            </div>
            {loading && <Loading />}
            {(!loading) && (
                <div className='pager-more'>
                    {hasMore && <>
                        <p>Showing <FormatNumber value={resultsCount} /> Merchants of <FormatNumber value={count} /></p>
                        <button className='load-more' onClick={() => setPage(page + 1)}>Show More</button>
                    </>}
                    {!hasMore && <>
                        <p>Showing all <FormatNumber value={count} /> Merchants</p>
                    </>}
                </div>
                )
            }
        </div>
    )
}

function Sorter({dir}){
    return (
        <div className='sorter'>
            <i className={`sorter-up fa fa-caret-up ${dir === 'asc' ? 'active' : ''}`}></i>
            <i className={`sorter-dn fa fa-caret-down ${dir === 'desc' ? 'active' : ''}`}></i>
        </div>
    )
}

