import React, { useState, useEffect, useRef } from 'react'
import ExpandingRow from 'shared/ExpandingRow'
import MerchantServiceFeedbackDetails from './service-panel/MerchantServiceFeedbackDetails'
import MerchantStarFeedbackDetails from './service-panel/MerchantStarFeedbackDetails'
import ExternalLink from "../../../shared/ExternalLink";
import Modal from "../../../shared/Modal";
import SubmitReviewsURLForm from "./service-panel/SubmitReviewsURLForm";
import FormatDate from 'shared/FormatDate';
import ReadMoreHighlighter from 'shared/ReadMoreHighlighter'

const review_sources = ['Angi Biz', 'BBB', 'BuildZoom', 'Google', 'Yelp', 'WebMD', 'Vitals', 'Healthgrds']

const serviceNameSearchOverrides = {
    'Angi Biz': 'Angi',
    'Vitals': 'Vitals',
    'WebMD': 'WebMD',
    'Healthgrds': 'Healthgrades',
}

function serviceLineLink(service, merchant, details){
    // details is passed in but ignored, as we may want to
    // use details.url here in the future
    return `https://www.google.com/search?q=${[
            serviceNameSearchOverrides[service.alias] || service.alias,
            merchant.name,
            merchant.city,
            merchant.state
        ].filter(Boolean).map(encodeURIComponent).join('+')}`;
}

function ReviewEntry({review}){

    // This gives us a class name like rating-5 or rating-recommended
    // to trigger styling on
    const reviewRatingClassName = 'rating-' + review.rating
    const ratingNumber = Number(review.rating)
    const ratingPercent = 100 * ( ratingNumber / 5 )

    return (
        <div className='review-entry'>
            <div className='review-date'>
                <span>Date: </span><FormatDate date={review.review_dt} />
            </div>
            <div className='review-name'>
                <span>Name: </span><span>{review.name}</span>
            </div>
            <div className='review-text'><ReadMoreHighlighter text={review.review_text} /></div>
            <div className={`review-rating ${reviewRatingClassName}`}
                 style={{'--percent': `${ratingPercent}%`}}>
                <span className='rating-text'>{review.rating}</span>
                <div className='rating-stars'>
                    <div className={`star ${ratingNumber > 0 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 1 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 2 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 3 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 4 ? 'star-on' : 'star-off'}`} />
                </div>
            </div>
        </div>
    )
}

export default function({merchantReport, score, setRenderComplete, 
    service_summary_expanded=false, service_stars_expanded=false}){
    const [ editReviewsURLData, setEditReviewsURLData ] = useState(null)
    const [ ratingBySourceRendered, setRatingBySourceRendered ] = useState(false)
    const [ ratingByStarsRendered, setRatingByStarsRendered ] = useState(false)
  
    const alertReviewRef = useRef(null);

    const scrollToAlertReviews = () => {
        return alertReviewRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect( ()=> {
        if (ratingBySourceRendered && ratingByStarsRendered) {
            if (setRenderComplete) {
                setRenderComplete(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratingBySourceRendered, ratingByStarsRendered])  
    
    useEffect( ()=>{
        var hash = window.location.hash.split('#').pop();
        if (hash == 'alert-word-review'){
            console.log("ran")
            let delay = 1000;
            setTimeout(function() {
                scrollToAlertReviews();
            }, delay)
        }
    },[])

    let service_merchant_map = {}
    merchantReport.lender_merchants.forEach(lender_merchant => {
        service_merchant_map[lender_merchant.lender] = lender_merchant
    });

    return (
        <div className="box">
            <div className="score-line">
                    {score && (<>
                        <h2 className="label">{score.description}</h2>
                    </>)}
            </div>
            <h3>Online Ratings By Source</h3>
            <table className='ratings-by-source'>
                <thead>
                    <tr>
                        <th className='source-name'>Source</th>
                        <th>Reviews From<br />Last 6 Months</th>
                        <th>Average Rating<br />From Last 6 Months</th>
                        <th>Average Rating<br />From Last 3 Months</th>
                        <th>Average Rating<br />From Last 1 Months</th>
                        <th>Total Reviews</th>
                        <th>Average Rating</th>
                        <th>Trending</th>
                        <th></th>
                    </tr>

                    {merchantReport.service_summary && merchantReport.service_summary.slice(0,1).filter(service_line =>
                        !service_line.alias.includes('License')
                    ).map(service_line => 
                        <tr key={service_line.alias}>
                            <th className='source-name'>{service_line.alias}</th>
                            <th>{service_line.count_6_month}</th>
                            <th>{service_line.avg_6_month ? (service_line.avg_6_month).toFixed(2) + '/5' : 'n/a'}</th>
                            <th>{service_line.avg_3_month ? (service_line.avg_3_month).toFixed(2) + '/5' : 'n/a'}</th>
                            <th>{service_line.avg_1_month ? (service_line.avg_1_month).toFixed(2) + '/5' : 'n/a'}</th>
                            <th>{service_line.count_total}</th>
                            <th>{service_line.avg_total ? (service_line.avg_total).toFixed(2) + '/5' : 'n/a'}</th>
                            <th>{service_line.trending ? (service_line.trending).toFixed(2) : 'n/a'}</th>
                            <th></th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {merchantReport.service_summary && merchantReport.service_summary.slice(1).filter(service_line => !service_line.alias.includes('License')).map((service_line, index) =>
                        review_sources.indexOf(service_line.alias) <= -1
                            ? null
                            : <ExpandingRow
                                key={service_line.alias} stripe={index % 2}
                                expandable={ !!service_merchant_map[service_line.name]?.url }
                                expanded={service_summary_expanded}
                                DetailsPanel={[
                                    MerchantServiceFeedbackDetails, {
                                      merchantId: merchantReport.merchant.id,
                                      service: {
                                        lender: service_line.alias,
                                        lender_type: service_line.alias,
                                        url: service_merchant_map[service_line.name]?.url
                                      },
                                      onSubmitReviewsURL: ()=> setEditReviewsURLData(service_line),
                                      setRenderComplete: setRatingBySourceRendered
                                    }
                                ]}
                                lastCellOverride={!service_merchant_map[service_line.name]?.url && (
                                    <button className='inverted inline bordered'
                                            onClick={() => setEditReviewsURLData(service_line)}>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        Submit Source URL
                                    </button>
                                )}>
                                  <td className='source-name'>
                                      <ExternalLink
                                          href={serviceLineLink(
                                              service_line,
                                              merchantReport.merchant,
                                              service_merchant_map[service_line.name] || {}
                                              )}>
                                          {service_line.alias === 'Healthgrds' ? service_line.name : service_line.alias} Reviews
                                      </ExternalLink>
                                  </td>
                                  { !!service_merchant_map[service_line.name]?.url
                                      ? <>
                                          <td>{service_line.count_6_month}</td>
                                          <td>{service_line.avg_6_month ? (service_line.avg_6_month).toFixed(2) + '/5' : 'n/a'}</td>
                                          <td>{service_line.avg_3_month ? (service_line.avg_3_month).toFixed(2) + '/5' : 'n/a'}</td>
                                          <td>{service_line.avg_1_month ? (service_line.avg_1_month).toFixed(2) + '/5' : 'n/a'}</td>
                                          <td>{service_line.count_total}</td>
                                          <td>{service_line.avg_total ? (service_line.avg_total).toFixed(2) + '/5' : 'n/a'}</td>
                                          <td>{service_line.trending ? (service_line.trending).toFixed(2) : 'n/a'}</td>
                                      </>
                                      : <td colSpan={7}>
                                            <i>No URL found</i>
                                        </td>
                                  }
                              </ExpandingRow>
                    )}
                </tbody>
            </table>

            <footer>
                <button className='inverted inline bordered'
                        onClick={() => setEditReviewsURLData({})}>
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    Submit New Reviews Service
                </button>
            </footer>

            <h3>Online Ratings By Stars</h3>
            <table className='ratings-by-source'>
                <thead>
                    <tr>
                        <th className='source-name'>Total</th>
                        <th>Last 6 Months</th>
                        <th>Last 3 Months</th>
                        <th>Last 1 Months</th>
                        <th>Total Reviews</th>
                    </tr>
                </thead>
                <tbody>
                    {merchantReport.service_summary_by_stars && merchantReport.service_summary_by_stars.map((service_line, index) =>
                        <ExpandingRow 
                            key={service_line.rating}
                            stripe={index % 2}
                            expanded={service_stars_expanded}                            
                            DetailsPanel={[
                                MerchantStarFeedbackDetails, {
                                    merchantId: merchantReport.merchant.id, 
                                    service: {rating: service_line.rating},
                                    setRenderComplete: setRatingByStarsRendered
                                }]}>
                                  <td className='source-name'>
                                    {service_line.rating} {service_line.rating > 1 ? 'Stars' : 'Star'}
                                  </td>
                                  <td>{service_line.count_6_month}</td>
                                  <td>{service_line.count_3_month}</td>
                                  <td>{service_line.count_1_month}</td>
                                  <td>{service_line.count_total}</td>
                        </ExpandingRow>
                    )}
                </tbody>
            </table>

            <footer>
                <div>
                </div>
            </footer>

            <h3 ref={alertReviewRef}>Alert Word Reviews</h3>
                <div className={`merchant-service-feedback service-google`}>
                    { merchantReport.alert_word_reviews && merchantReport.alert_word_reviews.length === 0
                        ? <span>No Alert Word Reviews Found</span>
                        : merchantReport.alert_word_reviews.map(e => <ReviewEntry review={e} key={e.id} />)
                    }
                </div>

            <footer>
                <div>
                </div>
            </footer>

            <hr />

            <p>
                <strong>No reviews</strong> - Click on any source to do a Google search for [source name] + [business name] + [city, state]
                and verify there is no profile page or reviews for the business.
            </p>
            <p>
                <strong>Corrections</strong> - If you are able to match a business to a review site that is not linked here, or if you believe
                a review site is mis-matched, please click on the "edit" button and paste the corrected URL. Verdata's team will review the
                correction to verify. This way, Verdata will monitor the corrected URL for changes on an ongoing basis.
            </p>

            { editReviewsURLData && (
                <Modal>
                    <SubmitReviewsURLForm
                        merchant={merchantReport.merchant}
                        source={editReviewsURLData}
                        onClose={() => setEditReviewsURLData(null)}
                    />
                </Modal>
            )}
        </div>
    )
}
