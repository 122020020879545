import React, { useState, useEffect, useContext, Fragment, useRef } from 'react'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'
import useApi from 'hooks/useApi'
import Modal from 'shared/Modal'
import ComplianceAgreement from "./ComplianceAgreement"
import ErrorsContext from "ErrorsContext";
import CreatableSelect from 'react-select/creatable';



import './OnboaringForm.scss'
import toast from 'react-hot-toast'

const defaultInquiry = {
    name: '', ein: '',
    addr1: '', city: '', state: '', zip5: '',
    email: '', phone: '', domain: '', store_id: '', alt_phone: '', domain_name: '',
}

const defaultPrincipal = {
    first_name: '', middle_name: '', last_name: '', suffix: '',
    addr1: '', city: '', state: '', zip5: '',
    email: '', phone: ''
}
let lastInquiry, lastInquiryPrincipals

const agreementStatuses = {
    checking: 'checking',
    ok: 'ok',
    stale: 'stale'
}

const agreementCheckInterval = 30 * 60 * 1000; // 30 mins

export default withRouter(OnboardingForm)
function OnboardingForm({history}){
    const [ inquiry, setInquiry ] = useState(lastInquiry || defaultInquiry)
    const [ inquiryPrincipals, setInquiryPrincipals ] = useState(lastInquiryPrincipals || [])
    const [ agreementStatus, setAgreementStatus ] = useState(agreementStatuses.checking)
    const [ agreementRecheck, setAgreementRecheck ] = useState(0)
    const { apiPost, apiGet } = useApi()
    const { setError } = useContext(ErrorsContext)
    const [ quotaStatus, setQuotaStatus] = useState(null)
    const [ monthlyAllowance, setMonthlyAllowance] = useState(null)
    // eslint-disable-next-line
    const [ creditBalance, setCreditBalance ] = useState(null)

    //autocomplete for city, state, zip
    const [totalOptions, setTotalOptions] = useState([])
    const [cityOptions, setCityOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [zipOptions, setZipOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedZip, setSelectedZip] = useState("");
    const selectedStateRef = useRef();

    const lender = localStorage.getItem('lender')

    // To show new or old UI, temporary
    const lender_obj = JSON.parse(localStorage.getItem('lender'))
    const filtered_lenders = ['f8489ddb-c290-4afe-8be2-ddd1f472870b', '410ca628-b399-421f-9fd9-c922c9cab0c3', '31e1e58c-8cfe-4b38-8b89-81f01808d86e']
    const showNewMatching = filtered_lenders.includes(lender_obj.id)


    useEffect(() => {
        if (inquiry){
            if (!!inquiry.state){
                setSelectedState({ value: inquiry.state, label: inquiry.state })
            }
            if (!!inquiry.zip5){
                setSelectedZip({ value: inquiry.zip5, label: inquiry.zip5 })
            }
            if (!!inquiry.city){
                setSelectedCity({ value: inquiry.city, label: inquiry.city })
            }
        }
    }, [])



    useEffect(() => {
        if (!totalOptions?.length) return;
        
        if (selectedState) {
            // First, filter by state
            let stateFiltered = totalOptions.filter(item => 
                item.state === selectedState.value
            );
            
            // If both city and zip are selected, apply both filters
            if (selectedCity && selectedZip) {
                stateFiltered = stateFiltered.filter(item => 
                    item.city === selectedCity.value && 
                    item.zip5 === selectedZip.value
                );
            }
            // If only city is selected, filter by city and update zip options
            else if (selectedCity) {
                stateFiltered = stateFiltered.filter(item => 
                    item.city === selectedCity.value
                );
                
                // Update zip options based on selected city
                const uniqueZips = [...new Set(stateFiltered.map(item => item.zip5))];
                const newZipOptions = uniqueZips.map(zip => ({value: zip, label: zip })).sort((a, b) => a.value - b.value);
                setZipOptions(newZipOptions);

            }
            // If only zip is selected, filter by zip and update city options
            else if (selectedZip) {
                stateFiltered = stateFiltered.filter(item => 
                    item.zip5 === selectedZip.value
                );
                
                // Update city options based on selected zip
                const uniqueCities = [...new Set(stateFiltered.map(item => item.city))];
                const newCityOptions = uniqueCities.map(city => ({value: city, label: city })).sort((a, b) => a.label.localeCompare(b.label));
                setCityOptions(newCityOptions);

            }
            // If only state is selected, show all cities and zips for that state
            else {
                const uniqueCities = [...new Set(stateFiltered.map(item => item.city))];
                const uniqueZips = [...new Set(stateFiltered.map(item => item.zip5))];
                
                setCityOptions(uniqueCities.map(city => ({value: city, label: city})).sort((a, b) => a.label.localeCompare(b.label)));
                setZipOptions(uniqueZips.map(zip => ({ value: zip, label: zip })).sort((a, b) => a.value - b.value));
            }
    
            // Update inquiry state
            setInquiry(prev => ({
                ...prev,
                state: selectedState.value,
                city: selectedCity?.value || '',
                zip5: selectedZip?.value || ''
            }));
        }
    }, [selectedState, selectedCity, selectedZip, totalOptions]);



    useEffect(() => {
        apiGet('user/check-inquiry-terms').then(({status}) => {
            setAgreementStatus( status === 'ok' ? agreementStatuses.ok : agreementStatuses.stale)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreementRecheck])

    useEffect(() => {
       const interval = setInterval(() => setAgreementRecheck(agreementRecheck+1), agreementCheckInterval)
       return () => clearInterval(interval)
    }, [agreementRecheck, setAgreementRecheck])

    useEffect(() => {
        apiGet('quota_details').then(result =>{
            setCreditBalance(result.credit_balance)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lender])

    // call to grab settings, specifically if status is active and monthly allowance
    useEffect(() => {
        apiGet('quota_settings').then(result =>{
            setQuotaStatus(result.is_active)
            setMonthlyAllowance(result.monthly_allowance)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lender])

    useEffect(() => {
        const params = {}
        apiGet('inquiry/address', params)
            .then(data => {
                setTotalOptions(data)
                // Extract unique cities, states, and zips from the response
                const uniqueStates = [...new Set(data.map(item => item.state))];

                // Convert cities, states, and zips to format expected by React Select
                const stateOptions = uniqueStates.map(state => ({ value: state, label: state })).sort((a, b) => a.label.localeCompare(b.label));

                // Update state variables with options
                setStateOptions(stateOptions);
            })
    }, []);

    // add conditional here
    function submitInquiry(inquiry){
        setError(null)

        let endpoint = 'inquiry/new'

        apiPost(endpoint, inquiry)
        .then(data => {
            if (quotaStatus === true) {
                apiGet('quota_details').then(result =>{
                    setCreditBalance(result.credit_balance)
                    if ((result.credit_balance <= (0.15*monthlyAllowance)) && (result.email_sent === false) ) {
                        const payload = {
                            "status": true
                        };
                        apiPost('quota_details/update_email_sent', payload).then(
                        ).catch(err => {
                            console.log(err.message)
                        })
                        toast('85% of Inquiry Credit Balance has been used')
                    }
                });
            }
            history.push(`/onboarding/${data.id}`)
        })

    }
    

    function handleSubmit(ev){
        ev.preventDefault()
        if (agreementStatus !== agreementStatuses.ok ) return
        // add condition here
        let isBusinessFormValid = (inquiry.name || (inquiry.addr1 && inquiry.state) || inquiry.phone || inquiry.store_id);

        if (showNewMatching) {
            isBusinessFormValid = ((inquiry.name && inquiry.state && inquiry.zip5) || inquiry.store_id);
        } 

        let isPrincipalsFormValid = (
            inquiryPrincipals && inquiryPrincipals.length > 0 && (
                    inquiryPrincipals[0].last_name || (inquiryPrincipals[0].addr1 && inquiryPrincipals[0].state) || inquiryPrincipals[0].phone
            )
        );

        if (showNewMatching) {
            isPrincipalsFormValid = (
                inquiryPrincipals && inquiryPrincipals.length > 0 && (
                    inquiryPrincipals[0].state && (inquiryPrincipals[0].last_name || inquiryPrincipals[0].addr1 || inquiryPrincipals[0].phone)
                )
            );
        }

        // if quota system is active, and if credit balance is <= 15% of monthly allowance + email notif hasnt been sent, send low balance email
        if (isBusinessFormValid || isPrincipalsFormValid) {
            setError(null)
            inquiry.principals = inquiryPrincipals
            submitInquiry(inquiry)
            lastInquiry = inquiry
            lastInquiryPrincipals = inquiryPrincipals
        }
        else {
            let errorContent = (
                <Fragment>
                    One of the following is required to search:<br /><br />
                        <ul style={{ textAlign: "left" }}>
                            <li>Business Name</li>
                            <li>Business Address and State</li>
                            <li>Business Phone</li>
                            <li>Principal / Owner Last Name</li>
                            <li>Principal / Owner Address and State</li>
                            <li>Principal / Owner Phone</li>
                            <li>Store ID</li>
                        </ul>
                </Fragment>
            );
            // add condition here
            if (showNewMatching) {
                errorContent = (
                    <Fragment>
                        One of the following is required to search:<br /><br />
                            <ul style={{ textAlign: "left" }}>
                                <li>Business Name, State and Zip</li>
                                <li>Principal / State, Owner Last Name</li>
                                <li>Principal / State, Owner Address</li>
                                <li>Principal / State, Owner Phone</li>
                                <li>Store ID</li>
                            </ul>
                    </Fragment>
                );
            }


            setError({ userMessage: errorContent })
        }
    }

    function handleReset(ev){
        ev.preventDefault()
        setInquiry(defaultInquiry)
        setInquiryPrincipals([])
        lastInquiry = lastInquiryPrincipals = undefined
    }

    function handleStateReset(){
        // selectedStateRef.current.clearValue()

        setSelectedState("")
        setSelectedCity("")
        setSelectedZip("")

        setInquiry({
            ...inquiry,
            ["state"]: ""
        })
        setInquiry({
            ...inquiry,
            ["city"]: ""
        })
        setInquiry({
            ...inquiry,
            ["zip5"]: ""
        })
    }

    function handleCityReset(){
        setSelectedCity("")

        setInquiry({
            ...inquiry,
            ["city"]: ""
        })

    }

    function handleZipReset(){
        setSelectedZip("")

        setInquiry({
            ...inquiry,
            ["zip5"]: ""
        })
    }

    function setInquiryValue(key){
        return (ev) => {
            if (key === "phone") {
                ev.target.value = ev.target.value.replace(/\D+/g, "");
            }
            if (key === "alt_phone") {
                ev.target.value = ev.target.value.replace(/\D+/g, "");
            }
            setInquiry({
                ...inquiry,
                [key]: ev.target.value
            })
        }
    }

    function setInquiryPrincipal(atIndex){
        return (newPrincipal) => {
            const newPrincipals = inquiryPrincipals.slice()
            newPrincipals.splice(atIndex, 1, newPrincipal)
            setInquiryPrincipals(newPrincipals)
        }
    }

    function acceptAgreement(){
        setAgreementStatus(agreementStatuses.ok)
    }

    return (
      <div className='onboarding-form'>
        <form onSubmit={handleSubmit}
              onReset={handleReset}
              className={agreementStatus !== agreementStatuses.ok ? 'is-disabled' : ''}>
            <div className="form-header">
                <h1>Seller</h1>
            </div>

            <div className="field field-business-name">
                <label htmlFor="business-name">Business Name or DBA
                </label>
                <input type="text" id="business-name" autoFocus
                       value={inquiry.name} onChange={setInquiryValue('name')}
                />
            </div>

            <div className="field field-fein">
                <label htmlFor="fein">FEIN / SSN</label>
                <input type="text" id="fein"
                       value={inquiry.ein} onChange={setInquiryValue('ein')}
                />
            </div>
            {/* add condition here */}
            {showNewMatching && 
            <div className="field field-state">
                <label htmlFor="business-state">{"  " + "State" + " "}
                    <i className="fa fa-rotate-right" aria-hidden="true" onClick={() => handleStateReset()}></i>
                    <span style={{ color: 'red' }}> *</span>
                </label>
                <Select className="select"
                    ref={selectedStateRef}
                    id="business-state"
                    style={{ backgroundColor: '#ffffff' }}
                    value={selectedState}
                    onChange={(value) => {handleStateReset(); setSelectedState(value)}}
                    options={stateOptions}
                />
            </div>            
            }
            
            <div className="field field-address">
                <label htmlFor="business-address">Street Address
                </label>
                <input type="text" id="business-address"
                       value={inquiry.addr1} onChange={setInquiryValue('addr1')}
                 />
            </div>
            {/* add condition here */}
            {showNewMatching && 
            <div className="field field-city">
                <label htmlFor="business-city">{" " + "City "}
                <i className="fa fa-rotate-right" aria-hidden="true" onClick={() => handleCityReset()}></i>
                </label>
                <CreatableSelect
                    className="select"
                    id="business-city"
                    value={selectedCity}
                    onChange={(value) => {
                        setSelectedCity(value);
                        setInquiry(prev => ({
                            ...prev,
                            city: value?.value || value?.inputValue || ''
                        }));
                    }}
                    options={cityOptions}
                    noOptionsMessage={({inputValue}) => !inquiry.state ? "State Required" : !inputValue ? "No Options Found" : "No Options Found"}
                    formatCreateLabel={(inputValue) => `Use "${inputValue}"`}
                />
            </div>
            }

            {/* add condition here */}
            {showNewMatching && 
            <div className="field field-zip">
                <label htmlFor="business-zip">{" " + "Zip "}
                <i className="fa fa-rotate-right" aria-hidden="true" onClick={() => handleZipReset()}></i>
                <span style={{ color: 'red' }}> *</span>
                </label>
                <CreatableSelect
                    className="select"
                    id="business-zip"
                    value={selectedZip}
                    onChange={(value) => {
                        // handleCityReset();
                        setSelectedZip(value);
                        setInquiry(prev => ({
                            ...prev,
                            zip5: value?.value || value?.inputValue || ''
                        }));
                    }}
                    options={zipOptions}
                    noOptionsMessage={({inputValue}) => !inquiry.state ? "State Required" : !inputValue ? "No Options Found" : "No Options Found"}
                    formatCreateLabel={(inputValue) => `Use "${inputValue}"`}
                />
            </div>            
            }

            {/* add condition here */}
            {!showNewMatching && 
            <>
            <div className="field field-city">
                <label htmlFor="business-city">City</label>
                <input type="text" id="business-city" value={inquiry.city} onChange={setInquiryValue('city')}/>
            </div>

            <div className="field field-state">
                <label htmlFor="business-state">State</label>
                <input type="text" id="business-state" value={inquiry.state} onChange={setInquiryValue('state')}/>
            </div>

            <div className="field field-zip">
                <label htmlFor="business-zip">Zip
                </label>
                <input type="text" id="business-zip" value={inquiry.zip5} onChange={setInquiryValue('zip5')}/>
            </div>    
            </>        
            }


            <div className="field field-business-phone">
                <label htmlFor="business-phone">Business Phone</label>
                <input type="phone" id="business-phone"
                       value={inquiry.phone} onChange={setInquiryValue('phone')}
                 />
            </div>
                <div className="field field-alt-phone">
                    <label htmlFor="alt-phone">Alternate Phone</label>
                    <input type="phone" id="alt-phone"
                        value={inquiry.alt_phone} onChange={setInquiryValue('alt_phone')}
                    />
                </div>
                <div className="field field-business-email">
                <label htmlFor="business-email">Business Email</label>
                <input type="email" id="business-email"
                       value={inquiry.email} onChange={setInquiryValue('email')}
                 />
            </div>
                <div className="field field-domain-name">
                    <label htmlFor="domain-name">Domain Name</label>
                    <input type="text" id="domain-name"
                        value={inquiry.domain_name} onChange={setInquiryValue('domain_name')}
                    />
                </div>

            <h1>Principal / Owner</h1>

            <InquiryPrincipalForm principal={inquiryPrincipals[0]}
                                  onChange={setInquiryPrincipal(0)} />

            <h1>Store ID</h1>
            <div className="field field-store-id">
                <label htmlFor="store_id">Store ID</label>
                <input type="text" id="store_id"
                       value={inquiry.store_id} onChange={setInquiryValue('store_id')}
                 />
            </div>

            <footer>
                <button type="submit" className="primary"
                        disabled={agreementStatus !== agreementStatuses.ok}>
                    Search
                </button>
                <button type="reset">Clear</button>
            </footer>
        </form>

        { agreementStatus === agreementStatuses.stale && (
            <Modal>
                <ComplianceAgreement onAccepted={acceptAgreement}/>
            </Modal>
        )}
      </div>
    )
}


function InquiryPrincipalForm({principal= defaultPrincipal, onChange}){

    function setPrincipalValue(key){
        return (ev)=> {
            if (key === "phone") {
                ev.target.value = ev.target.value.replace(/\D+/g, "");
            }
            const newPrincipal = {
                ...principal,
                [key]: ev.target.value
            }
            onChange(newPrincipal)
        }
    }

    return (
        <div className="InquiryPrincipalForm">
            <div className="field field-first-name">
                <label htmlFor="first-name">First Name</label>
                <input type="text" id="first-name"
                       value={principal.first_name} onChange={setPrincipalValue('first_name')}
                  />
            </div>
            <div className="field field-middle-name">
                <label htmlFor="middle-name">Middle Name</label>
                <input type="text" id="middle-name"
                       value={principal.middle_name} onChange={setPrincipalValue('middle_name')}
                   />
            </div>
            <div className="field field-last-name">
                <label htmlFor="last-name">Last Name</label>
                <input type="text" id="last-name"
                       value={principal.last_name} onChange={setPrincipalValue('last_name')}
                   />
            </div>
            <div className="field field-name-suffix">
                <label htmlFor="name-suffix">Suffix</label>
                <input type="text" id="name-suffix"
                       value={principal.suffix} onChange={setPrincipalValue('suffix')}
                   />
            </div>


            <div className="field field-address">
                <label htmlFor="personal-address">Street Address</label>
                <input type="text" id="personal-address"
                       value={principal.addr1} onChange={setPrincipalValue('addr1')}
                   />
            </div>
            <div className="field field-city">
                <label htmlFor="personal-city">City</label>
                <input type="text" id="personal-city"
                       value={principal.city} onChange={setPrincipalValue('city')}
                   />
            </div>
            <div className="field field-state">
                <label htmlFor="personal-state">State</label>
                <input type="text" id="personal-state"
                       value={principal.state} onChange={setPrincipalValue('state')}
                   />
            </div>
            <div className="field field-zip">
                <label htmlFor="personal-zip">Zip</label>
                <input type="text" id="personal-zip"
                       value={principal.zip5} onChange={setPrincipalValue('zip5')}
                   />
            </div>


            <div className="field field-personal-email">
                <label htmlFor="personal-email">Personal Email</label>
                <input type="text" id="personal-email"
                       value={principal.email} onChange={setPrincipalValue('email')}
                   />
            </div>
            <div className="field field-personal-phone">
                <label htmlFor="personal-phone">Personal Phone</label>
                <input type="phone" id="personal-phone"
                       value={principal.phone} onChange={setPrincipalValue('phone')}
                   />
            </div>
        </div>
    )
}

