// import React from 'react'
import { withRouter } from 'react-router-dom'
import Loading from 'shared/Loading'
import Modal from 'shared/Modal'
import MerchantRequest from './MerchantRequest'
import useApi from '../../hooks/useApi'

import './SelectMerchant.scss'
import ExternalLink from "../../shared/ExternalLink";
import React, { useState, useContext, useEffect } from 'react'
import DoctorMetadata from '../../shared/DoctorMetadata'
import SessionContext from "../../SessionContext";




export default SelectMerchant
function SelectMerchant({inquiryResults, inquiryTooManyResults, inquiryId}){
    const { user } = useContext(SessionContext)
    const [seeMore, setSeeMore] = useState(false)
    const [showMerchantRequestModal, setShowMerchantRequestModal] = useState(false)
    const { apiPost, apiGet } = useApi()
    const [inquiry, setInquiry] = useState({'business_name': '', 'business_address': '', 'business_city': '', 'business_state': '', 'inquiry_id': inquiryId})
    const [ matches, setMatches ] = useState([{}])
    const [ requestChecked, setRequestChecked ] = useState(false)
    const [ merchantRequestSent, setMerchantRequestSent ] = useState(false)

    useEffect(() => {
        if ((!inquiryResults || inquiryResults.length === 0)){
            if(!!inquiry.business_name && !!inquiry.business_address && !!inquiry.business_city && !!inquiry.business_state){
                let request = { ...inquiry };

                apiPost('merchant/request_check', request)
                    .then(res => {
                        if(res){
                            setMatches(res)
                            setRequestChecked(true)
                        }
                    })
            }
        }

    },[inquiry])

    useEffect(() => {
        if(!!requestChecked && matches.length === 0){
            // Request has not been submitted before
            setMerchantRequestSent(true)
            apiPost('merchant/request', inquiry);
        }
    },[requestChecked, matches])

    useEffect(() => {
        if (!!inquiryId) {
            apiGet(`inquiry/details/${inquiryId}`).then(data => {

                let temp_request = { ...inquiry }; // Create a new object based on defaultrequest
                if ('name' in data) {
                    temp_request['business_name'] = data.name;
                }
                if ('addr1' in data) {
                    temp_request['business_address'] = data.addr1;
                }
                if ('city' in data) {
                    temp_request['business_city'] = data.city;
                }
                if ('state' in data) {
                    temp_request['business_state'] = data.state;
                }
                if ('lender' in data) {
                    temp_request['created_by'] = user.email;
                }
                temp_request['inquiry_id'] = inquiryId
                temp_request['business_phone'] = "" 
                setInquiry(temp_request); // Update the state
            }).catch(response => {
                console.log(response);
            });
        }
    }, [inquiryId]);
    

    function closeModal() {
        setShowMerchantRequestModal(false)
    }


    function handleSeeMore() {

        if (seeMore === false) {
            setSeeMore(true)
        } else {
            setSeeMore(false)
        }   

    } 

    if (!inquiryResults) return <Loading />

    return <div className="SelectMerchant">
        <div className='header-with-button'>
            <h1>Merchant Matches</h1>
            {(!merchantRequestSent) && <button onClick={() => {setShowMerchantRequestModal(true)}}>Trouble Finding a Business? Click Here</button>}
        </div>
                
        {inquiryTooManyResults && 
            <>                  
                <span>                
                    <h3>
                        Too many results found, please narrow your search
                    </h3>
                </span>  

                
                {inquiryResults.slice(0,20).map( result =>
                    <div key={result.merchant.id} className="match-group">
                        <InquiryMerchant inquiryId={inquiryId} {...result} />
                    </div>
                )}
                {seeMore && inquiryResults.slice(21, 40).map( result =>
                    <div key={result.merchant.id} className="match-group">
                        <InquiryMerchant inquiryId={inquiryId} {...result} />
                    </div>
                )}
                <button className="primary" id="more-results" onClick={()=> handleSeeMore()}> {!seeMore ? 'See More Results' : 'See Less Results'}</button>        

            </>
        }
        {(!inquiryResults || inquiryResults.length === 0) ? 
            (merchantRequestSent ? (
                <h3>
                    No results found, please expand your search.<br/> We have submitted a request to find this business automatically
                </h3>
            ) : (
                <h3>
                    No results found, please expand your search.
                </h3>
            )) : (<></>)
        }
        { inquiryResults && !inquiryTooManyResults && inquiryResults.map( result =>
            <div key={result.merchant.id} className="match-group">
                <InquiryMerchant inquiryId={inquiryId} {...result} />
            </div>
        )}
        <div className="merchant-request-modal">
            {showMerchantRequestModal &&
                <Modal>
                    <MerchantRequest inquiry={inquiry} closeMerchantRequestModal={closeModal}>
                    </MerchantRequest>
                    
                </Modal>
            }
        </div>
    </div>
}


const InquiryMerchant = withRouter( ({history, merchant, principals, doctors, inquiryId}) => {
    let principal_names = new Set(
        principals.map(principal => `${principal.first_name} ${principal.last_name} ${principal.principal_type ? '('+principal.principal_type+')' : ''}`)
    )

    function dedupeDoctors(doctors) {
        const seen = new Set();
        return doctors.filter(obj => {
          if (seen.has(obj.npi_id)) {
            return false;
          } else {
            seen.add(obj.npi_id);
            return true;
          }
        });
    }

    principal_names = Array.from(principal_names).sort()
    // console.log('heres my npi??: ', NPI)
    return (
        <div className="merchant">
            <h2>{merchant.name}</h2>
            <p>
                {merchant.name_dba !== '' ? 
                <>
                    <i>DBA: {merchant.name_dba}</i>
                    <br></br>

                </> 
                :
                <></>}
                <FormatAddress {...merchant} />
                <FormatPhone phone={merchant.phone} />
                <FormatDomainAddress domain_name={merchant.domain_name} />
                <FormatSicCode sic_code={merchant.sic_code} />
                <FormatFEIN ein={merchant.ein} />
            </p>
            <p>
                <strong>Principals</strong><br />
                { principal_names && principal_names.map( principal_name =>
                    <span key={principal_name}>
                        {principal_name}
                        <br />
                    </span>
                )}
            </p>
            {(doctors?.length > 0) &&
                <p>
                    <span>
                        <strong>Doctors</strong><br />
                        { dedupeDoctors(doctors)?.slice(0, 3).map((p, index) =>
                            <React.Fragment key={p.id}>
                                <DoctorMetadata doctor={p} />
                            </React.Fragment>
                        )}
                    </span>
                </p> 
            }
            <i className="spacer" />
            <button className="primary" onClick={()=> history.push(`/onboarding/${inquiryId}/${merchant.id}`)}>Run Report</button>
        </div>
    )
})

function FormatDomainAddress({domain_name}) {
    return ( domain_name && domain_name.length > 1 ) && <><ExternalLink href={domain_name}>Business Domain </ExternalLink><br /></>
}

function FormatSicCode({sic_code}) {
    return (sic_code) && <>SIC Code: {sic_code}<br /></>;
}

function FormatFEIN({ein}) {
    return (ein) && <>FEIN No: {ein}<br /></>;
}

function FormatAddress({addr1, addr2, city, state, zip5, zip4}) {
    let address1 = addr1;
    if (addr2) address1 += ", " + addr2;

    let address2 = city;
    if (state) address2 += ", " + state;
    if (zip5) address2 += " " + zip5;
    if (zip4) address2 += "-" + zip4;

    return (
        (address1 && address1.length > 0) ? <>{address1}<br />{address2}<br /></> :
        (!address1 && address2 && address2.length > 0) && <>{address2}<br /></>
    );
}

function FormatPhone({phone}) {
    if (phone) {
        let bare_phone = phone.replaceAll(/[^0-9]/g, '');
        const phone_str = (bare_phone.length === 10)
            ? "(" + bare_phone.slice(0,3) + ") " + bare_phone.slice(3,6) + "-" + bare_phone.slice(6)
            : phone;
        return phone_str && <>{phone_str} <br /></>
    }
    return phone
}
