import React from 'react'
import {score2category, score2className} from 'utils'

import './ScoreInfo.scss'
import vRiskLogo from 'assets/vrisk-logo.png'

export default function ScoreInfo({score}){
    const rank = score ? score2className(score) : 'unknown'
    const classNames = ["score-display", 'rank-score-' + rank].filter(Boolean).join(' ');

    return (
        <div className="score-info">
            <div className={classNames}>
                {score ? score : 'N/A'}
            </div>
            <div className="score-display-details">
                <img src={vRiskLogo} alt="vRisk" />
                <br />
                <strong>{score ? score2category(score) : 'Unknown'} Risk</strong>
                <br />
                <br />
                {/*<span className="more-information">No risk factors identified</span>*/}
            </div>
        </div>
    )
}