import React, { useState, useEffect } from 'react'
import './PrincipalPanel.scss'
import useApi from '../../../hooks/useApi'
import useInterval from '../../../hooks/useInterval'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function Principal({merchantReport, principal_name_lookup, principal_tlo, update_principal_tlo, principal_tlo_details, is_updating, principal_cobalt, printVersion}){

    const { apiGet, apiPost } = useApi()
    const [isActive, setIsActive] = useState([]);
    const [isInitialPrintState, setInitialPrintState] = useState(false);
    const [merchantReportPrincipals, setMerchantReportPrincipals] = useState([])
    const [nonSosPrincipals, setNonSosPrincipals] = useState([])
    const [ get_tlo, setGetTlo ] = useState(null)
    const [ name_lookup, setNameLookup ] = useState([])

    useEffect(() => {
        let temp_principals = []
        let full_temp_principals = []
        if(merchantReport){
            merchantReport.principals.forEach(principal => {
                full_temp_principals.push(principal)
                if(principal.email || principal.phone){
                    temp_principals.push(principal)
                }
            })
        }

        if(temp_principals.length >= 2){
            temp_principals.sort((a,b) => {
                if(a.first_name + " " + a.last_name < b.first_name + " " + b.last_name){
                    return -1
                }
                if(a.first_name + " " + a.last_name > b.first_name + " " + b.last_name){
                    return 1
                }
                return 0
            })
        }

        if(full_temp_principals.length >= 2){
            full_temp_principals.sort((a,b) => {
                if(a.first_name + " " + a.last_name < b.first_name + " " + b.last_name){
                    return -1
                }
                if(a.first_name + " " + a.last_name > b.first_name + " " + b.last_name){
                    return 1
                }
                return 0
            })
        }


        if(principal_cobalt.length >= 1){
            if(principal_cobalt.length >= 2){
                principal_cobalt.sort((a,b) => {
                    if(a.first_name + " " + a.last_name < b.first_name + " " + b.last_name){
                        return -1
                    }
                    if(a.first_name + " " + a.last_name > b.first_name + " " + b.last_name){
                        return 1
                    }
                    return 0
                })
            }
            if(isInitialPrintState){
                principal_cobalt.forEach(principal => {
                    toggleAll(principal.id)
                })
            }
        }
        setMerchantReportPrincipals(full_temp_principals)
        setNonSosPrincipals(temp_principals)

    }, [merchantReport, principal_tlo, principal_cobalt])


    function toggle(button){
        if(isActive.includes(button)){
            setIsActive(isActive.filter(e => e !== button))
        }
        else{
            setIsActive([...isActive, button])
        }
    }
    
    function toggleAll(index){
        if(!isActive.some(v => [`${index}d`, `${index}p`, `${index}e`, `${index}r`, `${index}a`, `${index}w`, `${index}j`, `${index}l`, `${index}b`].includes(v))){
            setIsActive([...isActive,`${index}d`, `${index}p`, `${index}e`, `${index}r`, `${index}a`, `${index}w`, `${index}j`, `${index}l`, `${index}b`,])
        }
        else{
            setIsActive(prevState => {
                return prevState.filter(state => ![`${index}d`, `${index}p`, `${index}e`, `${index}r`, `${index}a`, `${index}w`, `${index}j`, `${index}l`, `${index}b`].includes(state))
            })
        }
    }

    //NEEDS TO BE REWORKED
    function openAllTabs() {
        let temp = []
        principal_tlo.forEach(tlo => {
            temp.push(`${tlo.principal_id}d`, `${tlo.principal_id}p`, `${tlo.principal_id}e`, `${tlo.principal_id}r`, `${tlo.principal_id}a`, `${tlo.principal_id}w`, `${tlo.principal_id}j`, `${tlo.principal_id}l`, `${tlo.principal_id}b`)
        })
        if(principal_cobalt.length >= 1){
            principal_cobalt.forEach(principal => {
                temp.push(`${principal.id}d`, `${principal.id}p`, `${principal.id}e`, `${principal.id}r`, `${principal.id}a`, `${principal.id}w`, `${principal.id}j`, `${principal.id}l`, `${principal.id}b`)
            })
        }
        if(nonSosPrincipals){
            nonSosPrincipals.map((principal, index) => {
                temp.push(`${index}d`, `${index}p`, `${index}e`, `${index}r`, `${index}a`, `${index}w`, `${index}j`, `${index}l`, `${index}b`)
            })
        }
        setIsActive(temp)
        setInitialPrintState(true)
    }

    function normalize(obj){
        if(isNaN(parseInt(obj))){
            return 0
        }
        else{
            return parseInt(obj)
        }
    }

    useEffect(() => {
        var tmpnamearr = []
        merchantReportPrincipals.map((principal, index) => {
            var tmpnamedict = {}
            tmpnamedict['id'] = principal.id
            tmpnamedict['first_name'] = principal.first_name
            tmpnamedict['last_name'] = principal.last_name
            tmpnamearr.push(tmpnamedict)
        })
        principal_name_lookup.map((principal, index) => {
            var tmpnamedict = {}
            tmpnamedict['id'] = principal.principal
            var name = principal.full_name.split(" ")
            if (name[0] === name[0].toUpperCase()) {
                tmpnamedict['first_name'] = name[0].charAt(0).toUpperCase() + name[0].slice(1).toLowerCase()
            }else{
                tmpnamedict['first_name'] = name[0]
            }
            if(name.length >> 1){
                if (name[1] === name[1].toUpperCase()) {
                    tmpnamedict['last_name'] = name[1].charAt(0).toUpperCase() + name[1].slice(1).toLowerCase()
                }else{
                    tmpnamedict['last_name'] = name[1]
                }
            }else{
                tmpnamedict['last_name'] = ""
            }
            tmpnamearr.push(tmpnamedict)
        })
        var uniqueObjects = Object.values(tmpnamearr.reduce((acc, obj) => {
            // Use the id as the key to check for uniqueness
            acc[obj.id] = acc[obj.id] || obj;
            return acc;
        }, {}));

        setNameLookup(uniqueObjects)

    }, [principal_name_lookup, merchantReportPrincipals])


    return (
        <div className="box1">
            <h2>Principal Information</h2>
            {principal_tlo.length === 1 && principal_tlo[0].principal_id === null && principal_cobalt.length === 0 && nonSosPrincipals.length === 0 ? <div>No Principal Information Available for this Business.</div> : ""}
            {principal_cobalt.length === 0 ? "" : <>
                {principal_cobalt.map((principal, index) => 
                    <div key={index}>
                        <h3 className="h3NoTop">{principal.first_name + " " + principal.last_name}
                            { (printVersion) && (!isInitialPrintState) && openAllTabs()}
                            {(!printVersion) && <button onClick={() => toggleAll(`${principal.id}`)} style={{marginLeft:'auto', marginRight:0, float:'right', transform: 'translate(0%,-15%)'}}>
                                {(!isActive.includes(`${principal.id}a`)) ? 'Open All' : 'Close All'}</button>}
                            <small style={{marginRight:'32%', float:'right', fontWeight:'normal'}}>Corporate Filing</small>
                        </h3>
                        <div className="grid2">
                            <div>
                                <span>
                                    <button className="button" onClick={() => toggle(`${principal.id}a`)}>{isActive.includes(`${principal.id}a`) ? (
                                        <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                    ) : (
                                        <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                    )}<b>Address</b>&ensp;</button><br/>
                                    {isActive.includes(`${principal.id}a`) && (
                                        <>
                                        <span style={{marginLeft:10, fontSize:'.9em'}}>Street Address:  {!!principal.addr1 ? principal.addr1 : 'N/A'}</span><br/>
                                        <span style={{marginLeft:10, fontSize:'.9em'}}>City:  {!!principal.city ? principal.city : 'N/A'}</span><br/>
                                        <span style={{marginLeft:10, fontSize:'.9em'}}>State:  {!!principal.state ? principal.state : 'N/A'}</span><br/>
                                        <span style={{marginLeft:10, fontSize:'.9em'}}>Zip:  {!!principal.zip5 ? principal.zip5 : 'N/A'}</span><br/>
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </>}
            {/* WORKING HERE */}
            {merchantReport.principals.length === 0 && name_lookup.length === 0 ? "" : <>
            {name_lookup.map((principal, index) => 
                principal_tlo.filter(tlo => principal.id === tlo.principal_id).map((tlo)=>
                <div key={index}>
                <h3 className="h3NoTop">{tlo.can_update && (
                    !is_updating.includes(tlo.principal_id) ? (
                        <i className="fa fa-rotate-right" aria-hidden="true" onClick={() => update_principal_tlo(tlo)}></i>
                    ) : (
                        <i className="fa fa-hourglass-start" aria-hidden="true"></i>
                    )
                )}{"  " + principal.first_name + " " + principal.last_name}&ensp;
                {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                        calcPrincipal(principalDetail) !== null ?
                        <div className="block-meter rank-worst is-small">{calcPrincipal(principalDetail)}</div>
                        : <></>
                    ))}
{ (printVersion) && (!isInitialPrintState) && openAllTabs()}
                {(!printVersion) && <button onClick={() => toggleAll(`${tlo.principal_id}`)} style={{marginLeft:'auto', marginRight:0, float:'right', transform: 'translate(0%,-15%)'}}>
                    {(!isActive.some(v => [`${tlo.principal_id}d`, `${tlo.principal_id}p`, `${tlo.principal_id}e`, `${tlo.principal_id}r`, `${tlo.principal_id}a`, `${tlo.principal_id}w`, `${tlo.principal_id}j`, `${tlo.principal_id}l`, `${tlo.principal_id}b`].includes(v))) ? 'Open All' : 'Close All'}</button>}
                    <small style={{marginRight:'27%', float:'right', fontWeight:'normal'}}>Bankruptcy, Lien, Judgements</small>
                </h3>
                    {!!tlo.updated_at ? 
                    <div>
                        {!((!!tlo.DECEASED_003) || (!!tlo.RELATIVES_SUMMARY_003 || !!tlo.RELATIVES_SUMMARY_002) || 
                        (!(!tlo.JUDGEMENTS_SUMMARY_002 || tlo.JUDGEMENTS_SUMMARY_002 === "None Found") || !!tlo.JUDGEMENTS_SUMMARY_003 ||  !!tlo.JUDGEMENTS_SUMMARY_004) ||
                        (!!tlo.IND_PROF_LICENSE_002 || !!tlo.IND_PROF_LICENSE_003 ||  !!tlo.IND_PROF_LICENSE_004) ||
                        (!!tlo.ASSOCIATES_SUMMARY_002 || !!tlo.ASSOCIATES_SUMMARY_003) || 
                        (!(!tlo.IND_LIENS_SUMMARY_002 || tlo.IND_LIENS_SUMMARY_002 === "None Found") || !!tlo.IND_LIENS_SUMMARY_003 ||  !!tlo.IND_LIENS_SUMMARY_004) || 
                        (!!tlo.EMPLOYMENT_SUMMARY_01_003 || !!tlo.EMPLOYMENT_SUMMARY_01_002 ||  !!tlo.EMPLOYMENT_SUMMARY_02_003 || !!tlo.EMPLOYMENT_SUMMARY_02_002) ||
                        (!!tlo.IND_WATCH_LIST_SUMMARY_003) ||
                        (!!tlo.IND_BANKRUPTCY_SUMMARY_002 || !!tlo.IND_BANKRUPTCY_SUMMARY_003 ||  !!tlo.IND_BANKRUPTCY_SUMMARY_004 || !!tlo.IND_BANKRUPTCY_SUMMARY_005 || !!tlo.IND_BANKRUPTCY_SUMMARY_006)) ? 
                        "No BLJ Data Available" : 
                        <>
                        </>}

                    {!printVersion && 
                    <>
                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.bankruptcies.length > 0 && 
                            <>
                                <br></br>
                                <div className="carousel-div-container">
                                    <PrincipalBankruptcyCarousel data={principalDetail.bankruptcies} />
                                </div>
                            </>
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.liens.length > 0 && 
                            <>
                                <br></br>
                                <div className="carousel-div-container">
                                    <PrincipalLienCarousel data={principalDetail.liens} />
                                </div>
                            </>
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.judgements.length > 0 && 
                            <>
                                <br></br>
                                <div className="carousel-div-container">
                                    <PrincipalJudgementCarousel data={principalDetail.judgements} />
                                </div>
                            </>
                            
                        ))}


                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.associates.length > 0 && 
                            <>
                                <br></br>
                                <div className="carousel-div-container">
                                    <PrincipalAssociateCarousel data={principalDetail.associates} />
                                </div>
                            </>
                            
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.relatives.length > 0 && 
                            <>
                                <br></br>
                                <div className="carousel-div-container">
                                    <PrincipalRelativeCarousel data={principalDetail.relatives} />
                                </div>
                            </>
                            
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.employers.length > 0 && 
                            <>
                                <br></br>
                                <div className="carousel-div-container">
                                    <PrincipalEmployerCarousel data={principalDetail.employers} />
                                </div>
                            </>
                            
                        ))}
                        <br></br>

                    </>
                    }
                    {printVersion && 
                    <>
                    <div className="print-all-tlo-blj">
                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id)?.map(principalDetail => (
                            principalDetail.bankruptcies.length > 0 && 
                            <>
                                <h2>Bankruptcy Details <div className="block-meter rank-worst is-small">{principalDetail.bankruptcies.length}</div></h2>
                                {principalDetail.bankruptcies?.map((item, index) => (
                                    <div key={index} className="slider-card">
                                        <span><b>Debtor Name:</b> {item.debtor_name}</span>
                                        <span className='rightIndent'><b>Case Number:</b> {item.case_number}</span><br></br>
                                        <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                                        <span className='rightIndent'><b>Chapter:</b> {item.chapter}</span><br></br>
                                        <span><b>Debtor City:</b> {item.debtor_city}</span>
                                        <span className='rightIndent'><b>Court Id:</b> {item.court_id}</span><br></br>
                                        <span><b>Debtor State:</b> {item.debtor_state}</span>
                                        <span className='rightIndent'><b>Judge Name:</b> {item.judge_name}</span><br></br>
                                        <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                                        <span className='rightIndent'><b>Attorney Name:</b> {item.attorney_name}</span><br></br>
                                        <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                                        <span className='rightIndent'><b>Law Firm:</b> {item.law_firm}</span><br></br>
                                        <span><b>File Date:</b> {item.file_date}</span>
                                        <span className='rightIndent'><b>Trustee Name:</b> {item.trustee_name}</span><br></br>
                                        <span><b>Notice Type:</b> {item.notice_type}</span><br></br>
                                    </div>
                                ))}
                                <br></br>
                            </>
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                                                principalDetail.liens.length > 0 && 
                            <>
                                <h2>Lien Details <div className="block-meter rank-worst is-small">{principalDetail.liens.length}</div></h2>
                                {principalDetail.liens?.map((item, index) => (
                                                <div key={index} className="slider-card">

                                                    <span><b>Full Name:</b> {item.full_name}</span>
                                                    <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                                                    <span><b>Plaintiff Business Name:</b> {item.plaintiff_business_name}</span>
                                                    <span className='rightIndent'><b>Filing Type:</b> {item.filing_type}</span><br></br>
                                                    <span><b>Total Amount:</b> {item.total_amount}</span>
                                                    <span className='rightIndent'><b>Court Case Number:</b> {item.court_case_number}</span><br></br>
                                                    <span><b>Recording Date:</b> {item.recording_date}</span>
                                                    <span className='rightIndent'><b>Court Name:</b> {item.court_name}</span><br></br>
                                                    <span><b>Recording Number:</b> {item.recording_number}</span>
                                                    <span className='rightIndent'><b>Extension Date</b> {item.extension_date}</span><br></br>
                                                </div>
                                            ))}
                                            <br></br>
                            </>
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                        principalDetail.judgements.length > 0 && 
                            <>
                                <h2>Judgement Details <div className="block-meter rank-worst is-small">{principalDetail.judgements.length}</div></h2>
                                {principalDetail.judgements?.map((item, index) => (
                                    <div key={index} className="slider-card">
                                        <span><b>Full Name:</b> {item.full_name}</span>
                                        <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                                        <span><b>Plaintiff Business Name:</b> {item.plaintiff_business_name}</span>
                                        <span className='rightIndent'><b>Filing Type:</b> {item.filing_type}</span><br></br>
                                        <span><b>Interest Rate:</b> {item.interest_rate}</span>
                                        <span className='rightIndent'><b>Filing Location:</b> {item.filing_location}</span><br></br>
                                        <span><b>Total Amount:</b> {item.total_amount}</span>
                                        <span className='rightIndent'><b>Court Case Number</b> {item.court_case_number}</span><br></br>
                                        <span><b>Recording Date:</b> {item.recording_date}</span>
                                        <span className='rightIndent'><b>Court Case Name:</b> {item.court_name}</span><br></br>
                                        <span><b>Recording Number:</b> {item.recording_number}</span>
                                    </div>
                                ))}
                                <br></br>
                            </>
                        
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.associates.length > 0 && 
                            <>
                                <h2>Associate Details <div className="block-meter rank-ok is-small">{principalDetail.associates.length}</div></h2>
                                {principalDetail.associates?.map((item, index) => (
                                    <div key={index} className="slider-card">
                                        <span><b>Full Name:</b> {item.full_name}</span>
                                        <span className='rightIndent'><b>State:</b> {item.state}</span><br></br>
                                        <span><b>Address:</b> {item.plaintiff_business_name}</span>
                                        <span className='rightIndent'><b>Zip5:</b> {item.zip5}</span><br></br>
                                        <span><b>City:</b> {item.total_amount}</span>
                                        <span className='rightIndent'><b>Zip4:</b> {item.zip4}</span><br></br>
                                    </div>
                                ))}
                                <br></br>
                            </>
                            
                        ))}
                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                        principalDetail.relatives.length > 0 && 
                            <>
                                <h2>Relatives Details <div className="block-meter rank-unknown is-small">{principalDetail.relatives.length}</div></h2>
                                {principalDetail.relatives?.map((item, index) => (
                                                <div key={index} className="slider-card">

                                                    <span><b>Full Name:</b> {item.full_name}</span>
                                                    <span className='rightIndent'><b>State:</b> {item.state}</span><br></br>
                                                    <span><b>Address:</b> {item.plaintiff_business_name}</span>
                                                    <span className='rightIndent'><b>Zip5:</b> {item.zip5}</span><br></br>
                                                    <span><b>City:</b> {item.total_amount}</span>
                                                    <span className='rightIndent'><b>Zip4:</b> {item.zip4}</span><br></br>
                                
                                                </div>
                                ))}
                                <br></br>
                            </>
                        
                        ))}

                        {principal_tlo_details.length && principal_tlo_details.filter(item => item.id === tlo.principal_id).map(principalDetail => (
                            principalDetail.employers.length > 0 && 
                            <>
                                <h2>Employer Details <div className="block-meter rank-unknown is-small">{principalDetail.employers.length}</div></h2>
                                {principalDetail.employers?.map((item, index) => (
                                    <div key={index} className="slider-card">
                                        <span><b>Business Name:</b> {item.business_name}</span>
                                        <span className='rightIndent'><b>State:</b> {item.state}</span><br></br>
                                        <span><b>Address:</b> {item.plaintiff_business_name}</span>
                                        <span className='rightIndent'><b>Zip5:</b> {item.zip5}</span><br></br>
                                        <span><b>City:</b> {item.total_amount}</span>
                                        <span className='rightIndent'><b>Zip4:</b> {item.zip4}</span><br></br>
                    
                                    </div>
                                ))}
                                <br></br>
                            </>
                            
                        ))}
                    </div>
                    </>
                    }

                    
                    </div> : 
                    <>
                        <div className="blj-refresh-div">
                            <button className="blj-refresh" style={{backgroundColor: "#1997bc", color: "white"}} onClick={() => update_principal_tlo(tlo)} disabled={is_updating.includes(tlo.principal_id)}>Run BLJ for this Principal</button>
                        </div>
                        <div className="grid2"></div>
                    </>
                    }
                </div>))}
                </>}

                {!nonSosPrincipals ? "" : <>
                    {nonSosPrincipals.map((principal, index) => 
                        <div key={index}>
                            <h3 className="h3NoTop">{principal.first_name + " " + principal.last_name}
                                { (printVersion) && (!isInitialPrintState) && openAllTabs()}
                                {(!printVersion) && <button onClick={() => toggleAll(index)} style={{marginLeft:'auto', marginRight:0, float:'right', transform: 'translate(0%,-15%)'}}>
                                    {(!isActive.some(v => [`${index}a`, `${index}e`, `${index}p`].includes(v))) ? 'Open All' : 'Close All'}</button>}
                                    <small style={{marginRight:'29%', float:'right', fontWeight:'normal'}}>Supplementary Sources</small>
                            </h3>
                            <div className="grid2">
                                <div>
                                    <span>
                                        <button className="button" onClick={() => toggle(`${index}a`)}>{isActive.includes(`${index}a`) ? (
                                            <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                        ) : (
                                            <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                        )}<b>Address</b>&ensp;</button><br/>
                                        {isActive.includes(`${index}a`) && (
                                            <>
                                            <span style={{marginLeft:10, fontSize:'.9em'}}>Street Address:  {!!principal.addr1 ? principal.addr1 : 'N/A'}</span><br/>
                                            <span style={{marginLeft:10, fontSize:'.9em'}}>City:  {!!principal.city ? principal.city : 'N/A'}</span><br/>
                                            <span style={{marginLeft:10, fontSize:'.9em'}}>State:  {!!principal.state ? principal.state : 'N/A'}</span><br/>
                                            <span style={{marginLeft:10, fontSize:'.9em'}}>Zip:  {!!principal.zip5 ? principal.zip5 : 'N/A'}</span><br/>
                                            </>
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <button className="button" onClick={() => toggle(`${index}e`)}>{isActive.includes(`${index}e`) ? (
                                            <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                        ) : (
                                            <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                        )}<b>Email</b>&ensp;</button><br/>
                                        {isActive.includes(`${index}e`) && (
                                            <>
                                            <span style={{marginLeft:10, fontSize:'.9em'}}>Email Address:  {!!principal.email ? principal.email : 'N/A'}</span><br/>
                                            </>
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <button className="button" onClick={() => toggle(`${index}p`)}>{isActive.includes(`${index}p`) ? (
                                            <><i className="fa fa-chevron-down" aria-hidden="true"/></>
                                        ) : (
                                            <><i className="fa fa-chevron-right" aria-hidden="true"/></>
                                        )}<b>Phone</b>&ensp;</button><br/>
                                        {isActive.includes(`${index}p`) && (
                                            <>
                                            <span style={{marginLeft:10, fontSize:'.9em'}}>Phone Number:  {!!principal.phone ? principal.phone : 'N/A'}</span><br/>
                                            </>
                                        )}
                                    </span>
                                </div>
                            
                            </div>
                        </div>
                    )}
            </>}
            
        </div>
    )
}

function PrincipalJudgementCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>Judgement Details <div className="block-meter rank-worst is-small">{data.length}</div></h2>
    <br></br>
      <div className="slider-container">
        {/* <h3>Judgement</h3> */}
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">
                    <span><b>Full Name:</b> {item.full_name}</span>
                    <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                    <span><b>Plaintiff Business Name:</b> {item.plaintiff_business_name}</span>
                    <span className='rightIndent'><b>Filing Type:</b> {item.filing_type}</span><br></br>
                    <span><b>Interest Rate:</b> {item.interest_rate}</span>
                    <span className='rightIndent'><b>Filing Location:</b> {item.filing_location}</span><br></br>
                    <span><b>Total Amount:</b> {item.total_amount}</span>
                    <span className='rightIndent'><b>Court Case Number</b> {item.court_case_number}</span><br></br>
                    <span><b>Recording Date:</b> {item.recording_date}</span>
                    <span className='rightIndent'><b>Court Case Name:</b> {item.court_name}</span><br></br>
                    <span><b>Recording Number:</b> {item.recording_number}</span>
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}

function PrincipalLienCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>Lien Details <div className="block-meter rank-worst is-small">{data.length}</div></h2>
    <br></br>
      <div className="slider-container">
        {/* <h3>Lien</h3> */}
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">

                    <span><b>Full Name:</b> {item.full_name}</span>
                    <span className='rightIndent'><b>Filing Date:</b> {item.filing_date}</span><br></br>
                    <span><b>Plaintiff Business Name:</b> {item.plaintiff_business_name}</span>
                    <span className='rightIndent'><b>Filing Type:</b> {item.filing_type}</span><br></br>
                    <span><b>Total Amount:</b> {item.total_amount}</span>
                    <span className='rightIndent'><b>Court Case Number:</b> {item.court_case_number}</span><br></br>
                    <span><b>Recording Date:</b> {item.recording_date}</span>
                    <span className='rightIndent'><b>Court Name:</b> {item.court_name}</span><br></br>
                    <span><b>Recording Number:</b> {item.recording_number}</span>
                    <span className='rightIndent'><b>Extension Date</b> {item.extension_date}</span><br></br>
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}

function PrincipalBankruptcyCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>Bankruptcy Details <div className="block-meter rank-worst is-small">{data.length}</div></h2>
    <br></br>

      <div className="slider-container">
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">
                    <span><b>Debtor Name:</b> {item.debtor_name}</span>
                    <span className='rightIndent'><b>Case Number:</b> {item.case_number}</span><br></br>
                    <span><b>Debtor Addr:</b> {item.debtor_addr1}</span>
                    <span className='rightIndent'><b>Chapter:</b> {item.chapter}</span><br></br>
                    <span><b>Debtor City:</b> {item.debtor_city}</span>
                    <span className='rightIndent'><b>Court Id:</b> {item.court_id}</span><br></br>
                    <span><b>Debtor State:</b> {item.debtor_state}</span>
                    <span className='rightIndent'><b>Judge Name:</b> {item.judge_name}</span><br></br>
                    <span><b>Debtor Zip5:</b> {item.debtor_zip5}</span>
                    <span className='rightIndent'><b>Attorney Name:</b> {item.attorney_name}</span><br></br>
                    <span><b>Debtor Zip4:</b> {item.debtor_zip4}</span>
                    <span className='rightIndent'><b>Law Firm:</b> {item.law_firm}</span><br></br>
                    <span><b>File Date:</b> {item.file_date}</span>
                    <span className='rightIndent'><b>Trustee Name:</b> {item.trustee_name}</span><br></br>
                    <span><b>Notice Type:</b> {item.notice_type}</span><br></br>
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}


function PrincipalAssociateCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>Associate Details <div className="block-meter rank-ok is-small">{data.length}</div></h2>
    <br></br>
      <div className="slider-container">
        {/* <h3>Lien</h3> */}
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">
                    <span><b>Full Name:</b> {item.full_name}</span>
                    <span className='rightIndent'><b>State:</b> {item.state}</span><br></br>
                    <span><b>Address:</b> {item.plaintiff_business_name}</span>
                    <span className='rightIndent'><b>Zip5:</b> {item.zip5}</span><br></br>
                    <span><b>City:</b> {item.total_amount}</span>
                    <span className='rightIndent'><b>Zip4:</b> {item.zip4}</span><br></br>
  
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}

function PrincipalRelativeCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>Relative Details <div className="block-meter rank-unknown is-small">{data.length}</div></h2>
    <br></br>
      <div className="slider-container">
        {/* <h3>Lien</h3> */}
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">

                    <span><b>Full Name:</b> {item.full_name}</span>
                    <span className='rightIndent'><b>State:</b> {item.state}</span><br></br>
                    <span><b>Address:</b> {item.plaintiff_business_name}</span>
                    <span className='rightIndent'><b>Zip5:</b> {item.zip5}</span><br></br>
                    <span><b>City:</b> {item.total_amount}</span>
                    <span className='rightIndent'><b>Zip4:</b> {item.zip4}</span><br></br>
  
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}

function PrincipalEmployerCarousel({data}) {
    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>Employer Details <div className="block-meter rank-unknown is-small">{data.length}</div></h2>
    <br></br>
      <div className="slider-container">
        {/* <h3>Lien</h3> */}
        <Slider {...settings}>
            {data.map((item, index) => (
                <div key={index} className="slider-card">

                    <span><b>Business Name:</b> {item.business_name}</span>
                    <span className='rightIndent'><b>State:</b> {item.state}</span><br></br>
                    <span><b>Address:</b> {item.plaintiff_business_name}</span>
                    <span className='rightIndent'><b>Zip5:</b> {item.zip5}</span><br></br>
                    <span><b>City:</b> {item.total_amount}</span>
                    <span className='rightIndent'><b>Zip4:</b> {item.zip4}</span><br></br>
  
                </div>
            ))}
        </Slider>
        <div className="slideCounter">
            <span>{slideIndex} / {totalSlides}</span>
        </div>
      </div>
    </div>
    )
}

function calcPrincipal(principal){
    let count = 0
    let bankruptcy_count = principal.bankruptcies.length
    let judgement_count = principal.judgements.length
    let lien_count = principal.liens.length
    count = bankruptcy_count + judgement_count + lien_count
    if (count === 0) {
        return null
    }
    else {
        return count

    }
  }