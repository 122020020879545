import React from 'react';
import './NPIDoctorMatchBadges.scss'; // Add a CSS file for styling

const NPIDoctorMatchBadges = ({ doctor }) => {
    // Define the label descriptions for hover text
    const labelDescriptions = {
        first_name_similarity: 'First Name Similarity',
        middle_name_similarity: 'Middle Name Similarity',
        last_name_similarity: 'Last Name Similarity',
        street_number_similarity: 'Street Number Matches',
        street_name_similarity: 'Street Name Similarity',
        zip5_similarity: 'Zip5 Matches',
        city_similarity: 'City Similarity',
        state_similarity: 'State Matches',
        merchant_zip5_similarity: 'Merchant Zip5 Matches',
        merchant_city_similarity: 'Merchant City Similarity',
        merchant_state_similarity: 'Merchant State Matches',
        npi_id_similarity: 'NPI ID Similarity',
        taxonomy_similarity: 'Taxonomy Matches Other Doctors',
    };

    // Function to determine the badge color based on similarity value
    const getBadgeColor = (similarity) => {
        if (similarity === 1) return 'green'; // Perfect match
        if (similarity >= 0.75) return 'gold'; // High similarity
        if (similarity >= 0.5) return 'orange'; // Moderate similarity
        return 'red'; // Very low similarity
    };

    return (
        <div className="match-badge-container">
            {Object.entries(doctor).map(([key, value]) => (
                key.includes('_similarity') && key != 'overall_similarity' && value > 0.25 && ( // Check if similarity value is greater than 0.25
                    <span 
                        key={key}
                        className="match-badge-icon"
                        style={{ backgroundColor: getBadgeColor(value), color: 'white', borderRadius: '5px', padding: '5px 10px', margin: '5px 5px' }}
                        data-title={`${(Number(doctor[key] * 100).toFixed(0))}% Similar`} // Hover text based on the label
                    >
                        {labelDescriptions[key]}
                    </span>
                )
            ))}
        </div>
    );
};

export default NPIDoctorMatchBadges;