import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import { config as AWSConfig } from 'aws-sdk'
import appConfig from 'app-config.json'

AWSConfig.region = appConfig.region

// Creates a CognitoAuth instance
const createCognitoAuth = (userhandler={}) => {
  const appWebDomain = appConfig.userPoolBaseUri.replace('https://', '').replace('http://', '')
  const auth = new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri
  })
  auth.useCodeGrantFlow()
  auth.userhandler = userhandler
  if (localStorage.getItem('redirect-to') == null) {
    auth.setState(window.location.href)
  }
  else {
    auth.setState(localStorage.getItem('redirect-to'))
  }

  return auth
}

// transform Cognito Auth result into a session object
const sessionFromAuthResult = (result) => {
  return {
    credentials: {
      accessToken: result.accessToken.jwtToken,
      idToken: result.idToken.jwtToken,
      refreshToken: result.refreshToken.refreshToken
    },
    expires: result.idToken.payload.exp
  }
}

// Gets a new Cognito session. Returns a promise.
export const getCognitoSession = () => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth({
      onSuccess: result => resolve(sessionFromAuthResult(result)),
      onFailure: err => {
        signOutCognitoSession()
        getCognitoSession()
        // reject(new Error('Failure getting Cognito Session: ' + err))
      }
    })

    auth.getSession()
  })
}

export const refreshCognitoSession = (session) => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth({
      onSuccess: result => resolve(sessionFromAuthResult(result)),
      onFailure: err => {
        signOutCognitoSession()
        getCognitoSession()
        // reject(new Error('Failure refreshing Cognito Session: ' + err))
      }
    })
    auth.refreshSession(session.credentials.refreshToken)
  })
}

// Sign out of the current session (will redirect to signout URI)
export const signOutCognitoSession = () => {
  const auth = createCognitoAuth()
  auth.signOut()
}

export const cognitoAuthCodePresent = () => {
  return window.location.href.indexOf('code=') !== -1
}

export const completeCognitoLogin = () => {
  return parseCognitoWebResponse()
      .catch( () => {
        signOutCognitoSession()
        window.location = '/'
      })
}

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = () => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth({
      onSuccess: () => resolve(),
      onFailure: err => reject(new Error('Failure parsing Cognito web response: ' + err))
    })
    auth.parseCognitoWebResponse(window.location.href)
  })
}

