import React, {useState} from "react";
import * as ReactIs from 'react-is';

import './ExpandingRow.scss';

export default function ExpandingRow({
                          children, stripe, className='',
                          DetailsPanel, expandable=true, expanded=false,
                          lastCellOverride=false,
                          ...rest}){
    const [ isExpanded, setIsExpanded ] = useState(expanded)
    const rowStripeClass = stripe ? 'row-1' : 'row-0'
    const [ DetailsPanelComponent, detailsPanelProps ] = DetailsPanel

    // calculate the colSpan by summing up the colSpan of child nodes and adding one
    let colCount = 1 // start with an offset of 1 to account for injected column
    React.Children.forEach(children, element => {
        if (!React.isValidElement(element)) return
        const cells = ReactIs.isFragment(element) ? element.props.children : [element]
        cells.forEach( td => {
            const { colSpan } = td.props
            colCount += colSpan || 1
        })
    })

    return <>
        <tr className={`expanding-row ${className} ${rowStripeClass}`} {...rest}>
            { children }
            <td className='expander-cell'>
                { lastCellOverride || (expandable && (
                    <button className="inverted inline"
                          onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? (
                                <>Hide Details <i className="fa fa-chevron-up" aria-hidden="true"/></>
                            ) : (
                                <>Show Details <i className="fa fa-chevron-down" aria-hidden="true"/></>
                            )}
                    </button>
                ))}
            </td>
        </tr>
        { isExpanded && (
            <tr className={rowStripeClass}>
                <td colSpan={colCount}>
                    <DetailsPanelComponent {...detailsPanelProps} />
                </td>
            </tr>
        )}
    </>
}
