
/**
 * Helper function to convert a score to a css classname
 * !These all use return-breaking instead of ranges!
 */
const levels = ['highest', 'high', 'elevated', 'lowest', 'unknown']
export function score2className(score){
    if(!!score){
        if (score === 100) { return levels[4]}
        if(score.toString().length == 3){
            if(score >= 101 && score <= 600){
                return levels[0]
            }
            if(score >= 601 && score <= 650){
                return levels[1]
            }
            if(score >= 651 && score <= 720){
                return levels[2]
            }
            if(score >= 721){
                return levels[3]
            }
        }
        else{
            if(score < 75){
                return levels[0]
            }
            if(score < 85){
                return levels[1]
            }
            if(score < 93){
                return levels[2]
            }
        }
        return levels[3]
    }
    return levels[4]

    
    
}

const colorNames = ['Red', 'Orange', '#FED34F', 'Green', 'Green']
export function score2colorName(score){
    if(!!score){
        if (score === 100) { return 'Grey'}
        if(score.toString().length == 3){
            if(score >= 101 && score <= 600){
                return colorNames[0]
            }
            if(score >= 601 && score <= 650){
                return colorNames[1]
            }
            if(score >= 651 && score <= 720){
                return colorNames[2]
            }
            if(score >= 721){
                return colorNames[3]
            }
        }
        else{
            if(score < 75){
                return colorNames[0]
            }
            if(score < 85){
                return colorNames[1]
            }
            if(score < 93){
                return colorNames[2]
            }
        }
        return colorNames[3]
    }
    return colorNames[3]
}

const scoreCategories = ['Highest', 'High', 'Medium', 'Lowest', 'Unknown']
export function score2category(score){
    if(!!score){
        if (score === 100) { return 'Default'}
        if(score.toString().length == 3){
            if(score >= 101 && score <= 600){
                return scoreCategories[0]
            }
            if(score >= 601 && score <= 650){
                return scoreCategories[1]
            }
            if(score >= 651 && score <= 720){
                return scoreCategories[2]
            }
            if(score >= 721){
                return scoreCategories[3]
            }
        }
        else{
            if(score < 75){
                return scoreCategories[0]
            }
            if(score < 85){
                return scoreCategories[1]
            }
            if(score < 93){
                return scoreCategories[2]
            }
        }
        return scoreCategories[3]
    }
    return scoreCategories[4]
}