import React, { useState, useMemo, useEffect } from 'react'
import './ScorecardPanel.scss'
import Loading from 'shared/Loading'
import toast from 'react-hot-toast'
import { Link } from "react-router-dom"
import debounce from 'lodash.debounce'
import useApi from '../../../hooks/useApi'
import Modal from 'shared/Modal'

export default function Scorecard({merchantReport, printVersion}){
    const lender = localStorage.getItem('lender')
    const parseLender = JSON.parse(lender)
    const [ loading, setLoading ] = useState(false)
    const [scorecard, setScorecard] = useState({})
    const { apiGet, apiPut } = useApi()
    const [firstRender, setFirstrender] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [ruleNote, setRuleNote] = useState()
    const [ruleNoteIndex, setRuleNoteIndex] = useState()
    const [targetGlossary, setTargetGlossary] = useState([])


    useEffect(() => {
        apiGet('scorecard/get_target_glossary').then(targets => {
            setTargetGlossary(targets)
        })
    }, [])

    function calculateTotalScore(rows) {
        let total_score = 0
        for (let row of rows) {
            if (row['result'] === true) {
                total_score = parseInt(total_score) + parseInt(row['pass_score'])
            }
            if (row['result'] === false) {
                total_score = parseInt(total_score) + parseInt(row['fail_score'])
            }
        }
        return total_score
    }

    function calculateTotalPossibleScore(rows) {
        let total_score = 0
        for (let row of rows) {
            total_score = total_score + row['fail_score']
        }
        return total_score
    }


    function handleNoteChange() {
        if(ruleNote){
            const updatedScorecard = {...scorecard.scorecard[ruleNoteIndex], notes: ruleNote};
            const newScorecard = [
                ...scorecard.scorecard.slice(0, ruleNoteIndex),
                updatedScorecard,
                ...scorecard.scorecard.slice(ruleNoteIndex + 1)
            ];
            const dummyScoreCard = {...scorecard};
            dummyScoreCard.scorecard = newScorecard
            setScorecard(dummyScoreCard);
            toast('New Rule Note Added - ' + "'" + ruleNote + "'")
        }
        setRuleNote()
        setShowModal(false)
    }

    
    function ruleDateFormatter(date){
        const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"}
        return new Date(date).toLocaleDateString(undefined, options)
    }

    const handleValueChange = index => (event) => {
        let result = true
        if (event.target.value === 'false'){
            result = false
        }
        else if(event.target.value === ""){
            result = ""
        }
        const updatedScorecard = {...scorecard.scorecard[index], result: result};
        const newScorecard = [
            ...scorecard.scorecard.slice(0, index),
            updatedScorecard,
            ...scorecard.scorecard.slice(index + 1)
        ];
        const dummyScoreCard = {...scorecard};
        dummyScoreCard.scorecard = newScorecard
        setScorecard(dummyScoreCard);
    }

    useEffect( ()=> {
        async function handleUpdateScorecard(){
            apiPut(`scorecard/${scorecard.id}`, scorecard)
        }
        if (firstRender){
            setFirstrender(false)
        }
        else{
            handleUpdateScorecard()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        apiGet('scorecard/get_target_glossary').then(targets => {
            setTargetGlossary(targets)
        })
    }, [scorecard])

    async function handleGenerateScorecard() {
            apiGet(`scorecard/${parseLender['id']}/${merchantReport.merchant.id}`)
            .then(res => {
                res.scorecard.sort((a, b)=> a.rule_description.localeCompare(b.rule_description))
                setScorecard(res)
    
            }).finally(setLoading(false))
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }


    function showValue(target){
        if(target){
            return targetGlossary.filter((rule, i) => {
                return rule.target === target
            })[0].show_value
        }
        else return false
        
    }


    return (
        <div className="box1">
            <div className="scorecard-tab">
            <div className="scorecard-header">
                <span>
                <h3>{!printVersion && parseLender['name']} Scorecard
                    {!printVersion &&
                            <button className='print-button button' style={{ float:'right', transform: 'translate(0%,-15%)'}} onClick={window.print}><i className="fa fa-print"></i>Print Scorecard</button>
                    }
                    </h3>
                </span>
            </div>

            {scorecard?.scorecard ?
            <div className="scorecard-table">
                <table>
                        <thead>
                            <tr>
                                <th>Rule Description</th>
                                <th>Selection</th>
                                <th>Result</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            { scorecard.scorecard.map((rule_row, index) =>
                                <tr className={'row-' + (index + 1) % 2} key={index}>
                                    <td>
                                        <button className='note-button' onClick={() => {setShowModal(true); setRuleNoteIndex(index)}}>
                                            <i className="fa fa-sticky-note-o"/>
                                        </button>
                                        {rule_row.rule_description}
                                    </td>
                                    <td>{rule_row.dropdown === true ? (
                                        <select value={rule_row.result} onChange={handleValueChange(index)}>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                            <option value="">N/A</option>
                                        </select>
                                    ) : rule_row.result === true ? 'True' : 'False'}</td>
                                    <td>{rule_row.result === '' ? (
                                        <span className="not-applicable"> N/A </span>
                                    ) : rule_row.result === true ? <span className="pass"> PASS </span> : <span className="fail"> FAIL </span>}</td>
                                    <td>{rule_row.result === '' ? (
                                        rule_row.pass_score
                                    ) : rule_row.result === true ? rule_row.pass_score : rule_row.fail_score}</td>
                                </tr>
                            )}
                        </tbody>
                </table>
                <div className="total-score">
                    <table>
                    <tbody>
                    <tr>
                        <th>Total Score</th>
                        <th></th>
                        <th></th>
                        <th>{calculateTotalScore(scorecard.scorecard) > 0 ? <span className="pass"> {calculateTotalScore(scorecard.scorecard)} </span> : <span className="fail"> {calculateTotalScore(scorecard.scorecard)} </span>} / {calculateTotalPossibleScore(scorecard.scorecard)}</th>
                    </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            : 
            <>
                {!loading ?               
                <div className="generate-scorecard-container">
                    <br></br><br></br>
                    {!printVersion && 
                        <button onClick={handleGenerateScorecard}>Click to Generate/Retrieve Scorecard</button>
                    }
                    {printVersion && 
                    <p>Print scorecard in the Scorecard tab</p>}
                </div>
                : <Loading/>
                    }
            </>
            }
            
            </div>
            <div className="scorecard-modal">
            {showModal &&
                <Modal>
                    <h1>Scorecard Notes</h1>
                    <header>
                        <h2>Rule Note:</h2>
                        <i className="spacer"/>
                        <button className="inverted" onClick={() => {setShowModal(false)}}>Cancel</button>
                        <button className="primary" onClick={() => {handleNoteChange()}}>Save</button> 
                    </header>
                    <div className="field rule-input-container">
                        <span className='input-span'>
                            <input className="rule-input-field" value={ruleNote} onChange={(event) => setRuleNote(event.target.value)}/> 
                            <span className='rule-return-value-text'>{showValue(scorecard.scorecard[ruleNoteIndex].target) === "true" && <><b>Returned Value: </b> {scorecard.scorecard[ruleNoteIndex].value}</>}</span>
                        </span>
                        <span className="rule-suggestion">       
                            <u>Note:</u> Adding this note will overwrite the previous note for this rule
                        </span>
                    </div>
                    <br/>
                    <h4>Current Note:
                        <span>Last Updated on {ruleDateFormatter(scorecard.scorecard[ruleNoteIndex].updated_at)} </span>
                    </h4>
                    <div className="rule-current-note-container">
                        <p className='rule-current-note-text'>{scorecard.scorecard[ruleNoteIndex].notes}</p>
                    </div>
                </Modal>}
            </div>
        </div>
    )
}
    