import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import FormatDate from '../../shared/FormatDate'

import './RecentReportsNavigator.scss'

export default function InquiryNavigator({inquiryHistory}){

    const [ hideDropdown, setHideDropdown ] = useState(false)

    // just to provide a toggle switch to collapse the dropdown contents on click
    useEffect(()=> {
       setHideDropdown(false)
    }, [ hideDropdown ])

    const showMerchantSelector = !!inquiryHistory && inquiryHistory.length > 0

    return (
       <div className='recent-reports-navigator' style={{/*display: 'none'*/}}>
          { showMerchantSelector && (
              <div className='recent-reports-navigator-content-wrap'>
                 <div>
                      <div className={`match-selector ${hideDropdown ? 'collapse' : ''}`}>
                          <strong>
                              Account History
                          </strong>
                          <ul>
                              { inquiryHistory.map( ({merchant, inquiry, result_dt}) => (
                                  <li key={merchant.id}>
                                      <Link to={`/onboarding/${inquiry}/${merchant.id}`}
                                            onClick={()=> setHideDropdown(true)}>
                                          {merchant.name}
                                          <MerchantAddress merchant={merchant} />
                                          <span className="report-date">(<FormatDate date={result_dt} timezome='' />)</span>
                                      </Link>
                                  </li>
                              )) }
                          </ul>
                      </div>
                  </div>
              </div>
          )}
       </div>
    )
}

function MerchantAddress({merchant}){
    return (
      <span className='address'>
          {[merchant.addr1, merchant.addr2, merchant.city, merchant.state].filter(Boolean).join(', ')}
      </span>
    )
}