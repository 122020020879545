import React from 'react'

export default function FormatAddress({loc}) {
    const street = [loc.addr1, loc.addr2].filter(Boolean).join(' ')
    return <>
        {street && street + ', '} 
        {loc.city && loc.city + ', '} 
        {loc.state && loc.state + ' '} 
        {loc.zip5 && loc.zip5} 
        </>
}