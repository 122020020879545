import React, {useContext, useState, useEffect} from "react";

import SessionContext from "../../SessionContext";
import appConfig from "../../app-config.json";
import useApi from "hooks/useApi"
import toast from 'react-hot-toast'


export default function MyProfile(){
    const {apiGet, apiPost, apiDelete} = useApi()

    const { user } = useContext(SessionContext)
    const [ message, setMessage ] = useState(null)
    const [ apiToken, setApiToken ] = useState(null)    
    const [ quotaStatus, setQuotaStatus] = useState(null)
    const [ monthlyAllowance, setMonthlyAllowance] = useState(null)
    const [ monthlyAllowanceInput, setMonthlyAllowanceInput] = useState(null)
    const [ creditBalance, setCreditBalance ] = useState(null)
    const [ creditBalanceInput, setCreditBalanceInput] = useState(null)

    const lender = localStorage.getItem('lender')
    const parseLender = JSON.parse(lender)




    const lender_admin = user.groups.some(g => g.name === 'Lender Admin')
    const verdata_admin = user.is_staff

    const apiUrl = new URL(appConfig.apiUri)
    const swaggerUrl = `${apiUrl.origin}/swagger`

    const resetPassword = () => {
        window.location=`${appConfig.userPoolBaseUri}/forgotPassword?client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}&response_type=code&username=${user.email}`
    }

    function sendPasswordResetEmail(user) {
        // ev.preventDefault()
        toast('Email to reset password Sent!')
        const payload = {
            "email": user.email,
            "first_name": user.first_name,
            "last_name": user.last_name,
        };
        apiPost('user/adminpasswordreset', payload).then(result=>{
            console.log(result)
        }).catch(err => {
            console.log(err.message)
        })
    }

    const clearMessage = (ev) => {
        ev.preventDefault()
        setMessage(null)
    }

    useEffect(() => {
        if (lender_admin || verdata_admin) {
            apiGet(`lender/${parseLender['id']}/token`).then(result => {
                setApiToken(result.token)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lender])    

    useEffect(() => {
        if (parseLender['id']) {
            apiGet('quota_details').then(result =>{
                setCreditBalance(result.credit_balance)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lender])


    // call to grab quota settings, specifically if status is active and monthly allowance
    useEffect(() => {
        apiGet('quota_settings').then(result =>{
            setQuotaStatus(result.is_active)
            setMonthlyAllowance(result.monthly_allowance)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lender])

    function sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }

    function handleAllowanceSubmit(ev) {
        ev.preventDefault()
        const payload = {
            "new_allowance": monthlyAllowanceInput
        };
        toast('Updating Monthly Allowance...')

        if (validateInput(monthlyAllowanceInput))
        apiPost('quota_settings/update_monthly_allowance', payload).then(result =>{
            setMonthlyAllowance(result.monthly_allowance)
            sleep(700)
            window.location.reload()

        }).catch(err => {
            console.log(err.message)
        })
    }

    function handleCreditSubmit(ev) {
        ev.preventDefault()
        const payload = {
            "new_balance": creditBalanceInput
        };
        toast('Updating Credit Balance...')

        if (validateInput(creditBalanceInput)) {
            apiPost('quota_details/set_credit_balance', payload).then(result =>{
                setCreditBalance(result.credit_balance)
                sleep(700)
                window.location.reload()
    
            }).catch(err => {
                console.log(err.message)
            })
        }
    }

    function handleActivitySubmit(ev) {
        ev.preventDefault()
        const payload = {
            "status": !quotaStatus
        };
        toast('Updating Credit System Status...')
        apiPost('quota_settings/update_active_status', payload).then(result =>{
            
            setQuotaStatus(result.is_active)
            sleep(1000)
            window.location.reload()

        }).catch(err => {
            console.log(err.message)
        })
    }

    //function to validate their input
    // example -- if input is negative then throw an error or something
    function validateInput(val) {
        if (val < 0 || Number.isInteger(val) === true) {
            toast('Invalid input -- should be a positive whole number.')
            return false
        }

        return true
    }


    function createToken() {
        if (verdata_admin) {
            apiPost(`lender/${parseLender['id']}/token`)
                .then(() => {
                    window.location.reload(false);
                })
        }
    }

    function removeToken() {
        if (verdata_admin) {
            const deleteConfirmed = window.confirm(`Are you sure you want to delete the API Token for [${parseLender['name']}]?`)
            if (!deleteConfirmed) return
            apiDelete(`lender/${parseLender['id']}/token`)
                .then(() => {
                    window.location.reload(false);
                })
        }
    }


    return (
        <div className='my-profile'>
            <h1>{user.first_name} {user.last_name}</h1>
            <p>
                Role: {user.is_superuser ? 'Verdata Admin' : user.groups[0].name}<br />
                { !user.is_staff && user.lenders.length > 0 && (
                <>Lender: {user.lenders[0].name}<br /></>
                )}
            </p>
            <p>
                <button className='primary' onClick={() => sendPasswordResetEmail(user)}>Reset Password</button>
            </p>
            {  message && (
                <p>{message} <button href='#' onClick={clearMessage} style={{
                    textTransform: "none",
                    background: "none",
                    color: "#1997bc",
                    textDecoration: "underline",
                    padding: 0,
                    marginLeft: 5,
                    fontSize: "1em",
                }}>clear</button></p>
            )}
            {/*
            <h1>Notification Settings</h1>
            <p>
                No notification settings to configure.
            </p>
            */}
            {verdata_admin &&
            <div className="credit-system-active">
                <h2 className="credit-system-active-text">Inquiry Credit System Status: <u>{quotaStatus ? 'ENABLED' : 'DISABLED'}</u></h2>
                {/* <form onSubmit={handleEmailSubmit}>
                        <button type="submit" >Send Email</button>
                </form> */}
                <div className="quota-status-input">
                    <form onSubmit={handleActivitySubmit}>
                        <button className="primary" type="submit" >{quotaStatus ? 'Disable Credit System' : 'Enable Credit System'}</button>
                    </form>
                </div>
            </div>}

            {(!verdata_admin && quotaStatus === true) &&
                <div>
                    <p>___________________________________________________</p>
                    <h1>Current Credit Balance: {creditBalance}</h1>
                    <p>___________________________________________________</p>
                </div>}

            {(verdata_admin && quotaStatus === true) &&
            <div className="credit-system">
                <p>___________________________________________________</p>
                <h1>Current Credit Balance: {creditBalance}</h1>
                <div className="credit-balance-input">
                    <p>Enter new credit balance</p>
                    <form onSubmit={handleCreditSubmit}>
                        <input
                            type="number"
                            value={creditBalanceInput}
                            placeholder="Credit Balance"
                            onChange={(e) => setCreditBalanceInput(e.target.value)}
                        /> 
                        <button className="primary" type="submit" >Submit</button>
                    </form>
                </div>
                <div className="monthly-allowance-input">
                <h1>Current Monthly Allowance: {monthlyAllowance}</h1>
                    <p>Enter new monthly allowance</p>
                    <form onSubmit={handleAllowanceSubmit}>
                        <input
                            type="number"
                            value={monthlyAllowanceInput}
                            placeholder="Monthly Allowance"
                            onChange={(e) => setMonthlyAllowanceInput(e.target.value)}
                        /> 
                        <button className="primary" type="submit" >Submit</button>
                    </form>
                    <p>___________________________________________________</p>
                </div>
            </div>}

            {((lender_admin || verdata_admin) && !!parseLender['is_api_enabled']) && 
            <div>
                <h1>API Access Details</h1>

                <h2>{parseLender['name']}</h2>
                <p>lenderId: {parseLender['id']}</p>
                <p>Lender API Key: {apiToken}</p>
                <p><a href={apiUrl.origin} target='_blank' rel="noopener noreferrer">API Documentation</a></p>
                <p><a href={swaggerUrl} target='_blank' rel="noopener noreferrer">Swagger URL</a></p>

                {(apiToken) &&
                <>
                    <h3>HTTP Headers Needed in API Calls</h3>
                    <table className="header-table">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Authorization</td>
                                <td>Token {apiToken}</td>
                            </tr>
                            <tr>
                                <td>LenderId</td>
                                <td>{parseLender['id']}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
                }

                {(verdata_admin) && 
                <>
                    {(!apiToken) && <p>
                        <button className='primary' onClick={createToken}>Generate API Key</button>
                    </p>}
                    {(apiToken) && <p>
                        <button className='primary' onClick={removeToken}>Remove API Key</button>
                    </p>}
                </>
                }
            </div>}
        </div>
    )
}