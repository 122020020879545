import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router } from 'react-router-dom'
// eslint-disable-next-line
import SessionContext, { getSession, scheduleSessionRenewal, inactivityTime } from 'SessionContext'
import { signOutCognitoSession } from "./utils/cognitoUtils";
import ErrorsContext from './ErrorsContext'
import Routes from './Routes'
import Header from './Header'
import Loading from 'shared/Loading'
import logo from 'assets/verdata_hires_logo.png'
import { Toaster } from 'react-hot-toast'

import 'App.scss';

export default function App() {
  const [ session, setSession ] = useState(null)
  const [ error, setError ] = useState(null)

  useEffect( ()=> {
    getSession(setError).then( session => {
      setSession(session)
      scheduleSessionRenewal(session, setSession, setError)
      // inactivityTime()
      
    })
  }, [])

  useEffect( ()=> {
    localStorage.setItem('redirect-to', window.location.href)
  }, [])


  return (
    <ErrorsContext.Provider value={{error, setError}}>
      <SessionContext.Provider value={session}>
        { !!session &&
          <Router>
            <div className="App">
              <Toaster 
              toastOptions={{
                className: '',
                style: {
                  border: '1px solid #713200',
                  padding: '16px',
                  color: '#713200',
                },
              }}/>
              <Header />
              <main>
                <div className='mainContentWrap'>
                  { !!session.user && <Routes/> }
                  { !session.user && (
                    <p style={{textAlign: 'center'}}>
                      Your account has been disabled.<br/>
                      <button onClick={signOutCognitoSession}>Logout</button>
                    </p>
                  )}
                </div>
              </main>
            </div>
          </Router>
        }
        { !session &&
            <div className='app-loading'>
              <img src={logo} alt="Verdata" />
              { error ? <span>Error Loading Page. </span> : <Loading /> }
              { error ? <span style={{ fontSize: "12px", paddingTop: '25px' }}> { error.status } { error.statusText } </span> : null }
              { error ? <span style={{ fontSize: "12px" }}> { error.type } </span> : null }
            </div>
        }
      </SessionContext.Provider>
    </ErrorsContext.Provider>
  );
}
