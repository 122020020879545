
export default function FormatNumber({value, places=0, empty=''}){
    if (typeof value === 'number') {
        return value.toLocaleString("en", {minimumFractionDigits: places})
    }
    else if (typeof value === 'string') {
        return value
    }
    else {
        return empty
    }
}