import React, {useState, useEffect, useContext, useRef, Fragment} from 'react'

import './DoctorsPanel.scss'
import useApi from '../../../hooks/useApi'
import ErrorsContext from "../../../ErrorsContext";
import {dedupeDoctors} from "../MerchantReport"
import DoctorReviewDetails from './doctor-panel/DoctorReviewDetails';
import toast from 'react-hot-toast'
import appConfig from "../../../app-config.json";
import Modal from 'shared/Modal'
import LicenseLabelBadge from '../../../shared/LicenseLabelBadge';
import Loading from '../../../shared/Loading'
import NPIDoctorMatchBadges from './doctor-panel/NPIDoctorMatchBadges'
import { max } from 'lodash';


const states = {
    AL: "Alabama",AK: "Alaska",AZ: "Arizona",AR: "Arkansas",CA: "California",CO: "Colorado",CT: "Connecticut",
    DE: "Delaware",FL: "Florida",GA: "Georgia",HI: "Hawaii",ID: "Idaho",IL: "Illinois",IN: "Indiana",IA: "Iowa",
    KS: "Kansas",KY: "Kentucky",LA: "Louisiana",ME: "Maine",MD: "Maryland",MA: "Massachusetts",MI: "Michigan",
    MN: "Minnesota",MS: "Mississippi",MO: "Missouri",MT: "Montana",NE: "Nebraska",NV: "Nevada",NH: "New+Hampshire",
    NJ: "New+Jersey",NM: "New+Mexico",NY: "New+York",NC: "North+Carolina",ND: "North+Dakota",OH: "Ohio",
    OK: "Oklahoma",OR: "Oregon",PA: "Pennsylvania",RI: "Rhode+Island",SC: "South+Carolina",SD: "South+Dakota",
    TN: "Tennessee",TX: "Texas",UT: "Utah",VT: "Vermont",VA: "Virginia",WA: "Washington",WV: "West+Virginia",
    WI: "Wisconsin",WY: "Wyoming"
};


export default function Doctors({baseUrl, merchantReport, newDoctors, refreshDoctors, furnishDoctorsEnabled, printVersion}){
    const defaultDoctorInquiry = {
        merchant_id: merchantReport.merchant.id,
        first_name: '', middle_name:'', last_name: '', 
        street_address_number: '', street_address_name: '', 
        city:'', state:'', zip5:'', npi_id:'',
    }

    const { apiPost, apiGet} = useApi()
    const { setError } = useContext(ErrorsContext)
    const [reviewActive, setReviewActive] = useState([]);
    const [licenseActive, setLicenseActive] = useState([]);
    const [licenseSubmissionId, setLicenseSubmissionId] = useState('')
    const [isExpanded, setIsExpanded] = useState([]);
    const [licenseVerificationEnabled, setLicenseVerificationEnabled] = useState(false)
    const [awaitingSubmission, setAwaitingSubmission] = useState(false)
    const [isInitialPrintState, setInitialPrintState] = useState(false)
    const [showFurnishDoctorModal, setShowFurnishDoctorModal] = useState(false)
    const [showConfirmationDoctorModal, setShowConfirmationDoctorModal] = useState(false)
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [furnishDoctorInquiry, setFurnishDoctorInquiry] = useState(defaultDoctorInquiry)
    const [search, setSearch] = useState('')
    const [NPIDoctors, setNPIDoctors] = useState([])
    const [loading, setLoading] = useState(false)
    const latestRequestId = useRef(0); // Ref to track the latest request ID
    const [licenseId, setLicenseId] = useState('');
    const [state, setState] = useState('');



    useEffect(() => {
        apiGet(`lender/license-verification-enabled`).then(data => {
            if ('license_verification_enabled' in data){
                setLicenseVerificationEnabled(data.license_verification_enabled)
            }
        }).catch( response => {
            console.log(response)
        })
    }, [])

    useEffect(() => {
        if (isInitialPrintState) {
            toggleAll('all')
        }
    }, [newDoctors])


    useEffect(() => {
        setLoading(true);
        const currentRequestId = ++latestRequestId.current; // Increment the request ID

        apiGet(`merchant/npi-doctors`, {
            firstName: furnishDoctorInquiry.first_name,
            middleName: furnishDoctorInquiry.middle_name,
            lastName: furnishDoctorInquiry.last_name,
            merchant: furnishDoctorInquiry.merchant_id,
            streetNumber: furnishDoctorInquiry.street_address_number,
            streetName: furnishDoctorInquiry.street_address_name,
            city: furnishDoctorInquiry.city,
            state: furnishDoctorInquiry.state,
            zip5: furnishDoctorInquiry.zip5,
            npiId: furnishDoctorInquiry.npi_id,
        }).then((results) => {
            // Only update state if this request is the latest one
            if (currentRequestId === latestRequestId.current) {
                setNPIDoctors(results);
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            // Only set loading to false for the latest request
            if (currentRequestId === latestRequestId.current) {
                setLoading(false);
            }
        });
    }, [furnishDoctorInquiry]);



    function convertStateAbbrToFullName(abbr) {    
        abbr = abbr.toUpperCase(); // Ensure abbreviation is uppercase
    
        if (states.hasOwnProperty(abbr)) {
            return states[abbr]; // Return the full state name if abbreviation is valid
        } else {
            return "Unknown State"; // Return a default message for invalid abbreviations
        }
    }


    function replaceEmptyStringsWithNull(obj) {
        for (const key in obj) {
            if (obj[key] === '') {
                obj[key] = null;
            } 
        }
        return obj;
    }


    function setDoctorInquiryValue(key) {
        return (ev) => {
            const value = ev.target.value;
            setFurnishDoctorInquiry((prevState) => ({
                ...prevState,
                [key]: value,
                ...(key === 'licensee_category' && { licensee_subcategory: '' }) // Reset subcategory if category changes
            }));
        }
    }

    function handleClear(ev){
        ev.preventDefault()
        setFurnishDoctorInquiry(defaultDoctorInquiry)
    }

    function handleSubmit(ev){
        ev.preventDefault()

        setAwaitingSubmission(true)

        if (!!!furnishDoctorInquiry.first_name){
            toast('First Name is Required')
            setAwaitingSubmission(false)
            return
        } 

        if (!!!furnishDoctorInquiry.last_name){
            toast('Last Name is Required')
            setAwaitingSubmission(false)
            return
        } 

        apiPost('doctors/furnish', furnishDoctorInquiry)
        .then(data => { 
            if (data.status === 'Server Error'){
                toast('Failed to add Doctor -- Reach out to Verdata Support')
            }
        }).finally(() => {
            setAwaitingSubmission(false)
            refreshDoctors(merchantReport)
        });

        setShowFurnishDoctorModal(false)
        setFurnishDoctorInquiry(defaultDoctorInquiry)

    }


    function submitLicenseInquiry(licenseInquiry){
        let tempLicenseInquiry = { ...licenseInquiry };
        tempLicenseInquiry.merchant_id = merchantReport.merchant.id
        // Regular expression pattern to match the first instance of a UUID
        const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
        tempLicenseInquiry = replaceEmptyStringsWithNull(tempLicenseInquiry)
        setAwaitingSubmission(true)
        apiPost('merchant/submit-license-verification-order', tempLicenseInquiry)
            .then(data => { 
                if (!!data.status){
                    toast('License Verification Submitted for ' + "'" + licenseInquiry.licensee_name + "'")
                }else{
                    toast('License Verification Submission Failed')
                }
            }).finally(() => {
                setAwaitingSubmission(false)
            });
    }


    function handleLicenseSubmission(license){
        var isLicenseFormValid = true

        if(license?.verifications?.length != 0 && !isOlderThanSixMonths(license?.verifications[0].updated_at)){
            toast('License Verification is up to date for ' + "'" + license.name + "'")
            isLicenseFormValid = false
        }

        let temp_license = {
            merchant_id: '',
            npi_id: license?.npi_id,
            licensee_name: license?.name,
            licensee_addr1: '', licensee_city: '', licensee_state: license?.license_number_state_code, licensee_zip5: '',
            licensee_id: license?.license_number, licensee_category: 'dentistry', licensee_subcategory: 'otherDentistry'
        }

        if (isLicenseFormValid) {
            submitLicenseInquiry(temp_license)
        }

        refreshDoctors(merchantReport)
    }


    const isOlderThanSixMonths = (updatedAt) => {
        const updatedDate = new Date(updatedAt);
        const currentDate = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
        return updatedDate < sixMonthsAgo;
      };


    function toggle(id, dropdown){

        if(dropdown === "review"){
            if(reviewActive.includes(id)){
                setReviewActive(reviewActive.filter(e => e !== id))
                setLicenseActive(licenseActive.filter(e => e !== id))
            }
            else{
                setLicenseActive(licenseActive.filter(e => e !== id))
                setReviewActive([...reviewActive, id])
            }
        }else if(dropdown === "license"){
            if(licenseActive.includes(id)){
                setReviewActive(reviewActive.filter(e => e !== id))
                setLicenseActive(licenseActive.filter(e => e !== id))
            }
            else{
                setReviewActive(reviewActive.filter(e => e !== id))
                setLicenseActive([...licenseActive, id])
            }
        }
    }

    function expand(id){
        // call api with doctor_id from the reviews to grab doctor reviews for that doctor. 
        if(isExpanded.includes(id)){
            setIsExpanded(isExpanded.filter(e => e !== id))
        }
        else{
            setIsExpanded([...isExpanded, id])
        }
    }

    // expand(`${p.id}_${p.review_summary[index].alias}

    function expandAll() {
        let temp = []
        dedupeDoctors(newDoctors).forEach(d => {
            let id = d.id
            d.review_summary.forEach(review => {
                let alias = review.alias
                temp.push(id + '_' + alias)
            })
        })

        setIsExpanded(temp)
    }

    function toggleAll(dropdown){
        let temp = []
        dedupeDoctors(newDoctors).forEach(d => {
            temp.push(d.id)
        })

        if(dropdown === "review"){
            if(reviewActive.length === dedupeDoctors(newDoctors).length){
                setLicenseActive([])
                setReviewActive([])
            }
            else{
                setLicenseActive([])
                setReviewActive(temp)
            }
        }else if(dropdown === "license"){
            if(licenseActive.length === dedupeDoctors(newDoctors).length){
                setLicenseActive([])
                setReviewActive([])
            }
            else{
                setReviewActive([])
                setLicenseActive(temp)
            }
        } else if (dropdown === "all") {
            setReviewActive(temp)
            setLicenseActive(temp)
            setInitialPrintState(true)
            expandAll()
        }
    }

    function handleCardClick(doctor) {
        setSelectedDoctor(doctor);
        setShowConfirmationDoctorModal(true);

    }

    function handleCloseModal(doctor) {
        setShowConfirmationDoctorModal(false);
        setSelectedDoctor(null);
    }

    function handleConfirmSubmit() {
        let tempInquiry = {
            merchant_id: merchantReport.merchant.id,
            first_name: selectedDoctor.first_name, middle_name:selectedDoctor.middle_name, last_name: selectedDoctor.last_name, 
            street_address_number: selectedDoctor.doctor_street_number_1, street_address_name: selectedDoctor.doctor_street_name_1, 
            city:selectedDoctor.doctor_city, state:selectedDoctor.doctor_state, zip5:selectedDoctor.doctor_zip_5, npi_id:selectedDoctor.npi_id,
        }
        setFurnishDoctorInquiry(tempInquiry)
        // After submitting, close the modal
        setShowConfirmationDoctorModal(false);
        // setShowFurnishDoctorModal(false);
        setSelectedDoctor(null);
    };

    const formatAddress = (doctor, entity) => {
        const { doctor_address_1, doctor_city, doctor_state, doctor_zip_5, doctor_zip_4, doctor_postal_code } = doctor;
        const { practice_address_1, practice_city, practice_state, practice_zip_5, practice_zip_4, practice_postal_code } = doctor;
        // Determine the zip code format
        let zipCode;

        if (entity === 'doctor'){
            if (doctor_zip_5 && doctor_zip_4) {
                zipCode = `${doctor_zip_5}-${doctor_zip_4}`;
            } else if (doctor_zip_5) {
                zipCode = doctor_zip_5;
            } else if (doctor_zip_4) {
                zipCode = doctor_zip_4;
            } else {
                zipCode = doctor_postal_code || ''; // Fallback to postal code if neither zip exists
            }

            return `${doctor_address_1}, ${doctor_city}, ${doctor_state} ${zipCode}`;
        }
            

        if (entity === 'practice'){
            if (practice_zip_5 && practice_zip_4) {
                zipCode = `${practice_zip_5}-${practice_zip_4}`;
            } else if (practice_zip_5) {
                zipCode = practice_zip_5;
            } else if (practice_zip_4) {
                zipCode = practice_zip_4;
            } else {
                zipCode = practice_postal_code || ''; // Fallback to postal code if neither zip exists
            }
            return `${practice_address_1}, ${practice_city}, ${practice_state} ${zipCode}`;
        }
        
        return '';
    };

    const getOverallScore = (doctor) => {
        let maxScore = Object.entries(furnishDoctorInquiry).filter(([key, value]) => value !== '' && value !== null && key !== 'merchant_id').length;
        let overall_score = doctor.overall_similarity
        // remove auto similaritys based on merchant since its not a real input from the user
        for (const key in doctor) {
            if ((key.includes("merchant") || key.includes("taxonomy")) && key.includes("similarity")) {
                const similarityValue = doctor[key];
                if (similarityValue !== 0) {
                    overall_score -= similarityValue;
                }
            }
        }
        if (!isFinite(Math.round(overall_score*10/maxScore*10))){
            return "NA"
        }
        return Math.round(overall_score*10/maxScore*10)
    }

    const getSimilarityClass = (score) => {

        if(score === "NA"){
            return 'neutral-similarity';
        } else if (score >= 85) {
            return 'high-similarity'; // High similarity
        } else if (score >= 65) {
            return 'medium-similarity'; // Medium similarity
        } else if (score >= 40) {
            return 'low-similarity'; // Medium similarity
        }else {
            return 'bad-similarity'; // Low similarity
        }
    };

    function openAllPrintVersion() {
        let temp = []
        dedupeDoctors(newDoctors).forEach(d => {
            temp.push(d.id)
        })
        setLicenseActive(temp)
        setReviewActive(temp)
    }

    function serviceLineLink(service, doctor){
        return `https://www.google.com/search?q=${[
                service,
                doctor
            ].filter(Boolean).map(encodeURIComponent).join('+')}`;
    }

    function handleRenderComplete(){
        // console.log("Render is complete!");
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'licenseId') {
            setLicenseId(value);
        } else if (name === 'state') {
            setState(value);
        }
    };

    const handleLicenseSubmit = (doctor) => {
        let inquiryIsValid = !!state
        inquiryIsValid = inquiryIsValid && !!licenseId
        inquiryIsValid = inquiryIsValid && !!doctor?.npi_id

        if(!inquiryIsValid){
            toast('Missing License ID or State')
            return
        }

        let stateExists = false
        let tempState = state
        tempState = tempState.trim().toUpperCase();

        if (!!states[tempState]) {
            stateExists = true
        }

        // Check for full state name
        let stateAbbreviation = Object.keys(states).find(
            (abbreviation) => states[abbreviation].toUpperCase() === tempState
        );

        if (stateExists || !!stateAbbreviation) {
            inquiryIsValid = true
            if (!!stateAbbreviation){
                tempState = stateAbbreviation
            }
        }else{
            toast('State should be an Abbreviated State')
            return
        }

        // Handle form submission logic here
        let inquiry = {npi_id: doctor.npi_id, license_id: licenseId, state: tempState}
        setState(tempState)
        
        apiPost('merchant/submit-npi-license', inquiry)
            .then(response => {
                if (!!response.status){
                    if (response.status === "submitted"){
                        toast("License Submission Successful")
                    }else{
                        toast(response.status)
                    }
                }
            }).catch((e) => {
                console.log(e)
            }).finally(() => {
                setLicenseId('')
                setState('')
                setLicenseSubmissionId('')
                refreshDoctors(merchantReport)
            });
    };

    if (!merchantReport) return <div>No Report Data</div>

    return (
        <div className="box1">
            <h3 className="header">
                <span className='doctor-name'>Doctors Overview</span>
                <button onClick={() => toggleAll("license")}>
                    {licenseActive.length === dedupeDoctors(newDoctors).length ? 'Close All Licenses' : 'Open All License'}
                </button>
                <button onClick={() => toggleAll("review")}>
                    {reviewActive.length === dedupeDoctors(newDoctors).length ? 'Close All Reviews' : 'Open All Reviews'}
                </button>
            </h3>
            <div className="doctors-section">
                <table>
                    <thead></thead>
                    <tr></tr>
                </table>
                {printVersion && !isInitialPrintState && toggleAll('all')}
                {dedupeDoctors(newDoctors)?.map((p, index) => 
                    <div className='doctor'>
                        <span className='doctor-header'>
                            <h2 className='doctor-name'><strong>{p.name}</strong></h2>
                            <span className='dropdown' onClick={() => toggle(p.id, "license")}>License Details<i className={`fa ${licenseActive.includes(p.id) ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden="true"/></span>
                            <span className='dropdown' onClick={() => toggle(p.id, "review")}>Review Details<i className={`fa ${reviewActive.includes(p.id) ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden="true" /></span>
                        </span>
                        <span>{p.specialty}</span>
                        {licenseActive.includes(p.id) && (
                            <div key={index} className="doctor-licenses-container">
                                {p.doctor_licenses.map((license, index) => (
                                    <div className={`doctor-licenses-details ${!furnishDoctorsEnabled && index === p.doctor_licenses.length - 1 ? 'no-border' : ''}`} key={index}>
                                        <div className="items">
                                            <span><b>License Name:</b> {license.name}</span><br />
                                            <span><b>NPI ID:</b> {license?.npi_id?.length === 10 ? license.npi_id : ''}</span><br />
                                            <span><b>License ID:</b> {license.license_number}</span><br />
                                            <span><b>State:</b> {license.license_number_state_code}</span><br />
                                            <span><b>Taxonomy:</b> <a href="#" onClick={() => window.open(`https://npidb.org/taxonomy/${license.license_taxonomy_code}/`, '_blank')}>{license.license_taxonomy_code}</a></span><br />
                                            <br></br>
                                            <a href="#" onClick={() => window.open(`${appConfig.signoutUri}regulatory-search/medical/?state=${convertStateAbbrToFullName(license.license_number_state_code)}&use_case=Professional+Licensing`, '_blank')}>
                                                View {license.license_number_state_code} Medical Licensing
                                            </a>
                                            <br></br>
                                            <a href="#" onClick={() => window.open(`${appConfig.signoutUri}regulatory-search/all/?use_case=Professional+Licensing`, '_blank')}>
                                                View All State Professional Licensing Searches
                                            </a>
                                        </div>
                                        <div className="blur-text-container">
                                            <div className={`blur-container ${!licenseVerificationEnabled ? 'blurred' : ''}`}>
                                                <div className="items">
                                                    {license?.verifications?.length === 0 ? (
                                                        <>
                                                            <span><b>Verification Status:</b> Unverified</span><br />
                                                            <span><b>Verification Date:</b> N/A</span><br />
                                                            <span><b>Order Status:</b> N/A</span><br />
                                                            <span><b>License Status:</b> N/A</span><br />
                                                            <span><b>Adverse Action Status:</b> N/A</span><br />
                                                            <span><b>License Authority Board:</b> N/A</span><br />
                                                            <span><b>License Expiration:</b> N/A</span><br />
                                                            <span><b>License Category:</b> N/A</span><br />
                                                            <span><b>License Issue Date:</b> N/A</span><br />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span><b>Verification Status:</b> {license?.verifications[0]?.mesh_order_status}</span><br />
                                                            <span><b>Verification Date:</b> {license?.verifications[0]?.updated_at}</span><br />
                                                            <span><b>Order Status:</b> {license?.verifications[0]?.mesh_item_status}</span><br />
                                                            <span className='license-flex'>
                                                                <b>License Status:</b> {license?.verifications[0]?.mesh_license_status}
                                                                <LicenseLabelBadge order_labels={license?.verifications[0]?.mesh_order_labels} style={{ padding: '0 10px' }}/>
                                                            </span><br />
                                                            <span><b>Adverse Action Status:</b> {license?.verifications[0]?.mesh_license_adverse_action_status}</span><br />
                                                            <span><b>License Authority Board:</b> {license?.verifications[0]?.mesh_license_authority_board}</span><br />
                                                            <span><b>License Expiration:</b> {license?.verifications[0]?.mesh_license_exp_date}</span><br />
                                                            <span><b>License Category:</b> {license?.verifications[0]?.mesh_licensee_category}</span><br />
                                                            <span><b>License Issue Date:</b> {license?.verifications[0]?.mesh_license_issue_date}</span><br />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {!licenseVerificationEnabled && <div className="overlay-text">Reach Out to Enable Verification Details</div>}
                                        </div>
                                        <div>
                                            <button onClick={() => handleLicenseSubmission(p.doctor_licenses[index])} className="header-button" disabled={!licenseVerificationEnabled || awaitingSubmission || (license?.license_taxonomy_code && !license?.license_taxonomy_code?.startsWith('1223'))}>
                                                Verify License
                                            </button>
                                            {license?.license_taxonomy_code && !license?.license_taxonomy_code?.startsWith('1223') && (
                                                <p>Cannot Verify This License</p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                <div className="add-license-container">
                                    {licenseSubmissionId === p.id ? (
                                        <div className="items">
                                            {(p?.doctor_licenses?.length === 0) && <h2 className='message'>No NPI License Found</h2>}
                                            <h2 className='message'>Submit New License</h2>
                                            <div className="inputs-container">
                                                <div>
                                                    <label htmlFor="licenseId">License ID</label>
                                                    <input type="text" name="licenseId" value={licenseId} onChange={handleInputChange} />
                                                </div>
                                                <div>
                                                    <label htmlFor="state">State</label>
                                                    <input type="text" name="state" value={state} onChange={handleInputChange} />
                                                </div>
                                                <button onClick={() => handleLicenseSubmit(p)}>Submit</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="button-container">
                                            {p?.doctor_licenses?.length === 0 ? (
                                                <h2 className='message'>No Professional Licenses Found</h2>
                                            ) : (
                                                furnishDoctorsEnabled && <h2 className='message'>Submit New License</h2>
                                            )}
                                            {furnishDoctorsEnabled && (<button onClick={() => {setLicenseSubmissionId(p.id); setLicenseId(''); setState('')}}>Add License</button>)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {reviewActive.includes(p.id) && (
                            <div className="doctor-review-details">
                                <table border="1" className="ratings-table">
                                    <thead>
                                        <tr>
                                            <th className='right-border'>Source</th>
                                            <th>Rating</th>
                                            <th>Total Ratings</th>
                                            <th>Total Reviews</th>
                                            <th>Reviews with Alert Words</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {p.review_summary?.map((review, index) => (
                                            <>
                                                <tr key={`${p.id}_${p.alias}`}>
                                                    <td className="right-border">
                                                        {p.review_summary[index].url_found === 0 
                                                            ?  <a href="#" onClick={() => window.open(serviceLineLink(p.review_summary[index].alias, p.name), '_blank')}>
                                                                    {p.review_summary[index].name} Reviews
                                                                </a>
                                                            :   <a href="#" onClick={() => window.open(p.review_summary[index].source_url, '_blank')}>
                                                                    {p.review_summary[index].name} Reviews
                                                                </a>
                                                        }
                                                    </td>
                                                    <td>
                                                        {p.review_summary[index].url_found === 0 
                                                            ? "No Url Found" 
                                                            : p.review_summary[index].avg_review_rating.toFixed(2)
                                                        }
                                                    </td>
                                                    <td>{p.review_summary[index].review_count}</td>
                                                    <td>{p.review_summary[index].total_reviews}</td>
                                                    <td>{p.review_summary[index].alert_word_count}</td>
                                                    <td>
                                                        {p.review_summary[index].url_found === 1 &&
                                                            <span className='dropdown' onClick={() => expand(`${p.id}_${p.review_summary[index].alias}`)}>
                                                                Show Reviews
                                                                <i className={`fa ${isExpanded.includes(`${p.id}_${p.review_summary[index].alias}`) ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden="true"/>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                                {isExpanded.includes(`${p.id}_${p.review_summary[index].alias}`) && p.review_summary[index].doctor_id !== null && (
                                                    <tr>
                                                        <td colSpan="6">
                                                            <div>
                                                                <DoctorReviewDetails 
                                                                    doctorId={p.review_summary[index].doctor_id}
                                                                    service={{
                                                                        lender: p.review_summary[index].lender_id,
                                                                        lender_type: p.review_summary[index].alias,
                                                                        url: p.review_summary[index].source_url
                                                                    }}
                                                                    setRenderComplete={handleRenderComplete}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))}
                                        <tr className='top-border'>
                                            <td className="right-border"><b>Totals</b></td>
                                            <td></td> {/* No total for Rating */}
                                            <td>
                                                {p.review_summary?.reduce((total, review) => total + review.review_count, 0)}
                                            </td>
                                            <td>
                                                {p.review_summary?.reduce((total, review) => total + review.total_reviews, 0)}
                                            </td>
                                            <td>
                                                {p.review_summary?.reduce((total, review) => total + review.alert_word_count, 0)}
                                            </td>
                                            <td></td> {/* No action needed for the last column */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                        
                        <br></br>
                    </div>
                )}
                {dedupeDoctors(newDoctors)?.length === 0 && 
                <span>No Doctor Data Available</span>
                }
            </div>
            {furnishDoctorsEnabled && !printVersion &&
                <>
                    <div className="add-doctor-div">
                        <button className="primary" onClick={() => {setShowFurnishDoctorModal(true)}}>Add Doctor</button>
                    </div>
                </>
            }

            <div className="doctor-submission-modal">
                {showFurnishDoctorModal &&
                    <Modal>
                        <div className='modal-header-button'>
                            <h1>Doctor Submission</h1>
                            <button onClick={() => {setShowFurnishDoctorModal(false); setFurnishDoctorInquiry(defaultDoctorInquiry)}}>Close</button>
                        </div>
                        <form className='doctor-submission-form' onSubmit={handleSubmit} onReset={handleClear}>
                            <div className="field field-license-name">
                                <label htmlFor="first-name">First Name</label>
                                <input type="text" id="first-name" value={furnishDoctorInquiry.first_name} onChange={setDoctorInquiryValue('first_name')} />
                            </div>
                            <div className="field field-license-name">
                                <label htmlFor="middle-name">Middle Name</label>
                                <input type="text" id="middle-name" value={furnishDoctorInquiry.middle_name} onChange={setDoctorInquiryValue('middle_name')} />
                            </div>
                            <div className="field field-license-name">
                                <label htmlFor="last-name">Last Name</label>
                                <input type="text" id="last-name" value={furnishDoctorInquiry.last_name} onChange={setDoctorInquiryValue('last_name')} />
                            </div>
                            <div className="field field-npi-id">
                                <label htmlFor="npi-id">NPI ID</label>
                                <input type="text" id="npi-id" value={furnishDoctorInquiry.npi_id} onChange={setDoctorInquiryValue('npi_id')} />
                            </div>
                            <div className="field field-street-address-number">
                                <label htmlFor="street-address-number">Street Number</label>
                                <input type="text" id="street-address-number" value={furnishDoctorInquiry.street_address_number} onChange={setDoctorInquiryValue('street_address_number')} />
                            </div>
                            <div className="field field-street-address">
                                <label htmlFor="street-address-name">Street Name</label>
                                <input type="text" id="street-address-name" value={furnishDoctorInquiry.street_address_name} onChange={setDoctorInquiryValue('street_address_name')} />
                            </div>
                            <div className="field field-street-address">
                                <label htmlFor="city">City</label>
                                <input type="text" id="city" value={furnishDoctorInquiry.city} onChange={setDoctorInquiryValue('city')} />
                            </div>
                            <div className="field field-street-address-state">
                                <label htmlFor="state">State</label>
                                <input type="text" id="state" value={furnishDoctorInquiry.state} onChange={setDoctorInquiryValue('state')} />
                            </div>
                            <div className="field field-street-address-zip">
                                <label htmlFor="zip5">Zip5</label>
                                <input type="text" id="zip5" value={furnishDoctorInquiry.zip5} onChange={setDoctorInquiryValue('zip5')} />
                            </div>
                            <footer>
                                <button disabled={awaitingSubmission} type="submit" className="primary">Submit</button>
                                <button type="reset">Clear</button>
                            </footer>
                        </form>
                        <div className='npi-doctor-container'>
                            {loading && <Loading />}
                            {NPIDoctors.length > 0 ? (
                                NPIDoctors.map((doctor, index) => (
                                    <div className='npi-doctor-card' key={index} onClick={() => handleCardClick(doctor)}>
                                        <div className='doctor-content'>
                                            <div className='doctor-info'>
                                                <h2>{doctor.prefix} {doctor.first_name} {doctor.middle_name} {doctor.last_name} {doctor.suffix}</h2>
                                                <h2>{doctor.other_prefix} {doctor.other_first_name} {doctor.other_middle_name} {doctor.other_last_name} {doctor.other_suffix}</h2>
                                                <p><strong>NPI ID:</strong> {doctor?.npi_id?.length === 10 ? doctor.npi_id : ''}</p>
                                            </div>
                                            <div className='doctor-address'>
                                                <p><strong>Doctor Address:</strong> {formatAddress(doctor, 'doctor')}</p>
                                                <p><strong>Practice Address:</strong> {formatAddress(doctor, 'practice')}</p>
                                            </div>
                                            <div className='doctor-overall-similarity'>
                                                <div data-title={"Only Inputs Affect This Score"} className={`similarity-badge ${getSimilarityClass(getOverallScore(doctor))}`}>
                                                    {getOverallScore(doctor)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='doctor-similarity'>
                                            <NPIDoctorMatchBadges doctor={doctor} />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </Modal>
                }
            </div>

            <div className="doctor-confirmation-modal">
                {showConfirmationDoctorModal &&
                    <Modal>
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Are you sure you want to submit this doctor?</h2>
                                <p>{selectedDoctor?.first_name} {selectedDoctor?.middle_name} {selectedDoctor?.last_name}</p>
                                <p>NPI ID: {selectedDoctor?.npi_id}</p>
                                <div className='button-container'>
                                    <button onClick={handleConfirmSubmit} style={{background: '#1997bc'}}>Submit</button>
                                    <button onClick={handleCloseModal}>Close</button>
                                </div>
                                
                            </div>
                        </div>
                    </Modal>
                }
            </div>
       </div>
    )
}