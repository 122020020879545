import React, { useContext } from 'react'
import {Link, useRouteMatch, useLocation} from "react-router-dom";

import SessionContext from 'SessionContext'
import LenderManager from './LenderManager'
import UserManager from './UserManager'
import UsageManager from './UsageManager'
import MyProfile from './MyProfile'
import ScorecardManager from './ScorecardManager'
import Deployments from './Deployments'

import './Settings.scss'

export default function Settings(){
    const { user } = useContext(SessionContext)
    const { params: {  section = 'my-profile' } } = useRouteMatch()
    const queryParams = new URLSearchParams(useLocation().search)

    function activeIf(_section){
        return _section === section ? 'active' : ''
    }

    const verdata_admin = user.is_staff
    const lender_admin = user.groups && user.groups.some(g => g.name === 'Lender Admin')

    return (
      <div className="Settings">

       <nav>
           <Link to={`/settings`} className={activeIf('my-profile')}>My Profile</Link>
           { verdata_admin && <Link to={`/settings/lenders`} className={activeIf('lenders')}>Lenders</Link> }
           { (verdata_admin || lender_admin) && <Link to={`/settings/users`} className={activeIf('users')}>Users</Link> }
           { (verdata_admin || lender_admin) && <Link to={`/settings/usage`} className={activeIf('usage')}>Usage</Link> }
           { (verdata_admin || lender_admin) && <Link to={'/settings/scorecard'} className={activeIf('scorecard')}>Scorecard</Link>}
           <Link to={'/settings/deployments'} className={activeIf('deployments')}>Deployments</Link>
       </nav>

       { section === 'my-profile' && <MyProfile /> }
       { verdata_admin && section === 'lenders' && <LenderManager queryParams={queryParams} /> }
       { (verdata_admin || lender_admin) && section === 'users' && <UserManager queryParams={queryParams} /> }
       { (verdata_admin || lender_admin) && section === 'usage' && <UsageManager /> }
       { (verdata_admin || lender_admin) && section === 'scorecard' && <ScorecardManager /> }
       {section === 'deployments' && <Deployments></Deployments>}
       
      </div>
    )
}
