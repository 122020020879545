import React, { useEffect, useState } from 'react'
import useApi from 'hooks/useApi'
import FormatDate from 'shared/FormatDate'
import Loading from 'shared/Loading'
import ReadMoreHighlighter from 'shared/ReadMoreHighlighter'

import './MerchantCompaintDetails.scss'

export default function MerchantComplaintAlertWords({
       merchantId, bbb_merchant, no_bbb_data_message, alertWordIds, parentCallback
}){
    const alertWordIdArr = alertWordIds.map(idObj => idObj.id)
    const idStr = alertWordIdArr.length === 0 ? "00000000-0000-0000-0000-000000000000" :alertWordIdArr.join(",");

    const { apiGet } = useApi()
    const [ page, setPage ] = useState(0)
    const [ complaint, setComplaint ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ rendered, setRendered ] = useState(false)

    useEffect(()=> {
        setLoading(true)
        // TODO: do we want to pass through the inquiryID for tracking/authorization purposes?
        apiGet('merchant-bbb-complaints', { merchantId, idStr, page: page+1 })
            .then( response => {
                const prevResults = complaint ? complaint.results : []
                setLoading(false)
                setComplaint({
                    ...response,
                    results: [ ...prevResults, ...response.results ]
                })
            } )
        setRendered(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId, idStr, page])

    useEffect(()=> {
        parentCallback(true)
    }, [rendered])

    if ( complaint === null ) return <Loading />
    
    const hasMore = complaint.results.length < complaint.count

    return (
        <>
        <h3>Alert Word Complaint {bbb_merchant && <>({complaint.count})</>} </h3>
        {bbb_merchant ? 
        <div className={`merchant-complaint-detail`}>
            { complaint.results.length === 0
                ? <span>No Alert Word Complaints Found</span>
                : <ComplaintTable complaints={complaint.results} />
            }
            { (loading || hasMore) && (
                <footer>
                    { loading && <Loading /> }
                    { (!loading && hasMore) && (
                        <div className='pager-more'>
                            <button className='load-more'
                                    onClick={() => setPage(page+1)}>See More Complaints</button>
                        </div>
                    )}
                </footer>
            )}
        </div>
        : <p>{no_bbb_data_message}</p>
        }
        </>
    )
}

function ComplaintTable({complaints}){
    return (
        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Complaint</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            { complaints.map((complaint, index) =>
                <tr className={'row-' + (index + 1) % 2} key={index}>
                    <td><FormatDate date={complaint.complaint_dt}/></td>
                    <td>{complaint.description}</td>
                    <td><ReadMoreHighlighter text={complaint.complaint_text} /></td>
                    <td>{complaint.resolved_status}</td>
                </tr>
            )}
            </tbody>
        </table>
    )
}
