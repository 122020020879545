import React, {useEffect, useState} from 'react'
import useApi from 'hooks/useApi'
import Loading from 'shared/Loading'
import vMonitorlogo from 'assets/Vmonitor-logo-03.png'
import { useContext } from 'react'
import SessionContext from 'SessionContext'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import appConfig from 'app-config.json'


import './notifications.scss'
import {Link} from "react-router-dom";
import {MultiButtonSwitcher} from "../../shared/MultiButtonSwitcher";
import toast from 'react-hot-toast'
import DatePicker from "react-datepicker";

import "./datepicker.css";


export default function Notifications() {

    const { apiGet, apiPost } = useApi()
    const [ results, setResults ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ config, setConfig ] = useState({})
    const [ users, setUsers] = useState({})
    const [ active, setActive ] = useState("closures")
    const [reload, setReload ] = useState(false)
    const [ email, setEmail ] = useState(" ")
    const [ downloadingCsv, setDownloadingCsv ] = useState(false)
    const [ viewDatePicker, setViewDatePicker] = useState(false)
    const [ historyDownload, setHistoryDownload] = useState({})
    const [ downloadDate, setDownloadDate] = useState(new Date())
    const [ notifDate, setNotifDate] = useState(new Date())
    const session = useContext(SessionContext)
    const idToken = session.credentials.idToken
    const [ closuresKey, setClosuresKey] = useState(1)
    const [ complaintsKey, setComplaintsKey] = useState(1)   
    const [ loansKey, setLoansKey] = useState(1)    
    const [ reviewsKey, setReviewsKey] = useState(1)    
    const [ ratingsKey, setRatingsKey] = useState(1)    
    const [ historyKey, setHistoryKey] = useState(1)    

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
    
    const lender = localStorage.getItem('lender')
    const parseLender = JSON.parse(lender)
    const lenderId =  lender && parseLender['id']

    let today = new Date()
    
    

    useEffect(()=> {
        setLoading(true)
        apiGet(`notifications/config`, {
        }).then( response => {
            setConfig(response[0])
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(()=> {
        setLoading(true)
        apiGet(`notifications/users`, {
        }).then( response => {
            setUsers(response)
        }).finally(setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    var payload = {
        "date": notifDate.toLocaleString()
    }
    
    useEffect(()=> {
        setLoading(true)
        apiPost(`notifications/notifications_by_date`, payload).then( response => {
            if (JSON.stringify(response) !== '{}') {
                let parsed = JSON.parse(response['json_blob'])
                setResults(parsed)
            } else {
                setResults({})
            }
            setClosuresKey(closuresKey+1)
            setComplaintsKey(complaintsKey+1)
            setLoansKey(loansKey+1)
            setReviewsKey(reviewsKey+1)
            setRatingsKey(ratingsKey+1)
            setHistoryKey(historyKey+1)
        }).finally(setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reload])


    const downloadBySection = () => {
        // converts a blob to a download in the browser
        function saveBlob(blob, filename) {
           const a = document.createElement("a");
           document.body.appendChild(a);
           const blobUrl = window.URL.createObjectURL(blob);
           a.href = blobUrl;
           a.download = filename;
           a.click();
           setTimeout(() => {
             window.URL.revokeObjectURL(blobUrl);
             document.body.removeChild(a);
           }, 10);
        }
        setDownloadingCsv(true)
        const payload = {
            "section": active,
            "date": notifDate.toLocaleString()
        }
        const headers = { 
            'content-type': 'application/json'
           }

        if(idToken) headers['Authorization'] = `Bearer ${idToken}`
        if(lenderId) headers['LenderId'] = lenderId

        fetch(`${appConfig.apiUri}/notifications/download_by_date`, {method:'post', headers, body:JSON.stringify(payload)}).then( response => {
            return response.blob()
        }).then( blob => {
            saveBlob(blob, `vmonitor_notifications_${active}_${notifDate.toLocaleString(undefined, options).replace(/\//g,'-')}.csv`)
            setDownloadingCsv(false)
        })
    }
  
    // For the history download
    function setNotificationsToDownload(date) {
        setDownloadDate(date)
        const payload = {
            "date": date.toLocaleString()
        }

        apiPost(`notifications/notifications_by_date`, payload).then( response => {
            if (JSON.stringify(response) !== '{}') {
                let parsed = JSON.parse(response['json_blob'])
                setHistoryDownload(parsed)
            } else {
                setHistoryDownload({})
            }
        })
    }
    // Switching between dates to view
    function setNotificationsToView(date) {
        setNotifDate(date)
        const payload = {
            "date": date.toLocaleString()
        }

        apiPost(`notifications/notifications_by_date`, payload).then( response => {
            if (JSON.stringify(response) !== '{}') {
                let parsed = JSON.parse(response['json_blob'])
                setResults(parsed)
                handleReload()
            } else {
                setResults({})
                handleReload()
            }
            setClosuresKey(closuresKey+1)
            setComplaintsKey(complaintsKey+1)
            setLoansKey(loansKey+1)
            setReviewsKey(reviewsKey+1)
            setRatingsKey(ratingsKey+1)
            setHistoryKey(historyKey+1)

        })
    }
    // The download all notifs button
    async function downloadAllByDate() {
        setDownloadingCsv(true)
        const zip = new JSZip();

        let fileNames = []

        if (results['closures'].length !== 0){
            fileNames.push("closures")
        }
        if (results['complaints'].length !== 0){
            fileNames.push("complaints")
        }
        if (results['loans'].length !== 0){
            fileNames.push("loans")
        }
        if (results['reviews'].length !== 0){
            fileNames.push("reviews")
        }
        if (results['rating_change'].length !== 0){
            fileNames.push("rating")
        }

        let count = 0;
        if (fileNames.length !== 0) {
            for(let name of fileNames) {
                const payload = {
                    "section": name,
                    "date": notifDate.toLocaleString()
                }
                const headers = { 
                    'content-type': 'application/json'
                    }
    
                if(idToken) headers['Authorization'] = `Bearer ${idToken}`
                if(lenderId) headers['LenderId'] = lenderId
    
                fetch(`${appConfig.apiUri}/notifications/download_by_date`, {method:'post', headers, body:JSON.stringify(payload)}).then( response => {
                    return response.blob()
                }).then( blob => {
                    const final_name = `vmonitor_notifications_${name}_${notifDate.toLocaleString(undefined, options).replace(/\//g,'-')}.csv`
                    zip.file(final_name, blob)
                    count = count + 1
                    if (count === fileNames.length) {
                        zip.generateAsync({ type: 'blob' }).then(function(content) {
                            saveAs(content, `vmonitor_notifications_${notifDate.toLocaleString(undefined, options).replace(/\//g,'-')}.zip`);
                            });
                        setDownloadingCsv(false)
                    } 
                })        
            }
        } else {
            setDownloadingCsv(false)
            toast('No Notifications to download!')
        }
    }

    // history download
    async function historyDownloadByDate() {
        setDownloadingCsv(true)
        const zip = new JSZip();

        let fileNames = []

        if (historyDownload['closures'].length !== 0){
            fileNames.push("closures")
        }
        if (historyDownload['complaints'].length !== 0){
            fileNames.push("complaints")
        }
        if (historyDownload['loans'].length !== 0){
            fileNames.push("loans")
        }
        if (historyDownload['reviews'].length !== 0){
            fileNames.push("reviews")
        }
        if (historyDownload['rating_change'].length !== 0){
            fileNames.push("rating")
        }

        let count = 0;
        if (fileNames.length !== 0) {
            for(let name of fileNames) {
                const payload = {
                    "section": name,
                    "date": downloadDate.toLocaleString()
                }
                const headers = { 
                    'content-type': 'application/json'
                    }
    
                if(idToken) headers['Authorization'] = `Bearer ${idToken}`
                if(lenderId) headers['LenderId'] = lenderId
    
                fetch(`${appConfig.apiUri}/notifications/download_by_date`, {method:'post', headers, body:JSON.stringify(payload)}).then( response => {
                    return response.blob()
                }).then( blob => {
                    const final_name = `vmonitor_notifications_${name}_${downloadDate.toLocaleString(undefined, options).replace(/\//g,'-')}.csv`
                    zip.file(final_name, blob)
                    count = count + 1
                    if (count === fileNames.length) {
                        zip.generateAsync({ type: 'blob' }).then(function(content) {
                            saveAs(content, `vmonitor_notifications_${downloadDate.toLocaleString(undefined, options).replace(/\//g,'-')}.zip`);
                            });
                        setDownloadingCsv(false)
                    } 
                })        
            }
        } else {
            setDownloadingCsv(false)
            toast('No Notifications to download!')
        }
    }

    function isSameDay(date1, date2) {
        if (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
          ) {
            return true
          }
          else {
            return false
          }
    }

    function setTab(tab){
        setActive(tab)
    }

    function handleReload(){
        setReload(!reload)
    }

    
    async function addUser(ev){
        ev.preventDefault()
        let request = {
            "email": email
        }
        await apiPost('notifications/user/add', request)
                    .then(res => {
                        if(res){
                            handleReload()
                        }
                    }).finally(setEmail(""))
    }

    async function updateConfig(ev){
        ev.preventDefault()
        let request = config
        await apiPost('notifications/config/update', request)
                    .then(res => {
                        if(res){
                            handleReload()
                        }
                    })
    }

    function handleConfigClick() {
        if (isSameDay(notifDate, today) === true) {
            setTab("configuration")
        } else {
            toast('Can only edit configurations for present day')
        }
    }


    async function handleDelete(user){
         let request = {
            "email": user.email
        }
        await apiPost('notifications/user/delete', request)
                    .then(res => {
                        if(res){
                            handleReload()
                        }
                    })
    }

    const sortAndFilter = {
        onClickSortBy: (sortCol, sortBy, setSortBy) => {
            const [currentCol, currentDir] = sortBy
            if (sortCol !== currentCol) {
                // switch to requested column, ascending
                setSortBy([sortCol, 'asc'])
            } else {
                if (currentDir === 'asc') {
                    // same column, switch to descending
                    setSortBy([ sortCol, 'desc'])
                } else {
                    // same column, switch to ascending
                    setSortBy([ sortCol, 'asc'])
                }
            }
        },
        sortItems: (section, sortBy) => {
            if (!section) return

            section.sort((a, b) => {
                const [sortCol, sortDir] = sortBy
                if (sortDir === 'asc') {
                    return a[sortCol] > b[sortCol] ? 1 : -1
                } else {
                    return a[sortCol] < b[sortCol] ? 1 : -1
                }
            })
        },
        sortDirFor: (col, sortBy) => sortBy && sortBy[0] === col && sortBy[1],
        setFilter: (filter, filterValues, setFilterBy, section, sectionData, setSectionData) => {
            setFilterBy(filterValues[filter])
            if (filter === 'all') {
                setSectionData(section)
            } else {
                setSectionData(section.filter((obj) => obj[filterValues[filter]] !== 0))
            }
        },
    }

    if (loading) return <Loading />

    return (
        <>
          {config.notifications_enabled !== false ?
              <div className='notifications-table'>
                <div className='centered-image'><img src={vMonitorlogo} alt="vMonitor" style={{alignSelf:"center",width:"400px"}}/><h2>
                  Daily Notifications for {notifDate.toLocaleString(undefined, options).replace(/\//g,'-')}
                  </h2>
                  {!viewDatePicker ?
                    <div className='view-past-notifs' onClick={() => setViewDatePicker(true)}> View Past Notifications
                    </div>
                    :
                    <DatePicker selected={notifDate} minDate={new Date(2023,4, 22)} maxDate={new Date()}
                    onChange={(date) => setNotificationsToView(date)} startOpen={true} placeholderText="View notifications for a different date" />
 
                  }
                  </div>
                  

                  <header>
                    <div className='link-button' onClick={() => setTab("closures")}>Closures</div> {results?.closures && results?.closures.length !== (null || 0) ? <div className="round-meter rank-unknown is-small">{results?.closures.length}</div> : ''}
                    <div className='link-button' onClick={() => setTab("complaints")}>Complaints</div> {results?.complaints && results?.complaints.length !== (null || 0) ? <div className="round-meter rank-unknown is-small">{results?.complaints.length}</div> : ''}
                    <div className='link-button' onClick={() => setTab("loans")}>Loans</div> {results?.loans && results?.loans.length !== (null || 0) ? <div className="round-meter rank-unknown is-small">{results?.loans.length}</div> : ''}
                    <div className='link-button' onClick={() => setTab("reviews")}>Reviews</div> {results?.reviews && results?.reviews.length !== (null || 0) ? <div className="round-meter rank-unknown is-small">{results?.reviews.length}</div> : ''}
                    <div className='link-button' onClick={() => setTab("rating")}>Rating Change</div> {results?.rating_change && results?.rating_change.length !== (null || 0) ? <div className="round-meter rank-unknown is-small">{results?.rating_change.length}</div> : ''}
                    <div className='link-button' onClick={() => handleConfigClick()}>Configuration</div>
                    <div className='link-button' onClick={() => setTab("history")}>History</div>
                  </header>
                  <div className='download-all'>
                        {downloadingCsv
                            ? <button className='inverted'>Generating CSV...</button>
                            : <button
                                  onClick={downloadAllByDate}
                                  className='inverted'
                                  disabled={downloadingCsv || Object.entries(results).length === 0 }>
                                  <i className="fa fa-download" aria-hidden="true" />
                                  Download All Notifications
                              </button>
                        }
                    </div>
                  <div>
                {active === "closures" ? <ClosuresSectionTable key={closuresKey} section={results?.closures} loading={loading} config={config} sortAndFilter={sortAndFilter} downloadingCsv={downloadingCsv} downloadBySection={downloadBySection}
                  /> :  null}

                {active === "complaints" ? <ComplaintsSectionTable key={complaintsKey} section={results?.complaints} loading={loading} config={config} sortAndFilter={sortAndFilter} downloadingCsv={downloadingCsv} downloadBySection={downloadBySection}
                  /> : null}

                  {active === "loans" ? <LoansSectionTable key={loansKey} section={results?.loans} loading={loading} config={config} sortAndFilter={sortAndFilter} downloadingCsv={downloadingCsv} downloadBySection={downloadBySection}
                  /> : null}

                  {active === "reviews" ? <ReviewsSectionTable key={reviewsKey} section={results?.reviews} loading={loading} config={config} sortAndFilter={sortAndFilter} downloadingCsv={downloadingCsv} downloadBySection={downloadBySection}
                  /> : null}

                  {active === "rating" ? <RatingSectionTable key={ratingsKey} section={results?.rating_change} loading={loading} config={config} sortAndFilter={sortAndFilter} downloadingCsv={downloadingCsv} downloadBySection={downloadBySection}
                  /> : null}

                {active === "history" ? <HistorySectionTable key={historyKey} loading={loading} config={config} sortAndFilter={sortAndFilter} downloadingCsv={downloadingCsv} downloadDate = {downloadDate} historyDownload={historyDownload}setHistoryDownload={setHistoryDownload} reload={reload} setNotificationsToDownload={setNotificationsToDownload} historyDownloadByDate={historyDownloadByDate}
                  /> : null}


                  {active === "configuration" ? <>
                    <h2>Email Digest Recipients</h2>
                    <div>
                        {users && users.length !== 0 ?
                    <table className="user-table">
                        <thead>
                        <tr>
                            <th style={{textAlign:"left"}}>
                                Recipient
                            </th>

                            <th>
                                
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {users && users.map((user, i) => (
                            <tr className={i % 2 === 0 ? '' : 'stripe'} key={user.email}>
                                <td style={{justifyContent:"left"}}>{user.email}</td>
                                <td style={{justifyContent:"right"}} className="last-child"><button className ="button-primary" onClick={() => handleDelete(user)}>Remove</button></td>
                            </tr>

                        ))}
                        </tbody>
                    </table>
                    :
                    <div>No Recipients Found.</div>
                    }

                    <h2>Add Recipients</h2>
                    <form onSubmit={addUser}>
                        <div className="field user-email-field" style={{width:"430px"}}>
                                    <label htmlFor="user-email">Email</label>
                                    <input type="email" id="user-email"
                                        value={email} onChange={ev => setEmail(ev.target.value)} />
                                </div>
                                <button className="primary">Save</button>
                            </form>
                            
                    <h2>Update Packages</h2>
                    <form onSubmit={updateConfig}>
                        <div>
                                    <label htmlFor="user-email">Closures</label>
                                    <input
                                    type="checkbox"
                                    checked={config.notifications_closures}
                                    onChange={() => {
                                    let newConfig = {...config}
                                    newConfig.notifications_closures = !newConfig.notifications_closures
                                    setConfig(newConfig)}
                                    }
                                    />
                                </div>
                                <div>
                                    <label htmlFor="user-email">Complaints</label>
                                    <input
                                    type="checkbox"
                                    checked={config.notifications_complaints}
                                    onChange={() => {
                                        let newConfig = {...config}
                                        newConfig.notifications_complaints = !newConfig.notifications_complaints
                                        setConfig(newConfig)}
                                        }
                                    />
                                </div>
                                <div>
                                    <label htmlFor="user-email">Loans</label>
                                    <input
                                    type="checkbox"
                                    checked={config.notifications_loans}
                                    onChange={() => {
                                        let newConfig = {...config}
                                        newConfig.notifications_loans = !newConfig.notifications_loans
                                        setConfig(newConfig)}
                                        }
                                    />
                                </div>
                                <div>
                                    <label htmlFor="user-email">Reviews</label>
                                    <input
                                    type="checkbox"
                                    checked={config.notifications_reviews}
                                    onChange={() => {
                                        let newConfig = {...config}
                                        newConfig.notifications_reviews = !newConfig.notifications_reviews
                                        setConfig(newConfig)}
                                        }
                                    />
                                </div>
                                <div>
                                    <label htmlFor="user-email">Rating Change</label>
                                    <input
                                    type="checkbox"
                                    checked={config.notifications_rating_change}
                                    onChange={() => {
                                        let newConfig = {...config}
                                        newConfig.notifications_rating_change = !newConfig.notifications_rating_change
                                        setConfig(newConfig)}
                                        }
                                    />
                                </div>
                                <button className="primary" style={{marginTop:"10px"}}>Save</button>
                        </form>
                        <h4>Note: Updated packages will be reflected on the next day</h4>
                                    
                    </div>
  
        {loading && <Loading />}
    </> : null}
                  </div>

              </div>
          :
              <div className='coming-soon'>
                  <h1>Notifications not enabled. Please contact support@verdata.com with questions.</h1>
              </div>
          }
        </>
    )
}




function ClosuresSectionTable({section, loading, config, sortAndFilter, downloadingCsv, downloadBySection}){
    const [ sortBy, setSortBy ] = useState(['name', 'asc'])
    const [ filterBy, setFilterBy ] = useState('all')
    const [ sectionData, setSectionData ] = useState(section)

    useEffect(() => {
        sortAndFilter.sortItems(sectionData, sortBy)
    }, [sectionData, sortBy, sortAndFilter])

    const onClickSortBy = (sortCol) => {
        sortAndFilter.onClickSortBy(sortCol, sortBy, setSortBy)
    }

    const sortDirFor = (col) => sortAndFilter.sortDirFor(col, sortBy)

    const filterValues = {
        'all': 'all',
        'Fraud/Performance': 'closed_fraud_performance',
        'Closed/Partner': 'closed_partner',
        'Closed/Other': 'closed_other',
        'Suspended': 'suspended',
        'Declined': 'closed_decline',
        'Out of Business': 'closed_oob',
        'Compliance': 'closed_compliance',
    }

    const setFilter = (filter) => {
        sortAndFilter.setFilter(filter, filterValues, setFilterBy, section, sectionData, setSectionData)
    }

    return (
        <>
        <div>
            <div >
            <h2>Closures</h2>
        <p style={{fontSize:"1em",paddingBottom:"2em"}}>Verdata has received information within the past 7 days indicating the following businesses have a closure on record:</p>

            </div>
            {section && section.length !== 0 ?
            <>
            <div className="notifications-filter-closures">
                <MultiButtonSwitcher values={Object.keys(filterValues)}
                                 value={Object.keys(filterValues).find(filter => filterValues[filter] === filterBy)}
                                 onChange={(filter) => setFilter(filter)}/>
            </div>
            </> : null
            }

            {sectionData && sectionData.length !== 0 ?
            <>
            <div className="notif-container">
            <table className="notif-table">
                <thead>
                <tr>
                    <th onClick={() => onClickSortBy('name')}>
                        <Sorter dir={sortDirFor('name')} />
                        Name
                    </th>
                    <th onClick={() => onClickSortBy('date_reported')}>
                        <Sorter dir={sortDirFor('date_reported')} />
                        Date Reported
                    </th>
                    <th onClick={() => onClickSortBy('closed_fraud_performance')}>
                        <Sorter dir={sortDirFor('closed_fraud_performance')} />
                        Fraud/Performance
                    </th>
                    <th onClick={() => onClickSortBy('closed_partner')}>
                        <Sorter dir={sortDirFor('closed_partner')} />
                        Closed/Partner
                    </th>
                    <th onClick={() => onClickSortBy('closed_other')}>
                        <Sorter dir={sortDirFor('closed_other')} />
                        Closed/Other
                    </th>
                    <th onClick={() => onClickSortBy('suspended')}>
                        <Sorter dir={sortDirFor('suspended')} />
                        Suspended
                    </th>
                    <th onClick={() => onClickSortBy('closed_decline')}>
                        <Sorter dir={sortDirFor('closed_decline')} />
                        Declined
                    </th>
                    <th onClick={() => onClickSortBy('closed_oob')}>
                        <Sorter dir={sortDirFor('closed_oob')} />
                        Out of Business
                    </th>
                    <th onClick={() => onClickSortBy('closed_compliance')}>
                        <Sorter dir={sortDirFor('closed_compliance')} />
                        Compliance
                    </th>
                    <th>
                        Store ID(s)
                    </th>
                    <th>
                    {downloadingCsv
                            ? <button className='inverted'>Generating CSV...</button>
                            : <button
                                  onClick={downloadBySection}
                                  className='inverted'
                                  disabled={downloadingCsv}>
                                  <i className="fa fa-download" aria-hidden="true" />
                                  Download CSV
                              </button>
                        }
                    </th>
                </tr>
                </thead>
                <tbody>
                {sectionData && sectionData.map((notif, i) => (
                    <tr className={i % 2 === 0 ? '' : 'stripe'} key={notif.notification_id}>
                        <td>{notif.name}</td>
                        <td>{notif.last_reported}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif.closed_fraud_performance !== 0 ? 'X' : ''}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif?.closed_partner === 0 || !notif.hasOwnProperty("closed_partner")? '' : 'X'}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif?.closed_other === 0 || !notif.hasOwnProperty("closed_other") ? '' : 'X'}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif?.suspended === 0 || !notif.hasOwnProperty("suspended") ? '' : 'X'}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif.closed_decline !== 0 ? 'X' : ''}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif.closed_oob !== 0 ? 'X' : ''}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif.closed_compliance !== 0 ? 'X' : ''}</td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif.storeid_agg}</td>
                        <td>
                            <Link to={`/healthcheck/overview/merchant/${notif.merchant_id}`} className='button primary' target='_blank'>Run Report</Link>
                        </td>
                    </tr>

                ))}
                </tbody>
            </table>
            </div>
            </>
            : config.notifications_closures === true ? <div style={{marginTop:"1em"}}>No Notifications found.</div> : <div style={{marginTop:"1em"}}>Notification Package Disabled. To enable, visit Configuration tab.</div>}
            {loading && <Loading />}
            </div>
        </>
    )
}

function ComplaintsSectionTable({section, loading, config, sortAndFilter, downloadingCsv, downloadBySection}){
    const [ sortBy, setSortBy ] = useState(['name', 'asc'])
    const [ filterBy, setFilterBy ] = useState('all')
    const [ sectionData, setSectionData ] = useState(section)

    useEffect(() => {
        sortAndFilter.sortItems(sectionData, sortBy)
    }, [sectionData, sortBy, sortAndFilter])

    const onClickSortBy = (sortCol) => {
        sortAndFilter.onClickSortBy(sortCol, sortBy, setSortBy)
    }

    const sortDirFor = (col) => sortAndFilter.sortDirFor(col, sortBy)

    const filterValues = {
        'all': 'all',
        'Regulatory Only': 'regulatory_complaint',
        'Alert Words Only': 'complaint_alert_word',
        '7 Day Only': 'seven_day_bbb_notification',
        '14 Day Only': 'fourteen_day_bbb_notification',
        '30 Day Only': 'thirty_day_bbb_notification',
    }

    const setFilter = (filter) => {
        sortAndFilter.setFilter(filter, filterValues, setFilterBy, section, sectionData, setSectionData)
    }

    return (
        <>
        <div>
        <div>
        <h2>Complaints</h2>
        <p style={{fontSize:"1em",paddingBottom:"2em"}}>Verdata monitors complaints regularly. Within the past 5 days, Verdata has received information
            indicating the following businesses have incurred regulatory complaints, complaints including one or more of Verdata's alert words, or
            has exceeded the following thresholds: </p>
            <ul>
            <li>Greater than 0 complaint(s) in 7 days</li>
            <li>Greater than 1 complaint(s) in 14 days</li>
            <li>Greater than 3 complaint(s) in 30 days</li>
            </ul>
            For a full list of Verdata alert words, <Link to={`/alert-words`} style={{paddingBottom:"2em"}} target='_blank'>Click Here.</Link>
            <br></br><br></br>
        </div>

            {section && section.length !== 0 ?
            <>
            <div className="notifications-filter">
                <MultiButtonSwitcher values={Object.keys(filterValues)}
                                 value={Object.keys(filterValues).find(filter => filterValues[filter] === filterBy)}
                                 onChange={(filter) => setFilter(filter)}/>
            </div>
            </> : null
            }

        {sectionData && sectionData.length !== 0 ?
        <>

        <div className="notif-container">
            <table className="notif-table">
            <thead>
            <tr>
                <th onClick={() => onClickSortBy('name')}>
                    <Sorter dir={sortDirFor('name')} />
                    Name
                </th>
                <th onClick={() => onClickSortBy('regulatory_complaint')}>
                    <Sorter dir={sortDirFor('regulatory_complaint')} />
                    Regulatory
                </th>
                <th onClick={() => onClickSortBy('complaint_alert_word')}>
                    <Sorter dir={sortDirFor('complaint_alert_word')} />
                    Alert Words
                </th>
                <th onClick={() => onClickSortBy('seven_day_bbb_notification')}>
                    <Sorter dir={sortDirFor('seven_day_bbb_notification')} />
                    7 Day
                </th>
                <th onClick={() => onClickSortBy('fourteen_day_bbb_notification')}>
                    <Sorter dir={sortDirFor('fourteen_day_bbb_notification')} />
                14 Day
                </th>
                <th onClick={() => onClickSortBy('thirty_day_bbb_notification')}>
                    <Sorter dir={sortDirFor('thirty_day_bbb_notification')} />
                30 Day
                </th>
                <th>
                    Store ID(s)
                </th>
                <th>
                {downloadingCsv
                            ? <button className='inverted'>Generating CSV...</button>
                            : <button
                                  onClick={downloadBySection}
                                  className='inverted'
                                  disabled={downloadingCsv}>
                                  <i className="fa fa-download" aria-hidden="true" />
                                  Download CSV
                              </button>
                        }
                </th>
            </tr>
            </thead>
            <tbody>
            {sectionData && sectionData.map((notif, i) => (
                <tr className={i % 2 === 0 ? '' : 'stripe'} key={notif.store_id}>
                    <td>{notif.name}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.regulatory_complaint !== 0 ? 'X' : ''}</td>
                    <td>
                        <Link to={`/healthcheck/overview/merchant/${notif.merchant_id}/regulatory#alert-word-complaint`} target='_blank' style={{textalign:"center",justifyContent:"center"}}> {notif.complaint_alert_word !== 0 ? 'Link' : ''} </Link>
                    </td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.seven_day_bbb_notification !== 0 ? 'X' : ''}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.fourteen_day_bbb_notification !== 0 ? 'X' : ''}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.thirty_day_bbb_notification !== 0 ? 'X' : ''}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.storeid_agg}</td>

                    <td>
                        <Link to={`/healthcheck/overview/merchant/${notif.merchant_id}`} className='button primary' target='_blank'>Run Report</Link>
                    </td>
                </tr>

            ))}
            </tbody>
        </table>
        </div>
        </>
            : config.notifications_complaints === true ? <div style={{marginTop:"1em"}}>No Notifications found.</div> : <div style={{marginTop:"1em"}}>Notification Package Disabled. To enable, visit Configuration tab.</div>}
        {loading && <Loading />}
        </div>
    </>
    )
}

function LoansSectionTable({section, loading, config, sortAndFilter, downloadingCsv, downloadBySection}){
    const [ sortBy, setSortBy ] = useState(['name', 'asc'])
    const [ filterBy, setFilterBy ] = useState('all')
    const [ sectionData, setSectionData ] = useState(section)

    useEffect(() => {
        sortAndFilter.sortItems(sectionData, sortBy)
    }, [sectionData, sortBy, sortAndFilter])

    const onClickSortBy = (sortCol) => {
        sortAndFilter.onClickSortBy(sortCol, sortBy, setSortBy)
    }

    const sortDirFor = (col) => sortAndFilter.sortDirFor(col, sortBy)

    const filterValues = {
        'all': 'all',
        'Charge-off Only': 'charge_off_notification',
        'Delinquent Only': 'delinquency_monitoring',
        'First Payment Default Only': 'first_payment_default_monitoring',
    }

    const setFilter = (filter) => {
        sortAndFilter.setFilter(filter, filterValues, setFilterBy, section, sectionData, setSectionData)
    }

    return (
        <>
        <div>
            <div >
                <h2>Loans</h2>
                <p style={{fontSize:"1em",paddingBottom:"2em"}}>Verdata has received information within the past 7 days indicating the following businesses have adverse loan performance on record:</p>
            </div>
            {section && section.length !== 0 ?
            <>
            <div className="notifications-filter">
                <MultiButtonSwitcher values={Object.keys(filterValues)}
                                 value={Object.keys(filterValues).find(filter => filterValues[filter] === filterBy)}
                                 onChange={(filter) => setFilter(filter)}/>
            </div>
            </> : null
            }

        {sectionData && sectionData.length !== 0 ?
        <>
        
        <div className="notif-container">
            <table className="notif-table">
            <thead>
            <tr>
                <th onClick={() => onClickSortBy('name')}>
                    <Sorter dir={sortDirFor('name')} />
                    Name
                </th>
                <th onClick={() => onClickSortBy('charge_off_notification')}>
                    <Sorter dir={sortDirFor('charge_off_notification')} />
                    Charge-off
                </th>
                <th onClick={() => onClickSortBy('delinquency_monitoring')}>
                    <Sorter dir={sortDirFor('delinquency_monitoring')} />
                    Delinquent
                </th>
                <th onClick={() => onClickSortBy('first_payment_default_monitoring')}>
                    <Sorter dir={sortDirFor('first_payment_default_monitoring')} />
                    First Payment Default
                </th>
                <th>
                    Store ID(s)
                </th>
                <th>
                {downloadingCsv
                            ? <button className='inverted'>Generating CSV...</button>
                            : <button
                                  onClick={downloadBySection}
                                  className='inverted'
                                  disabled={downloadingCsv}>
                                  <i className="fa fa-download" aria-hidden="true" />
                                  Download CSV
                              </button>
                        }
                </th>
            </tr>
            </thead>
            <tbody>
            {sectionData && sectionData.map((notif, i) => (
                <tr className={i % 2 === 0 ? '' : 'stripe'} key={notif.notification_id}>
                    <td>{notif.name}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.charge_off_notification !== 0 ? 'X' : ''}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.delinquency_monitoring !== 0 ? 'X' : ''}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.first_payment_default_monitoring !== 0 ? 'X' : ''}</td>
                    <td style={{textalign:"center",justifyContent:"center"}}>{notif.storeid_agg}</td>
                    <td>
                        <Link to={`/healthcheck/overview/merchant/${notif.merchant_id}`} className='button primary' target='_blank'>Run Report</Link>
                    </td>
                </tr>

            ))}
            </tbody>
        </table>
        </div>
        </>
            : config.notifications_loans === true ? <div style={{marginTop:"1em"}}>No Notifications found.</div> : <div style={{marginTop:"1em"}}>Notification Package Disabled. To enable, visit Configuration tab.</div>}
        {loading && <Loading />}
        </div>
    </>
    
    )
}

function ReviewsSectionTable({section, loading, config, sortAndFilter, downloadingCsv, downloadBySection}){
    const [ sortBy, setSortBy ] = useState(['name', 'asc'])
    const [ filterBy, setFilterBy ] = useState('all')
    const [ sectionData, setSectionData ] = useState(section)

    useEffect(() => {
        sortAndFilter.sortItems(sectionData, sortBy)
    }, [sectionData, sortBy, sortAndFilter])

    const onClickSortBy = (sortCol) => {
        sortAndFilter.onClickSortBy(sortCol, sortBy, setSortBy)
    }

    const sortDirFor = (col) => sortAndFilter.sortDirFor(col, sortBy)

    const filterValues = {
        'all': 'all',
        'One Star Reviews': 'one_star_review',
        'Two Star Reviews': 'two_star_review',
        'Alert Words Only': 'review_content_monitoring',
    }

    const setFilter = (filter) => {
        sortAndFilter.setFilter(filter, filterValues, setFilterBy, section, sectionData, setSectionData)
    }

    return (
        <>
        <div>
            <div >
                <h2>Reviews</h2>
                <p style={{fontSize:"1em",paddingBottom:"2em"}}>Verdata monitors reviews regularly. Within the past 5 days, Verdata has received information
                    indicating the following businesses have incurred One/Two-Star reviews or a review containing one or more of Verdata's alert words.</p>
                    For a full list of Verdata alert words, <Link to={`/alert-words`} style={{paddingBottom:"2em"}} target='_blank'>Click Here.</Link>
                    <br></br><br></br>
            </div>

            {section && section.length !== 0 ?
            <>
            <div className="notifications-filter">
                <MultiButtonSwitcher values={Object.keys(filterValues)}
                                 value={Object.keys(filterValues).find(filter => filterValues[filter] === filterBy)}
                                 onChange={(filter) => setFilter(filter)}/>
            </div>
            </> : null
            }

        {sectionData && sectionData.length !== 0 ?
        <>
            <div className="notif-container">
            <table className="notif-table">
                <thead>
                <tr>
                    <th onClick={() => onClickSortBy('name')}>
                        <Sorter dir={sortDirFor('name')} />
                        Name
                    </th>
                    <th onClick={() => onClickSortBy('one_star_review')}>
                        <Sorter dir={sortDirFor('one_star_review')} />
                        One Star Review
                    </th>
                    <th onClick={() => onClickSortBy('two_star_review')}>
                        <Sorter dir={sortDirFor('two_star_review')} />
                        Two Star Review
                    </th>
                    <th onClick={() => onClickSortBy('review_content_monitoring')}>
                        <Sorter dir={sortDirFor('review_content_monitoring')} />
                        Alert Words
                    </th>
                    <th>
                        Store ID(s)
                    </th>
                    <th>
                    {downloadingCsv
                            ? <button className='inverted'>Generating CSV...</button>
                            : <button
                                  onClick={downloadBySection}
                                  className='inverted'
                                  disabled={downloadingCsv}>
                                  <i className="fa fa-download" aria-hidden="true" />
                                  Download CSV
                              </button>
                        }
                    </th>
                </tr>
                </thead>
                <tbody>
                {sectionData && sectionData.map((notif, i) => (
                    <tr className={i % 2 === 0 ? '' : 'stripe'} key={notif.store_id}>
                        <td>{notif.name}</td>
                        <td>{notif.one_star_review}</td>
                        <td>{notif.two_star_review}</td>
                        <td>
                            <Link to={`/healthcheck/overview/merchant/${notif.merchant_id}/service#alert-word-review`} target='_blank' style={{textalign:"center",justifyContent:"center"}}> {notif.review_content_monitoring !== 0 ? 'Link' : ''} </Link>
                        </td>
                        <td style={{textalign:"center",justifyContent:"center"}}>{notif.storeid_agg}</td>
                        <td>
                            <Link to={`/healthcheck/overview/merchant/${notif.merchant_id}`} className='button primary' target='_blank'>Run Report</Link>
                        </td>
                    </tr>

                ))}
                </tbody>
            </table>
            </div>
            </>
            : config.notifications_reviews === true ? <div style={{marginTop:"1em"}}>No Notifications found.</div> : <div style={{marginTop:"1em"}}>Notification Package Disabled. To enable, visit Configuration tab.</div>}
            {loading && <Loading />}
            </div>
        </>
    )
}

function RatingSectionTable({section, loading, config, sortAndFilter, downloadingCsv, downloadBySection}){
    const [ sortBy, setSortBy ] = useState(['name', 'asc'])
    const [ filterBy, setFilterBy ] = useState('all')
    const [ sectionData, setSectionData ] = useState(section)

    useEffect(() => {
        sortAndFilter.sortItems(sectionData, sortBy)
    }, [sectionData, sortBy, sortAndFilter])

    const onClickSortBy = (sortCol) => {
        sortAndFilter.onClickSortBy(sortCol, sortBy, setSortBy)
    }

    const sortDirFor = (col) => sortAndFilter.sortDirFor(col, sortBy)

    const filterValues = {
        'all': 'all',
        '1 Point Decrease': '1',
        '1 to 2 Points Decrease': '1-2',
        '2 Points or More Decrease': '2',
    }

    const setFilter = (filter) => {
        setFilterBy(filterValues[filter])
        if (filter === 'all') {
            setSectionData(section)
        } else {
            let comparisonValue = filterValues[filter]
            if (comparisonValue === '1') {
                setSectionData(section.filter((obj) => (
                    (parseFloat(obj['minus_6_months']) - parseFloat(obj['rev_avg'])) === 1.0 ||
                    (parseFloat(obj['minus_3_months']) - parseFloat(obj['rev_avg'])) === 1.0 ||
                    (parseFloat(obj['minus_1_months']) - parseFloat(obj['rev_avg'])) === 1.0)
                ))
            } else if (comparisonValue === '1-2') {
                setSectionData(section.filter((obj) => (
                    ((parseFloat(obj['minus_6_months']) - parseFloat(obj['rev_avg']) > 1.0) &&
                    (parseFloat(obj['minus_6_months']) - parseFloat(obj['rev_avg'])) <= 2.0)||

                    ((parseFloat(obj['minus_3_months']) - parseFloat(obj['rev_avg']) > 1.0) &&
                    (parseFloat(obj['minus_3_months']) - parseFloat(obj['rev_avg'])) <= 2.0) ||

                    ((parseFloat(obj['minus_1_months']) - parseFloat(obj['rev_avg']) > 1.0) &&
                    (parseFloat(obj['minus_1_months']) - parseFloat(obj['rev_avg'])) <= 2.0))
                ))
            } else if (comparisonValue === '2') {
                setSectionData(section.filter((obj) =>
                    (parseFloat(obj['minus_6_months']) - parseFloat(obj['rev_avg'])) > 2.0 ||
                    (parseFloat(obj['minus_3_months']) - parseFloat(obj['rev_avg'])) > 2.0 ||
                    (parseFloat(obj['minus_1_months']) - parseFloat(obj['rev_avg'])) > 2.0
                ))
            } else {
                setSectionData(section)
            }
        }
    }

    return (
        <>
        <div>
        <div >
            <h2>Rating Change</h2>
            <p style={{fontSize:"1em",paddingBottom:"2em"}}>Verdata aggregates Online Review Rating from a number of sources. Our records indicate that the following businesses
                have incurred an average rating decrease of greater than 1 full point during the past 6 months:</p>
        </div>

            {section && section.length !== 0 ?
            <>
            <div className="notifications-filter">
                <MultiButtonSwitcher values={Object.keys(filterValues)}
                                 value={Object.keys(filterValues).find(filter => filterValues[filter] === filterBy)}
                                 onChange={(filter) => setFilter(filter)}/>
            </div>
            </> : null
            }

        {sectionData && sectionData.length !== 0 ?
        <>
            <div className="notif-container">
            <table className="notif-table">
                <thead>
                <tr>
                    <th onClick={() => onClickSortBy('name')}>
                        <Sorter dir={sortDirFor('name')} />
                        Name
                    </th>
                    <th onClick={() => onClickSortBy('minus_6_months')}>
                        <Sorter dir={sortDirFor('minus_6_months')} />
                        Average 6 Months Ago
                    </th>
                    <th onClick={() => onClickSortBy('minus_3_months')}>
                        <Sorter dir={sortDirFor('minus_3_months')} />
                        Average 3 Months Ago
                    </th>
                    <th onClick={() => onClickSortBy('minus_1_months')}>
                        <Sorter dir={sortDirFor('minus_1_months')} />
                        Average 1 Months Ago
                    </th>
                    <th onClick={() => onClickSortBy('rev_avg')}>
                        <Sorter dir={sortDirFor('rev_avg')} />
                        Review Average
                    </th>
                    <th>
                        Store ID(s)
                    </th>
                    <th>
                    {downloadingCsv
                            ? <button className='inverted'>Generating CSV...</button>
                            : <button
                                  onClick={downloadBySection}
                                  className='inverted'
                                  disabled={downloadingCsv}>
                                  <i className="fa fa-download" aria-hidden="true" />
                                  Download CSV
                              </button>
                        }
                    </th>
                </tr>
                </thead>
                <tbody>
                {sectionData && sectionData.map((notif, i) => (
                    <tr className={i % 2 === 0 ? '' : 'stripe'} key={notif.notification_id}>
                        <td>{notif.name}</td>
                        <td>{notif.minus_6_months}</td>
                        <td>{notif.minus_3_months}</td>
                        <td>{notif.minus_1_months}</td>
                        <td>{notif.rev_avg}</td>
                        <td>{notif.storeid_agg}</td>
                        <td>
                            <Link to={`/healthcheck/overview/merchant/${notif.merchant_id}`} className='button primary' target='_blank'>Run Report</Link>
                        </td>
                    </tr>

                ))}
                </tbody>
            </table>
            </div>
            </>
            : config.notifications_rating_change === true ? <div style={{marginTop:"1em"}}>No Notifications found.</div> : <div style={{marginTop:"1em"}}>Notification Package Disabled. To enable, visit Configuration tab.</div>
            }
            {loading && <Loading />}
            </div>
        </>
    )
}

function HistorySectionTable({section, loading, config, sortAndFilter, reload, downloadingCsv, downloadDate, setNotificationsToDownload, historyDownloadByDate, historyDownload, setHistoryDownload}){
    const { apiGet, apiPost } = useApi()
    const payload = {
        "date": downloadDate.toLocaleString()
    }
    
    useEffect(()=> {
        apiPost(`notifications/notifications_by_date`, payload).then( response => {
            if (JSON.stringify(response) !== '{}') {
                let parsed = JSON.parse(response['json_blob'])
                setHistoryDownload(parsed)
            } else {
                setHistoryDownload({})
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reload])

    

    return (
        <>
            <div >
            <h2>Notifications History</h2>
            <p style={{fontSize:"1em",paddingBottom:"2em"}}>Select a date to download notifications from 
            the past. If the download button is disabled, then there are no notifications for that date.
            </p>
            <div>
                
                <DatePicker selected={downloadDate} startOpen={true} minDate={new Date(2023,4, 22)} maxDate={new Date()}
                onChange={(date) => setNotificationsToDownload(date)} shouldCloseOnSelect={false} open={true}/>
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                {downloadingCsv
                                ? <button className="primary">Generating CSV...</button>
                                : <button
                                    onClick={historyDownloadByDate}
                                    className="primary"
                                    disabled={downloadingCsv || JSON.stringify(historyDownload) === '{}'}>
                                    <i className="fa fa-download" aria-hidden="true" />
                                    Download Notifications By Date
                                </button>
                            }
            </div>

            {loading && <Loading />}
            </div>
        </>
        )
}

function Sorter({dir}){
    return (
        <div className='sorter'>
            <i className={`sorter-up fa fa-caret-up ${dir === 'asc' ? 'active' : ''}`}></i>
            <i className={`sorter-dn fa fa-caret-down ${dir === 'desc' ? 'active' : ''}`}></i>
        </div>
    )
}


