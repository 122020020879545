import React from 'react'

import './ScoreGauge.scss'

function CalculateScorebarNeedlePosition(score) {
    // Const values based on .score-gauge-scorebar in css
    const scorebarTierWidth = 120;
    const scorebarMargin = 4;
    // 2 digit score cannot be 100, 100 is a default score for 3 digit
    // if (score === 100) { return (scorebarTierWidth * 4) +  (scorebarMargin * 2)}
    // if (score === 0) {return 0}

    if(score.toString().length == 3){
        // Determine position within scorebar tier
        if (score >= 721) {
            let pixelsPerScorePoint = 0.693
            let startPosition = 404
            let output = ((score - 721) * pixelsPerScorePoint) + startPosition
            return output
        }
        if (score >= 651 && score <= 720) {
            let pixelsPerScorePoint = 1.797
            let startPosition = 270
            let output = ((score - 651) * pixelsPerScorePoint) + startPosition
            return output
        }
        if (score >= 601 && score <= 650) {
            let pixelsPerScorePoint = 2.531
            let startPosition = 136
            let output = ((score - 601) * pixelsPerScorePoint) + startPosition
            return output
        }
        if (score >= 101 && score <= 600) {
            let pixelsPerScorePoint = 0.248
            let startPosition = 2
            let output = (((score) - 101) * pixelsPerScorePoint) + startPosition
            return output
        }
    }
    // 130 width on each with a 2 px buffer between them
    else{
        // Determine position within scorebar tier
        if (score >= 93) {
            let pixelsPerScorePoint = 20.667
            let startPosition = 404
            let output = ((score - 93) * pixelsPerScorePoint) + startPosition
            return output
        }
        if (score >= 85 && score <= 92) {
            let pixelsPerScorePoint = 17.845
            let startPosition = 270
            let output = ((score - 85) * pixelsPerScorePoint) + startPosition
            return output
        }
        if (score >= 75 && score <= 84) {
            let pixelsPerScorePoint = 13.778
            let startPosition = 136
            let output = ((score - 75) * pixelsPerScorePoint) + startPosition
            return output
        }
        if (score >= 0 && score <= 74) {
            let pixelsPerScorePoint = 1.654
            let startPosition = 2
            let output = (score * pixelsPerScorePoint) + startPosition
            return output
        }
    }
}

function CalculateScorebarScorePosition(score) {
    let position = CalculateScorebarNeedlePosition(score)

    // If the score is 100 we want the score on the far right justified on the edge of the bar
    if (score <= 999 && score >= 100) { return ( position - 10 ) }

    // If score is 2 digits
    if (score <= 99 && score >= 10) { return ( position - 5 )}

    // If the score is single digit we want to adjust for single character width
    if (score < 10) { return ( position ) }
}


export default function ScoreGauge({score2, reasons}){
    var score = parseInt(score2)
    return (
        <div>
            <div className="score-gauge">
                <div className="score-gauge-header">
                    Total Score
                </div>
                <div className="score-gauge-details">
                    {(score !== 100) ?
                    <>
                        <div className="score-gauge-score" style={{ left: CalculateScorebarScorePosition(score) }}>{score}</div>
                        <div className="score-gauge-needle" style={{ left: CalculateScorebarNeedlePosition(score) }}></div>
                    </> : 
                        <div className="score-gauge-default-score-div"> 100 is the default score for a thin-file merchant</div>}
                    <div className="score-gauge-scorebar-container">
                        <div className="score-gauge-scorebar highest"></div>
                        <div className="score-gauge-scorebar high"></div>
                        <div className="score-gauge-scorebar elevated"></div>
                        <div className="score-gauge-scorebar lowest"></div>
                    </div>
                    {(score.toString().length === 3) ?
                    <div>
                        <div className="score-gauge-scoredesc highest">
                            Highest Risk (101-600)
                        </div>
                        <div className="score-gauge-scoredesc high">
                            High Risk (601-650)
                        </div>
                        <div className="score-gauge-scoredesc elevated">
                            Medium Risk (651-720)
                        </div>
                        <div className="score-gauge-scoredesc lowest">
                            Lowest Risk (721-900)
                        </div> 
                    </div> :
                    <div>
                        <div className="score-gauge-scoredesc highest">
                            Highest Risk (0-74)
                        </div>
                        <div className="score-gauge-scoredesc high">
                            High Risk (75-84)
                        </div>
                        <div className="score-gauge-scoredesc elevated">
                            Medium Risk (85-92)
                        </div>
                        <div className="score-gauge-scoredesc lowest">
                            Lowest Risk (93-99)
                        </div> 
                    </div>}
                </div>
                {score >= 100 && (
                    <>
                        <div className="score-gauge-reasons-header">
                            Reason Codes
                        </div>
                        <div className="score-gauge-reasons">
                            {reasons.length > 0 ? (
                                reasons.filter(reason => reason !== null && reason !== undefined).map((reason, index) => (
                                    <div key={index} className="score-gauge-reason-codes">
                                        Reason Code {index + 1}: {reason}
                                    </div>
                                ))
                            ) : (
                                'No Reason Codes for this Merchant'
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
