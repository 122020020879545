import React from 'react'

import PrincipalMetadata from 'shared/PrincipalMetadata'
import DoctorMetadata from './DoctorMetadata';
import LocationMetadata from 'shared/LocationMetadata'
import ScoreInfo from "shared/ScoreInfo";
import ExternalLink from 'shared/ExternalLink'

import './MerchantMetadata.scss'

function addressLine(loc){
    const street = [loc.addr1, loc.addr2].filter(Boolean).join(' ')
    return <>
        {street && street + ', '}
        {loc.city && loc.city + ', '} 
        {loc.state && loc.state + ' '} 
        {loc.zip5 && loc.zip5} 
        </>
}

function phoneLine(phone) {
    if (phone) {
        let bare_phone = phone.replaceAll(/[^0-9]/g, '');
        return (bare_phone.length === 10) ? "(" + bare_phone.slice(0,3) + ") " + bare_phone.slice(3,6) + "-" + bare_phone.slice(6) : phone;
    }
}

function extract_unique_principals(principals) {
    if (principals) {
        let unique_principals = []
        let principal_map = {}
        function principal_key(principal) {
            return `${principal.first_name}_${principal.middle_name}_${principal.last_name}_${principal.suffix}_${principal.addr_key}`;
        }

        principals.forEach(principal => {
            if (!principal_map[principal_key(principal)]) {
                principal_map[principal_key(principal)] = principal
                if(unique_principals.length < 3){
                    unique_principals.push(principal)
                }
            }
        });
    return unique_principals
    }
}

export default function MerchantMetadata({merchant, locations, principals, doctors, doctor_licenses=[], linkPrincipals=true, score, showScore=true}){
    const lender = localStorage.getItem('lender')
    const parseLender = JSON.parse(lender)
    let unqiue_principals = extract_unique_principals(principals)
    return (
        <div className="merchant-metadata">
            <div className="section">
                { merchant.merchant && <>{merchant.name}<br /></> }
                { merchant.name_dba && <>DBA: {merchant.name_dba}<br /></> }
                <FormatAddress {...merchant} />
                {/* { ( merchant.addr1 || (!merchant.addr1 && ( merchant.city || merchant.state ) ) ) && <>{addressLine(merchant)}<br /></> } */}
                { merchant.phone && <>{phoneLine(merchant.phone)}<br /></> }
                { merchant.fax && <>{phoneLine(merchant.fax)}<br /></> }
                { merchant.email && <>{merchant.email}<br /></> }
                { ( merchant.domain_name && merchant.domain_name.length > 1 ) && <>
                    <ExternalLink href={merchant.domain_name}>Business Domain</ExternalLink><br />
                </>}
                { merchant.url && <>
                    <ExternalLink href={merchant.url}>Business Domain 2</ExternalLink><br />
                </> }
                { (!merchant.lender_type || merchant.lender_type === 'Lending Partner') && parseLender['name'] === merchant.lender && merchant.store_id && <>Store Id: {merchant.store_id}<br /></> }
                { merchant.sic_code && <>SIC Code: {merchant.sic_code}<br /></> }
                { merchant.ein && <>FEIN No: {merchant.ein}<br /></> }
            </div>
            {locations &&
            <div className="section">
                <strong>Locations</strong><br />
                { locations.map((l, index) =>
                    <React.Fragment key={l.id}>
                        {l.state && index > 0 && <br />}
                        <LocationMetadata location={l} />
                    </React.Fragment>
                   )
                }
            </div>
            }
            {unqiue_principals &&
            <div className="section">
                <strong>Principals</strong><br />
                { unqiue_principals.map((p, index) =>
                    <React.Fragment key={p.id}>
                        {p.state && index > 0 && <br />}
                        <PrincipalMetadata principal={p} link={linkPrincipals} />
                    </React.Fragment>
                   )
                }
            </div>
            }
            {doctors &&
            <div className="section">
                <strong>Doctors</strong><br />
                { doctors?.slice(0, 3).map((p, index) =>
                    <React.Fragment key={p.id}>
                        <DoctorMetadata doctor={p} link={linkPrincipals} />
                    </React.Fragment>
                   )
                }
            </div>
            }

            <div className="section">
                {showScore &&
                <ScoreInfo score={score}></ScoreInfo>
                }           
            </div>
        </div>
    )
}

function FormatAddress({addr1, addr2, city, state, zip5, zip4}) {
    let address1 = addr1;
    if (addr2) address1 += ", " + addr2;

    let address2 = city;
    if (state) address2 += ", " + state;
    if (zip5) address2 += " " + zip5;
    if (zip4) address2 += "-" + zip4;

    return (
        (address1 && address1.length > 0) ? (
            <>
                {address1}<br />
                {address2 && <>{address2}<br /></>}
            </>
        ) : (address2 && address2.length > 0 ? (
                <>{address2}<br /></>
            ) : (
                <></> 
            )
        )
    );
}