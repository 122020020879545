import React, {useState} from 'react'
import useApi from 'hooks/useApi'

import './SubmitReviewsURLForm.scss'

const states = {
    ready: 'ready',
    submitted: 'submitted'
}

export default function SubmitReviewsURLForm({merchant, source, onClose}){
    const { apiPost } = useApi()
    const [ state, setState ] = useState(states.ready)
    const [ formData, setFormData ] = useState({
        merchant_name: merchant.name,
        merchant_id: merchant.id,
        service_name: source?.name || 'New Source',
        url: '',
        description: ''
    })

    function onSubmit(ev){
        ev.preventDefault();
        apiPost('submit-review-url', formData).then(() => setState(states.submitted))
    }

    function updateFormData(field, value){
        setFormData({...formData, [field]: value})
    }

    if (state === states.submitted) {
        return <div className=''>
            <h1>Review URL submitted!</h1>
            <p>
                Thank you for submitting a new Reviews URL for consideration.<br />
                A member of the Verdata team will contact you once we have reviewed your submission.
            </p>
            <footer>
                <button onClick={onClose} className='primary'>Close</button>
            </footer>
        </div>
    }

    return (
        <form className='submit-reviews-form'>
            <h1>Submit Reviews URL</h1>

            <p>
                <i>If you think we are missing a related URL that you were able to find, please submit it to us below for review. We will add the submitted URL to our queue of records to review and add it to our database.
                    Some URLs are better than others. For our process, we are looking for the initial landing page of a given business. General search URLs will not process correctly.
                </i>
            </p>
            {source.name === 'Google' && 
            <div>
                <br></br>
                <p>
                    <i>For Google, we are looking for the Google Maps URL. See below for a typical structure of a Google Maps link: </i>
                    "https://www.google.com/maps/place/..."
                </p>
            </div>

            }

            <p>
                <strong>Merchant: </strong> {merchant.name}<br />
                { source && source.name && <><strong>Source: </strong> {source.name}<br /></> }
            </p>

            <div className='field'>
                <label htmlFor='service-url'>URL: </label>
                <input type='url' id='service-url' value={formData.url} autoFocus
                       onChange={(ev) => updateFormData('url', ev.target.value)} />
            </div>

            <div className='field'>
                <label htmlFor='service-description'>Description: </label>
                <textarea id='service-description' value={formData.description}
                       onChange={(ev) => updateFormData('description', ev.target.value)} />
            </div>

            <footer>
                <button className='primary' onClick={onSubmit}>Submit</button>
                <button onClick={onClose}>Cancel</button>
            </footer>

        </form>
    )
}