import React from 'react'
import vMonitorlogo from 'assets/Vmonitor-logo-03.png'

import './notifications.scss'


export default function Notifications() {

    return (
              <div className='notifications-table'>
                <div className='centered-image'><img src={vMonitorlogo} alt="vMonitor" style={{alignSelf:"center",width:"400px"}}/></div>
                <h2>Alert Words</h2>
                <p>Verdata monitors online content for the inclusion of "Alert Words". We have found these terms to be highly indicative of changes in public sentiment regarding a merchant. The full list of terms monitored for contains:</p>
                <div style={{display:"flex",flexDirection:"row"}}>
                <ul>
                <li>Fraud</li>
                <li>Fraudster</li>
                <li>Fraudulent</li>
                <li>Defraud</li>
                <li>Elder Abuse</li>
                <li>Elderly Abuse</li>
                <li>Scam</li>
                <li>Scammer</li>
                <li>Scamming</li>
                <li>Scammed</li>
                <li>Theft</li>
                <li>Thief</li>
                <li>Thieving</li>
                <li>Stolen</li>
                <li>Stole</li>
                <li>Steal</li>
                <li>Stealing</li>
                <li>"Out of business"</li>
                <li>"Not in business"</li>
                </ul>
                <ul>
                <li>Misrepresent</li>
                <li>Misrepresentation</li>
                <li>Mislead</li>
                <li>Misleading</li>
                <li>Pyramid</li>
                <li>Sue</li>
                <li>Suing</li>
                <li>Lawsuit</li>
                <li>Legal</li>
                <li>Legal Action</li>
                <li>Attorney General</li>
                <li>Attourney general</li>
                <li>AG</li>
                <li>CFPB</li>
                <li>FTC</li>
                <li>Better Business Bureau</li>
                <li>BBB</li>
                <li>Malpractice</li>
                <li>Criminal</li>
                </ul>
                <ul>
                <li>Untruthful</li>
                <li>Unresponsive</li>
                <li>Deceptive</li>
                <li>Deceived</li>
                <li>Lied</li>
                <li>Collusion</li>
                <li>"ID theft"</li>
                </ul>
                </div>
                <p>For questions about this list or to request inclusion of a term not listed, please contact support@verdata.com.</p>
                  </div>
          
    )
}
