import React from 'react';
import './LicenseLabelBadge.scss'; // Add a CSS file for styling

const LicenseLabelBadge = ({ order_labels }) => {
    // Define the label descriptions for hover text
    const labelDescriptions = {
        approved: 'Approved',
        approved_with_comments: 'Approved with Comments',
        manual_review_recommended: 'Manual Review Recommended',
        rejected: 'Rejected'
    };

    return (
        <div className="badge-container">
            {order_labels && order_labels.map((order_label, index) => (
                <span 
                    key={index} 
                    className={`badge-icon ${order_label}`} 
                    data-title={labelDescriptions[order_label]} // Hover text based on the label
                >
                    {/* Use different FontAwesome icons or any other icon library */}
                    {order_label === 'approved' && <i className="fa fa-check-circle"></i>}
                    {order_label === 'approved_with_comments' && <i className="fa fa-exclamation-circle"></i>}
                    {order_label === 'manual_review_recommended' && <i className="fa fa-question-circle"></i>}
                    {order_label === 'rejected' && <i className="fa fa-times-circle"></i>}
                </span>
            ))}
        </div>
    );
};

export default LicenseLabelBadge;