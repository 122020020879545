import React from 'react'

import './ExternalLink.scss'

function ensureProtocol(url){
    return url.indexOf('://') > -1 ? url : 'https://'+url;
}

export default function ExternalLink({href, children, className='', ...rest}){
    className = [className, 'external-link'].filter(Boolean).join(' ')
    return (
       <a href={ensureProtocol(href)}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          {...rest}>
           {children}
       </a>
    )
}