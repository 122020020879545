import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Link } from "react-router-dom"
import useApi from '../../hooks/useApi'
import useInterval from '../../hooks/useInterval'
import ErrorsContext from "ErrorsContext";

import OverviewPanel from './merchant-report/OverviewPanel'
import AssociationsPanel from './merchant-report/AssociationsPanel'
import FraudPanel from './merchant-report/FraudPanel'
import RegulatoryPanel from './merchant-report/RegulatoryPanel'
import ServicePanel from './merchant-report/ServicePanel'
import FinancialPanel from './merchant-report/FinancialPanel'
import PrincipalPanel from './merchant-report/PrincipalPanel'
import ScorecardPanel from './merchant-report/ScorecardPanel'
import DoctorsPanel from './merchant-report/DoctorsPanel'

import { score2className } from 'utils'
import MerchantMetadata from 'shared/MerchantMetadata'
import AlternateInfo from './AlternateInfo'
import Loading from 'shared/Loading'
import LoadingSpinner from 'shared/LoadingSpinner'
import FormatDate from '../../shared/FormatDate'
import toast from 'react-hot-toast'


import "./MerchantReport.scss"

export function onlineSourceReportedClosed(merchantReport) {
    let closed = false
    if (merchantReport.online_presence) {
        for(let x=0; x<merchantReport.online_presence.length; x++) {
            if (merchantReport.online_presence[x].permanently_closed === true) {
                closed = true
            }
        }
    }


    return closed
}

export function dedupeDoctors(doctors) {
    if (!doctors)
        return []
    if (doctors?.length === 0 )
        return []
    const seen = new Set();
    return doctors?.filter(obj => {
      if (seen.has(obj.npi_id)) {
        return false;
      } else {
        seen.add(obj.npi_id);
        return true;
      }
    });
}

export default function Report({merchantReport, baseUrl, section='overview', id}){

    
    const dummyScore = [
        {"merchant_id":null,
        "curr_score":null,
        "curr_score_tier":null,
        "curr_reason_codes":null,
        "prev_score":null,
        "prev_score_tier":null,
        "prev_reason_codes":null,
        "updated_at":null,}

    ]

    const dummyPrincipalTLO = [
        {"merchant_id":null,
        "principal_id":null,
        "updated_at":null,
        "can_update":false,
        "IND_PROF_LICENSE_001":null,
        "IND_PROF_LICENSE_002":null,
        "IND_PROF_LICENSE_003":null,
        "IND_PROF_LICENSE_004":null,
        "DECEASED_001":null,
        "DECEASED_002":null,
        "DECEASED_003":null,
        "DECEASED_004":null,
        "IND_BANKRUPTCY_SUMMARY_001":null,
        "IND_BANKRUPTCY_SUMMARY_002":null,
        "IND_BANKRUPTCY_SUMMARY_003":null,
        "IND_BANKRUPTCY_SUMMARY_004":null,
        "IND_BANKRUPTCY_SUMMARY_005":null,
        "IND_BANKRUPTCY_SUMMARY_006":null,
        "EMPLOYMENT_SUMMARY_001":null,
        "EMPLOYMENT_SUMMARY_01_002":null,
        "EMPLOYMENT_SUMMARY_01_003":null,
        "EMPLOYMENT_SUMMARY_02_002":null,
        "EMPLOYMENT_SUMMARY_02_003":null,
        "EMPLOYMENT_SUMMARY_03_002":null,
        "EMPLOYMENT_SUMMARY_03_003":null,
        "EMPLOYMENT_SUMMARY_04_002":null,
        "EMPLOYMENT_SUMMARY_04_003":null,
        "EMPLOYMENT_SUMMARY_05_002":null,
        "EMPLOYMENT_SUMMARY_05_003":null,
        "EMPLOYMENT_SUMMARY_06_002":null,
        "EMPLOYMENT_SUMMARY_06_003":null,
        "EMPLOYMENT_SUMMARY_07_002":null,
        "EMPLOYMENT_SUMMARY_07_003":null,
        "EMPLOYMENT_SUMMARY_08_002":null,
        "EMPLOYMENT_SUMMARY_08_003":null,
        "EMPLOYMENT_SUMMARY_09_002":null,
        "EMPLOYMENT_SUMMARY_09_003":null,
        "EMPLOYMENT_SUMMARY_10_002":null,
        "EMPLOYMENT_SUMMARY_10_003":null,
        "RELATIVES_SUMMARY_001":null,
        "RELATIVES_SUMMARY_002":null,
        "RELATIVES_SUMMARY_003":null,
        "ASSOCIATES_SUMMARY_001":null,
        "ASSOCIATES_SUMMARY_002":null,
        "ASSOCIATES_SUMMARY_003":null,
        "JUDGEMENTS_SUMMARY_001":null,
        "JUDGEMENTS_SUMMARY_002":null,
        "JUDGEMENTS_SUMMARY_003":null,
        "JUDGEMENTS_SUMMARY_004":null,
        "IND_LIENS_SUMMARY_001":null,
        "IND_LIENS_SUMMARY_002":null,
        "IND_LIENS_SUMMARY_003":null,
        "IND_LIENS_SUMMARY_004":null,
        "IND_WATCH_LIST_SUMMARY_001":null,
        "IND_WATCH_LIST_SUMMARY_002":null,
        "IND_WATCH_LIST_SUMMARY_003":null}
    ]

    const dummyMerchantTLO = [
        {"merchant_id":null,
        "BUS_LIENS_SUMMARY_001":null,
        "BUS_LIENS_SUMMARY_002":null,
        "BUS_LIENS_SUMMARY_003":null,
        "BUS_LIENS_SUMMARY_004":null,
        "BUS_BANKRUPTCY_SUMMARY_001":null,
        "BUS_BANKRUPTCY_SUMMARY_002":null,
        "BUS_BANKRUPTCY_SUMMARY_003":null,
        "BUS_BANKRUPTCY_SUMMARY_004":null,
        "BUS_BANKRUPTCY_SUMMARY_005":null,
        "BUS_BANKRUPTCY_SUMMARY_006":null,
        "UCC_SUMMARY_001":null,
        "UCC_SUMMARY_002":null,
        "UCC_SUMMARY_003":null,
        "BUS_JUDGEMENT_SUMMARY_001":null,
        "BUS_JUDGEMENT_SUMMARY_002":null,
        "BUS_JUDGEMENT_SUMMARY_003":null,
        "BUS_JUDGEMENT_SUMMARY_004":null,
        "BUS_JUDGEMENT_SUMMARY_005":null,
        "CORP_FILING_001":null,
        "CORP_FILING_002":null,
        "CORP_FILING_003":null,
        "CORP_FILING_004":null,
        "CORP_FILING_005":null,
        "CORP_FILING_006":null,
        "CORP_FILING_007":null,
        "WATCH_LIST_SUMMARY_001":null,
        "WATCH_LIST_SUMMARY_002":null}
    ]

    const dummyMerchantTLO2 = {
        "summary": {},
        "bankruptcy": [],
        "lien": [],
        "judgement": [],
        "corp_filing": [],
        "principal": [],
        "ucc": []
    }

    const dummyDetailedPrincipalTLO = {
        "bankruptcies": [],
        "liens": [],
        "judgements": [],
        "associates": [],
        "employers": [],
        "relatives": []
    }

    const { apiGet } = useApi()
    const { apiPost } = useApi()
    const { setError } = useContext(ErrorsContext)
    const [ vRisk, setvRisk ] = useState(dummyScore)
    const [ related_vRisk, setRelatedVrisk ] = useState([])
    const [ principal_tlo, setPrincipalTlo ] = useState(dummyPrincipalTLO)
    const [ detailed_principal_tlo, setDetailedPrincipalTlo] = useState(dummyDetailedPrincipalTLO)
    const [ merchant_tlo, setMerchantTlo ] = useState(dummyMerchantTLO)
    const [ tlo_2, setTlo2] = useState(dummyMerchantTLO2)
    const [ finance_perms, setFinancePerms ] = useState(false)
    const [ inquiry_history, setInquiryHistory] = useState([])
    const [ verified_state, setVerifiedState] = useState()
    const [ regulatory_links, setRegulatoryLinks] = useState([])
    const [ principal_cobalt, setPrincipalCobalt ] = useState([])
    const [ merchant_cobalt, setMerchantCobalt ] = useState(false)
    const [ sos_status, setSosStatus ] = useState("loading")
    const [ tlo_status, setTloStatus ] = useState("loading")
    const [ get_sos, setGetSos ] = useState(null)
    const [ get_tlo, setGetTlo ] = useState(null)
    const [ get_tlo_principal, setGetTloPrincipal ] = useState(null)
    const [ ordered_principals, setOrderedPrincipals ] = useState([])
    const [ finance_count, setFinanceCount ] = useState(0)
    const [ principal_count, setPrincipalCount] = useState(0)
    const [ isUpdating, setIsUpdating ] = useState([]);
    const [ tlo_principal_lookup, setTloPrincipalLookup ] = useState([])
    const [newDoctors, setNewDoctors] = useState([])
    const [hsLicenseOrders, setHsLicenseOrders] = useState([])
    const [doctorRefreshInterval, setDoctorRefreshInterval] = useState(null)
    const [furnishDoctorsEnabled, setFurnishDoctorsEnabled] = useState(false)
    

    var ucc_count;
    
    function activeIf(_section){
        return _section === section ? 'active': ''
    }

    useEffect(()=> {
        if(!!merchantReport){
            const payload = {
                'merchant_id' : merchantReport.merchant.id,
            }
            apiPost(`merchant/update_merchant_blj`, payload).then(res => {
                if(res.status && (res.status === '202' || res.status === '200')){
                    setGetTlo(1500)
                }
            }).catch(err => {
                // eslint-disable-next-line
                const mute = err;
            })
        }
    }, [merchantReport])

    useEffect(() => {
        apiGet(`lender/furnish-doctors-enabled`).then(data => {
            if ('furnish_doctors' in data){
                setFurnishDoctorsEnabled(data.furnish_doctors)
            }
        }).catch( response => {
            console.log(response)
        })
    }, [])

    useEffect(() => {
        if(!!merchantReport){
            const payload = {
                "merchant_id": merchantReport.merchant.id
            }
            apiPost(`doctors/get_all`, payload).then(data => {
              setNewDoctors(data)
            }).catch( response => {
                console.log(response)
            })

        }

    }, [merchantReport])

    const refreshDoctors = useCallback(async () => {
        
        if(!!merchantReport){
            const payload = {
                "merchant_id": merchantReport.merchant.id
            }
            apiPost(`doctors/get_all`, payload).then(data => {
              setNewDoctors(data)
            }).catch( response => {
                console.log(response)
            })

        }
    }, [merchantReport])

    const refreshHsLicenses = useCallback(async () => {
        if(!!merchantReport){
            const payload = {
                "merchant_id": merchantReport.merchant.id,
            }
            apiPost(`license_orders/home_services`, payload).then(data => {
              setHsLicenseOrders(data)
            }).catch( response => {
                console.log(response)
            })

        }
    }, [merchantReport])

    useEffect(() => {
        refreshHsLicenses();
        const interval = setInterval(refreshHsLicenses, 30000);
        return () => clearInterval(interval);
      }, [refreshHsLicenses]);

    useEffect(() => {
        refreshDoctors();
        const interval = setInterval(refreshDoctors, 30000);
        return () => clearInterval(interval);
      }, [refreshDoctors]);


    useEffect(() => {
        if(!!merchantReport){
            const payload = {
                "merchant_id": merchantReport.merchant.id,
            }
            apiPost(`license_orders/home_services`, payload).then(data => {
              setHsLicenseOrders(data)
            }).catch( response => {
                console.log(response)
            })

        }

    }, [merchantReport])




    useEffect(()=> {
        if(!!merchantReport && tlo_status === "done"){
            apiGet(`merchant/blj_principals/${id}`).then(res => {
                if(res && res != 404){
                    var tmptloarr = []
                    var tmpdetailedtloarr = []
                    res.forEach(principal => {
                        var tmptlo = {...dummyPrincipalTLO[0]}
                        tmptlo.merchant_id = id
                        tmptlo.principal_id = principal.id
                        if(!!principal.updated_at){
                            tmptlo.updated_at = new Date(principal.updated_at.split(' ')[0])
                            let currentDate = new Date(); // Get the current date
                            let timeDifference = Math.abs(currentDate - tmptlo.updated_at); // Calculate the time difference in milliseconds
                            let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert time difference to day
                            let is184DaysOrMore = dayDifference >= 184; // Check if the difference is 184 days or more
                            tmptlo.can_update = is184DaysOrMore
                        }
                        //Associates
                        if(principal.associates && principal.associates.length !== 0){
                            tmptlo.ASSOCIATES_SUMMARY_001 = principal.id
                            tmptlo.ASSOCIATES_SUMMARY_002 = principal.associates.length
                            tmptlo.ASSOCIATES_SUMMARY_003 = 0
                        }
                        //Employement
                        if(principal.employers && principal.employers.length !== 0){
                            tmptlo.EMPLOYMENT_SUMMARY_001 = principal.id
                            principal.employers.forEach((employer, index) => {
                                if(index < 3){
                                    tmptlo[`EMPLOYMENT_SUMMARY_0${index+1}_002`] = employer.business_name
                                    tmptlo[`EMPLOYMENT_SUMMARY_0${index+1}_003`] = null
                                }
                            })
                        }
                        //Bankruptcy
                        if(principal.bankruptcies && principal.bankruptcies.length !== 0){
                            tmptlo.IND_BANKRUPTCY_SUMMARY_001 = principal.id
                            var chapter_7 = principal.bankruptcies.filter(item => item.chapter === 7)
                            var chapter_13 = principal.bankruptcies.filter(item => item.chapter === 13)
                            var validDates = principal.bankruptcies.filter(item => item.file_date !== null).map(item => new Date(item.file_date));
                            var mostRecentFilingDate = validDates.length > 0 ? Math.max(...validDates) : null;
                            mostRecentFilingDate = mostRecentFilingDate !== null ? new Date(mostRecentFilingDate).toISOString().split('T')[0] : null
                            tmptlo.IND_BANKRUPTCY_SUMMARY_002 = chapter_7.length
                            tmptlo.IND_BANKRUPTCY_SUMMARY_003 = chapter_13.length
                            tmptlo.IND_BANKRUPTCY_SUMMARY_004 = mostRecentFilingDate
                        }
                        //Liens
                        if(principal.liens && principal.liens.length !== 0){
                            tmptlo.IND_LIENS_SUMMARY_001 = principal.id
                            tmptlo.IND_LIENS_SUMMARY_002 = principal.liens.length
                            var validDates = principal.liens.filter(item => item.filing_date !== null).map(item => new Date(item.filing_date));
                            var mostRecentFilingDate = validDates.length > 0 ? Math.max(...validDates) : null;
                            mostRecentFilingDate = mostRecentFilingDate !== null ? new Date(mostRecentFilingDate).toISOString().split('T')[0] : null
                            tmptlo.IND_LIENS_SUMMARY_003 = mostRecentFilingDate
                            tmptlo.IND_LIENS_SUMMARY_004 = principal.liens[0].total_amount
                        }
                        //Judgements
                        if(principal.judgements && principal.judgements.length !== 0){
                            tmptlo.JUDGEMENTS_SUMMARY_001 = principal.id
                            tmptlo.JUDGEMENTS_SUMMARY_002 = principal.judgements.length
                            var validDates = principal.judgements.filter(item => item.file_date !== null).map(item => new Date(item.file_date));
                            var mostRecentFilingDate = validDates.length > 0 ? Math.max(...validDates) : null;
                            mostRecentFilingDate = mostRecentFilingDate !== null ? new Date(mostRecentFilingDate).toISOString().split('T')[0] : null
                            tmptlo.JUDGEMENTS_SUMMARY_003 = mostRecentFilingDate
                            tmptlo.JUDGEMENTS_SUMMARY_004 = principal.judgements[0].total_amount
                        }
                        //Relatives
                        if(principal.relatives && principal.relatives.length !== 0){
                            tmptlo.RELATIVES_SUMMARY_001 = principal.id
                            tmptlo.RELATIVES_SUMMARY_002 = principal.relatives.length
                        }
                        tmptloarr.push(tmptlo)
                        tmpdetailedtloarr.push(principal)

                    })
                    setPrincipalTlo(tmptloarr)
                    setDetailedPrincipalTlo(tmpdetailedtloarr)
                    setPrincipalCount(detailed_principal_tlo.length > 0 ? detailed_principal_tlo.reduce((sum, obj) => sum + obj.bankruptcies.length, 0) + detailed_principal_tlo.reduce((sum, obj) => sum + obj.judgements.length, 0) + detailed_principal_tlo.reduce((sum, obj) => sum + obj.liens.length, 0) : null )

                }
            }).catch(err => {
                // eslint-disable-next-line
                const mute = err;
            })
        }
    }, [merchantReport, tlo_status])



    useInterval(async () => {
        apiGet(`merchant/blj_merchant/${id}`).then(res => {
            if(res.status && res.status !== "no_data" && res.status !== "error"){
                setGetTlo(10000)
            }
            if(res.status && (res.status === "error" || res.status === "no_data")){
                setGetTlo(null)
                setTloStatus("done")
            }
            if(res.summary){
                setGetTlo(null)
                var tmptlo = merchant_tlo
                var tmptlo2 = tlo_2
                if(res.summary && res.summary !== 0){    
                    tmptlo2.summary = res.summary
                    tmptlo[0].merchant_id = id
                    tmptlo[0].BUS_LIENS_SUMMARY_001 = res.summary.lien_debtor_count !== 0 ? 
                        res.summary.lien_debtor_count : null
                    tmptlo[0].BUS_BANKRUPTCY_SUMMARY_001 = res.summary.bankruptcy_subject_count !== 0 ? 
                        res.summary.bankruptcy_subject_count : null
                    tmptlo[0].BUS_JUDGEMENT_SUMMARY_001 = res.summary.judgement_debtor_count !== 0 ? 
                        res.summary.judgement_debtor_count : null
                }
                if(res.judgement && res.judgement.length !== 0){     
                    tmptlo2.judgement = res.judgement
                    tmptlo[0].BUS_JUDGEMENT_SUMMARY_002 = res.judgement[0].filing_date !== null ? 
                        res.judgement[0].filing_date : null
                    tmptlo[0].BUS_JUDGEMENT_SUMMARY_005 = res.judgement[0].owed_amount !== null ? 
                        res.judgement[0].owed_amount : null
                }
                if(res.lien && res.lien.length !== 0){         
                    tmptlo2.lien = res.lien                    
                    tmptlo[0].BUS_LIENS_SUMMARY_002 = res.lien[0].filing_date !== null ? 
                        res.lien[0].filing_date : null
                    tmptlo[0].BUS_LIENS_SUMMARY_004 = res.lien[0].lien_amount !== null ? 
                        res.lien[0].lien_amount : null
                }
                if(res.bankruptcy && res.bankruptcy.length !== 0){       
                    tmptlo2.bankruptcy = res.bankruptcy
                    tmptlo[0].BUS_BANKRUPTCY_SUMMARY_002 = res.bankruptcy[0].filing_date !== null ? 
                        res.bankruptcy[0].filing_date : null
                    tmptlo[0].BUS_BANKRUPTCY_SUMMARY_003 = res.bankruptcy[0].filing_number !== null ? 
                        res.bankruptcy[0].filing_number : null
                    tmptlo[0].BUS_BANKRUPTCY_SUMMARY_005 = res.bankruptcy[0].status !== null ? 
                        res.bankruptcy[0].status : null
                    tmptlo[0].BUS_BANKRUPTCY_SUMMARY_006 = res.bankruptcy[0].status_date !== null ? 
                        res.bankruptcy[0].status_date : null
                }
                if(res.ucc && res.ucc.length !== 0){    
                    tmptlo2.ucc = res.ucc                           
                    tmptlo[0].UCC_SUMMARY_001 = res.ucc[0].filing_date !== null ? 
                        res.ucc[0].filing_date : null
                    tmptlo[0].UCC_SUMMARY_002 = res.ucc[0].filing_type !== null ? 
                        res.ucc[0].filing_type : null
                    tmptlo[0].UCC_SUMMARY_003 = res.ucc[0].expiration_date !== null ? 
                        res.ucc[0].expiration_date : null
                }
                if(res.corp_filing && res.corp_filing.length !== 0){       
                    tmptlo2.corp_filing = res.corp_filing                         
                    tmptlo[0].CORP_FILING_001 = res.corp_filing[0].name !== null ? 
                        res.corp_filing[0].name : null
                    tmptlo[0].CORP_FILING_002 = res.corp_filing[0].filing_date !== null ? 
                        res.corp_filing[0].filing_date : null
                    tmptlo[0].CORP_FILING_004 = res.corp_filing[0].corp_type !== null ? 
                        res.corp_filing[0].corp_type : null
                    tmptlo[0].CORP_FILING_005 = res.corp_filing[0].entity_type !== null ? 
                        res.corp_filing[0].entity_type : null
                    tmptlo[0].CORP_FILING_006 = res.corp_filing[0].status !== null ? 
                        res.corp_filing[0].status : null
                }
                if(res.principal && res.principal.length !== 0){
                    setTloPrincipalLookup(res.principal)
                }
                if (res.ucc) {
                    ucc_count = res.ucc.length
                } else {
                    ucc_count = 0
                }
                setMerchantTlo(tmptlo)
                setTlo2(tmptlo2)
                setFinanceCount(parseInt(res.summary.lien_debtor_count) + parseInt(res.summary.bankruptcy_subject_count) + parseInt(res.summary.judgement_debtor_count) + ucc_count)
                setTloStatus("done")
            }
        }).catch(err => {
            // eslint-disable-next-line
            console.error('An error occurred:', err);

            // const mute = err;
        })
    }, get_tlo)


    // Get SOS data for merchant
    useEffect( ()=> {
        if(!!merchantReport){
            const payload = {
                'merchant_id' : merchantReport.merchant.id,
                'merchant_name': merchantReport.merchant.name,
                'merchant_state': merchantReport.merchant.state,
                'merchant_zip': merchantReport.merchant.zip5
            }

            apiPost(`merchant/update_sos`, payload).then(res => {
                if(res.message){
                    setGetSos(1500)

                }
            }).catch(err => {
                // eslint-disable-next-line
                const mute = err;
            })
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantReport])

    useInterval(async () => {
        apiGet(`merchant/sos/${id}`).then(res => {
            if(res.status && res.status !== "no_data" && res.status !== "error" && res.status !== "imported_rejected" && res.status !== "imported_manual"){
                setGetSos(10000)
            }
            if(res.status && (res.status === "no_data" || res.status === "imported_rejected")){

                setGetSos(null)
                setSosStatus("cancel")
            }
            if(res.status && res.status === "imported_manual"){
                setGetSos(null)
                setSosStatus("manual")
            }
            if(res.status && res.status === "error"){
                setGetSos(null)
                setSosStatus("error")
            }
            if(res.merchant){
                setGetSos(null)
                setSosStatus("done")
                setMerchantCobalt(res.merchant)
            }
            if(res.principals){
                setGetSos(null)
                setSosStatus("done")
                setPrincipalCobalt(res.principals)
            }
        })
    }, get_sos)


    useEffect(() => {
        if(!!merchantReport){
            setOrderedPrincipals(order_principals())
        }
    }, [merchantReport])
    

    useEffect( ()=> {
        apiGet(`merchant/get_regulatory_links`).then(res => {
            setRegulatoryLinks(res)  
        }).catch(err=>{
            // eslint-disable-next-line
            const mute = err;
        }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantReport])


    useEffect( ()=> {
        const payload = {
            "merchant_id": id
        };
        apiPost(`merchant/verify_business_state`, payload).then(res => {
            setVerifiedState(res.state)  
        }).catch(err=>{
            // eslint-disable-next-line
            const mute = err;
        }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantReport])

    useEffect( ()=> {
        window.scroll(0,0)
    }, [merchantReport])

    useEffect( ()=> {
        if(!!merchantReport){
        apiGet(`merchant/vrisk/${id}`)
        .then(res => {
            if(res !== "Denied"){
                if(res.length !== 0){
                    setvRisk(res)
                }
                else{
                    setvRisk(dummyScore)
                }
            }
        })}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantReport])

    useEffect( ()=> {
        if(!!merchantReport){
        apiGet(`merchant/related_vrisk/${id}`)
        .then(res => {
            if(res !== "Denied"){
                if(res.length !== 0){
                    setRelatedVrisk(res)
                }
                else{
                    setRelatedVrisk([])
                }
            }
        })}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantReport])


    useEffect( ()=> {
        if(!!merchantReport){
        const payload = {
            "merchant_id": id
        };
        apiPost(`merchant/inquiryhistory`, payload).then(res => {
            setInquiryHistory(res)  
        }).catch(err=>{
            // eslint-disable-next-line
            const mute = err;
        }
        )}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantReport])


    function updateTloPrincipal(index){
        const payload = {
            'principal_id' : index.principal_id,
        }
        apiPost(`merchant/update_principal_blj`, payload).then(res => {
            if(res.status && (res.status === '202')){
                setGetTloPrincipal(5000)
                setIsUpdating([...isUpdating,`${index.principal_id}`])
            }
            else if(res.status && (res.status === '200')){
                toast('You do not have Permission to refresh this principal!')
            }
        }).catch(err => {
            // eslint-disable-next-line
            const mute = err;
        })
    }


    useInterval(async () => {
        isUpdating.forEach(id => {
            apiGet(`merchant/blj_principal/${id}`).then(res => {
                if(res.status && res.status !== "no_data" && res.status !== "error"){
                    setGetTloPrincipal(10000)
                }
                if(res.status && (res.status === "error" || res.status === "no_data")){
                    setGetTloPrincipal(null)
                    setIsUpdating(prevState => prevState.filter(item => item !== id));
                }
                if(res.id){
                    if(isUpdating.length <= 1){
                        setGetTloPrincipal(null)
                    }
                    setIsUpdating(prevState => prevState.filter(item => item !== id));
                    var tmptlo = {...dummyPrincipalTLO[0]}
                    tmptlo.principal_id = res.id
                    if(!!res.updated_at){
                        tmptlo.updated_at = new Date(res.updated_at.split(' ')[0])
                        let currentDate = new Date(); // Get the current date
                        let timeDifference = Math.abs(currentDate - tmptlo.updated_at); // Calculate the time difference in milliseconds
                        let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert time difference to day
                        let is184DaysOrMore = dayDifference >= 184; // Check if the difference is 184 days or more
                        tmptlo.can_update = is184DaysOrMore
                    }
                    //Associates
                    if(res.associates && res.associates.length !== 0){
                        tmptlo.ASSOCIATES_SUMMARY_001 = res.id
                        tmptlo.ASSOCIATES_SUMMARY_002 = res.associates.length
                        tmptlo.ASSOCIATES_SUMMARY_003 = 0
                    }
                    //Employement
                    if(res.employers && res.employers.length !== 0){
                        tmptlo.EMPLOYMENT_SUMMARY_001 = res.id
                        res.employers.forEach((employer, index) => {
                            if(index < 3){
                                tmptlo[`EMPLOYMENT_SUMMARY_0${index+1}_002`] = employer.business_name
                                tmptlo[`EMPLOYMENT_SUMMARY_0${index+1}_003`] = null
                            }
                        })
                    }
                    //Bankruptcy
                    if(res.bankruptcies && res.bankruptcies.length !== 0){
                        tmptlo.IND_BANKRUPTCY_SUMMARY_001 = res.id
                        var chapter_7 = res.bankruptcies.filter(item => item.chapter === 7)
                        var chapter_13 = res.bankruptcies.filter(item => item.chapter === 13)
                        var validDates = res.bankruptcies.filter(item => item.file_date !== null).map(item => new Date(item.file_date));
                        var mostRecentFilingDate = validDates.length > 0 ? Math.max(...validDates) : null;
                        mostRecentFilingDate = mostRecentFilingDate !== null ? new Date(mostRecentFilingDate).toISOString().split('T')[0] : null
                        tmptlo.IND_BANKRUPTCY_SUMMARY_002 = chapter_7.length
                        tmptlo.IND_BANKRUPTCY_SUMMARY_003 = chapter_13.length
                        tmptlo.IND_BANKRUPTCY_SUMMARY_004 = mostRecentFilingDate
                    }
                    //Liens
                    if(res.liens && res.liens.length !== 0){
                        tmptlo.IND_LIENS_SUMMARY_001 = res.id
                        tmptlo.IND_LIENS_SUMMARY_002 = res.liens.length
                        var validDates = res.liens.filter(item => item.filing_date !== null).map(item => new Date(item.filing_date));
                        var mostRecentFilingDate = validDates.length > 0 ? Math.max(...validDates) : null;
                        mostRecentFilingDate = mostRecentFilingDate !== null ? new Date(mostRecentFilingDate).toISOString().split('T')[0] : null
                        tmptlo.IND_LIENS_SUMMARY_003 = mostRecentFilingDate
                        tmptlo.IND_LIENS_SUMMARY_004 = res.liens[0].total_amount
                    }
                    //Judgements
                    if(res.judgements && res.judgements.length !== 0){
                        tmptlo.JUDGEMENTS_SUMMARY_001 = res.id
                        tmptlo.JUDGEMENTS_SUMMARY_002 = res.judgements.length
                        var validDates = res.judgements.filter(item => item.file_date !== null).map(item => new Date(item.file_date));
                        var mostRecentFilingDate = validDates.length > 0 ? Math.max(...validDates) : null;
                        mostRecentFilingDate = mostRecentFilingDate !== null ? new Date(mostRecentFilingDate).toISOString().split('T')[0] : null
                        tmptlo.JUDGEMENTS_SUMMARY_003 = mostRecentFilingDate
                        tmptlo.JUDGEMENTS_SUMMARY_004 = res.judgements[0].total_amount
                    }
                    //Relatives
                    if(res.relatives && res.relatives.length !== 0){
                        tmptlo.RELATIVES_SUMMARY_001 = res.id
                        tmptlo.RELATIVES_SUMMARY_002 = res.relatives.length
                    }
                    setPrincipalTlo(prevState => {
                        return prevState.map(principal => {
                          // If the principal_id matches, update the dictionary
                          if (principal.principal_id === tmptlo.principal_id) {
                            return { ...principal, ...tmptlo };
                          }
                          // Otherwise, return the original dictionary
                          return principal;
                        });
                    });
                    setDetailedPrincipalTlo(prevState => {
                        return prevState.map(principal => {
                          // If the principal_id matches, update the dictionary
                          if (principal.id === res.id) {
                            return {principal, ...res};
                          }
                          // Otherwise, return the original dictionary
                          return principal;
                        });
                    });
                    setPrincipalCount(detailed_principal_tlo.length > 0 ? detailed_principal_tlo.reduce((sum, obj) => sum + obj.bankruptcies.length, 0) + detailed_principal_tlo.reduce((sum, obj) => sum + obj.judgements.length, 0) + detailed_principal_tlo.reduce((sum, obj) => sum + obj.liens.length, 0) : null )

                }
            }).catch(err => {
                // eslint-disable-next-line
                console.error('An error occurred:', err);
    
                // const mute = err;
            })
        })
    }, get_tlo_principal)


    function calcPrincipal(){
      let count = 0
       if(detailed_principal_tlo.length > 0){
            let bankruptcy_count = detailed_principal_tlo.reduce((sum, obj) => sum + obj.bankruptcies.length, 0);
            let judgement_count = detailed_principal_tlo.reduce((sum, obj) => sum + obj.judgements.length, 0);
            let lien_count = detailed_principal_tlo.reduce((sum, obj) => sum + obj.liens.length, 0);
            count = bankruptcy_count + judgement_count + lien_count
        }
        if (count === 0)
            return null
        else
            return count
    }
    function calcOverview(){
        let risk_factors = vRisk[0] && vRisk[0].curr_reason_codes ? vRisk[0].curr_reason_codes.filter(code => code !== null && code !== undefined && code.toLowerCase() !== 'nan').length: 0;
        let watch_list = merchant_tlo[0] && merchant_tlo[0].WATCH_LIST_SUMMARY_002 ? merchant_tlo[0].WATCH_LIST_SUMMARY_002 : 0;

        return risk_factors + watch_list;
    }

    function calcAssociations(){
        return related_vRisk.filter(score=> score.curr_score < 60).length;
    }

    function calcPerformance(){
        return merchantReport.lender_merchants.reduce( (previousValue, currentItem) =>
            currentItem.status.indexOf("Closed") >= 0 ||
            currentItem.status.indexOf("Declined") >= 0 ||
            currentItem.status.indexOf("Suspended") >= 0 ||
            currentItem.status.indexOf("Probation") >= 0 ?
                previousValue + 1 :
                previousValue
            , 0);
    }

    function calcRegulatory(){
        let bbb = merchantReport.bbb_resolutions.reduce( (previousValue, currentItem) =>
            currentItem.resolved_status.indexOf("Respond") >= 0 ||
            currentItem.resolved_status.indexOf("Locate") >= 0 ?
                previousValue + currentItem.count :
                previousValue,
            0);

        let regulatory_body = merchantReport.regulatory_complaint_details.length;

        return regulatory_body + bbb;
    }

    function calcService(){
        return merchantReport.service_summary_by_stars.reduce( (previousValue, currentItem) =>
            currentItem.rating < 3 ?
                previousValue + currentItem.count_6_month :
                previousValue,
            0);
    }

    function order_principals(){
        let cobalt_lender_merchants = merchant_cobalt.lender_merchant_id
        let principals = []
        let remaining_principals = []
        if (cobalt_lender_merchants){
            merchantReport.principals.forEach(principal => {
                if(cobalt_lender_merchants.includes(principal.lender_merchant)){
                    principals.push(principal)
                }
                else{
                    remaining_principals.push(principal)
                }
            })
            remaining_principals.forEach(principal => {
                principals.push(principal)
            })
            return principals
        }
        return merchantReport.principals
    }



    function findSourceClosed() {
        let sourceName = ""
        for(let x=0; x<merchantReport.online_presence.length; x++) {
            if (merchantReport.online_presence[x].permanently_closed === true) {
                if (sourceName !== "") {
                    return "multiple sources"
                } else {
                    if (merchantReport.online_presence[x].alias === 'Angi Biz'){
                        sourceName = 'Angi'
                    } else {
                        sourceName = merchantReport.online_presence[x].alias
                    }
                }
            }
        }

        return sourceName
    }
    // TODO: style Loading view and put on other screens as needed
    if (!merchantReport) return <Loading/>


    // setup a dict for easier lookup by score name
    const scoresByName = {}
    merchantReport.scores.forEach( s => scoresByName[s.name] = s)

    const printVersion = !!(new URLSearchParams(window.location.search).get("printVersion"))
    
    const currentDate = (new Date()).toLocaleDateString("en-US")
    const urllink = window.location.href

    return (
        <div className="MerchantReport">
            <em className='report-date'>Report Date: <FormatDate date={merchantReport.report_date} timezome="" empty={currentDate}/></em>
            <div className='merchant-header-line'>
                <h1>
                    {merchantReport.merchant.name}
                </h1>
                {!printVersion && 
                    <Link to={`?printVersion=true`} className='print-button button' target='_blank'>Printable Report</Link>
                }
                {printVersion && 
                    <button className='print-button button' onClick={window.print}>Print</button>
                }
                {/* <button onClick={() => {navigator.clipboard.writeText(urllink); toast('Report Link Copied to Clipboard!') }}>Copy Report Link to Clipboard</button> */}
            </div>
            <div>
            {onlineSourceReportedClosed(merchantReport) ? 
            <>
            <span style={{color: "red"}}><b>This business has been reported as closed by {findSourceClosed()} </b></span>
            <br></br>
            <br></br>
            </> : ''}
            </div>

            <MerchantMetadata merchant={merchantReport.merchant} npi={merchantReport.npi} doctors={dedupeDoctors(newDoctors)} doctor_licenses={merchantReport.doctor_licenses} principals={ordered_principals} lender_merchants={merchantReport.lender_merchants} score={vRisk[0] ? parseInt(vRisk[0].curr_score) : null}>
                <div className={['overall-score', score2className(vRisk[0] ? parseInt(vRisk[0].curr_score) : null)].join(' ')}>
                    {vRisk[0] ? parseInt(vRisk[0].curr_score) : null}%
                </div>
            </MerchantMetadata>

            <AlternateInfo merchant={merchantReport.merchant} lender_merchants={merchantReport.lender_merchants} expanded={printVersion}/>
            
            {!printVersion && <nav>
                <Link to={`${baseUrl}/${merchantReport.merchant.id}/overview`} className={activeIf('overview')} regulatoryLinks={regulatory_links} verifiedState={verified_state} score2={vRisk[0]}>
                    Overview {calcOverview() !== (null || 0) ? <div className="block-meter rank-worst is-small">{calcOverview()}</div> : ''}
                </Link>

                <Link to={`${baseUrl}/${merchantReport.merchant.id}/associations`} className={activeIf('associations')} key="associations">
                    Associations {calcAssociations() !== (null || 0) ? <div className="block-meter rank-worst is-small">{calcAssociations()}</div> : ''}
                </Link>

                <Link to={`${baseUrl}/${merchantReport.merchant.id}/fraud`} className={activeIf('fraud')} key="fraud">
                    Performance {calcPerformance() !== (null || 0) ? <div className="block-meter rank-worst is-small">{calcPerformance()}</div> : ''}
                </Link>

                <Link to={`${baseUrl}/${merchantReport.merchant.id}/regulatory`} className={activeIf('regulatory')} regulatoryLinks={regulatory_links} verifiedState={verified_state} key="regulatory">
                    Regulatory {calcRegulatory() !== (null || 0) ? <div className="block-meter rank-worst is-small">{calcRegulatory()}</div> : ''}
                </Link>

                <Link to={`${baseUrl}/${merchantReport.merchant.id}/service`} className={activeIf('service')} key="service">
                    Service {calcService() !== (null || 0) ? <div className="block-meter rank-worst is-small">{calcService()}</div> : ''}
                </Link>

                <Link to={`${baseUrl}/${merchantReport.merchant.id}/financial`} className={activeIf('financial')} key="financial">
                    <span className='loading-spinner'>
                        <LoadingSpinner status={tlo_status} width="15" height="15" activateTT={true} text="We are retrieving Bankruptcy, Lien, and Judgement data" moveRight='8px'/>
                    </span>
                    Financial {finance_count !== (null || 0) ? <div className="block-meter rank-worst is-small">{finance_count}</div> : ''}
                </Link>

                <Link to={`${baseUrl}/${merchantReport.merchant.id}/principal`} className={activeIf('principal')} key="principal">
                    <span className='loading-spinner'>
                        <LoadingSpinner status={sos_status} width="15" height="15" activateTT={true} text="We are retrieving SOS Principals" moveRight='8px'/>
                    </span>
                    Principal {calcPrincipal() !== null ? <div className="block-meter rank-worst is-small">{calcPrincipal()}</div> : ''}
                </Link>
                
                {(newDoctors?.length > 0 || furnishDoctorsEnabled) && 
                <Link to={`${baseUrl}/${merchantReport.merchant.id}/doctors`} className={activeIf('doctors')} key="doctors">
                Doctors
                </Link>
                }


                <Link to={`${baseUrl}/${merchantReport.merchant.id}/scorecard`} className={activeIf('scorecard')} key="scorecard">Scorecard</Link>

            </nav>}

            <div>
                { (printVersion) &&     
                    <OverviewPanel merchantReport={merchantReport} newDoctors={newDoctors} refreshHsLicenses={refreshHsLicenses} hsLicenseOrders={hsLicenseOrders} regulatoryLinks={regulatory_links} verifiedState={verified_state} baseUrl={baseUrl} score2={vRisk[0]} merchant_tlo={merchant_tlo[0]} merchant_cobalt={merchant_cobalt} sos_status={sos_status} printVerison={true}/> }
                { (!printVersion && section === 'overview') &&     
                    <OverviewPanel merchantReport={merchantReport} newDoctors={newDoctors} refreshHsLicenses={refreshHsLicenses} hsLicenseOrders={hsLicenseOrders} regulatoryLinks={regulatory_links} verifiedState={verified_state} baseUrl={baseUrl} score2={vRisk[0]} merchant_tlo={merchant_tlo[0]} merchant_cobalt={merchant_cobalt} sos_status={sos_status} printVersion={false}/> }


                { (printVersion || section === 'associations') && 
                    <AssociationsPanel merchantReport={merchantReport} baseUrl={baseUrl} score={scoresByName['Associations']} vRisk={related_vRisk}/> }

                { (printVersion) &&        
                    <FraudPanel merchantReport={merchantReport} score={scoresByName['Fraud']} printVersion={true} inquiryHistory={inquiry_history} /> }
                { (!printVersion && section === 'fraud') &&        
                    <FraudPanel merchantReport={merchantReport} score={scoresByName['Fraud']} printVersion={false} inquiryHistory={inquiry_history} /> }

                { (printVersion || section === 'regulatory') &&
                    <RegulatoryPanel merchantReport={merchantReport} regulatoryLinks={regulatory_links} verifiedState={verified_state} score={scoresByName['Regulatory']} /> }

                { (printVersion || section === 'service') &&
                    <ServicePanel merchantReport={merchantReport} score={scoresByName['Service']} 
                        service_stars_expanded={printVersion} /> }
                        
                { (printVersion) &&
                    <FinancialPanel merchantReport={merchantReport} printVersion={true} score={scoresByName['Financial']} merchant_tlo={merchant_tlo[0]} level2TLO={tlo_2} warnings={finance_count}/> }
                { (!printVersion && section === 'financial') &&
                    <FinancialPanel merchantReport={merchantReport} printVersion={false} score={scoresByName['Financial']} merchant_tlo={merchant_tlo[0]} level2TLO={tlo_2} warnings={finance_count}/> }

                { (printVersion) &&
                    <PrincipalPanel merchantReport={merchantReport} printVersion={true} score={scoresByName['Principal']} principal_name_lookup={tlo_principal_lookup} principal_tlo={principal_tlo} principal_tlo_details={detailed_principal_tlo}  update_principal_tlo={updateTloPrincipal} is_updating={isUpdating} principal_cobalt={principal_cobalt}/> }
                { (!printVersion && section === 'principal') &&
                    <PrincipalPanel merchantReport={merchantReport} printVersion={false} score={scoresByName['Principal']} principal_name_lookup={tlo_principal_lookup} principal_tlo={principal_tlo} principal_tlo_details={detailed_principal_tlo}  update_principal_tlo={updateTloPrincipal} is_updating={isUpdating} principal_cobalt={principal_cobalt}/> }
                { (printVersion) &&
                    <DoctorsPanel merchantReport={merchantReport} printVersion={true} newDoctors={newDoctors} refreshDoctors={refreshDoctors} furnishDoctorsEnabled={furnishDoctorsEnabled}/>
                }

                { (!printVersion && section === 'doctors') &&
                    <DoctorsPanel merchantReport={merchantReport} printVersion={false} newDoctors={newDoctors} refreshDoctors={refreshDoctors} furnishDoctorsEnabled={furnishDoctorsEnabled}/>
                }
                { (!printVersion && section === 'scorecard') &&
                    <ScorecardPanel merchantReport={merchantReport} printVersion={false} /> }
                

            </div>
        </div>
    )
}
