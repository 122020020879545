import React from 'react'

import './Modal.scss'

export default function Modal({children, className='', ...rest}){
    return (
        <div className={`${className} modal`} {...rest}>
            <div className='modal-content'>
                {children}
            </div>
        </div>
    )
}