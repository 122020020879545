import React from 'react'

import './ScoreMeter.scss'

const rankText = {
    worst: [ 'Highest', 'Highest Risk' ],
    bad: [ 'High', 'High Risk' ],
    ok: [ 'Med', 'Medium Risk' ],
    good: [ 'Low', 'Low Risk'],
    best: [ 'Lowest', 'Lowest Risk'],
    unknown: ['Unk', 'Unknown Risk']
}
export default function BlockMeter({rank, small}){
    rank = rank || 'unknown'
    return <div className={`block-meter rank-${rank} ${small && 'is-small'}`}>
        {rankText[rank][small ? 0 : 1]}
    </div>
}

/*
export default function Meter({score}){
    return score === undefined    
        ? <div className="meter blank" /> 
        : <div className="meter" style={{'--value': `${score}%`}}>
                <div className="meter-value score-very-bad" />
                <div className="meter-value score-bad" />
                <div className="meter-value score-ok" />
                <div className="meter-value score-good" />
                <div className="meter-value score-very-good" />
          </div>
}
*/