import React, { useEffect, useState } from 'react'
import { useRouteMatch } from "react-router-dom"

import useApi from 'hooks/useApi'
import MerchantReport from 'pages/onboarding/MerchantReport'

import scrollToTop from 'utils/scrollToTop'
import { useHistory } from 'react-router-dom'

export default function PortfolioRoutes(){
    const { apiGet } = useApi()
    const { params: { portfolioSection, merchantId, section } } = useRouteMatch()
    const [ merchantResults, setMerchantResults ] = useState(null)

    const history = useHistory()

    function backToPortfolio(){
        history.push(`/healthcheck/overview`)
    }
        
    useEffect(()=> {
        // when it changes, clear out any previous merchant report first
        setMerchantResults(null)
        scrollToTop()
        if ( merchantId ){
            apiGet(`merchant/${merchantId}/report`)
                .then( reportData => {
                    setMerchantResults(reportData);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId])

    return (
        <div className='inquiry-flow'>
            <div className='inquiry-navigator'>
                <button onClick={backToPortfolio}>Back to Portfolio</button>
            </div>
            <MerchantReport
                merchantReport={merchantResults}
                baseUrl={`/healthcheck/${portfolioSection}/merchant`}
                section={section || portfolioSection}
                id ={merchantId}
            />
        </div>
    )
}
