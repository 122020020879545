import React, { useReducer } from "react";

import FormatDate from 'shared/FormatDate'
import MerchantMetadata from 'shared/MerchantMetadata'
import PrincipalMetadata from 'shared/PrincipalMetadata'
import LocationMetadata from 'shared/LocationMetadata'
import DisplayLender from 'shared/DisplayLender'

import './AlternateInfo.scss'
import { filter } from "lodash";

function toggle(state, action) {
    return !state
}

// If there are duplicate lender merchant reports from a furnished source, first grab
// most updated lender merchant row for that source, and then backfill any empty fields from other duplicate
// lender rows from the same source
function filterLenderMerchants(data) {
    // Filter only furnished sources and all other sources
    const furnishedLenders = data.filter(obj => obj.lender.includes("Furnished Source"))
    const otherSources = data.filter(obj => !obj.lender.includes("Furnished Source"))

    // Split out furnished sources that do and dont have duplicates
    const singleOccurances = new Map();

    furnishedLenders.forEach(obj => {
    const fieldValue = obj.lender;
    singleOccurances.set(fieldValue, (singleOccurances.get(fieldValue) || 0) + 1);
    });

    const lendersWithoutDuplicates = furnishedLenders.filter(obj => singleOccurances.get(obj.lender) === 1)
    const lendersWithDuplicates = furnishedLenders.filter(obj => singleOccurances.get(obj.lender) > 1)
    // Grab unique list of only the furnished source names that have dupes to use later
    let duplicate_lender_names = []
    let most_updated = []
    lendersWithDuplicates.forEach((obj) => {
        duplicate_lender_names.push(obj.lender)
    })
    let dedupe_set = new Set(duplicate_lender_names)
    let unique_lender_names = [...dedupe_set]

    // Grab most updated lender merchant object for each source that has dupes, we will display
    // this object per source
    if (unique_lender_names.length > 0) {
        unique_lender_names.forEach((lender_name) => {
            let onlyOneLender = lendersWithDuplicates.filter(obj => obj.lender === lender_name)
            let currMostUpdated = onlyOneLender[0]
            onlyOneLender.forEach((obj) => {
                if (obj.updated_at > currMostUpdated.updated_at) {
                    currMostUpdated = obj
                }
            })
            most_updated.push(currMostUpdated)
        })
    }
    // Loop through the other lender merchant rows per source and if there is a null field
    // for the most_updated obj but nonnull for the other row -- update the most_updated
    if (most_updated.length > 0) {
        most_updated.forEach((most_updated_obj) => {
            //filter on same furnished source
            let onlyOneLender = lendersWithDuplicates.filter(obj => obj.lender === most_updated_obj.lender)
            //sort by most recent updated_at column
            onlyOneLender.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
            onlyOneLender.forEach((other_merchant_row) => { 
                if (most_updated_obj.name === null && other_merchant_row.name !== null)
                    most_updated_obj.name = other_merchant_row.name
                if (most_updated_obj.name_dba === null && other_merchant_row.name_dba !== null) 
                    most_updated_obj.name_dba = other_merchant_row.name_dba
                if (most_updated_obj.city === null && other_merchant_row.city !== null)
                    most_updated_obj.city = other_merchant_row.city
                if (most_updated_obj.state === null && other_merchant_row.state !== null)
                    most_updated_obj.state = other_merchant_row.state
                if (most_updated_obj.addr1 === null && other_merchant_row.addr1 !== null)
                    most_updated_obj.addr1 = other_merchant_row.addr1
                if (most_updated_obj.phone === null && other_merchant_row.phone !== null)
                    most_updated_obj.phone = other_merchant_row.phone
                if (most_updated_obj.fax === null && other_merchant_row.fax !== null)
                    most_updated_obj.fax = other_merchant_row.fax
                if (most_updated_obj.email === null && other_merchant_row.email !== null)
                    most_updated_obj.email = other_merchant_row.email
                if (most_updated_obj.domain_name === null && other_merchant_row.domain_name !== null)
                    most_updated_obj.domain_name = other_merchant_row.domain_name
                if (most_updated_obj.url === null && other_merchant_row.url !== null)
                    most_updated_obj.url = other_merchant_row.url
                if (most_updated_obj.sic_code === null && other_merchant_row.sic_code !== null)
                    most_updated_obj.sic_code = other_merchant_row.sic_code
                if (most_updated_obj.ein === null && other_merchant_row.ein !== null)
                    most_updated_obj.ein = other_merchant_row.ein
                if (most_updated_obj.locations.length < other_merchant_row.locations.length)
                    most_updated_obj.locations = other_merchant_row.locations
                if (most_updated_obj.principals.length < other_merchant_row.principals.length)
                    most_updated_obj.principals = other_merchant_row.principals
            })
        })
    }

    //Combine everything back together
    let finalFurnishedArray = lendersWithoutDuplicates.concat(most_updated)
    let finalLenderMerchants = otherSources.concat(finalFurnishedArray)
    //Alphabetical order in display
    finalLenderMerchants.sort((a, b)=> a.lender.localeCompare(b.lender))
  
    return finalLenderMerchants
  }

export default function AlternateInfo({merchant, lender_merchants, expanded}) {
    const [hidden, dispatch] = useReducer(toggle, !expanded);
    return (
        <>
            <span className="additional-info link-button" onClick={() => dispatch()}>
                { hidden ? (
                    <><i className="fa fa-chevron-down" aria-hidden="true" />Additional Information</>
                ) : (
                    <><i className="fa fa-chevron-up" aria-hidden="true" />Additional Information</>
                )}
            </span>
            <>
            {!hidden && 
            <div className="alternate-info">
                <table>
                    <thead>
                        <tr>
                            <th>Source</th>
                            <th>Business</th>
                            <th>Locations</th>
                            <th>Principals</th>
                            <th>Dates</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterLenderMerchants(lender_merchants).map( (lender_merchant, index) =>
                            <tr className={'row-' + (index) % 2} key={lender_merchant.id}>
                                <td><DisplayLender alias={lender_merchant.lender}/></td>
                                <td>
                                    <MerchantMetadata merchant={lender_merchant} showScore={false} />
                                </td>
                                <td>
                                    { lender_merchant.locations.map((l, index) =>
                                        <React.Fragment key={l.id}>
                                            {l.state && index > 0 && <br />}
                                            <LocationMetadata location={l} />
                                        </React.Fragment>
                                    )
                                    }
                                </td>
                                <td>
                                    { lender_merchant.principals.map((p, index) =>
                                        <React.Fragment key={p.id}>
                                            {p.state && index > 0 && <br />}
                                            <PrincipalMetadata principal={p} />
                                        </React.Fragment>
                                    )
                                    }
                                </td>
                                <td className="dates">
                                    {/* <strong>First Seen:</strong> <FormatDate date={lender_merchant.created_at} /> <br /> */}
                                    <strong>Last Updated:</strong> <FormatDate date={lender_merchant.updated_at} />
                                </td>
                                </tr>
                        )}
                    </tbody>
                </table>
            </div>
            }
            </>
        </>
    )
}
