import React, { useEffect, useState } from 'react'
import { useRouteMatch, useHistory } from "react-router-dom"

import useApi from 'hooks/useApi'
import OnboardingForm from './OnboardingForm'
import SelectMerchant from './SelectMerchant'
import MerchantReport from './MerchantReport'
import InquiryNavigator from './InquiryNavigator'
import RecentReportsNavigator from './RecentReportsNavigator'

import scrollToTop from 'utils/scrollToTop'

export default function OnboardingRoutes(){
    const { params: { inquiryId, merchantId, section } } = useRouteMatch()
    const { apiGet, apiPost } = useApi()
    const [ inquiryResults, setInquiryResults ] = useState(null)
    const [ inquiryHistory, setInquiryHistory ] = useState(null)
    const [ inquiryTooManyResults, setInquiryTooManyResults ] = useState(false)
    const [ merchantResults, setMerchantResults ] = useState(null)
    const history = useHistory()

    useEffect(() => {
        apiGet('inquiry/result/history', { page_size: 50 }).then(response => {
            setInquiryHistory(response.results)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=> {
        // when it changes, clear out any previous results first
        setInquiryResults(null)
        scrollToTop()
        const payload = {
            offset: 0,
            limit: 40
        }
        if ( inquiryId ){
            apiPost(`inquiry/${inquiryId}/results`, payload).then(results => {
                if (results && results.length > 20) {
                    setInquiryResults(results)
                    setInquiryTooManyResults(true)
                }
                else {
                    setInquiryResults(results)
                    setInquiryTooManyResults(false)
                }
            }).catch( response => {
                history.push('/onboarding')
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiryId])

    useEffect(()=> {
        // when it changes, clear out any previous merchant report first
        setMerchantResults(null)
        scrollToTop()
        if ( merchantId ){
            apiPost(`inquiry/${inquiryId}/result`, { merchant_id: merchantId })
                .then( reportData => {
                    if (reportData.inquiry !== inquiryId) {
                        history.push(`/onboarding/${reportData.inquiry}/${merchantId}`)
                    }
                    
                    setMerchantResults(reportData.result_blob);
                })
                .catch( response => {
                    history.push('/onboarding')
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inquiryId, merchantId])

    const viewState = merchantId ? 'merchant-report' : inquiryId ? 'select-merchant' : 'search-form'

    return (
        <div className='inquiry-flow'>
            {(!!inquiryId) ? (
                <InquiryNavigator inquiryResults={inquiryResults} merchantId={merchantId}
                                inquiryId={inquiryId} />
            ) : (
                <RecentReportsNavigator inquiryHistory={inquiryHistory} />
            )}                           

            { viewState === 'search-form' &&
                <OnboardingForm />
            }
            { viewState === 'select-merchant' &&
                <SelectMerchant inquiryResults={inquiryResults} inquiryTooManyResults={inquiryTooManyResults} inquiryId={inquiryId} />
            }
            { viewState === 'merchant-report' &&
                <MerchantReport
                    merchantReport={merchantResults}
                    baseUrl={`/onboarding/${inquiryId}`}
                    section={section}
                    id={merchantId}
                />
            }
        </div>
    )
}
