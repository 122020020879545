import React, { useEffect, useState } from 'react'

import useApi from 'hooks/useApi'
import './RegulatorySearch.scss'
import ExternalLink from "../../../shared/ExternalLink"
import {Link, useHistory, useLocation, useRouteMatch} from "react-router-dom";







export default function RegulatorySearch() {

    const { params: {  section, } } = useRouteMatch()
    const location = useLocation();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);
    const state_param = queryParams.get('state') || '';
    const use_param = queryParams.get('use_case') || '';
    const category_param = queryParams.get('category') || '';


    const { apiGet } = useApi()
    const [regulatory_links, setRegulatoryLinks] = useState([])
    const [medical_links, setMedicalLinks] = useState([])
    const [vet_links, setVetLinks] = useState([])
    const [home_improv_links, setHomeImprovLinks] = useState([])
    const [filtered_links, setFilteredLinks] = useState([])
    const [filtered_med_links, setFilteredMedLinks] = useState([])
    const [filtered_vet_links, setFilteredVetLinks] = useState([])
    const [filtered_home_improv_links, setFilteredHomeImprovLinks] = useState([])
    const [stateFilterValue, setStateFilterValue] = useState(state_param || '')
    const [useFilterValue, setUseFilterValue] = useState(use_param || '')
    const [catFilterValue, setCatFilterValue] = useState(category_param || '')


 

    useEffect( ()=> {
        apiGet(`merchant/get_regulatory_links`).then(res => {
            setRegulatoryLinks(res)
            setFilteredLinks(res)
            const med_links = res.filter(populateMedicalLinks)
            setMedicalLinks(med_links) 
            setFilteredMedLinks(med_links) 
            const vet_links = res.filter(populateVetLinks)
            setVetLinks(vet_links) 
            setFilteredVetLinks(vet_links) 
            const home_improv_links = res.filter(populateHomeImprovLinks)
            setHomeImprovLinks(home_improv_links) 
            setFilteredHomeImprovLinks(home_improv_links) 

        }).catch(err=>{
            // eslint-disable-next-line
            const mute = err;
        }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        applyFilters();
      }, [section, stateFilterValue, catFilterValue, useFilterValue, regulatory_links, medical_links, vet_links, home_improv_links]);


    function populateMedicalLinks(link) {
        return link['categories'].includes('Medical') || link['categories'].includes('Nursing') ||  link['categories'].includes('Dental') || link['categories'].includes('Physicians')
    }

    function populateVetLinks(link) {
        return link['categories'].includes('Veterinary')
    }

    function populateHomeImprovLinks(link) {
        return link['categories'].includes('Plumbing') || link['categories'].includes('Electrical') ||  link['categories'].includes('General-Building') || link['categories'].includes('Contractors')
        || link['categories'].includes('Architecture') || link['categories'].includes('HVAC') || link['categories'].includes('Roofing')
    }

    // Filtering logic
    const applyFilters = () => {
        const filteredLinks = regulatory_links.filter(
        (item) =>
            (stateFilterValue === '' || item.state_name.includes(stateFilterValue)) &&
            (catFilterValue === '' || item.categories.includes(catFilterValue)) &&
            (useFilterValue === '' || item.use.includes(useFilterValue))
        )
        setFilteredLinks(filteredLinks)
        const filteredMed = medical_links.filter(
            (item) =>
                (stateFilterValue === '' || item.state_name.includes(stateFilterValue)) &&
                (catFilterValue === '' || item.categories.includes(catFilterValue)) &&
                (useFilterValue === '' || item.use.includes(useFilterValue))
            )
        setFilteredMedLinks(filteredMed)

        const filteredVet = vet_links.filter(
                (item) =>
                    (stateFilterValue === '' || item.state_name.includes(stateFilterValue)) &&
                    (catFilterValue === '' || item.categories.includes(catFilterValue)) &&
                    (useFilterValue === '' || item.use.includes(useFilterValue))
                )
        setFilteredVetLinks(filteredVet)
        const filteredHomeImprov = home_improv_links.filter(
                    (item) =>
                        (stateFilterValue === '' || item.state_name.includes(stateFilterValue)) &&
                        (catFilterValue === '' || item.categories.includes(catFilterValue)) &&
                        (useFilterValue === '' || item.use.includes(useFilterValue))
                    )
        setFilteredHomeImprovLinks(filteredHomeImprov)

        // update the URL with the selected filter values
        const urlParams = new URLSearchParams()
        if (stateFilterValue) {
            urlParams.set('state', stateFilterValue)
        }
        if (useFilterValue) {
            urlParams.set('use_case', useFilterValue)
        }
        if (catFilterValue) {
            urlParams.set('category', catFilterValue)
        }

        history.push(`/regulatory-search/${section}/?${urlParams.toString()}`);

    }

    // Update filters and apply filters when filter values change
    const handleFilterChange = (filterType, value) => {
        switch (filterType) {
        case 'state_filter':
            setStateFilterValue(value)
            break
        case 'category_filter':
            setCatFilterValue(value)
            break
        case 'use_filter':
            setUseFilterValue(value)
            break
        default:
            break
        }
        applyFilters()
    }


    function activeIf(_section){
        return _section === section ? 'active' : ''
    }



    return (
        <div className="regulatory-search">
            <h2>State Regulatory and Professional Search</h2>
                <div>
                    <div className="centered-nav">
                        <nav>
                            <Link to={`/regulatory-search/all`} className={activeIf('all')}>All</Link>
                            <Link to={`/regulatory-search/medical`} className={activeIf('medical')}>Medical</Link>
                            <Link to={`/regulatory-search/veterinary`} className={activeIf('veterinary')}>Veterinary</Link>
                            <Link to={`/regulatory-search/home-improvement`} className={activeIf('home-improvement')}>Home-Improvement</Link>

                        </nav>
                    </div>
                    <div className="centered-nav">
                        <Filters onFilterChange={handleFilterChange} selectedState={stateFilterValue} selectedCat={catFilterValue} selectedUse={useFilterValue}></Filters>
                    </div>

                        <table className="regulatory-table">
                            <thead>
                                <tr >
                                    <th className='column1'>State</th>
                                    <th>Categories</th>
                                    <th>Use Case</th>
                                    <th className='right-col'>External Search Link</th>
                                </tr>
                            </thead>
                            <tbody>
                            {section === 'all' && filtered_links.map((stateRow, index) =>
                                            <>
                                            {stateRow.hidden !== true && 
                                            <tr className={'row-' + (index + 1) % 2} key={index}>
                                                <td>{stateRow.state_name}</td>
                                                <td>{stateRow.categories}</td>
                                                <td>{stateRow.use}</td>
                                                <td>{stateRow.url !== '' ? <ExternalLink href={stateRow.url} target='_blank'>{stateRow.agency}</ExternalLink> : <p>N/A</p>}</td>
                                            </tr>
                                            }</>
                                        )}
                            {section === 'medical' && filtered_med_links.map((stateRow, index) =>
                                            <tr className={'row-' + (index + 1) % 2} key={index}>
                                                <td>{stateRow.state_name}</td>
                                                <td>{stateRow.categories}</td>
                                                <td>{stateRow.use}</td>
                                                <td>{stateRow.url !== '' ? <ExternalLink href={stateRow.url} target='_blank'>{stateRow.agency}</ExternalLink> : <p>N/A</p>}</td>
                                            </tr>
                                        )}
                            {section === 'veterinary' && filtered_vet_links.map((stateRow, index) =>
                                            <tr className={'row-' + (index + 1) % 2} key={index}>
                                                <td>{stateRow.state_name}</td>
                                                <td>{stateRow.categories}</td>
                                                <td>{stateRow.use}</td>
                                                <td>{stateRow.url !== '' ? <ExternalLink href={stateRow.url} target='_blank'>{stateRow.agency}</ExternalLink> : <p>N/A</p>}</td>
                                            </tr>
                                        )}
                            {section === 'home-improvement' && filtered_home_improv_links.map((stateRow, index) =>
                                            <tr className={'row-' + (index + 1) % 2} key={index}>
                                                <td>{stateRow.state_name}</td>
                                                <td>{stateRow.categories}</td>
                                                <td>{stateRow.use}</td>
                                                <td>{stateRow.url !== '' ? <ExternalLink href={stateRow.url} target='_blank'>{stateRow.agency}</ExternalLink> : <p>N/A</p>}</td>
                                            </tr>
                                        )}
                            </tbody>
                        </table>
                </div>
        </div>
    )
}


  const Filters = ({ onFilterChange, selectedState, selectedCat, selectedUse }) => {

    const useCaseOptions = ['Business Registration', 'Professional Licensing', 'Consumer Complaint']
    const categoryOptions = ['General', 'General-Building', 'Roofing', 'HVAC', 'Veterinary', 'Nursing', 'Contractors', 'Plumbers',
                             'Medical', 'Physicians', 'Electricians', 'Dental', 'Architects', 'Charity']
    const stateNames = ['National', 'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida',
        'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
        'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
        'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 
        'West Virginia', 'Wisconsin', 'Wyoming'];


    return (
      <div>
            <select value={selectedState} onChange={(event) => onFilterChange('state_filter', event.target.value)}>
                <option value="">All States</option>
                {stateNames.map((state) => (
                    <option key={state} value={state}>
                    {state}
                    </option>
                ))}
            </select>
            <select value={selectedCat} onChange={(event) => onFilterChange('category_filter', event.target.value)}>
                <option value="">All Categories</option>
                {categoryOptions.map((category) => (
                    <option key={category} value={category}>
                    {category}
                    </option>
                ))}
            </select>
            <select value={selectedUse} onChange={(event) => onFilterChange('use_filter', event.target.value)}>
                <option value="">All Use Cases</option>
                {useCaseOptions.map((use) => (
                    <option key={use} value={use}>
                    {use}
                    </option>
                ))}
            </select>
      </div>
    );
  };