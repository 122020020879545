import React, {useEffect, useState} from 'react'
import useApi from 'hooks/useApi'
import Loading from 'shared/Loading'
import FormatNumber from 'shared/FormatNumber'
import FormatPercentage from 'shared/FormatPercentage'
import {MultiButtonSwitcher} from 'shared/MultiButtonSwitcher'
import vRiskLogo from 'assets/vrisk-logo.png'

import './HealthCheckPage.scss'
import {Link} from "react-router-dom";

const filterLevels = ['All Levels', 'Company Level Only', 'Individual Locations Only']
const encodeLevelFilter = (level) => level.toLowerCase().replace(/\s/g, '-')

export default function HealthCheckPage() {

    const { apiGet } = useApi()
    const [ results, setResults ] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ levelFilter, setLevelFilter ] = useState(encodeLevelFilter(filterLevels[0]))

    useEffect(()=> {
        setLoading(true)
        apiGet(`lender/portfolio`, {
            level: levelFilter
        }).then( response => {
            setLoading(false)
            setResults(response.portfolio_health_summary)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [levelFilter])

    if (loading) return <Loading />

    return (
        <>
          {results ?
              <div className='health-check-page'>
                  <p>
                  Every month, Verdata monitors your active portfolio and updates the risk scoring for each business. For each, we
                  segment your merchants into 4 categories. New results are provided at the beginning of each month.<br />
                      Click on "View Portfolio" to see the breakdown for each segment.
                  </p>

                  <header>
                      <MultiButtonSwitcher values={filterLevels}
                                           value={filterLevels.find( level => encodeLevelFilter(level) === levelFilter)}
                                           onChange={(level) => setLevelFilter(encodeLevelFilter(level))} />
                  </header>

                  <HealthCheckSectionTable section='overview' counts={results.overview}
                                           title='Portfolio Onboarding Risk'
                                           labels={['Highest Risk', 'High Risk', 'Medium Risk', 'Lowest Risk']}
                                           levelFilter={levelFilter}
                  />

                  <p>
                  Verdata's risk segments are based on data from Verdata's Partner Insight Network, licensed data vendors, and on publicly available information obtained by Verdata. Each month, the Portfolio Health Check is refreshed with new information to provide you with a
                  monthly update on the risk allocation of your current portfolio.
                  </p>
              </div>
          :
              <div className='coming-soon'>
                  <h1>Portfolio HealthCheck Product Coming Soon!</h1>
              </div>
          }
        </>
    )
}

function HealthCheckSectionTable({section, counts, title, labels, levelFilter}){
    const empty_record = {total: 0, percent: 0, new: 0}
    return (
        <>
            <table className='merchants-risk'>
                <thead>
                    <tr>
                        <th><img src={vRiskLogo} alt="vRisk" /><h1>{ title }</h1></th>
                        <th>Total</th>
                        <th>Percent</th>
                        <th>Added to Segment Since Last Month</th>
                    </tr>
                </thead>

                {counts ?
                <tbody>
                    <tr>
                        <td><Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[0])}`}>{ labels[0] }</Link></td>
                        <td><FormatNumber value={(counts.worst || empty_record).total} /></td>
                        <td><FormatPercentage value={(counts.worst || empty_record).percent} places={2} /></td>
                        <td>
                            <Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[0])}/new`}>
                                <FormatNumber value={(counts.worst || empty_record).new} />
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td><Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[1])}`}>{ labels[1] }</Link></td>
                        <td><FormatNumber value={(counts.bad || empty_record).total} /></td>
                        <td><FormatPercentage value={(counts.bad || empty_record).percent} places={2} /></td>
                        <td>
                            <Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[1])}/new`}>
                                <FormatNumber value={(counts.bad || empty_record).new} />
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td><Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[2])}`}>{ labels[2] }</Link></td>
                        <td><FormatNumber value={(counts.ok || empty_record).total} /></td>
                        <td><FormatPercentage value={(counts.ok || empty_record).percent} places={2} /></td>
                        <td>
                            <Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[2])}/new`}>
                                <FormatNumber value={(counts.ok || empty_record).new} />
                            </Link>
                            <Link to={`/healthcheck/${section}/${levelFilter}`} className='button primary'>View Portfolio</Link>
                        </td>
                    </tr>
                    <tr>
                        <td><Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[3])}`}>{ labels[3] }</Link></td>
                        <td><FormatNumber value={(counts.best || empty_record).total} /></td>
                        <td><FormatPercentage value={(counts.best || empty_record).percent} places={2} /></td>
                        <td>
                            <Link to={`/healthcheck/${section}/${levelFilter}/${slugify(labels[3])}/new`}>
                                <FormatNumber value={(counts.best || empty_record).new} />
                            </Link>
                            <Link to={`/healthcheck/${section}/${levelFilter}`} className='button primary'>View Portfolio</Link>
                        </td>
                    </tr>
                </tbody>
                :
                <tbody>
                    <tr>
                        <td>No historical portfolio health data</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                }
            </table>
        </>
    )
}

/*
  Takes a string, lower-kebob-cases it
 */
function slugify(str){
    return str.toLowerCase().replace(/\s/g, '-')
}

