import React from 'react'
import {
    getCognitoSession,
    refreshCognitoSession,
    cognitoAuthCodePresent,
    completeCognitoLogin,
    signOutCognitoSession
} from 'utils/cognitoUtils'
import { apiCall } from 'hooks/useApi'
import queryString from 'query-string'




const mins = 60 * 1000 // conversion from mins to ms
const sessionRenewalThreshold = 5 * mins // time threshold before token expiration to renew

// Context used in React to get current session where needed
const SessionContext = React.createContext(null)
export default SessionContext

export async function getSession(setError){

    if (cognitoAuthCodePresent()) await completeCognitoLogin()


    const session = await getCognitoSession()
    const parsed = queryString.parse(localStorage.getItem('redirect-to'))

    //redirect back to original page after logging in
    if (parsed['state'] != null) {
        window.location = parsed['state']
    }

    session.user = await getCurrentUser(setError, session, true)

    let activeLender
    
    const activeLenderId = localStorage.getItem('active-lender-id') || localStorage.getItem('last-active-lender-id')
    if(!!session.user.is_staff){
        activeLender = session.user.lenders.find(l => l.id === activeLenderId) || session.user.lenders.find(l => l.id === 'e0d5339b-83c3-4723-b0f1-9898484595f5')
    }
    else{activeLender = session.user.lenders.find(l => l.id === activeLenderId) || session.user.lenders[0]}
    _activeLenderId = activeLenderId
    localStorage.setItem('active-lender-id', activeLender.id)

    localStorage.setItem('lender', JSON.stringify(activeLender))
    session.switchLender = (lender) => {
        localStorage.setItem('active-lender-id', lender.id)
        localStorage.setItem('lender', JSON.stringify(lender))

        window.location.reload()
    }


    return session
}

let _activeLenderId = null
window.addEventListener('focus', function(){
    if (_activeLenderId) {
        localStorage.setItem('last-active-lender-id', _activeLenderId)

    } else {
        localStorage.removeItem('last-active-lender-id')
    }
})

export function scheduleSessionRenewal(session, setNewSession, setError){
    const now = new Date(),
          sessionExpires = new Date(session.expires * 1000),
          sessionTimeRemaining = sessionExpires - now
    

    let timeTilRefresh = sessionTimeRemaining - sessionRenewalThreshold

    if (timeTilRefresh < 1) timeTilRefresh = 1

    const doRefresh = ()=> {
        localStorage.setItem('redirect-to', window.location.href)
        refreshCognitoSession(session).then( newSession => {
            getCurrentUser(setError, newSession).then( user => {
                newSession.user = user
                setNewSession(newSession)
                scheduleSessionRenewal(newSession, setNewSession)
            })
        })
    }



    inactivityTime()
    setTimeout(doRefresh, timeTilRefresh)
}

async function getCurrentUser(setError, session, registerLogin=false){
    return apiCall('get',
        session.credentials.idToken, session.lender && session.lender.id,
        setError, 'user/me', {registerLogin})
}

export async function inactivityTime() {
    let time;
    resetTimer()
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer;

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(signOutCognitoSession, 820000)
      localStorage.setItem('redirect-to', window.location.href)

    }
  };
