import React, {useState, useEffect, useContext, Fragment} from 'react'
import ScoreGauge from 'shared/ScoreGauge'
import FormatDate from 'shared/FormatDate'
import DisplayLender from 'shared/DisplayLender'
import LoadingSpinner from 'shared/LoadingSpinner'
import Modal from 'shared/Modal'
import LicenseLabelBadge from '../../../shared/LicenseLabelBadge'
import Slider from "react-slick"

import './OverviewPanel.scss'
import vRiskLogo from 'assets/vrisk-logo.png'
import ExternalLink from "../../../shared/ExternalLink"
import appConfig from "../../../app-config.json";
import exclamationMark from "../../../assets/red-exclamation.png"
import useApi from '../../../hooks/useApi'
import {onlineSourceReportedClosed, dedupeDoctors} from "../MerchantReport"
import toast from 'react-hot-toast'
import ErrorsContext from "../../../ErrorsContext";


function statusClass(license) {
    if (license.license_status === "Active") {
        return "status-current"
    }
    else if (license.license_status === "Inactive") {
        return "status-closed"
    }
    else if (['', null].includes(license.license_status)) {
        return "status-pending"
    }
    else {
        return ""
    }
}

function formatIssuer(license){
    if(license.license_state && license.license_locale){
        return license.license_state + " - " + license.license_locale
    }
    else if (license.license_state){
        return license.license_state
    }
    else if (license.license_locale){
        return license.license_locale
    }
    else{
        return ""
    }
}


function get_state_name(state_abbrev,links) {
    let state_name;
    for(let i =0; i<links.length; i++) {
        if(state_abbrev === links[i].state_abbrev) {
            state_name = links[i].state_name
        }
    }
    return state_name
}

function replaceEmptyStringsWithNull(obj) {
    for (const key in obj) {
        if (obj[key] === '') {
            obj[key] = null;
        } 
    }
    return obj;
}

function dateFormatter(date){
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"}
    return new Date(date).toLocaleString(undefined, options)
}

const defaultLicenseInquiry = {
    merchant_id: '',
    licensee_name: '',
    licensee_addr1: '', licensee_city: '', licensee_state: '', licensee_zip5: '',
    licensee_id: '', licensee_category: 'homeServices', licensee_subcategory: ''
}

const subcategories = [
    { electricians: 'Electricians' },
    { generalContractors: 'General Contractors' },
    { plumbers: 'Plumbers' },
    { otherHomeServices: 'Other Home Services' }
];


export default function Overview({baseUrl, merchantReport, newDoctors, hsLicenseOrders, refreshHsLicenses, score2, merchant_tlo, merchant_cobalt, sos_status, regulatoryLinks, verifiedState}){

    var score, reasons;
    const merchant_state = get_state_name(verifiedState, regulatoryLinks)
    const onlinePresenceColumn1 = merchantReport.online_presence.slice(0,5)
    const onlinePresenceColumn2 = merchantReport.online_presence.slice(5)
    const { apiPost, apiGet} = useApi()
    const [showLicenseModal, setShowLicenseModal] = useState(false)
    const [licenseInquiry, setLicenseInquiry] = useState(defaultLicenseInquiry)
    const [awaitingSubmission, setAwaitingSubmission] = useState(false)
    const [licenseVerificationEnabled, setLicenseVerificationEnabled] = useState(false)
    const { setError } = useContext(ErrorsContext)

    useEffect(() => {
        apiGet(`lender/license-verification-enabled`).then(data => {
            if ('license_verification_enabled' in data){
                setLicenseVerificationEnabled(data.license_verification_enabled)
            }
        }).catch( response => {
            console.log(response)
        })
    }, [])
    const printVersion = !!(new URLSearchParams(window.location.search).get("printVersion"))

    function submitLicenseInquiry(licenseInquiry){
        let tempLicenseInquiry = { ...licenseInquiry };
        tempLicenseInquiry.merchant_id = merchantReport.merchant.id
        // Regular expression pattern to match the first instance of a UUID
        const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
        tempLicenseInquiry = replaceEmptyStringsWithNull(tempLicenseInquiry)
        setAwaitingSubmission(true)
        apiPost('merchant/submit-license-verification-order', tempLicenseInquiry)
            .then(data => { 
                if (!!data.status){
                    toast('License Verification Submitted for ' + "'" + licenseInquiry.licensee_name + "'")
                }else{
                    toast('License Verification Submission Failed')
                }
            }).finally(() => {
                setAwaitingSubmission(false)
                setLicenseInquiry(defaultLicenseInquiry)
            });
        
        refreshHsLicenses(merchantReport)
    }

    function setLicenseInquiryValue(key) {
        return (ev) => {
            const value = ev.target.value;
            setLicenseInquiry((prevState) => ({
                ...prevState,
                [key]: value,
                ...(key === 'licensee_category' && { licensee_subcategory: '' }) // Reset subcategory if category changes
            }));
        }
    }

    function handleClear(ev){
        ev.preventDefault()
        setLicenseInquiry(defaultLicenseInquiry)
    }

    function handleSubmit(ev){
        ev.preventDefault()

        var isLicenseFormValid = true

        if (!!licenseInquiry.licensee_subcategory === false){
            const errorContent = (
                <Fragment>
                    The Licensee Subcategory is required to submit License Verification.
                </Fragment>
            );
            isLicenseFormValid = false
            setError({ userMessage: errorContent })
        }

        if (!!licenseInquiry.licensee_category && licenseInquiry.licensee_category === 'homeServices'){
            if (!!licenseInquiry.licensee_name === false || !!licenseInquiry.licensee_state === false || !!licenseInquiry.licensee_id === false){
                const errorContent = (
                    <Fragment>
                        The Licensee Name, License State, and License ID are required to submit a Home Services License Verification.
                    </Fragment>
                );
                isLicenseFormValid = false
                setError({ userMessage: errorContent })
            }
        }

        if (isLicenseFormValid) {
            submitLicenseInquiry(licenseInquiry)
        }else {
            setShowLicenseModal(false)
        }
    }

    function getAllDoctorLicenses(doctors) {
        let finalArray = []
        let deduped = dedupeDoctors(doctors)

            // Use a Map to store unique chicken objects by their id
        const uniqueDoctorIds = new Map();

        // Loop through each JSON object in the list
        deduped.forEach(jsonObject => {
            // Check if the jsonObject has a doctor_licenses field and it's an array
            if (Array.isArray(jsonObject.doctor_licenses)) {
                // Loop through each chicken object
                jsonObject.doctor_licenses.forEach(license => {
                    // Check if the license has an id
                    if (license.id !== undefined &&
                        Array.isArray(license.verifications) && 
                    license.verifications.length > 0) {
                        // If this license id isn't in our Map, or if it is but the current license has more/different data, update the Map
                        if (!uniqueDoctorIds.has(license.id) || 
                            JSON.stringify(uniqueDoctorIds.get(license.id)) !== JSON.stringify(license)) {
                            uniqueDoctorIds.set(license.id, license);
                        }
                    }
                });
            }
        });
        return Array.from(uniqueDoctorIds.values());

    }

    if(!!score2){
        if(score2.curr_score !== null){
            score = parseInt(score2.curr_score);
        }
        reasons = score2.curr_reason_codes;
    }

    if (!merchantReport) return <div>No Report Data</div>

    return (
        <div className="box1">
            <div>
                <h3 className="h3NoTop"> <img src={vRiskLogo} alt="vRisk"/> Risk Score </h3>
                {(score) ?
                    <ScoreGauge score2={score} reasons={reasons}></ScoreGauge>
                    :
                    <div>The vRisk Risk Score is not available for this business.</div>
                }
                {merchantReport.online_presence && 
                <>
                    <h3> Online Presence {onlineSourceReportedClosed(merchantReport) ? <img style={{"verticalAlign": "bottom"}}src={exclamationMark}></img> : ''} </h3>
                    {merchantReport.online_presence.length !== 0 ? 
                        <div className="online-presence">
                            <div className="online-presence-child">
                            {onlinePresenceColumn1.map(source =>
                            <>                                
                                <span><b>{source.alias === 'Angi Biz' ? 'Angi: ' : source.alias + ': '} </b>                          
                                    {source.permanently_closed === true ? <><span style={{color: "red"}}> <b>Closed</b></span><span> as of {source.permanently_closed_date !== null ? <FormatDate date={source.updated_at}></FormatDate> 
                                    : <FormatDate date={source.permanently_closed_date}></FormatDate>}</span> </> : source.oldest_review && Object.entries(source.oldest_review).length !== 0 ? <span>Oldest Review on <FormatDate date={source.oldest_review.review_dt}></FormatDate> </span> : 'No Online Reviews '} <span></span>
                                </span>
                                {source.alias === 'BBB' && source.business_started !== null ? <span>- Business Opened on <FormatDate date={source.business_started}></FormatDate></span> : ''}
                                <br></br>
                            </>

                            )}
                            </div>
                            <div className="online-presence-child">
                            {onlinePresenceColumn2.map(source =>
                                <>
                                    <span className='bRight'><b>{source.alias === 'Angi Biz' ? 'Angi: ' : source.alias + ':'} </b>                       
                                        {source.permanently_closed === true ? <><span style={{color: "red"}}> <b>Closed</b></span><span> as of {source.permanently_closed_date !== null ? <FormatDate date={source.updated_at}></FormatDate> 
                                    : <FormatDate date={source.permanently_closed_date}></FormatDate>}</span></>
                                        : source.oldest_review && Object.entries(source.oldest_review).length !== 0 ? <span>Oldest Review on <FormatDate date={source.oldest_review.review_dt} ></FormatDate></span> : 'No Online Reviews '} <span className='online-presence-column2'></span>
                                    </span>
                                    {source.alias === 'BBB' && source.business_started !== null ? <span>- Business Opened on <FormatDate date={source.business_started}></FormatDate></span> : ''}

                                    <br></br>
                                </>
                                )}
                            </div>
                        </div>
                        :
                        <div>
                            <p>No Public Sources found for this business.</p>
                        </div>}
                </>
                }
                <h3 className="h3CorpFiling"> 
                    <span className='override-loading-spinner'>
                        <LoadingSpinner status={sos_status} width="15" height="15" activateTT={true} text="We are retrieving Corporate Filing Data" moveRight="-3px"/>
                    </span>
                    Corporate Overview 
                </h3>
                    <div className='outer'>
                        {(merchant_cobalt)
                            ? <><span className='bLeft'><b>Legal Business Name:</b> <>{(merchant_cobalt.name) ? merchant_cobalt.name : 'None Found'}</></span><span className='bRight'><b>Filing State:</b><>  {(merchant_cobalt.filing_state) ? merchant_cobalt.filing_state : 'None Found'}</></span><br/>
                            <span className='bLeft'><b>DBA:</b> None Found</span><span className='bRight'><b>Filing Number:</b><>  {(merchant_cobalt.sos_id) ? merchant_cobalt.sos_id : 'None Found'}</></span><br/>
                            <span className='bLeft'><b>Entity Type:</b> <>{(merchant_cobalt.entity_type) ? merchant_cobalt.entity_type : 'None Found'}</></span><span className='bRight'><b>Filing Date:</b><>  {(merchant_cobalt.filing_date) ? merchant_cobalt.filing_date : 'None Found'}</></span><br/>
                            <span className='bLeft'><b>Status:</b><>  {(merchant_cobalt.status) ? merchant_cobalt.status : 'None Found'}</></span><span className='bRight'><b>Expiration Date:</b><>  {(merchant_cobalt.inactive_date) ? merchant_cobalt.inactive_date : 'None Found'}</></span><br/>
                            <span className='bLeft'><b>Date Last Seen:</b><>  {(merchant_cobalt.updated_at) ? dateFormatter(merchant_cobalt.updated_at) : 'None Found'}</></span><span className='bRight'><b>Industry:</b><>  {(merchant_cobalt.industry) ? merchant_cobalt.industry : 'None Found'}</></span><br/>
                            <br/>
                            {merchant_cobalt.mailing_addr1 && <><span><b>Mailing Address</b></span> {!merchant_cobalt.physical_addr1 && <br/>}</>} {merchant_cobalt.physical_addr1 && <><span className='bRight'><b>Physical Address</b></span><br/></>}
                            {merchant_cobalt.mailing_addr1 && <><span><b>Street Address:</b> {merchant_cobalt.mailing_addr1}</span> {!merchant_cobalt.physical_addr1 && <br/>}</>} {merchant_cobalt.physical_addr1 && <><span className='bRight'><b>Street Address:</b> {merchant_cobalt.physical_addr1}</span><br/></>}
                            {merchant_cobalt.mailing_addr1 && <><span><b>City, State Zip:</b> {merchant_cobalt.mailing_city}, {merchant_cobalt.mailing_state} {merchant_cobalt.mailing_zip5} </span> {!merchant_cobalt.physical_addr1 && <><br/><br/></>}</>} {merchant_cobalt.physical_addr1 && <><span className='bRight'><b>City, State Zip:</b> {merchant_cobalt.physical_city}, {merchant_cobalt.physical_state} {merchant_cobalt.physical_zip5}</span><br/><br/></>}
                            <span><b>Phone:</b><>  {(merchant_cobalt.phone) ? merchant_cobalt.phone : 'None Found'}</></span><br/>
                            <span><b>Email:</b><>  {(merchant_cobalt.email) ? merchant_cobalt.email : 'None Found'}</></span><br/></>

                            : <><span className='bLeft'><b>Legal Business Name:</b> <>{(merchant_tlo.CORP_FILING_001) ? merchant_tlo.CORP_FILING_001 : 'None Found'}</></span><span className='bRight'><b>Filing State:</b><>  {(merchant_tlo.CORP_FILING_008) ? merchant_tlo.CORP_FILING_008 : 'None Found'}</></span><br/>
                            <span className='bLeft'><b>DBA:</b> None Found</span><span className='bRight'><b>Filing Number:</b> None Found</span><br/>
                            <span className='bLeft'><b>Entity Type:</b> <>{(merchant_tlo.CORP_FILING_005) ? merchant_tlo.CORP_FILING_005 : 'None Found'}</></span><span className='bRight'><b>Filing Date:</b><>  {(merchant_tlo.CORP_FILING_002) ? merchant_tlo.CORP_FILING_002 : 'None Found'}</></span><br/>
                            <span className='bLeft'><b>Status:</b><>  {(merchant_tlo.CORP_FILING_006) ? merchant_tlo.CORP_FILING_006 : 'None Found'}</></span><span className='bRight'><b>Expiration Date:</b> None Found</span><br/></>
                        }
                        <br/>
                    </div>

                    <div className='outer'>
                        <div>
                            {verifiedState != '' && 
                                <>
                                    <ExternalLink href={`${appConfig.signoutUri}regulatory-search/all/?state=${merchant_state}&use_case=Business+Registration`}target='_blank'>
                                        View {merchant_state} Business Registration Searches
                                    </ExternalLink>
                                    <br></br><br></br>
                                </>
                            }

                            <ExternalLink href={`${appConfig.signoutUri}regulatory-search/all/?use_case=Business+Registration`}target='_blank'>
                                View All State Business Registration Searches
                            </ExternalLink>
                        </div>
                    </div>
                
                    <h3 className="header">
                        <span className="header-content">Professional Licensing</span>
                        {(licenseVerificationEnabled && newDoctors?.length === 0) && <button className="header-button" onClick={() => {setShowLicenseModal(true)}}>Submit License</button>}
                    </h3>
                {!printVersion && hsLicenseOrders.length > 0 &&
                <div className="carousel-div-container">
                    <HomeServicesLicenseCarousel data={hsLicenseOrders} lvEnabled={licenseVerificationEnabled}/>
                </div>
                }
  
                {!printVersion && getAllDoctorLicenses(newDoctors).length > 0 &&
                    <div className="carousel-div-container">
                        <MedicalLicenseCarousel data={getAllDoctorLicenses(newDoctors)} lvEnabled={licenseVerificationEnabled}/>
                    </div>
                }

                {printVersion && 
                <>
                    
                    {hsLicenseOrders.length > 0 && 
                        <div className='blur-text-container'>
                            <h2>License Verification Orders <div className="block-meter rank-unknown is-small">{hsLicenseOrders.length}</div></h2>
                            <div className={`blur-container ${!licenseVerificationEnabled ? 'blurred' : ''}`}>
                                <div className="slider-container">
                                        {hsLicenseOrders.map((item, index) => (
                                            <div key={index} className="slider-card">
                                                <span><b>Licensee Name:</b> {item.licensee_name}</span>
                                                <span className='rightIndent'><b>Verification Status:</b> {item.mesh_order_status}</span><br></br>
                                                <span className='license-flex'>
                                                    <b>License ID:</b> {item.licensee_id} 
                                                    <LicenseLabelBadge order_labels={item?.mesh_order_labels} style={{ padding: '0 10px' }}/>
                                                </span>
                                                <span className='rightIndent'><b>Verification Date:</b> {item.updated_at}</span><br></br>
                                                <span><b>License Category:</b> {item.mesh_licensee_category}</span>
                                                <span className='rightIndent'><b>License Expiration:</b> {item.mesh_license_exp_date}</span><br></br>
                                                <span><b>License Status:</b> {item.mesh_license_status}</span>
                                                <span className='rightIndent'><b>License Issue Date:</b> {item.judge_name}</span><br></br>
                                                <span><b>Order Status:</b> {item.mesh_item_status}</span>
                                            </div>
                                        ))}
                                </div>
                                <br></br>
                            </div>
                            {!licenseVerificationEnabled && <div className="overlay-text">Reach Out to Enable Verification Details</div>}
                        </div>
                    }

                    {getAllDoctorLicenses(newDoctors).length > 0 && 
                        <div className='blur-text-container'>
                            <h2>License Verification Orders</h2>
                            <div className={`blur-container ${!licenseVerificationEnabled ? 'blurred' : ''}`}>
                                <div className="slider-container">
                                    {getAllDoctorLicenses(newDoctors)?.map((license, index) => (
                                        license.verifications?.map((item, index) => (
                                            <div key={index} className="slider-card">
                                                <span><b>Licensee Name:</b> {license.name}</span>
                                                <span className='rightIndent'><b>Verification Status:</b> {item.mesh_order_status}</span><br></br>
                                                <span><b>NPI ID:</b> {license?.npi_id?.length === 10 ? license.npi_id : ''}</span>
                                                <span className='rightIndent'><b>Verification Date:</b> {item.updated_at}</span><br></br>
                                                <span className='license-flex'>
                                                    <b>License ID:</b> {item.licensee_id} 
                                                    <LicenseLabelBadge order_labels={item?.mesh_order_labels} style={{ padding: '0 10px' }}/>
                                                </span>
                                                <span className='rightIndent'><b>License Status:</b> {item.mesh_license_status}</span><br></br>
                                                <span><b>State:</b> {license.license_number_state_code}</span>
                                                <span className='rightIndent'><b>Adverse Action Status:</b> {item?.mesh_license_adverse_action_status} </span><br></br>
                                                <span><b>Taxonomy:</b> {license.license_taxonomy_code}</span>
                                                <span className='rightIndent'><b>License Issue Date:</b> {item.mesh_license_issue_date}</span><br></br>
                                                <span className='rightIndent'><b>License Expiration:</b> {item.mesh_license_exp_date}</span><br></br>
                                                <span className='rightIndent'><b>License Category:</b> {item.mesh_licensee_category}</span><br></br>
                                                <span className='rightIndent'><b>Order Status:</b> {item.mesh_item_status}</span><br></br>
                                            </div>
                                        ))
                                    ))}
                                </div>
                                <br></br>
                            </div>
                            {!licenseVerificationEnabled && <div className="overlay-text">Reach Out to Enable Verification Details</div>}
                        </div>
                    }
                </>
                }

                {(merchantReport.lender_merchants.reduce(
                    (total, lm) => lm.lender_license ? total + lm.lender_license.length : total, 0
                 ) === 0) ?
                    <>
                        <div>Other Professional Licensing data is not available for this business.</div>
                    </>
                    :
                    <>
                        <table>
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Issuer</th>
                                <th>Holder</th>
                                <th>Number</th>
                                <th>Issue Date</th>
                                <th>Expiration Date</th>
                                <th>Status</th>
                                <th>Source</th>
                            </tr>
                            </thead>
                            <tbody>
                            {merchantReport.lender_merchants.map(lm =>
                                lm.lender_license &&
                                lm.lender_license.map((license, index) =>
                                    <tr className={'row-' + (index + 1) % 2} key={index}>
                                        <td>{license.license_type}</td>
                                        <td>{formatIssuer(license)}</td>
                                        <td>{license.license_owner_type}</td>
                                        <td>{license.license_number}</td>
                                        <td><FormatDate date={license.issue_date}/></td>
                                        <td><FormatDate date={license.expiration_date}/></td>
                                        <td>
                                            <div className={"partnership-status " + statusClass(license)}>
                                                {license.license_status}
                                            </div>
                                        </td>
                                        <td><DisplayLender alias={lm.lender}/></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                }
                <br></br>
                <div>
                {verifiedState != '' && 
                <>
                    <ExternalLink href={`${appConfig.signoutUri}regulatory-search/all/?state=${merchant_state}&use_case=Professional+Licensing`}target='_blank'>
                        View {merchant_state} Professional Licensing Searches
                    </ExternalLink>
                    <br></br><br></br>
                </>
                }
                <ExternalLink href={`${appConfig.signoutUri}regulatory-search/all/?use_case=Professional+Licensing`}target='_blank'>
                    View All State Professional Licensing Searches
                </ExternalLink>
                </div>
                <h3>Business Watch List {merchant_tlo.WATCH_LIST_SUMMARY_002 !== null && merchant_tlo.WATCH_LIST_SUMMARY_002 > 0 ? 
                <div className="block-meter rank-worst is-small">{merchant_tlo.WATCH_LIST_SUMMARY_002}</div> : ''}</h3>
                <div>
                    <b>Number of Watch Lists Business Appears On:</b>{(merchant_tlo.WATCH_LIST_SUMMARY_002) ?
                    <>
                    {merchant_tlo.WATCH_LIST_SUMMARY_002}
                    </>
                    :
                    <span>  0</span>
                    }
                </div>
            </div>
            <div className="license-submission-modal">
                {showLicenseModal &&
                    <Modal>
                        <div className='modal-header-button'>
                            <h1>License Verification Submission</h1>
                            <button onClick={() => {setShowLicenseModal(false); setLicenseInquiry(defaultLicenseInquiry)}}>Close</button>
                        </div>
                        <form onSubmit={handleSubmit} onReset={handleClear}>
                            <div className="field field-license-name">
                                <label htmlFor="licensee-name">Licensee Name</label>
                                <input type="text" id="licensee-name" value={licenseInquiry.licensee_name} onChange={setLicenseInquiryValue('licensee_name')} />
                            </div>
                            <div className="field field-license-addr">
                                <label htmlFor="licensee-address">Street Address</label>
                                <input type="text" id="licensee-address" value={licenseInquiry.licensee_addr1} onChange={setLicenseInquiryValue('licensee_addr1')} />
                            </div>
                            <div className="field field-license-city">
                                <label htmlFor="licensee-city">City</label>
                                <input type="text" id="licensee-city" value={licenseInquiry.licensee_city} onChange={setLicenseInquiryValue('licensee_city')} />
                            </div>
                            <div className="field field-license-state">
                                <label htmlFor="licensee-state">State</label>
                                <input type="text" id="licensee-state" value={licenseInquiry.licensee_state} onChange={setLicenseInquiryValue('licensee_state')} />
                            </div>
                            <div className="field field-license-zip">
                                <label htmlFor="licensee-zip">Zip</label>
                                <input type="text" id="licensee-zip" value={licenseInquiry.licensee_zip5} onChange={setLicenseInquiryValue('licensee_zip5')} />
                            </div>
                            <div className="field field-licensee-id">
                                <label htmlFor="licensee-id">License ID</label>
                                <input type="text" id="licensee-id" value={licenseInquiry.licensee_id} onChange={setLicenseInquiryValue('licensee_id')} />
                            </div>
                            <div className="field field-license-category">
                                <label htmlFor="licensee-category">License Category</label>
                                <select id="licensee-category" value="homeServices" disabled>
                                    <option value="homeServices">Home Services</option>
                                </select>
                            </div>
                            <div className="field field-license-subcategory">
                                <label htmlFor="licensee-subcategory">Licensee Subcategory</label>
                                <select id="licensee-subcategory" value={licenseInquiry.licensee_subcategory} onChange={setLicenseInquiryValue('licensee_subcategory')}>
                                    <option value="">Select a subcategory</option>
                                    {subcategories.map((subcategory, index) => {
                                        const key = Object.keys(subcategory)[0];
                                        return <option key={index} value={key}>{subcategory[key]}</option>;
                                    })}
                                </select>
                            </div>
                            <footer>
                                <button disabled={awaitingSubmission} type="submit" className="primary">Submit</button>
                                <button type="reset">Clear</button>
                            </footer>
                        </form>
                    </Modal>
                }
            </div>
       </div>
    )
}

function HomeServicesLicenseCarousel({data, lvEnabled}) {


    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>License Verification Orders <div className="block-meter rank-unknown is-small">{data.length}</div></h2>
        <div className='blur-text-container'>
            <div className={`blur-container ${!lvEnabled ? 'blurred' : ''}`}>
                <div className="slider-container">
                            <Slider {...settings}>
                                        {data.map((item, index) => (
                                            <div key={index} className="slider-card">
                                                <span><b>Licensee Name:</b> {item.licensee_name}</span>
                                                <span className='rightIndent'><b>Verification Status:</b> {item.mesh_order_status}</span><br></br>
                                                <span className='license-flex'>
                                                    <b>License ID:</b> {item.licensee_id} 
                                                    <LicenseLabelBadge order_labels={item?.mesh_order_labels} style={{ padding: '0 10px' }}/>
                                                </span>
                                                <span className='rightIndent'><b>Verification Date:</b> {item.updated_at}</span><br></br>
                                                <span><b>License Category:</b> {item.mesh_licensee_category}</span>
                                                <span className='rightIndent'><b>License Expiration:</b> {item.mesh_license_exp_date}</span><br></br>
                                                <span><b>License Status:</b> {item.mesh_license_status}</span>
                                                <span className='rightIndent'><b>License Issue Date:</b> {item.judge_name}</span><br></br>
                                                <span><b>Order Status:</b> {item.mesh_item_status}</span>
                                            </div>
                                        ))}
                            </Slider>

                    <div className="slideCounter">
                        <span>{slideIndex} / {totalSlides}</span>
                    </div>
                </div>
            </div>
            {!lvEnabled && <div className="overlay-text">Reach Out to Enable Verification Details</div>}
        </div>
    </div>
    )
}

function MedicalLicenseCarousel({data, lvEnabled}) {


    const [slideIndex, setSlideIndex] = useState(1);
    const totalSlides = data.length
    var settings = {
        fade:true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (current, next) => setSlideIndex(next + 1)

    };
    return (
    <div>
    <h2>License Verification Orders <div className="block-meter rank-unknown is-small">{data.length}</div></h2>
        <div className='blur-text-container'>
            <div className={`blur-container ${!lvEnabled ? 'blurred' : ''}`}>
                <div className="slider-container">
                    <Slider {...settings}>
                                    {data.map((license, index) => (
                                        license.verifications.map((item, index) => (
                                            <div key={index} className="slider-card">
                                                <span><b>Licensee Name:</b> {license.name}</span>
                                                <span className='rightIndent'><b>Verification Status:</b> {item.mesh_order_status}</span><br></br>
                                                <span><b>NPI ID:</b> {license?.npi_id?.length === 10 ? license.npi_id : ''}</span>
                                                <span className='rightIndent'><b>Verification Date:</b> {item.updated_at}</span><br></br>
                                                <span className='license-flex'>
                                                    <b>License ID:</b> {item.licensee_id} 
                                                    <LicenseLabelBadge order_labels={item?.mesh_order_labels} style={{ padding: '0 10px' }}/>
                                                </span>
                                                <span className='rightIndent'><b>License Status:</b> {item.mesh_license_status}</span><br></br>
                                                <span><b>State:</b> {license.license_number_state_code}</span>
                                                <span className='rightIndent'><b>Adverse Action Status:</b> {item?.mesh_license_adverse_action_status} </span><br></br>
                                                <span><b>Taxonomy:</b> {license.license_taxonomy_code}</span>
                                                <span className='rightIndent'><b>License Issue Date:</b> {item.mesh_license_issue_date}</span><br></br>
                                                <span className='rightIndent'><b>License Expiration:</b> {item.mesh_license_exp_date}</span><br></br>
                                                <span className='rightIndent'><b>License Category:</b> {item.mesh_licensee_category}</span><br></br>
                                                <span className='rightIndent'><b>Order Status:</b> {item.mesh_item_status}</span><br></br>

                                            </div>
                                        ))
                                    ))}
                    </Slider>
                    <div className="slideCounter">
                        <span>{slideIndex} / {totalSlides}</span>
                    </div>
                </div>
            </div>
            {!lvEnabled && <div className="overlay-text">Reach Out to Enable Verification Details</div>}
        </div>
    </div>
    )
}