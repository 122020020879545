import React from 'react'
import MerchantCompaintDetails from './complaint-panel/MerchantCompaintDetails'
import MerchantComplaintAlertWords from './complaint-panel/MerchantComplaintAlertWords'
import FormatDate from 'shared/FormatDate'
import ExternalLink from "../../../shared/ExternalLink"
import appConfig from "../../../app-config.json"

import {useRef, useEffect, useState} from 'react';

function get_state_name(state_abbrev,links) {
    let state_name;
    for(let i =0; i<links.length; i++) {
        if(state_abbrev === links[i].state_abbrev) {
            state_name = links[i].state_name
        }
    }
    return state_name
}

function hasConsumerComplaintsLink(state, links) {

    // state_name = get_state_name(verifiedState, links)
    for(let i =0; i<links.length; i++) {
        if(state === links[i].state_name) {
            return true
        }
    }
    return false
}

function populateConsumerComplaintLinks(link) {
    return link['use'].includes('Consumer Complaint')
}


export default function({merchantReport, score, regulatoryLinks, verifiedState}){
    const bbb_complaints_summary = merchantReport.bbb_complaints_summary
    const regulatory_complaint_details = merchantReport.regulatory_complaint_details
    const merchant_state = get_state_name(verifiedState, regulatoryLinks)
    const consumerComplaintLinks = regulatoryLinks.filter(populateConsumerComplaintLinks)
    const cc_link_exists = hasConsumerComplaintsLink(merchant_state, consumerComplaintLinks)

    const terms = [
        // {name: 'thirty', description: 'Last 30 days'},
        {name: 'threemo', description: 'Last 3 months'},
        {name: 'sixmo', description: 'Last 6 months'},
        {name: 'twelvemo', description: 'Last 12 months'},
        {name: 'twoyear', description: 'Last 2 years'},
        {name: 'ever', description: 'Last 3+ years'}
    ]

    const no_bbb_data_message = 'We did not find a profile for this merchant on BBB.'

    const map = new Map(Object.entries(bbb_complaints_summary[terms[0].name]))
    const bbb_complaint_types = Array.from(map.keys()).filter(x => !['new', 'relative', 'cumulative'].includes(x)).sort()
    const bbb_merchants = merchantReport.lender_merchants.filter(x => x.lender_type === 'BBB')
    const bbb_merchant = (bbb_merchants.length > 0) ? bbb_merchants[0] : null

    const accumulator = (total_resolutions, resolution) => total_resolutions + Number(resolution.count)
    const total_resolutions = merchantReport.bbb_resolutions.reduce(accumulator, 0)

    const alertComplaintRef = useRef(null);

    const [ complaintRendered, setComplaintRendered ] = useState(false);

    const componentRendered = (childData) => {
        setComplaintRendered(childData)
    }

    const scrollToAlertComplaints = () => {
        return alertComplaintRef.current?.scrollIntoView({ behavior: 'smooth' });
      };

    useEffect(() => {
        var hash = window.location.hash.split('#').pop();
        if (hash == 'alert-word-complaint'){
            let delay = 1000;
            setTimeout(function() {
                scrollToAlertComplaints();
            }, delay)
        }
    }, [complaintRendered]);

    return (
        <div className="box">
            <div className="score-line">
                    {score && (<>
                        <h2 className="label">{score.description}</h2>
                    </>)}
            </div>

            <h3>Total Complaints from Regulatory Body Sources ({regulatory_complaint_details ? regulatory_complaint_details.length : 0})</h3>
            {regulatory_complaint_details && regulatory_complaint_details.length > 0 ?
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Source</th>
                        <th>Action</th>
                        <th>Thread ID</th>
                    </tr>
                </thead>
                <tbody>
                { regulatory_complaint_details && regulatory_complaint_details.map((complaint, index) =>
                    <>
                    <tr className={'row-' + (index + 1) % 2} key={index}>
                        <td><FormatDate date={complaint.complaint_dt} /></td>
                        <td>{complaint.description}</td>
                        <td>{isNaN(complaint.type) ? complaint.type : 'Unknown'}</td>
                        <td>{complaint.resolved_status}</td>
                        <td>{complaint.account_id.slice(0, 8).toUpperCase()}</td>
                    </tr>
                    </>
                )}
                </tbody>
            </table>
            : <p>There are no reported complaints for this merchant from regulatory bodies (State Attoneys General, CFPB, FTC).</p>
            }
            <br></br>
            <div>
                {verifiedState !== '' && cc_link_exists && 
                <>                    
                <ExternalLink href={`${appConfig.signoutUri}regulatory-search/all/?state=${merchant_state}&use_case=Consumer+Complaint`}target='_blank'>
                View {merchant_state} Consumer Complaint Searches
                </ExternalLink>
                <br></br><br></br>
                </>
                }
            </div>

            <div>
            <ExternalLink href={`${appConfig.signoutUri}regulatory-search/all/?use_case=Consumer+Complaint`}target='_blank'>
                                View All State Consumer Complaint Searches
                            </ExternalLink>
            </div>

            <h3>BBB Complaint Overview</h3>
            {bbb_merchant ? 
            <>
            <p>BBB Rating: {bbb_merchant.rating}</p>
            <p>BBB Accredited: {bbb_merchant.accredited ? 'Yes' : 'No'}</p>
            <p>BBB Profile Link: {bbb_merchant.url}</p>

            <table>
                <thead>
                    <tr>
                        <th>Term</th>
                        <th>New Complaints</th>
                        <th>Cumulative Complaints</th>
                        {/* <th>Relative Percentage Change</th> */}
                        { bbb_complaint_types.map(type => 
                            <th key={type}>{type}</th>
                        )}    
                    </tr>
                </thead>
                {bbb_complaints_summary && (
                <tbody>
                { terms.map((term, index) =>
                    <tr className={'row-' + (index + 1) % 2} key={index}>
                        <td>{term.description}</td>
                        <td>{bbb_complaints_summary[term.name]['new']}</td>
                        <td>{bbb_complaints_summary[term.name]['cumulative']}</td>
                        {/* <td>{bbb_complaints_summary[term.name]['relative'] && bbb_complaints_summary[term.name]['relative'].toLocaleString("en", {style: "percent", minimumFractionDigits: 2})}</td> */}
                        { bbb_complaint_types.map(type => 
                            <td key={type}>{bbb_complaints_summary[term.name][type]}</td>
                        )}    
                    </tr>
                )}
                </tbody>
                )}
            </table>
            </>
            : <p>{no_bbb_data_message}</p>
            }

            <h3>BBB Resolution Status</h3>
            {bbb_merchant ? 
            <table>
                <thead>
                    <tr>
                        {merchantReport.bbb_resolutions && merchantReport.bbb_resolutions.map(s => 
                            <th key={s.resolved_status}>{s.resolved_status}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    <tr className={'row-0'}>
                        {merchantReport.bbb_resolutions && merchantReport.bbb_resolutions.map(s => 
                            <th key={s.resolved_status}>{s.count}</th>
                        )}
                    </tr>
                    {(total_resolutions > 0) && 
                    <tr className={'row-0'}>
                        {merchantReport.bbb_resolutions && merchantReport.bbb_resolutions.map(s => 
                            <th key={s.resolved_status}>{(s.count / total_resolutions).toLocaleString("en", {style: "percent", minimumFractionDigits: 2})}</th>
                        )}
                    </tr>
                    }
                </tbody>
            </table>
            : <p>{no_bbb_data_message}</p>
            }

            <MerchantCompaintDetails merchantId={merchantReport.merchant.id}
                bbb_merchant={bbb_merchant} no_bbb_data_message={no_bbb_data_message}/>

            <div ref={alertComplaintRef}>
                <MerchantComplaintAlertWords  parentCallback={componentRendered} alertWordIds={merchantReport.alert_word_complaints} merchantId={merchantReport.merchant.id}
                    bbb_merchant={bbb_merchant} no_bbb_data_message={no_bbb_data_message}/>
            </div>
       </div>
    )
}