import React from 'react'

import './Loading.scss'

export default function Loading({text='Loading...'}){
    return (
        <div className='loading'>
            <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {text}
        </div>
    )
}
