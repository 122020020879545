import React, { useContext, useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import SessionContext from 'SessionContext'
import ErrorsContext from "./ErrorsContext";
import { signOutCognitoSession } from 'utils/cognitoUtils'
import useApi from './hooks/useApi'
import appConfig from 'app-config.json'
import ExternalLink from "shared/ExternalLink"




import logo from 'assets/verdata_hires_logo.png'
import './Header.scss'

export default withRouter(Header)
function Header({location}){
  const session = useContext(SessionContext)
  const { error, setError } = useContext(ErrorsContext)
  const [displayError, setDisplayError] = useState(null);
  const [licenseVerificationEnabled, setLicenseVerificationEnabled] = useState(false)
  const { apiPost, apiGet} = useApi()
  const [upcomingDeployments, setUpcomingDeployments] = useState([])
  const [deploymentDetail, setDeploymentDetail] = useState([])
  const [showModal, setShowModal] = useState(true);
  const { user } = useContext(SessionContext)


  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAcknowledgeDeployments = () => {
    let payload = {
      "user_id": user.id
    }
    apiPost('user/acknowledge-deployments', payload).then(
    ).catch( response => {
        console.log(response)
    }).finally(setShowModal(false))
  };



  function inSection(name){
    return location.pathname.toLowerCase().indexOf('/'+name.toLowerCase()) === 0
  }

  function activeIf(section){
    return inSection(section) ? 'active' : ''
  }

  function clearErrors(){
    setError(null)
    setDisplayError(null)
  }

  useEffect(() => {
        apiGet(`lender/license-verification-enabled`).then(data => {
            if ('license_verification_enabled' in data){
                setLicenseVerificationEnabled(data.license_verification_enabled)
            }
        }).catch( response => {
            console.log(response)
        })
    }, [])

  useEffect(() => {
    if (error) {
      setDisplayError(error);
      const timer = setTimeout(() => {
        setDisplayError(null);
      }, 5000); // Set the display time for 5 seconds

      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    apiGet(`deployment/get_upcoming`).then(data => {
      setUpcomingDeployments(data)
    }).catch( response => {
        console.log(response)
    })
}, [])

  useEffect(() => {
    if (upcomingDeployments.length !== 0) {
      let deployment_id = upcomingDeployments[0]['id']
      apiGet(`deployment/${deployment_id}`).then(data => {
        setDeploymentDetail(data)
  
      }).catch( response => {
          console.log(response)
      })
    }
  }, [upcomingDeployments])


  function switchLender(ev){
    const lenderName = ev.target.value
    const lender = session.user.lenders.find(l => l.name === lenderName)
    if(lender) session.switchLender(lender)
  }

  const username = session && session.user ? `${session.user.first_name} ${session.user.last_name}` : 'Anonymous'
  const lender = localStorage.getItem('lender')
  const parseLender = JSON.parse(lender)

  const lenders = session && session.user && session.user.lenders

  return <>
      <header className="Header">
        <Link to="/" className="Header-logo">
          <img src={logo} alt="Verdata" />
        </Link>
        {session.user?.is_active &&
        <>
          <nav className="Header-nav">
            <ul>
              <li>
                <Link to="/onboarding" className={activeIf('onboarding')}>Onboarding</Link>
              </li>
              <Link to="/license" className={activeIf('license')}
                style={{ color: licenseVerificationEnabled ? '' : '#c6c6c6', pointerEvents: licenseVerificationEnabled ? 'auto' : 'none' }}
                onClick={(e) => {if (!licenseVerificationEnabled) {e.preventDefault();}}}>
                License Verification
              </Link>
              <li>
                <Link to="/healthcheck" className={activeIf('healthcheck')}>Portfolio HealthCheck</Link>
              </li>
              <li>
                <Link to="/notifications" className={activeIf('notifications')}>Notifications</Link>
              </li>
            </ul>
          </nav>
          <nav className="Header-menu">
            <Link to="/settings" className={activeIf('settings')}><i className="fa fa-cog"></i> Settings</Link>
            <menu className='expanding'>
              <span className='user-info'>
                <span className="username">{username}</span>
                <span className="lendername">{lender && parseLender['name']}</span>
              </span>
              <ul className={lenders ? 'has-lender-switcher' : ''}>
                {lenders &&
                    <li>
                      <div className='field'>
                        <input type='text' placeholder='Switch Lender' list='lender-list'
                               onChange={switchLender}/>
                        <datalist id='lender-list'>
                          {lenders.map( lender => <option key={lender.name}>{lender.name}</option>)}
                        </datalist>
                      </div>
                    </li>
                }
                <li>
                  <button className='primary' onClick={signOutCognitoSession}>Signout</button>
                </li>
              </ul>
            </menu>
          </nav>
        </>
        }
        
        { displayError && (
            <div className='page-error'>
              <span>
                { error.message === 'Failed to fetch' && "API connection failed!" }
                { (error.status >= 500 && error.status < 600) && "Server Error" }
                { <div>{ error.status } { error.statusText }</div> }
                { error.userMessage }
              </span>
              <button href='#' onClick={clearErrors} className='clear'>
                <i className='fa fa-times' />
              </button>
            </div>
        )}
      </header>
      <header>
      {session.user.acknowledged_deployment === false && upcomingDeployments.length > 0 && deploymentDetail.length > 0 &&
        <>      
          <div className='deployment-banner'>
            <DeploymentModal show={showModal} onClose={handleCloseModal} acknowledge={handleAcknowledgeDeployments} deployments={upcomingDeployments} deployment_detail={deploymentDetail}>
            </DeploymentModal>
          </div>
        </>
      }
      </header>


    </>
}

const DeploymentModal = ({ show, onClose, deployments, acknowledge, deployment_detail}) => {
  let currDeployment = deployments[0]
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3 className="upcoming-release-text">Upcoming Release Notice</h3>
        <p className="scheduled-text"><i>Scheduled after hours on {currDeployment.planned_date}</i></p>
        {/* <p className="Changes"><b>Changes:</b></p>
        {deployment_detail && deployment_detail.map((detail, index) =>
        <div>
          <span className="high-level-text">{detail.high_level_text}</span><br></br>
          <span>-{detail['detailed_text']}</span><br></br>
          <br></br>
        </div>

        )} */}
          {/* <p><a href={apiUrl.origin} target='_blank' rel="noopener noreferrer">API Documentation</a></p> */}
          {/* <ExternalLink href={`${appConfig.signoutUri}regulatory-search/all/?use_case=Professional+Licensing`}target='_blank'> */}
          <div className="view-release-details">
          <ExternalLink href={`${appConfig.signoutUri}settings/deployments`} target='_blank'>
          View Release Details
          </ExternalLink>
          </div>

          <br></br>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
        <button className="acknowledge-button" onClick={acknowledge}>
          Acknowledge changes and don't show me this again
        </button>
      </div>
    </div>
  );
};