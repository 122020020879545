import React from 'react'
import { Link } from 'react-router-dom'
import AssociatedMerchant from './AssociatedMerchant'
import FormatAddress from 'shared/FormatAddress'
import {score2colorName} from 'utils'

import './AssociationsPanel.scss'

export function inPortfolioStyle(association){

    if (association.in_portfolio === true) {
        return {backgroundColor:"yellow", display: "inline"}
    } else {
        return {}
    }
}


export default function({merchantReport, baseUrl, score, vRisk}){
    return (
        <>
        <div className="box">
            <div className="score-line">
                <h2 className="label">{score.name}</h2>
                <p style={{backgroundColor:"yellow", display: "inline"}}><i>*Associated businesses that are in your portfolio are highlighted in yellow</i></p>
            </div>

            {(!merchantReport.flagged_related_merchants ||
                    !merchantReport.related_merchants ||
                    merchantReport.flagged_related_merchants === 0 ||
                    merchantReport.related_merchants === 0
            ) ?

                <>
                    No Associations are available for this business.
                </>
                :
                <>
                    <RelatedSection baseUrl={baseUrl} associations={merchantReport.flagged_related_merchants} flagged={true} vRisk={vRisk}/>
                    <RelatedSection baseUrl={baseUrl} associations={merchantReport.related_merchants} flagged={false} vRisk={vRisk}/>
                </>
            }

        </div>


        {((merchantReport.parents && merchantReport.parents.length > 0) || 
            (merchantReport.children && merchantReport.children.length > 0) || 
            (merchantReport.siblings && merchantReport.siblings.length > 0)) && 

            <>
            &nbsp;
            <div className="box">
                <div className="score-line">
                    <h2 className="label">Parents/Siblings</h2>
                </div>

                <ListSection baseUrl={baseUrl} associations={merchantReport.parents} preLabel='Parent' postLabel=''/>
                <ListSection baseUrl={baseUrl} associations={merchantReport.children} preLabel='Child' postLabel=''/>
                <ListSection baseUrl={baseUrl} associations={merchantReport.siblings} preLabel='Sibling' postLabel=''/>
            </div>
            </>
        }
        </>
    )
}

function ListSection({associations, baseUrl, preLabel, postLabel}) {
    if (!associations || associations.length === 0) return null

    return (
        <>
            <h3>
                {associations.length}&nbsp;
                {preLabel} {associations.length === 1 ? 'Record' : 'Records'} {postLabel}
            </h3>

            <div className="associations">
            { associations.map( (association, index) =>
                <AssociatedMerchant association={association} baseUrl={baseUrl} key={index} />
            )}
            </div>
        </>
    )
}

function RelatedSection({associations, baseUrl, flagged, vRisk}) {
    if ((!associations || associations.length === 0) && flagged) return null
    let current_category = ''

    return (
        <>
            {(flagged)
                ? (associations.length === 1) ? <h3>1 High Risk Association Issue</h3> : <h3>{associations.length} High Risk Association Issues</h3>
                : <h3>Associations ({associations.length})</h3>}
            
            <div className="associations">
            { associations.map( (association, index) => 
                
                <div key={index}>
                    {(current_category !== association.category) && <h4>{current_category = association.category}</h4>}
                    {association.reason}&nbsp;
                    
                    <Link to={`${baseUrl}/${association.related_merchant.id}`}>
                        <span className="label upper" style={inPortfolioStyle(association)}>{association.related_merchant.name}</span>
                    </Link> <FormatAddress loc={association.related_merchant} /> <br/>
                    {vRisk.filter(score => score.merchant_id === association.related_merchant.id).map( (score2, index) =>
                    <div key={index} style={{color:score2colorName(score2.curr_score)}}><b>vRisk Risk Score:</b>&emsp;<span>{score2.curr_score}</span></div>
                    )}
                </div>
            )}
            </div>
        </>
    )
}

