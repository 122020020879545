import React, { useEffect, useState } from 'react'
import useApi from 'hooks/useApi'
import FormatDate from 'shared/FormatDate'
import Loading from 'shared/Loading'

import './MerchantServiceFeedbackDetails.scss'
export default function MerchantStarFeedbackDetails({
       merchantId,
       service: { rating },
       setRenderComplete,
}){
    const { apiGet } = useApi()
    const [ page, setPage ] = useState(0)
    const [ feedback, setFeedback ] = useState(null)
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        setLoading(true)
        // TODO: do we want to pass through the inquiryID for tracking/authorization purposes?
        apiGet('merchant-service-feedback', { merchantId, rating, page: page+1 })
            .then( response => {
                const prevResults = feedback ? feedback.results : []
                const prevIds = new Set(prevResults.map(r => r.id))
                setLoading(false)
                setFeedback({
                    ...response,
                    results: [ ...prevResults, ...response.results.filter(r => !prevIds.has(r.id)) ]
                })
                if (setRenderComplete) {
                    setRenderComplete(true)
                }
            } )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId, rating, page])

    if ( feedback === null ) return <Loading />

    // This gives us a class name like service-facebook or service-yelp
    // to trigger service-specific styling on
    const hasMore = feedback.results.length < feedback.count

    return (
        <div className={`merchant-service-feedback`}>
            { feedback.results.length === 0
                ? <span>No Reviews</span>
                : feedback.results.map(e => <FeedbackEntry feedback={e} key={e.id} />)
            }
            { (loading || hasMore) && (
                <footer>
                    { loading && <Loading /> }
                    { (!loading && hasMore) && (
                        <div className='pager-more'>
                            <button className='load-more'
                                    onClick={() => setPage(page+1)}>See More Reviews</button>
                        </div>
                    )}
                </footer>
            )}
        </div>
    )
}

function FeedbackEntry({feedback}){

    // This gives us a class name like rating-5 or rating-recommended
    // to trigger styling on
    const feedbackRatingClassName = 'rating-' + feedback.rating
    const ratingNumber = Number(feedback.rating)
    const ratingPercent = 100 * ( ratingNumber / 5 )
    const serviceClassName = 'service-' + feedback.lender.toLowerCase().replace(/[^a-z0-9_]/gi, '')

    return (
        <div className={`feedback-entry ${serviceClassName}`}>
            <div className='feedback-date'>
                <span>Date: </span><FormatDate date={feedback.review_dt} />
            </div>
            <div className='feedback-name'>
                <span>Source: </span><span>{feedback.lender}</span>
            </div>
            <div className='feedback-name'>
                <span>Name: </span><span>{feedback.name}</span>
            </div>
            <div className='feedback-text'>{feedback.review_text}</div>
            {/* <div className='feedback-text'><ReadMore text={feedback.review_text} /></div> */}
            <div className={`feedback-rating ${feedbackRatingClassName}`}
                 style={{'--percent': `${ratingPercent}%`}}>
                <span className='rating-text'>{feedback.rating}</span>
                <div className='rating-stars'>
                    <div className={`star ${ratingNumber > 0 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 1 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 2 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 3 ? 'star-on' : 'star-off'}`} />
                    <div className={`star ${ratingNumber > 4 ? 'star-on' : 'star-off'}`} />
                </div>
            </div>
        </div>
    )
}
