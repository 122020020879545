import React, { useEffect, useState, useContext} from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import {useLocation} from "react-router-dom";
import useApi from 'hooks/useApi'
import Loading from 'shared/Loading'
import DatePicker from "react-datepicker";
import SessionContext from "../../SessionContext";

import "./datepicker.css";
import './UsageManager.scss'


export default function UsageManager({}){
    const { apiGet} = useApi()
    const [ months, setMonths ] = useState(11)
    const [ results, setResults ] = useState(null)
    const [ loading, setLoading ] = useState(true)
    const [ headers, setHeaders ] = useState([])
    const { user } = useContext(SessionContext)
    const [ users, setUsers ] = useState([])
    const [ inquiryTableCSV, setInquiryTableCSV ] = useState([])
    const [ searchTableCSV, setSearchTableCSV ] = useState([])

    let totalInquiries = 0
    const [userList, setUserList ] = useState([{}])
    const [userTarget, setUserTarget] =  useState('')
    const [userMetrics, setUserMetrics] = useState(null)
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const queryParams = new URLSearchParams(useLocation().search)
    const lender = localStorage.getItem('lender')
    const parseLender = JSON.parse(lender)
    const lenderId =  lender && parseLender['id']


    useEffect(()=> {
        setLoading(true)
        let params = keyValuePairsToObject(queryParams.entries())
        params = {...params, 'lenders__id': lenderId}
        apiGet('user', params).then( users => {
            setUserList(users.results)
            if(users.results.length > 0){
                setUserTarget(users.results[0].id)
            }
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=> {
        apiGet('usage/inquiries', {"months": months})
            .then( res => {
                if (Object.keys(res).length !== 0){
                    setResults(res)
                    setInquiryTableCSV([])
                }

        })
    }, [months])

    useEffect(()=> {
        if(results !== null){
            let temp_headers = ["Name"]
            for (let i = 0; i < 12; i++)
                temp_headers.push(results[i]['date'])
            temp_headers.push("User Total")
            setHeaders(temp_headers)

            let temp_users = []
            let num_users = Object.keys(results[0]['users']).length
            for (let i = 0; i < num_users; i++){
                temp_users.push(results[0]['users'][`user_${i}`]['name'])
            }
            setUsers(temp_users)
        }
    }, [results])

    useEffect(()=> {
        if(users.length !== 0 && headers.length !== 0){
            let temp_data = [headers]

            let temp_row = []
            users.map((u_key, u_index) => {
                temp_row.push(u_key)

                results.map((r_key, r_index) => {
                    temp_row.push(r_key['users'][`user_${u_index}`]['inquiry_count'])
                })

                let row_count = 0
                temp_row.map((key, value) => {
                    if(!isNaN(key)) {
                        row_count = row_count + key
                    }
                })

                temp_row.push(row_count)
                temp_data.push(temp_row)
                temp_row = []
            })

            temp_row = ["All Users"]
            results.map((key, index) => {
                temp_row.push(getColTotal(key['users']))
            })

            let row_count = 0
            temp_row.map((key, value) => {
                if(!isNaN(key)) {
                    row_count = row_count + key
                }
            })

            temp_row.push(row_count)
            temp_data.push(temp_row)

            setInquiryTableCSV(temp_data)
        }
    }, [users, headers])

    function getRowTotal(user) {
        let count = 0
        results.map((key, index) => {
            count = count + key['users'][`user_${user}`]['inquiry_count']
        })
        totalInquiries = totalInquiries + count
        return count
    }

    function getColTotal(record) {
        let count = 0
        let num_users = Object.keys(results[0]['users']).length
        for (let i = 0; i < num_users; i++){
            count = count + record[`user_${i}`]['inquiry_count']
        }

        return count
    }

    function keyValuePairsToObject(pairs){
        const o = {}
        for( const [key, value] of pairs) {
            o[key] = value
        }
        return o
    }

    function renderTableHeaders() {
        return headers.map((key, index) => {
            return <th key={index}>{key}</th>
        })
    }

    function renderTableData() {
        return users.map((u_key, u_index) => {
            return (
                <tr key={u_key}>
                    <td style={{borderRight: '1px solid #e7e7e7'}}>{u_key}</td>
                    {renderUserInquiries(u_index=u_index)}
                    {renderUserTotal(u_index=u_index)}
                </tr>
            )
                
        })
    }

    function renderUserInquiries(u_index) {
        return results.map((r_key, r_index) => {
            return(<td key={r_key['date']}  style={{textAlign:'center'}}>{r_key['users'][`user_${u_index}`]['inquiry_count']}</td>)
        })
    }

    function renderUserTotal(u_index) {
        return(
            <td key={u_index} style={{textAlign:'center', borderLeft: '1px solid #e7e7e7'}}>{getRowTotal(u_index)}</td>
        )
    }

    function renderTotals() {
        return (
            <tr key="totals" style={{borderTop: '2px solid #e7e7e7'}}>
                <td style={{borderRight: '2px solid #e7e7e7'}}>All Users</td>
                {results.map((key, index) => {
                    return(
                        <td key={key['date']} style={{textAlign:'center'}}>{getColTotal(key['users'])}</td>
                    )
                })}
                <td key="total" style={{textAlign:'center', borderLeft: '2px solid #e7e7e7'}}>{totalInquiries}</td>
            </tr>
        )
    }

    function getUserMetrics(ev){
        ev.preventDefault()
        let params = {
            user_id: userTarget,
            start_date: startDate.toISOString().split("T")[0],
            end_date: endDate.toISOString().split("T")[0]
        }
        apiGet('usage/user-metrics', params).then( res => {
            setUserMetrics(res)
            let temp_data = [["Created At","Business","EIN","Street Addr","City","State","Zip","Principal","Merchant Reports"]]
            res.map((key, index) => (
                temp_data.push([key['created_at'],key['inquiry_business_name'],key['inquiry_ein'],key['inquiry_addr1'],key['inquiry_city'],key['inquiry_state'],key['inquiry_zip5'],key['inquiry_principal_name'],key['num_inquiry_results']])
            ))
            setSearchTableCSV(temp_data)
        })
    }

    const hasUsers = userList && userList.length > 0

    if ( loading ) return  (
        <>
        <h1>Usage</h1>
        <Loading />
        </>
    )

    return (
        <>
        <div style={{marginBottom:'10px'}}>
            <header style={{justifyContent:'space-between'}} >
                <h1>Usage</h1>
                {(results !== null) && (
                    <CSVLink className='csv-link' data={inquiryTableCSV} asyncOnClick={true} filename='Inquiry_Usage.csv'>
                    <i className="fa fa-download" aria-hidden="true" style={{marginRight:'5px'}}/>
                    Download CSV
                    </CSVLink>
                )}
            </header>
            {(results === null) && (
                <div>
                    <p>No Users Found</p>
                </div>
            )}
            {(results !== null) && (
                <div>
                    <table className='inquiry-table'>
                        <thead>
                            <tr>
                                {renderTableHeaders()}
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableData()}
                            {renderTotals()}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
        <div>
            <header style={{justifyContent:'space-between'}} >
                <h1>User Metrics</h1>
                {(userMetrics && userMetrics.length >= 1) && (
                    <CSVLink className='csv-link' data={searchTableCSV} asyncOnClick={true} filename='User_Metrics.csv'>
                    <i className="fa fa-download" aria-hidden="true" style={{marginRight:'5px'}}/>
                    Download CSV
                    </CSVLink>
                )}
            </header>
            {(loading || !hasUsers) && ( loading && <Loading />)} 
            {(hasUsers) && (
                <div>
                    <form onSubmit={getUserMetrics}>
                        <header style={{justifyContent:'space-between'}} >
                            <select onChange={ev => setUserTarget(ev.target.value)}>
                                {userList.map((user) => (
                                <option value={user.id} key={user.id}>
                                    {user.first_name + ' ' + user.last_name}
                                </option>
                                ))}
                            </select>
                            <DatePicker selectsRange={true} startDate={startDate} endDate={endDate} placeholderText="Pick Date Range" onChange={(update) => {setDateRange(update);}} withPortal />
                            <button disabled={(!startDate || !endDate || !userTarget)} className="primary">Generate User Metrics</button>
                            {(!userMetrics) && (<p>0 results</p>)}
                            {(userMetrics && userMetrics.length >= 1) && (<p>{userMetrics.length} Results</p>)}
                            {(userMetrics && userMetrics.length == 0) && (<p>0 Results</p>)}
                        </header>
                    </form>
                    {(userMetrics && userMetrics.length == 0) && (
                        <div>
                            <p>No Inquiries Found</p>
                        </div>
                    )}
                    {(userMetrics && userMetrics.length >= 1) && (
                        <table className='inquiry-table'>
                            <thead>
                                <tr>
                                    <th style={{textAlign:'center'}}>Created At</th>
                                    <th style={{textAlign:'center'}}>Business</th>
                                    <th style={{textAlign:'center'}}>EIN</th>
                                    <th style={{textAlign:'center'}}>Street Addr</th>
                                    <th style={{textAlign:'center'}}>City</th>
                                    <th style={{textAlign:'center'}}>State</th>
                                    <th style={{textAlign:'center'}}>Zip</th>
                                    <th style={{textAlign:'center'}}>Principal</th>
                                    <th style={{textAlign:'center'}}>Merchant Reports</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userMetrics.map((key, index) => (
                                    <tr key={key['inquiry_id']}>
                                        <td style={{textAlign:'center'}}>{key['created_at']}</td>
                                        <td style={{textAlign:'center'}}>{key['inquiry_business_name']}</td>
                                        <td style={{textAlign:'center'}}>{key['inquiry_ein']}</td>
                                        <td style={{textAlign:'center'}}>{key['inquiry_addr1']}</td>
                                        <td style={{textAlign:'center'}}>{key['inquiry_city']}</td>
                                        <td style={{textAlign:'center'}}>{key['inquiry_state']}</td>
                                        <td style={{textAlign:'center'}}>{key['inquiry_zip5']}</td>
                                        <td style={{textAlign:'center'}}>{key['inquiry_principal_name']}</td>
                                        <td style={{textAlign:'center'}}>{key['num_inquiry_results']}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>)}
                </div>
            )}
            {(!loading && !hasUsers) && (
                <div>
                    <p>No Users Found</p>
                </div>
            )}
        </div>
        </>
    )
}
