import React, { useEffect, useState, useContext } from 'react'
import useApi from 'hooks/useApi'
import FormatDate from 'shared/FormatDate'
import Loading from 'shared/Loading'
import SessionContext from "../../SessionContext";
import useDebouncedEffect from '../../hooks/useDebouncedEffect';
import toast from 'react-hot-toast'
import appConfig from "../../app-config.json";


import './UserManager.scss'

export default function UserManager({queryParams}){
    const { apiGet, apiPost, apiDelete } = useApi()
    const { user } = useContext(SessionContext)
    const lender_admin = user.groups.some(g => g.name === 'Lender Admin')
    const verdata_admin = user.is_staff

    const [ addingUser, setAddingUser ] = useState(false)
    const [ users, setUsers ] = useState(undefined)
    const [ refreshList, setRefreshList ] = useState(0)
    const [ userSearch, setUserSearch ] = useState('')
    const [ userType, setUserType ] = useState('')
    // for user add form
    const [ first_name, setFirstName ] = useState('')
    const [ last_name, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ type, setType ] = useState(lender_admin ? 'Lender Admin' : 'Verdata Admin')
    const [ lender, setLender ] = useState(lender_admin ? user.lenders[0].id : '')
    const [ lenders, setLenders ] = useState(undefined)
    const [ loading, setLoading ] = useState(false)
    const [ editUser, setEditUser ] = useState(null)

    // Lender Admins should never see Verdata staff
    function filterAdmins(isLenderAdmin, usersList) {
        let newList = usersList

        if (isLenderAdmin === true) {
            newList = newList.filter(user => user['is_staff'] === false)
        }
        return newList
    }

    useDebouncedEffect(()=> {
        setLoading(true)
        let params = keyValuePairsToObject(queryParams.entries())
        if (lender_admin) params = {...params, 'lenders__id': user.lenders[0].id}
        if (userSearch) params = {...params, search: userSearch}
        if (userType) {
            if (userType === 'Verdata Admin')
                params = {...params, 'is_superuser': true}
            else
                params = {...params, 'groups__name': userType}
        }
        apiGet('user', params).then( users => {
            setUsers(filterAdmins(lender_admin,users.results))
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshList, queryParams, userSearch, userType])

    useEffect(()=> {
        !lender_admin && apiGet('lender').then( lenders => setLenders(lenders.results) )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lender_admin])

    function openAddUserForm(){
        setAddingUser(true)
    }

    function closeAddUserForm(ev){
        ev && ev.preventDefault()
        setAddingUser(false)
    }

    function resetAddUserForm(){
        setFirstName('')
        setLastName('')
        setEmail('')
        setLender(lender_admin ? user.lenders[0].id : '')
        setType(lender_admin ? 'Lender Admin' : 'Verdata Admin')
    }

    function addUser(ev){
        ev.preventDefault()
        if (!(first_name && last_name && email && type)) {
            alert('First Name, Last Name, Email and User Type are all required.')
            return
        }
        if (['Lender Admin', 'Lender User'].indexOf(type) > -1 && !lender){
            alert('Lender is required for Lender Admin and Lender User users.')
            return
        }
        let acknowledged_deployment = false
        apiPost('user/new', {
            first_name, last_name, email, type, lender, acknowledged_deployment
        }).then(()=> {
            closeAddUserForm()
            resetAddUserForm()
            setRefreshList(refreshList+1)
        })
    }
    
    function sendPasswordResetEmail(user) {
        // ev.preventDefault()
        toast('Email to reset password Sent!')
        const payload = {
            "email": user.email,
            "first_name": user.first_name,
            "last_name": user.last_name,
        };
        apiPost('user/adminpasswordreset', payload).then(result=>{
            console.log(result)
        }).catch(err => {
            console.log(err.message)
        })
    }


    function removeUser(user){
        const deleteConfirmed = window.confirm(`Are you sure you want to delete user: ${user.email}?`)
        if (!deleteConfirmed) return
        apiDelete(`user/${user.id}`)
            .then(()=> {
                setRefreshList(refreshList+1)
            })
    }

    function deactivateUser(user){
        const confirmed = window.confirm(`Are you sure you want to deactivate user: ${user.email}?`)
        if (!confirmed) return
        apiPost(`user/${user.id}`, {is_active: false})
            .then(()=> {
                setRefreshList(refreshList+1)
            })
    }

    function activateUser(user){
        const confirmed = window.confirm(`Are you sure you want to re-activate user: ${user.email}?`)
        if (!confirmed) return
        apiPost(`user/${user.id}`, {is_active: true})
            .then(()=> {
                setRefreshList(refreshList+1)
            })
    }

    // Can't seem to find a way to get these dynamically from AWS user pool config
    // const passwordRequirements = ' - Password must contain a lower case letter\n' +
    //     ' - Password must contain an upper case letter\n' +
    //     ' - Password must contain a special character\n' +
    //     ' - Password must contain a number\n' +
    //     ' - Password must contain at least 12 characters'
    // function resetUserPassword(user) {
    //     const password = window.prompt(`Please enter a temporary password.\n${passwordRequirements}`)
    //     if (!password) return
    //     apiPost(`user/${user.id}`, {action: 'reset-password', password: password})
    //         .then((response)=> {
    //             if (response.success) {
    //                 window.alert(`The temporary password for ${user.email} has been set.`)
    //             } else {
    //                 window.alert(`Failed to set a temporary password for ${user.email}\n\n${response.error.Message}`)
    //             }
    //         })
    // }

    function closeEditUserForm(wasChanged) {
        setEditUser(null)
        if(wasChanged) setRefreshList(refreshList + 1)
    }

    const hasUsers = users && users.length > 0

    return <div className='user-manager'>
        <form onSubmit={addUser}>
            <header>
                <h1>Users</h1>
                <i className="spacer" />
                { addingUser && (
                    <>
                        <button className="inverted" onClick={closeAddUserForm}>Cancel</button>
                        <button className="primary">Save</button>
                    </>
                )}
                { !addingUser && (
                    <button className="primary" onClick={openAddUserForm}>Add User</button>
                )}
            </header>
            { addingUser && (
                <>
                <div className="add-user-form">
                    <div className="field user-name-field">
                        <label htmlFor="user-first-name">First Name</label>
                        <input type="text" id="user-first-name" autoFocus
                                value={first_name} onChange={ev => setFirstName(ev.target.value)} />
                    </div>

                    <div className="field user-name-field">
                        <label htmlFor="user-last-name">Last Name</label>
                        <input type="text" id="user-last-name"
                                value={last_name} onChange={ev => setLastName(ev.target.value)} />
                    </div>

                    <div className="field user-email-field">
                        <label htmlFor="user-email">Email</label>
                        <input type="email" id="user-email"
                               value={email} onChange={ev => setEmail(ev.target.value)} />
                    </div>

                    <div className="field user-type-field">
                        <label htmlFor="user-type">User Type</label>
                        <select id="user-type" value={type} onChange={ev => setType(ev.target.value)}>
                            { !lender_admin && <option>Verdata Admin</option> }
                            <option>Lender Admin</option>
                            <option>Lender User</option>
                        </select>
                    </div>

                    { !lender_admin && type.indexOf('Lender') === 0 && (
                        <div className="field user-lender-field">
                            <label htmlFor="user-lender">Lender</label>
                            <select id="user-type" value={lender} onChange={ev => setLender(ev.target.value)}>
                                <option></option>
                                {lenders.map(lender =>
                                    <option value={lender.id} key={lender.id}>{lender.name}</option>
                                )}
                            </select>
                        </div>
                    )}
                </div>
                <div className="edit-user-note">By clicking save, Verdata will automatically send an invitation via email with a username and temporary password.</div>
                </>
            )}
        </form>
        <table>
            <thead>
            <tr>
                <th>
                    <div className='flex-row'>
                        User
                        <input type='search'
                               value={userSearch}
                               onChange={ev => setUserSearch(ev.target.value)}
                               placeholder='search' />
                    </div>
                </th>
                <th>
                    <div className='flex-row'>
                        Type
                        <select
                            value={userType}
                            onChange={ev => setUserType(ev.target.value)}
                            style={userType ? {} : {color: '#767676'}}>
                            <option value=''>filter</option>
                            <option>Verdata Admin</option>
                            <option>Lender Admin</option>
                            <option>Lender User</option>
                        </select>
                    </div>
                </th>
                <th>Last Login</th>
                <th className='slim'></th>
            </tr>
            </thead>
            <tbody>
            { (loading || !hasUsers) && (
                <tr><td colSpan={4} className='message'>
                    { loading && <Loading /> }
                    { (!loading && !hasUsers) && "No Users Found" }
                </td></tr>
            )}
            { hasUsers && users.map( user =>
                editUser === user ? (
                    <tr className='stripe' key={user.id}>
                        <td colSpan={4}>
                            <EditUserForm user={user} close={closeEditUserForm}/>
                        </td>
                    </tr>
                ) : (
                    <tr key={user.id} className={user.is_active ? 'is-active' : 'is-inactive'}>
                        <td>
                            { (user.first_name || user.last_name) && <>{user.first_name} {user.last_name}<br /></> }
                            <span className='select-all nowrap'>{user.email}</span>
                            { !user.is_active  && <><br />(deactivated)</>}
                        </td>
                        <td>
                            <span className='nowrap'>{ userTypeText(user) }</span><br />
                            { !user.is_staff && user.lenders && user.lenders.map(l => l.name).join(', ')}
                        </td>
                        <td><FormatDate date={user.last_login} /></td>
                        <td className="actions">
                            <menu className='expanding'>
                                <span>actions</span>
                                <ul>
                                    <li>
                                        <button className='inverted' onClick={() => setEditUser(user)}>
                                            Edit <i className="fa fa-pencil"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button className='inverted' onClick={() => sendPasswordResetEmail(user)}>
                                            Reset Password <i className="fa fa-key" />
                                        </button>
                                    </li>
                                    { user.is_active &&
                                    <li>
                                        <button className="inverted" onClick={()=> deactivateUser(user)}>
                                            Deactivate <i className="fa fa-ban" />
                                        </button>
                                    </li>
                                    }
                                    { !user.is_active &&
                                    <li>
                                        <button className="inverted" onClick={()=> activateUser(user)}>
                                            Re-Activate <i className="fa fa-check-circle-o" />
                                        </button>
                                    </li>
                                    }
                                    <li>
                                        <button className="inverted" onClick={()=> removeUser(user)}>
                                            Remove <i className="fa fa-trash" />
                                        </button>
                                    </li>
                                </ul>
                            </menu>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
        <div className="admin-note">Your user type is {(lender_admin || verdata_admin) ? 'admin' : 'user'}. Only admins are authorized to add and remove users.</div>
    </div>
}

function EditUserForm({user, close}) {
    const [formData, setFormData] = useState({})
    const { apiPost } = useApi()
    // const { user: currentUser } = useContext(SessionContext)
    // const lender_admin = currentUser.groups.some(g => g.name === 'Lender Admin')

    useEffect(() => {
        setFormData({
            first_name: user.first_name,
            last_name: user.last_name,
            type: user.type
        })
    }, [user])

    function onSubmit(ev) {
        ev.preventDefault()
        apiPost(`user/${user.id}`, formData)
            .then(() => close(true))
    }

    function updateFormData(newData) {
        setFormData({...formData, ...newData})
    }

    return (
        <form onSubmit={onSubmit} className='edit-user-form'>
            <div className="field user-first-name-field">
                <label htmlFor="edit-user-first-name">First Name</label>
                <input type="name" id="edit-user-first-name" autoFocus
                       value={formData.first_name || ''} onChange={ev => updateFormData({first_name: ev.target.value})}/>
            </div>
            <div className="field user-last-name-field">
                <label htmlFor="edit-user-last-name">Last Name</label>
                <input type="name" id="edit-user-last-name"
                       value={formData.last_name || ''} onChange={ev => updateFormData({last_name: ev.target.value})}/>
            </div>
            {/* TODO: finish mapping this out so you can change the user type (group)
            <div className="field user-type-field">
                <label htmlFor="edit-user-type">User Type</label>
                <select id="edit-user-type" value={formData.type} onChange={ev => updateFormData({type: ev.target.value})}>
                    { !lender_admin && <option>Verdata Admin</option> }
                    <option>Lender Admin</option>
                    <option>Lender User</option>
                </select>
            </div>
            */}

            <i className='spacer'/>
            <button className="inverted" onClick={() => close(false)}>Cancel</button>
            <button className="primary" type='submit'>Save</button>
        </form>
    )
}

function keyValuePairsToObject(pairs){
    const o = {}
    for( const [key, value] of pairs) {
        o[key] = value
    }
    return o
}

function userTypeText(user){
    if (user.is_staff) return 'Verdata Admin'
    if (user.lenders.length > 0 && user.groups.length === 1) return user.groups[0].name
    return 'Invalid'
}