import React, { useState } from 'react'
import useApi from 'hooks/useApi'

import './ComplianceAgreement.scss'

export default function ComplianceAgreement({onAccepted}){

    const [ accepted, setAccepted ] = useState(false)
    const { apiPost } = useApi()

    function toggleAccepted(){
        setAccepted(!accepted);
    }

    function acceptAgreement(){
        apiPost('user/accept-inquiry-terms').then(() => {
            onAccepted()
        })
    }

    return (
        <div className='compliance-agreement'>
            <h1>Important Note</h1>

            <p>
                Welcome to Verdata's Onboarding Tool
            </p>

            <p>
                Please agree to these conditions each day before proceeding.
            </p>

            <p>
                The Underwriting tool may not be used to solicit new accounts. You
                are under contract to use Verdata's Underwriting Tool only to look
                up information on merchants for which you have received a signed
                application. To this end, all accounts are monitored for suspicious
                activity. If suspicious activity is detected, an audit will ensue
                which may lead to your loss of access to Verdata's network.
            </p>

            <p className='checkbox-field'>
                <input type='checkbox' id='accept-agreement'
                       checked={accepted} onChange={toggleAccepted}
                />
                <label htmlFor='accept-agreement'>
                    I will use Underwriting Tool exclusively for merchants
                    after I have recently (within 1 week) received a signed
                    application from the merchant.
                </label>
            </p>

            <footer>
                <button className='primary'
                        type='submit'
                        disabled={!accepted}
                        onClick={acceptAgreement}>
                    Use Underwriting Tool
                </button>
            </footer>
        </div>
    )

}