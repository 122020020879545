import React, { useEffect, useState, useRef } from "react";
import Tooltip from 'react-power-tooltip'

import "./LoadingSpinner.scss"

export default function LoadingSpinner({status, width, height, activateTT, text, moveRight}) {
    const [ circleClass, setCircleClass ] = useState(status)
    const [ showTT, setShowTT ] = useState(false)
    const [ TTText, setTTText ] = useState(text)
    const svgRectRef = useRef()

    useEffect(() => {
        if(status === "done" || status === "cancel" || status === "manual" || status === "error"){
            if(svgRectRef.current){
                svgRectRef.current.dispatchEvent(new Event('click'))
                setTimeout(() => {
                    setCircleClass(status)
                },1000)
            }
            if(status === "done"){
                setTimeout(() => {
                    setTTText("Data loaded")
                },1000)
            }
            if(status === "cancel"){
                setTimeout(() => {
                    setTTText("No data found")
                },1000)
                
            }
            if(status === "manual"){
                setTimeout(() => {
                    setTTText("Data needs manual approval")
                },1000)
                
            }
            if(status === "error"){
                setTimeout(() => {
                    setTTText("Error loading data")
                },1000) 
            }
        }
    }, [status])

    return (
        <>
        <span className='loading-spinner'>
            <Tooltip fontSize="4" zIndex='1' show={showTT} padding='0px 8px' arrowAlign="start" position="bottom left" moveRight={moveRight} textBoxWidth="auto">
                <p className="ttText">{TTText}</p>
            </Tooltip>
            <svg id={circleClass} width={width} height={height} viewBox="0 0 100 100" xmlns="<http://www.w3.org/2000/svg>">
                <circle id='circle' cx="50" cy="50" r="40" strokeWidth="10" stroke="#1997bc" fill="none"/>
                <polyline id="checkmark" points="25,55 45,70 75,33" fill="transparent" />
                <polyline id="x1" points="25,25 75,75" fill="transparent" />
                <polyline id="x2" points="25,75 75,25" fill="transparent" />
                <animate id="fade" attributeName="opacity" attributeType="CSS" from="1" to="0" dur="1s" begin="animationTrigger.click" fill="freeze"/>
                <animate id="blink" attributeName="opacity" attributeType="CSS" from="0" to="1" dur="0.5s" begin="fade.end" fill="freeze"/>
                <animate id="blink2" attributeName="opacity" attributeType="CSS" from="1" to="0" dur="0.5s" begin="blink.end" fill="freeze"/>
                <animate attributeName="opacity" attributeType="CSS" from="0" to="1" dur="0.5s" begin="blink2.end" fill="freeze"/>
                <rect ref={svgRectRef} id="animationTrigger"></rect>
                <rect width="100%" height="100%" fill="transparent" onMouseEnter={() => {if(activateTT){setShowTT(true)}}} onMouseLeave={() => {if(activateTT){setShowTT(false)}}}></rect>
            </svg>
        </span>
        </>
    )
}