import React from 'react'

import './LocationMetadata.scss'

function addressLine(loc){
    const street = [loc.addr1, loc.addr2].filter(Boolean).join(' ')
    return <>
        {street && street + ', '} 
        {loc.city && loc.city + ', '} 
        {loc.state && loc.state + ' '} 
        {loc.zip5 && loc.zip5} 
        </>
}

export default function LocationMetadata({location}){
    return (
        <div className="location-metadata">
        { location.alt_name && <>{location.alt_name}<br /></> }
        { location.addr1 && <>{addressLine(location)}<br /></> }
        </div>
    )
}
