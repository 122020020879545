import React from 'react'

import './DoctorMetadata.scss'
import ExternalLink from "./ExternalLink";

function queryLine(person){
    return [
        person.name,
        person.specialty,
        ].filter(Boolean).join('+')
}

export default function DoctorMetadata({doctor, link=false}){

    return (
        <span className="doctor-metadata">
            { link ? <ExternalLink href={`https://www.google.com/search?q=${queryLine(doctor)}`}>{doctor.name}</ExternalLink> : doctor.name }<br></br>
            NPI: {doctor.npi_id && doctor.npi_id}<br></br>
            {doctor.specialty && doctor.specialty}<br></br>
            <br></br>
        </span>
    )
}
