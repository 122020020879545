import React, {useContext, useState, useEffect} from "react";

import SessionContext from "../../SessionContext";
import appConfig from "../../app-config.json";
import useApi from "hooks/useApi"
import toast from 'react-hot-toast'
import './Deployments.scss'



export default function MyProfile(){
    const {apiGet, apiPost, apiDelete} = useApi()

    const { user } = useContext(SessionContext)

    const [ deployments, setDeployments] = useState([])
    const [ deploymentDetails, setDeploymentDetails] = useState([])

    const [showDeploymentDetails, setShowDeploymentDetails] = useState(null)

    const lender = localStorage.getItem('lender')



    const lender_admin = user.groups.some(g => g.name === 'Lender Admin')
    const verdata_admin = user.is_staff


    function closeShowDetailsTab() {
        setShowDeploymentDetails(null)
    }


    useEffect(() => {
        apiGet(`deployment/get_all`).then(data => {
          setDeployments(data)
          if (data.length > 0 && data[0].deployed === false) {
            setShowDeploymentDetails(data[0])
        }
        }).catch( response => {
            console.log(response)
        })
        apiGet(`deployment/get_all_details`).then(data => {
            setDeploymentDetails(data)

      
        }).catch( response => {
            console.log(response)
        })

    }, [])
    


    // will grab all the deployment detail rows for a specific deployment using deployment_id
    function getDeploymentDetailsById(deployment_id) {
        console.log('get deployment by id')
        let final_details = []
        for(let x=0; x<deploymentDetails.length; x++) {
            if (deploymentDetails[x].deployment_schedule_id === deployment_id) {
                final_details.push(deploymentDetails[x])
            }
        }
        return final_details
    }
    



    return (
        <div>
            <h1>Deployments</h1>
                <table>
                <thead>
                <tr>
                    <th>Release Date</th>
                    <th className='slim'></th>
                </tr>
                </thead>
                <tbody>

                {deployments.length > 0 && deployments.map((deployment, index) =>
                showDeploymentDetails === deployment ? 
                    (
                        <tr>
                            <td className="show-detail-td">
                                <ShowDetailsView deployment={deployment} deploymentDetails={getDeploymentDetailsById(deployment.id)} close={closeShowDetailsTab}/>
                            </td>

                        </tr>
                    ) : 
                    (
                        <tr>
                        <td>{deployment.planned_date}{deployment.deployed === false ? <span>&nbsp;(Upcoming)</span> : ''}</td>
                        <td>                        
                            <button className="show-detail-button" onClick={() => setShowDeploymentDetails(deployment)}>Show Details</button>
                        </td>
                        </tr>

                        
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function ShowDetailsView({ deployment, deploymentDetails, close}) {

    return (
        <div  className="deployment-detail-container">
            <p>{deployment.planned_date}{deployment.deployed === false ? <span>&nbsp;(Upcoming)</span> : ''}</p>
            {deploymentDetails && deploymentDetails.map((detail, index) =>
                <div>
                <span className="high-level-text">{detail.high_level_text}</span><br></br>
                <span className="detailed-text">-{detail.detailed_text}</span><br></br>
                <br></br>
                </div>

            )}
            <button className="close-detail-button" onClick={() => close(false)}>Close</button>

        </div>
    )

}
