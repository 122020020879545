import React, { useReducer } from "react";

import './ReadMoreHighlighter.scss'
import Highlighter from 'react-highlight-words';

function toggle(state, action) {
    return !state
}

export default function ReadMore({text, threshold=500}) {
    const [hidden, dispatch] = useReducer(toggle, true);

    const alertWords = [" Fraud ",
                        " Fraudster ",
                        " Fraudulent ",
                        " Defraud ",
                        " Elder Abuse ",
                        " Elderly Abuse ",
                        " Scam ",
                        " Scammer ",
                        " Scamming ",
                        " Scammed ",
                        " Theft ",
                        " Thief ",
                        " Thieving ",
                        " Stolen ",
                        " Stole ",
                        " Steal ",
                        " Stealing ",
                        " Out of business ",
                        " Not in business ",
                        " Misrepresent ",
                        " Misrepresentation ",
                        " Mislead ",
                        " Misleading ",
                        " Pyramid ",
                        " Sue ",
                        " Suing ",
                        " Lawsuit ",
                        " Legal ",
                        " Legal Action ",
                        " Attorney General ",
                        " Attourney general ",
                        " AG ",
                        " CFPB ",
                        " FTC ",
                        " Better Business Bureau ",
                        " BBB ",
                        " Malpractice ",
                        " Criminal ",
                        " Untruthful ",
                        " Unresponsive ",
                        " Deceptive ",
                        " Deceived ",
                        " Lied ",
                        " Collusion ",
                        " ID theft "]

    return (
        <>
            {text && 
                <>
                {hidden ? <Highlighter highlightClassName='review-highlight' searchWords={alertWords} autoEscape={true} textToHighlight={text.substring(0, threshold)}/>
                    : <Highlighter highlightClassName='review-highlight' searchWords={alertWords} autoEscape={true} textToHighlight={text}/>}&nbsp;
                {(text.length > threshold) && 
                <button className="link-button" onClick={() => dispatch()}>{hidden ? 'Read More' : 'Show Less'}</button>
                }
                </>
            }
        </>
    )
}