import React from "react"

import './MultiButtonSwitcher.scss'

export function MultiButtonSwitcher({values, value, onChange}) {
    return (
        <div className='multi-button-switcher'>
            {values.map((v) => (
                <button className={value === v ? 'active' : ''}
                        onClick={() => onChange(v)}
                        key={v}>
                    {v}
                </button>
            ))}
        </div>
    )
}