import React, { useReducer } from "react";

import ScoreInfo from 'shared/ScoreInfo'
import ScoreMeter from 'shared/ScoreMeter'
import MerchantMetadata from 'shared/MerchantMetadata'
import { Link } from 'react-router-dom'
import AlternateInfo from '../AlternateInfo'

import FormatAddress from 'shared/FormatAddress'
import {inPortfolioStyle} from './AssociationsPanel'

function toggle(state, action) {
    return !state
}

export default function AssociatedMerchant({association, baseUrl}) {
    const [hidden, dispatch] = useReducer(toggle, true);

    return (
        <div className="association" key={association.merchant.id}>

            <div className="score-line">
                
                { hidden ? (
                    <>
                        <Link to={`${baseUrl}/${association.merchant.id}`}>
                            <span className="label upper" style={inPortfolioStyle(association)}>{association.merchant.name}</span>
                        </Link>
                        <span className="pad upper"><FormatAddress loc={association.merchant} /></span>
                        <span className="link-button" onClick={() => dispatch()}>
                            <i className="fa fa-chevron-down" aria-hidden="true" />Details
                        </span>
                    </>
                ) : (
                    <>
                        <Link to={`${baseUrl}/${association.merchant.id}`}>
                            <span className="label upper">{association.merchant.name}</span>
                        </Link>
                        <span className="pad upper"></span>
                        <span className="link-button" onClick={() => dispatch()}>
                            <i className="fa fa-chevron-up" aria-hidden="true" />Collapse
                        </span>

                        {association.merchant.main_score && <>
                            <ScoreInfo score={association.merchant.main_score}
                                        rank={association.merchant.main_risk} />
                            <ScoreMeter score={association.merchant.main_score}
                                        rank={association.merchant.main_rank} /> </>}
                    </>
                )}

            </div>

            { !hidden && <>
            <MerchantMetadata merchant={association.merchant} principals={association.principals} />
            <AlternateInfo merchant={association.merchant} lender_merchants={association.lender_merchants} />
            </> }
        </div>
    )
}
